import React from "react";
import { render } from "react-dom";
import _ from "lodash";
// import { makeData } from "./Utils";

// Import React Table
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link'
import ReactTable from "react-table";
import "react-table/react-table.css";
import { postData,getData,BaseURL } from '../FetchService'
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CSV from '../management/CSV'
import ReactToPrint from 'react-to-print';
import Avatar from '@material-ui/core/Avatar';
// import { CSVLink } from "react-csv";

const useStyles = theme => ({
   paper:{padding:'30px',marginTop:'10px'},
  button: {
    margin: 0,
  },
  input: {
    display: 'none',
  },
});


class Table extends React.Component {

  constructor() {
    super();
    this.state = {
      data: [],
      pages: null,
      loading: true,
      list:[],
      columns:[],
      csvheader:[],
      refresh:'a'

    };
  }

  async componentDidUpdate(prevProps,prevState) {
    if(this.props!=prevProps){
      let body={}
      if(this.props.from!=='' || this.props.to!==''){
    body={'from':this.props.from,
      'to':this.props.to
  };
  }
  else{
    body={'from':'2000-01-01T18:30:00.000Z',
      'to':new Date()
    };
    }
   const list = await postData(`internship/displayAllBetweenDate`,body);
   this.setState({list})
   if(this.state.list==''){
   // alert("if")
  }
  else{
    // alert("else")
    this.setState({columns:Object.keys(this.state.list[0]).map((key, id)=>{
      return {
         Header: key,
         accessor: key
       }
     })})
     this.setState({csvheader:Object.keys(this.state.list[0]).map((k, id)=>{
       return {
          label: k,
          key: k
        }
      })})
  }
    console.log(`Data ${this.state.list}`)
 }

  }


  async componentDidMount() {

       const list = await getData(`internship/displayall`);
      console.log(`Data ${list}`)
        this.setState({ list });
        if(this.state.list!==[]){
        this.setState({columns:Object.keys(this.state.list[0]).map((key, id)=>{
          return {
             Header: key,
             accessor: key
           }
         })})
         this.setState({csvheader:Object.keys(this.state.list[0]).map((k, id)=>{
           return {
              label: k,
              key: k
            }
          })})
        }
      console.log(`Data ${this.state.list}`)
      }

      handleInternship=async(employerid,status)=>{
        let body={
          'employerid':employerid
        }
        if(status=='not verified'){
          var list=await postData('internship/verifyemployerbyid',body)
          if(list){
          {this.componentDidUpdate()}
            alert("Verified successfully")
          }
        }
        else{
          var list=await postData('internship/disverifyemployerbyid',body)
          if(list){
            {this.componentDidUpdate()}
            alert("Not Verified successfully")
          }
        }
      }

      checkStatus=(status,employerid)=>{
        if(status=='not verified'){
          return(<Button variant="contained" onClick={()=>{this.handleInternship(employerid,status)}} color="primary" >
            <DoneAllIcon/>
          </Button>)
        }
          else{
            return(
            <Button variant="contained" onClick={()=>{this.handleInternship(employerid,status)}} color="primary" >
              <CancelIcon/>
            </Button>)
          }

      }

  render() {
    // const { data, pages, loading } = this.state;
     const classes = useStyles()


    const columns=[
            {
              Header: "",
              accessor: "srno",
              style:{
                textAlign: 'center'
              },
              width: 40,
              maxWidth: 40,
              minWidth:40,
              filterable: false,
            },
            {
              Header: "organizationname",
              accessor: "organizationname",
              Cell: props =>{ return(
                <div>
                <Avatar src={`${BaseURL}/images/${props.original.logo}`}  /><br/>{props.original.organizationname}
                </div>)},
              style:{
                align: 'center'
              },
              width: 100,
              maxWidth: 100,
              minWidth:100
            },
            {
              Header: "emailid/Mobile",
              accessor: "",
              style:{
                textAlign: 'center'
              },
              Cell: props =>{ return(
                <div>
                {props.original.emailid}<br/>{props.original.mobile}
                </div>)},
              width: 100,
              maxWidth: 100,
              minWidth:100
            },
            {
              Header: "websiteurl",
              style:{
                textAlign: 'center'
              },
              Cell: props=>{
                return(<div><a href={`https://${props.original.websiteurl}`} target='blank'>{props.original.websiteurl}</a></div>)
              },
              width: 120,
              maxWidth: 120,
              minWidth:120
            },
            {
              Header: "primaryprofile",
              accessor: "primaryprofile",
              Cell: props=>{
                return(<div>{props.original.primaryprofile}<br/>Opening: {props.original.noofopenings}</div>)
              },
              style:{
                textAlign: 'center'
              },
              width: 100,
              maxWidth: 100,
              minWidth:100
            },
            {
              Header: "Locations",
              accessor: "internlocations",
              style:{
                textAlign: 'center'
              },
              width: 110,
              maxWidth: 110,
              minWidth:110
            },
            {
              Header: "Type",
              accessor: "typeofinternship",
              style:{
                textAlign: 'center'
              },
              width: 65,
              maxWidth: 65,
              minWidth:65
            },
            {
              Header: "Stipend",
              Cell: props=>{
                return(<div>
                  {props.original.currency} {props.original.stipend}/{props.original.duration}
                </div>)
              },
              style:{
                textAlign: 'center'
              },
              width: 100,
              maxWidth:100,
              minWidth:100
            },
            {
              Header: "applyby",
              accessor:"applyby",
              style:{
                textAlign: 'center'
              },
              width: 110,
              maxWidth: 110,
              minWidth: 110
            },
            {
              Header: "status",
              accessor: "status",
              Cell: props =>{ return(<span>
                {props.value}
                </span>
                )},
              style:{
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'center'
              },
              width: 80,
              maxWidth: 80,
              minWidth:80
            },
            {
              Header: "Update",
              Cell: props =>{ return(
                <div>
                {this.checkStatus(props.original.status,props.original.idemployer)}
                </div>)},
              filterable: false,
              sortable: false,
              width: 75,
              maxWidth: 75,
              minWidth:75
            },
          ]
    return (
      <div>
      <CSV data={this.state.list} headers={this.state.csvheader} file={'Internship'+this.props.from+this.props.to}/>
        <ReactTable
          columns= {columns}
          data={this.state.list}
          filterable
          defaultPageSize={10}
          noDataText={"Please Wait..."}
      >
        </ReactTable>
        <br />
      </div>
    );
  }
}

class TableExample extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <a href="#">Print this out!</a>}
          content={() => this.componentRef}
        />
        <Table ref={el => (this.componentRef = el)} />
      </div>
    );
  }
}

export default Table;
