import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  iconSmall: {
    fontSize: 20,
  },
  textField: {
     
    marginRight: theme.spacing.unit,
  },
  avatar: {
    margin: 5,
  },
  bigAvatar: {
    margin: 5,
    width: 100,
    height: 100,
  },
});
class  OrganizationDetails extends React.Component {
    state={
      organizationname:'',
      organizationdescription:'',
      icon:'',
      websiteUrl:'',
      facebookUrl:'',
      instagramUrl:'',
      linkedinUrl:'',
      youtubeUrl:'',
      file: "",
      

    }

    constructor(props){
      super(props)
      
      this.handleChangeIcon = this.handleChangeIcon.bind(this)
    }
    componentWillMount()
    {
      this.setState(this.props.states)
    }
    handleChangeIcon(event) {
    
      this.setState({file:event.target.files[0]});
      this.setState({icon:URL.createObjectURL(event.target.files[0])});
      this.props.states.icon=URL.createObjectURL(event.target.files[0])
      this.props.states.file=event.target.files[0]
      
  
    }
    
    render(){
      const { classes } = this.props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Organization Details
      </Typography>
       <div className={classes.root}>
        <Grid container spacing={24}>
        <Grid item xs={12}>
          <TextField 
           required 
           id = "organizationname"
           name = 'organizationname'
           value={this.state.organizationname}
           label="Organization Name"
           onChange={e=>{this.props.states.organizationname=e.target.value; this.setState({organizationname:e.target.value})}} 
           fullWidth
            />
        </Grid>
        <Grid item xs={12}>
         
           <TextField
             required 
             id="organizationdescription"
             name="organizationdescription" 
             value={this.state.organizationdescription}
           multiline
           rows="3"
           placeholder='Ex: Why you should hire for an intern? etc.'
           className={classes.textField}
           margin="normal"
           variant="outlined"
           label="Organization Description" 
           onChange={e=>{this.props.states.organizationdescription=e.target.value;this.setState({organizationdescription:e.target.value})}} 
           fullWidth 
           fullWidth
        />
        </Grid>
       
        <Grid item xs={12} sm={6}>
              Logo:<br/>
              <input
            accept="image/*"
            className={classes.input}
            id="icon"
            multiple
            name="icon"
            type="file"
            onChange={this.handleChangeIcon}
           
          />
          <label htmlFor="icon">
    
    
          <Button variant="contained" component="span" className={classes.button}>
              Upload
              <CloudUploadIcon className={classes.rightIcon}  />
            </Button>
         
          </label>
        
            </Grid>
            <Grid item xs={12} sm={6}>
            <Avatar alt="Remy Sharp" src={this.state.icon} className={classes.bigAvatar} />
             
           
            </Grid>
       
        <Grid item xs={12} md={6}>
          <TextField 
          required 
          id="websiteUrl"
          name='websiteUrl' 
          value={this.state.websiteUrl}
          label="Website URL:" 
          fullWidth
          onChange={e=>{this.props.states.websiteUrl=e.target.value;this.setState({websiteUrl:e.target.value})}}
           />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
         
          id="facebookUrl"
          name='facebookUrl' 
          value={this.state.facebookUrl}
          label="Facebook Page:" 
          fullWidth
          onChange={e=>{this.props.states.facebookUrl=e.target.value;this.setState({facebookUrl:e.target.value})}}
           />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
         
          id="linkedinUrl"
          name='linkedinUrl' 
          value={this.state.linkedinUrl}
          label="LinkedIn Page:" 
          fullWidth
          onChange={e=>{this.props.states.linkedinUrl=e.target.value;this.setState({linkedinUrl:e.target.value})}}
           />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
         
          id="youtubeUrl"
          name='youtubeUrl' 
          value={this.state.youtubeUrl}
          label="Youtube Channel Page:" 
          fullWidth
          onChange={e=>{this.props.states.youtubeUrl=e.target.value;this.setState({youtubeUrl:e.target.value})}}
           />
        </Grid>
        </Grid>
       </div>
      
    </React.Fragment>
  );
}
}
OrganizationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OrganizationDetails);