import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import { postData } from '../../FetchService';
import CircularProgress from '@material-ui/core/CircularProgress';
import RazorPay from './RazorPay';
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

// const steps = ['Shipping address', 'Payment details', 'Review your order'];
const steps = ['Shipping address', 'Review your order','Payment'];

const TAX_RATE = 0.18+0.0175;

class Checkout extends React.Component {
  state = {
    activeStep: 0,
    shipping_address:{
      first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        email: '',
        mobile: '',
        statename: '',
        city: '',
        zipcode: '',
        country:'',
        state_name:'',
        city_name:''
    },
    payment_form:{
        name_on_card:'',
        card_number:'',
        exp_number:'',
        cvv:'',
    },
    invoice_total:0,
    rows:[],
    invoiceSubtotal:0,
    invoiceTaxes:0,
    invoiceTotal:0
  };

  async componentDidMount(){
    await this.FillRow()
  }

  priceRow(qty, unit) {
    return qty * unit;
  }

  createRow1(id, row) {
    const qty=1
    const price=row.price
    const setorg=row.setorg
    const amount = this.priceRow(qty, price);
    return { id, setorg,qty, price,amount };
  }

  async FillRow()
    { let rows=[]
      for ( var i = 0, len = localStorage.length; i < len; ++i ) {
        console.log("set no:"+localStorage.key(i))
        if(localStorage.key(i)!='user'){
        let r=JSON.parse(localStorage.getItem(localStorage.key( i )))
        console.log("r:"+r)
        rows.push(r)}
      }
      rows=rows.map((row, id) => this.createRow1(id, row));
     await this.setState({rows})
      setTimeout(()=>{
        this.setValuesTotal()
      },2000)
    
    }


  subtotal(items) {
    return items.map(({ amount }) => amount).reduce((sum, i) => sum + i, 0);
  }


  setValuesTotal=()=>{
    this.setState({invoiceSubtotal: this.subtotal(this.state.rows)})
    this.setState({invoiceTaxes :TAX_RATE * this.state.invoiceSubtotal}) 
    this.setState({invoiceTotal : Math.round(this.state.invoiceTaxes + this.state.invoiceSubtotal)*100}) 
  }








   

 
   CircularIndeterminate=()=>{
  return (
    <div>
      <CircularProgress className={this.props.classes.progress} color="secondary" />
    </div>
  );
}

incrementfunction(){
  this.setState(state => ({
    activeStep: state.activeStep + 1,
  }))
}

changeValue=(value)=>{
  this.setState({invoice_total:value})
}

getStepContent(step) {
  switch (step) {
    case 0:
      return <AddressForm states={this.state.shipping_address}  />;
    case 1:
      return <Review shipping_address={this.state.shipping_address} payment_form={this.state.payment_form} changeValue={this.changeValue} />;
    case 2:
      return <RazorPay states={this.state.payment_form} invoice_total={this.state.invoice_total} addnewrecord={this.handleNext}  />;
    default:
      throw new Error('Unknown step');
  }
}
submit_order=()=>{
  console.log("submited");
  try{
    this.user = JSON.parse(localStorage.getItem("user"));
        
  const body={
    user_id:this.user.user_id,
    first_name:this.state.shipping_address.first_name,
    last_name: this.state.shipping_address.last_name,
    address1: this.state.shipping_address.address1,
    address2: this.state.shipping_address.address2,
    email: this.state.shipping_address.email,
    mobile: this.state.shipping_address.mobile,
    city: this.state.shipping_address.city_name,
    statename: this.state.shipping_address.state_name,
    zipcode: this.state.shipping_address.zipcode,
    country:this.state.shipping_address.country,
  }
   const result =  postData("userdetails/addnewrecord", body);
      if(result) {
      console.log("true");
      
          
      } else {
       return <div>Server Error\n Try Again later</div>
      }
    }catch (e) {
      console.log("UserRegistration:", e);
    }

}
  handleNext =async () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }))
    console.log(this.state.activeStep)
    console.log(steps.length)
  if(this.state.activeStep===steps.length){console.log("step");
   this.submit_order()}
   if(this.state.activeStep==3)
   {
     
    await this.addnewrecord()}

  };
  createRow(body,row) {
    const orderno=body.orderno
    const userid=body.emailid
    const qty=1
    const price=row.price
    const purchasedate=body.purchasedate
    const purchasetime=body.purchasetime
    const amount = qty*price;
    const itempurchased=row.setorg
    const status=body.status
    return {orderno,userid,purchasedate,purchasetime,amount,itempurchased,status};
  }
  addnewrecord=async()=>{
    const result1 = await postData(`purchase/generateorderno`);
    if(result1)
    {  // const {maincategoryid,maincategory,icon}=result[0];
      //    let data=JSON.stringify(result[0]);   
      let data= result1[0];
      let c=''
      let  cd=new Date();
      let u=JSON.parse(localStorage.getItem('user'));
      //alert(u.email)
      if(data.orderno==null){
              
            c='#1-'+u.user_id+"-"+cd.getDate()+(cd.getMonth()+1)+cd.getYear()+"-"+cd.getHours()+cd.getMinutes();
             
         }
         else{
              let sn=data.orderno+1;
            
              c='#'+sn+'-'+u.user_id+"-"+cd.getDate()+(cd.getMonth()+1)+cd.getYear()+"-"+cd.getHours()+cd.getMinutes();
         }
          try {
            let rows=[]
            let body=[]
            for ( var i = 0, len = localStorage.length; i < len; ++i ) {
              console.log("set no:"+localStorage.key(i))
              if(localStorage.key(i).startsWith('#')){
              let r=JSON.parse(localStorage.getItem(localStorage.key( i )))
               body={orderno:c,emailid:u.email,purchasedate:(cd.getFullYear()+"/"+(cd.getMonth()+1)+"/"+cd.getDate()),purchasetime:cd.getHours()+":"+cd.getMinutes(),status:'Take Test'}
               rows.push(r)}
            }
            rows=rows.map((row, id) => this.createRow(body, row));
      
          const result = await postData("purchase/addnewrecord", rows);
         alert(result.RESULT);
          if(result.RESULT) {
            this.setState({msg:'Record Submited....',duration:''});  
            this.clearCart()
               
          } else {
            this.setState({msg:'Fail to Submit Record....'});     
            alert('Server Error\nTry Again');
          }
        } catch (e) {
          alert(e)
          console.log("purchase:", e);
        }
   
    

      
    
    }

  }



clearCart=()=>{

  for (let i = localStorage.length-1;i>=0; --i ) {
    console.log("set no:"+localStorage.key(i))
    if(localStorage.key(i)==null)
    {localStorage.removeItem(localStorage.key(i))}
    else if(localStorage.key(i).startsWith('#'))
    {
      localStorage.removeItem(localStorage.key(i))
    }
    
  }
  this.props.cartaction('','')
}
  

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
   
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };


ShowCheckOut=(classes)=>{
  const { activeStep } = this.state;
return(
  <React.Fragment>
  <CssBaseline />
  <main className={classes.layout}>
    <Paper className={classes.paper}>
      <Typography component="h1" variant="h4" align="center">
        Checkout
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ? 
        <React.Fragment>
          <Typography variant="h5" gutterBottom>
            Thank you for your order.
          </Typography>
          <Typography variant="subtitle1">
            Your order Success. We have emailed your order confirmation, and will
            send you an update when your order has shipped.
          </Typography>
        </React.Fragment>
        : (
          <React.Fragment>
            {this.getStepContent(activeStep)}
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button onClick={this.handleBack} className={classes.button}>
                  Back
                </Button>
              )}
              {
                activeStep!==2?<Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
              </Button>:<React.Fragment></React.Fragment>
              }
              
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    </Paper>
  </main>
</React.Fragment>

);

}

  render() {
    const { classes } = this.props;
   

    return (<div>
     { this.ShowCheckOut(classes)
          }
    
     </div>
    );
  }
}

Checkout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Checkout);