import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({


  root:
  {
    marginTop:'5%',
    marginLeft:'10%',
    textAlign:'justify',
    marginRight:'10%'
  },
  hello:
  {
    marginTop:'0.5%',
    fontStyle:'calibri',
  },
hello1:{
  fontSize:'100%',
  marginTop:'0.5%',

},
hello2:{
  marginTop:'0.5%',


},
set:{
  marginTop:'1%'
},
set1:{
  marginTop:'2%',
  fontSize:'100%',
}
})


class Aboutus extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div>
          <div style={{textAlign:'center'}}><b>About Us</b></div>
            <p style={{textAlign:'center'}}><b>Our Vision</b></p>
            <p style={{marginLeft:'10%',marginRight:'10%'}} >


  Campusshala, a technology enabled company with the mission to prepare students with industrial skillsthrough internships.
  Campusshala also prepare students with the various placement preparation training and provide various mock test to the students that is designed and developed as per the companies pattern.
  Now thetime has arrived where your skills matter more than your university degree.With the aim to provide a comprehensive environment to become one stop destination for all the Placements preparation mock test with more than 5000+ questions by different reputed companies for college students and help them for many rounds conducted in the placement process.


            </p>
            <p style={{marginLeft:'10%'}}>
            To convert your dreams into reality,visit Campusshala.com
          </p>
          <Typography style={{textAlign:'center'}}><b> Why you choose career with us:</b></Typography>
          <p style={{marginLeft:'10%'}}>With the aim of transforming the lives of various students.
<p style={{marginLeft:'2%'}}>Join us.</p>
 </p>
 <p  style={{marginLeft:'12%'}}>  <b> 1.</b>	Work with an incredible team </p>
   <p  style={{marginLeft:'12%'}}><b> 2.</b>	Lots of creative freedom</p>
   <p  style={{marginLeft:'12%'}}><b> 3.</b>	Make a difference?</p>
   <p style={{marginLeft:'12%'}}> <b> 4.</b>	Very competitive packages</p>
    <p  style={{marginLeft:'12%'}}> <b>5.</b>	A career path of your choice</p>
    <p  style={{marginLeft:'12%'}}>  <b>6.</b>	Flexible work-hours</p>
   <p  style={{marginLeft:'12%'}}> <b>7.</b>	Fun all the time.

 </p>
</div>
    );
  }

}Aboutus.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Aboutus);
