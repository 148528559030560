import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import deepOrange from '@material-ui/core/colors/deepOrange'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TakeTest from '../userinterface/TakeTest'
import { BrowserRouter as Router,HashRouter, Route,NavLink,Redirect } from "react-router-dom";
import { getData,BaseURL } from "../FetchService"
import { postData } from "../FetchService"
import ReviewTestTab from './ReviewTestTab'



const styles = {
    root: {
      display: 'flex',
      flexWrap:"wrap",
      marginTop:'50px',
      justifyContent: 'center',
      alignItems: 'center',
      },
  card: {
    maxWidth: 345,
    marginRight:'25px',
    marginTop:'15px'
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    height:'100%',

    width: '100%',
  },
  orangeAvatar: {
    margin: 1,
    color: '#fff',
    backgroundColor: deepOrange[500],
    width: 80,
    height: 80,
    marginLeft:80,
  },
};
function Transition(props) {
  return <Slide direction="up" {...props} />;
}


class ShowPurchasedSetTraining extends React.Component {
    i=0
    state={listset:[], open: false, openr:false,data:[],item:[]}
   async componentDidMount() {
      this.handleClick= this.handleClick.bind(this)
     this.show()


    }
async show(){
  const body={userid:this.props.userid,
      setorg:this.props.setorg
      };
const listset = await postData(`purchase/fetchpurchasedsetnousertrainingadmin`,body);
this.setState({listset})

}

handleClick=async(setorg,record,statuspaper)=>{
 // let body={id:id,oid:oid}
 // this.props.history.replace({pathname:`/TakeTest`,data:body});
// this.props.history.replace({pathname:`/UserLogin`,data:body});
  //  console.log('cart:'+record.setorg)
 //await localStorage.setItem(setorg,JSON.stringify(record))
  if(statuspaper=='Review Test')
  {this.props.setViews(<ReviewTestTab setViews={this.props.setViews} body={record}/>)}


  }

  handleClickOpen =  (item) => {

    this.setState({ open: true });
    this.setState({item})
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleClickOpenR =  () => {

    this.setState({ openr: true });
  };

  handleCloseR = () => {
    this.setState({ openr: false });
  };

  handleAgree = async() => {
    await postData(`useranswer/deleteanswerretaketest`,this.state.data);
    this.show()
    this.setState({ openr: false });
  };
  retakeInfoDialog(){
    const { fullScreen } = this.props;
   return(


    <div>

        <Dialog
          open={this.state.openr}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Dear Canditate..... Beware???"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
             Retake Exam Will Remove Your All Previous Answers and Start Your Test From Begining...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseR} color="primary">
              Disagree
            </Button>
            <Button onClick={this.handleAgree} color="primary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
);


  }
  moreInfoDialog(){
    var d=new Date(this.state.item.purchasedate)
    var td=d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + d.getDate()).slice(-2)
  return(

      <div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll='paper'
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">More Info</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Order Number:{this.state.item.orderno}<br></br>

              Purchase Date:{td}<br></br>
              Purchase Time:{this.state.item.purchasetime}

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>

          </DialogActions>
        </Dialog>
      </div>


  );


  }



 ImgMediaCard(classes) {

  // let u=JSON.parse(localStorage.getItem('user'));

    return this.state.listset.map((item, index) => {
        let url=`${BaseURL}/images/${item.organizationicon}`

        let bodycart={userid:this.props.userid,setno:item.setno,setorg:item.setorg,organizationname:item.organizationname,organizationid:item.organizationid,price:item.price,duration:item.duration,status:item.paidstatus,transactionid:item.transactionid,paperstatus:item.paperstatus}
       console.log('BC:',bodycart)
      let stateb=false;
      if(item.statuspaper=='Take Test')
      {
        stateb=true;
      }
      else if(item.statuspaper=='Certificate')
      {
         stateb=false;
      }
  return (


    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component="img"

          className={classes.media}
          height="140"
          image={url}
          title={item.setorg}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Paper Set: {item.setorg}
          </Typography>

        </CardContent>
      </CardActionArea>
      <CardActions>
      {this.moreInfoDialog()}
      {this.retakeInfoDialog()}
      <table>
      <tr><td align='left'><Button size="small" color="primary" id={item.setno} disabled={stateb} onClick={() => this.handleClick(item.setorg,bodycart,'Review Test')}>
       Review Test
        </Button></td><td><Button size="small" color="primary" id={item.setno}   onClick={() => this.handleClickOpen(item)}>
       More Info...
        </Button></td></tr>
        </table>

      </CardActions>
    </Card>

  );
})
}
render() {
    const { classes } = this.props;
    return (<div>
 <div className={classes.root}>
        {this.ImgMediaCard(classes)}
        </div>
    </div>)}

}
ShowPurchasedSetTraining.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShowPurchasedSetTraining);
