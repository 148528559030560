/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import { getData, BaseURL } from '../../../FetchService';
// import renderHTML from 'react-render-html';
export default function ListCourse(props) {
	const [ getColumn ] = React.useState({
		columns: [
			{ title: 'Course', field: 'coursename' },
			{ title: 'Course Description', field: 'coursedescription' },
			{ title: 'Course Price', field: 'price' },
			{
				title: 'Course Image',
				field: 'courseicon',
				render: (rowData) => (
					<div>
						<img src={`${BaseURL}/images/${rowData.courseicon}`} height={50} width={50} />
					</div>
				)
			}

			// { title: 'Video', field: 'mobile', render: (rowData) => <div>{rowData.videoname}</div> }
		]
	});

	const [ state, setState ] = React.useState({ data: [] });

	const fetchAll = async () => {
		let list = await getData('course/fetchdata');
		setState({ data: list });
	};

	useEffect(function() {
		fetchAll();
	}, []);

	return (
		<MaterialTable
			title="Course list"
			columns={getColumn.columns}
			data={state.data}
			actions={[
				{
					icon: 'save',
					tooltip: 'Save User',
					onClick: (event, rowData) => {
						props.history.replace({ pathname: '/EditDeleteCourse', state: { id: rowData.courseid } });
					}
				}
			]}
			components={{
				Action: (props) => (
					<Button
						onClick={(event) => props.action.onClick(event, props.data)}
						color="primary"
						variant="contained"
						style={{ textTransform: 'none' }}
						size="small"
					>
						Change
					</Button>
				)
			}}
		/>
	);
}
