import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import NoSsr from '@material-ui/core/NoSsr';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { BrowserRouter as  HashRouter, Route, Redirect } from 'react-router-dom';

import DisplayUser from './DisplayUser';

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	);
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
};

function LinkTab(props) {
	return <Tab component="a" onClick={(event) => event.preventDefault()} {...props} />;
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	}
});

class HomeMainStyle extends React.Component {
	state = {
		value: 0
	};

	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const { classes } = this.props;
		const { value } = this.state;

		return (
			<HashRouter>
				<NoSsr>
					<div className={classes.root}>
						<AppBar position="static">
							<Tabs variant="fullWidth" value={value} onChange={this.handleChange}>
								<LinkTab label="Verified User" href="page1" />
								<LinkTab label="Unverified User" href="page2" />
							</Tabs>
						</AppBar>
						{value === 0 && (
							<TabContainer>
								<Redirect to={{pathname:'/Dash#/Verified',state:{status:'Verified'}}} />
							</TabContainer>
						)}
						{value === 1 && (
							<TabContainer>
								<Redirect to={{pathname:'/Dash#/Unverified',state:{status:'Not Verified'}}} />
							</TabContainer>
						)}
						{value === 2 && <TabContainer />}

						<Route path="/Dash#/Verified" component={DisplayUser} />
						<Route path="/Dash#/Unverified" component={DisplayUser}/>
					</div>
				</NoSsr>
			</HashRouter>
		);
	}
}

HomeMainStyle.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HomeMainStyle);
