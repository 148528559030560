/**
 * @author sandeep.sappal
 * @since last update 02/06/2020 by vishal.jain
 */
/* eslint-disable no-unused-vars */
import React from "react";
import clsx from "clsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import LayersIcon from "@material-ui/icons/Layers";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import HomeMain from "../maincategory/HomeMainStyle";
import HomeOrg from "../organization/HomeOrganizationStyle";
import HomeSubCat from "../subcategory/HomeSubCategoryStyle";
import HomeExamCat from "../examcategory/HomeExamCategoryStyle";
import ExamSet from "../examset/AddNewRecord";
import AddQuestion from "../questions/AddNewQuestions";
import EditQuestion from "../questions/EditDelete";
import ShowUser from "../management/ShowUser";
import Showuser from "../userreport/Showuser";
import DisplayUserStyle from "../userreport/usermanagement/DisplayUserStyle";
import ShowAllInternship from "../internshipreport/ShowAllInternship";
import DeleteQuestions from "../questions/DeleteQuestions";
import DisplayAllQuestions from "../questions/DisplayAllQuestions";
import CourseStyle from "../video/admin/course/CourseStyle";
import CourseDetailsStyle from "../video/admin/coursedetails/CourseDetailsStyle";
import ChapterDetailsStyle from "../video/admin/chapter/ChapterDetailsStyle";
import TopicDetailsStyle from "../video/admin/topic/TopicDetailsStyle";
import CategoryIcon from "@material-ui/icons/Category";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import WorkIcon from "@material-ui/icons/Work";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import AppsIcon from "@material-ui/icons/Apps";
import DetailsIcon from "@material-ui/icons/Details";
import CalendarViewDayIcon from "@material-ui/icons/CalendarViewDay";
import { withStyles } from "@material-ui/core/styles";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DescriptionIcon from '@material-ui/icons/Description';
import DnsIcon from '@material-ui/icons/Dns';
import SubtitlesIcon from '@material-ui/icons/Subtitles';

const styles = (theme) => ({
  root: {},
  select: {
    color:'white',
    backgroundColor: 'black'
  },
});

class listItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
      selected: -1,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleSelected = (index) => {
    this.setState({ selected: index });
  };

  handleClick = (opt) => {
    switch (opt) {
      case 1:
        this.handleSelected(1);
        this.props.action(<HomeMain />);
        break;
      case 2:
        this.handleSelected(2);
        this.props.action(<HomeSubCat />);
        break;
      case 3:
        this.handleSelected(3);
        this.props.action(<HomeOrg />);
        break;
      case 4:
        this.handleSelected(4);
        this.props.action(<HomeExamCat />);
        break;
      case 5:
        this.handleSelected(5);
        this.props.action(<ExamSet />);
        break;
      case 6:
        this.handleSelected(6);
        this.props.action(<AddQuestion />);
        break;
      case 7:
        this.handleSelected(7);
        this.props.action(<EditQuestion />);
        break;
      case 8:
        this.handleSelected(8);
        this.props.action(<ShowUser />);
        break;
      case 9:
        this.handleSelected(9);
        this.props.action(<Showuser />);
        break;
      case 10:
        this.handleSelected(10);
        this.props.action(<ShowAllInternship />);
        break;
      case 11:
        this.handleSelected(11);
        localStorage.removeItem("admin");
        this.props.history.replace("/UserMainPage");
        break;
      case 12:
        this.handleSelected(12);
        this.props.action(<DeleteQuestions />);
        break;
      case 13:
        this.handleSelected(13);
        this.props.action(<DisplayAllQuestions />);
        break;
      case 14:
        this.handleSelected(14);
        this.props.action(<CourseStyle />);
        break;
      case 15:
        this.handleSelected(15);
        this.props.action(<CourseDetailsStyle />);
        break;
      case 16:
        this.handleSelected(16);
        this.props.action(<ChapterDetailsStyle />);
        break;
      case 17:
        this.handleSelected(17);
        this.props.action(<TopicDetailsStyle />);
        break;
      case 18:
        this.handleSelected(18);
        this.props.action(<DisplayUserStyle />);
        break;
      default:
        this.props.action("");
        break;
    }
  };

  handleOpen1 = (statename, statevalue) => {
    this.setState({
      open1: false,
      open2: false,
      open3: false,
      open4: false,
      open5: false,
    });
    this.setState({ [statename]: !statevalue });
  };

  render() {
    const { classes, ...other } = this.props;
    console.log(classes);
    return (
      <div>
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem
          style={{ backgroundColor: this.state.open4 ? "#1976D2" : "" }}
          button
          onClick={() => this.handleOpen1("open4", this.state.open4)}
        >
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText style={{ color: this.state.open4 ? "white" : "" }}>
            <font style={{ fontWeight: this.state.open4 ? "bold" : "" }}>
              CATEGORY
            </font>
          </ListItemText>
          {this.state.open4 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open4} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              style={{ paddingLeft: 25 }}
              // backgroundColor:this.state.selected === 1?'red':''
              selected={this.state.selected === 1}
              onClick={() => this.handleClick(1)}
            >
              <ListItemIcon>
                <DnsIcon />
              </ListItemIcon>
              {/* <ListItemText
                style={{ color: this.state.selected === 1 ? "white" : "" }}
              >
                <font style={{ fontWeight: this.state.selected === 1 ? "bold" : "" }}>
                  Main Category
                </font>
              </ListItemText> */}
              <ListItemText primary="Main Category" />
            </ListItem>
            <ListItem
              button
              selected={this.state.selected === 2}
              style={{ paddingLeft: 25 }}
              onClick={() => this.handleClick(2)}
            >
              <ListItemIcon>
                <SubtitlesIcon />
              </ListItemIcon>
              <ListItemText primary="Sub Categories" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          style={{ backgroundColor: this.state.open5 ? "#1976D2" : "" }}
          button
          onClick={() => this.handleOpen1("open5", this.state.open5)}
        >
          <ListItemIcon>
            <MenuBookIcon />
          </ListItemIcon>
          <ListItemText style={{ color: this.state.open5 ? "white" : "" }}>
            <font style={{ fontWeight: this.state.open5 ? "bold" : "" }}>
              PAPERS
            </font>
          </ListItemText>
          {this.state.open5 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open5} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              selected={this.state.selected === 3}
              style={{ paddingLeft: 25 }}
              onClick={() => this.handleClick(3)}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="Organization" />
            </ListItem>
            <ListItem
              button
              selected={this.state.selected === 4}
              style={{ paddingLeft: 25 }}
              onClick={() => this.handleClick(4)}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="Exam Categories" />
            </ListItem>
            <ListItem
              button
              selected={this.state.selected === 5}
              style={{ paddingLeft: 25 }}
              onClick={() => this.handleClick(5)}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="Generate Set" />
            </ListItem>
            <ListItem
              button
              style={{ paddingLeft: 25 }}
              selected={this.state.selected === 6}
              onClick={() => this.handleClick(6)}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="Add Questions" />
            </ListItem>

            <ListItem
              button
              style={{ paddingLeft: 25 }}
              selected={this.state.selected === 7}
              onClick={() => this.handleClick(7)}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Questions" />
            </ListItem>

            <ListItem
              button
              style={{ paddingLeft: 25 }}
              selected={this.state.selected === 12}
              onClick={() => this.handleClick(12)}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="Delete Questions" />
            </ListItem>

            <ListItem
              button
              style={{ paddingLeft: 25 }}
              selected={this.state.selected === 13}
              onClick={() => this.handleClick(13)}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="All Questions" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          style={{ backgroundColor: this.state.open1 ? "#1976D2" : "" }}
          button
          onClick={() => this.handleOpen1("open1", this.state.open1)}
        >
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText style={{ color: this.state.open1 ? "white" : "" }}>
            <font style={{ fontWeight: this.state.open1 ? "bold" : "" }}>
              USER
            </font>
          </ListItemText>
          {this.state.open1 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              selected={this.state.selected === 8}
              onClick={() => this.handleClick(8)}
              style={{ paddingLeft: 25 }}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="User Report" />
            </ListItem>

            <ListItem
              button
              selected={this.state.selected === 9}
              onClick={() => this.handleClick(9)}
              style={{ paddingLeft: 25 }}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="All User Result" />
            </ListItem>

            <ListItem
              button
              selected={this.state.selected === 18}
              onClick={() => this.handleClick(18)}
              style={{ paddingLeft: 25 }}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="User Status" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          style={{ backgroundColor: this.state.open2 ? "#1976D2" : "" }}
          button
          onClick={() => this.handleOpen1("open2", this.state.open2)}
        >
          <ListItemIcon>
            <WorkIcon />
          </ListItemIcon>
          <ListItemText style={{ color: this.state.open2 ? "white" : "" }}>
            <font style={{ fontWeight: this.state.open2 ? "bold" : "" }}>
              INTERNSHIP
            </font>
          </ListItemText>
          {this.state.open2 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              selected={this.state.selected === 10}
              onClick={() => this.handleClick(10)}
              style={{ paddingLeft: 25 }}
            >
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="Internship Status" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          style={{ backgroundColor: this.state.open3 ? "#1976D2" : "" }}
          button
          onClick={() => this.handleOpen1("open3", this.state.open3)}
        >
          <ListItemIcon>
            <PlayCircleFilledIcon />
          </ListItemIcon>
          <ListItemText style={{ color: this.state.open3 ? "white" : "" }}>
            <font style={{ fontWeight: this.state.open3 ? "bold" : "" }}>
              COURSES
            </font>
          </ListItemText>
          {this.state.open3 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={this.state.open3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              style={{ paddingLeft: 25 }}
              selected={this.state.selected === 14}
              onClick={() => this.handleClick(14)}
            >
              <ListItemIcon>
                <AppsIcon />
              </ListItemIcon>
              <ListItemText primary="Course" />
            </ListItem>

            <ListItem
              button
              style={{ paddingLeft: 25 }}
              selected={this.state.selected === 15}
              onClick={() => this.handleClick(15)}
            >
              <ListItemIcon>
                <DetailsIcon />
              </ListItemIcon>
              <ListItemText primary="Course Details" />
            </ListItem>

            <ListItem
              button
              style={{ paddingLeft: 25 }}
              selected={this.state.selected === 16}
              onClick={() => this.handleClick(16)}
            >
              <ListItemIcon>
                <CalendarViewDayIcon />
              </ListItemIcon>
              <ListItemText primary="Chapter" />
            </ListItem>

            <ListItem
              button
              style={{ paddingLeft: 25 }}
              selected={this.state.selected === 17}
              onClick={() => this.handleClick(17)}
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Topic" />
            </ListItem>
          </List>
        </Collapse>

        <Divider />
        <ListItem button onClick={() => this.handleClick(11)}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </div>
    );
  }
}

export default withStyles(styles)(listItem);
