import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { postData ,ClientURL} from "../FetchService"
import renderHTML from 'react-render-html';
const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});


class SignIn extends React.Component {
    state = {
      email:'',
      password:'',
      msg:'',
      result:[],
      
      
    };
    valid=()=>{
      
    let eerr=this.state.email.length!=0?'':'Admin Id Should Not Blank...';
     let perr=this.state.password.length!=0?'':'Password Should Not Blank...';
     if(eerr!='' || perr!='')
     {let errm=`<font color='red'>${eerr}<br>${perr}</font>`;
     this.setState({msg:errm})
    
       return(false);} 
     else    
     {
       return(true)
     }

    }
    checkLogin=async()=>{
       if(this.valid())
       {
        const body={adminid:this.state.email,password:this.state.password};
        
       const result = await postData(`admin/checkadminlogin`,body);
       
       if(result.RESULT)
       {  // const {maincategoryid,maincategory,icon}=result[0];
      
       let data=JSON.stringify(result.RESULT[0]);   
       localStorage.setItem('admin',data);   
          this.setState({ result:data });
            console.log(`123xxxxxxxxxxxxxx ${result.RESULT}`);
          //  console.log(`xxxxxxxxxxxxxxxxx${maincategory}`)
          this.props.history.replace({pathname:`/Dash`,state:{result:this.state.result}});
 
       }
       else
       { this.setState({ msg:'<font color=red>Invalid Uid/Password....</font>' });
        console.log(`Invalid ${result}`);   
       }
      }
    }
   
 Signin(classes) {
  

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
         
      <img src={`/images/cssbig.png`}></img> 
     
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Admin Id</InputLabel>
            <Input id="email" name="email" autoComplete="email" autoFocus     onChange={e => this.setState({email: e.target.value })}/>
            
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input name="password" type="password" id="password" autoComplete="current-password"    onChange={e => this.setState({password: e.target.value })} />
          </FormControl>
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => this.checkLogin()}
          >
            Sign in
          </Button>
        </form>
      </Paper>
      
      
        {renderHTML(this.state.msg)}
     
    </main>
  );
}

render(){
    const { classes } = this.props;
return(
    <div>
    {this.Signin(classes)}
    </div>
    )

}

}
SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
