/* eslint-disable no-dupe-class-members */
/* eslint-disable eqeqeq */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {ClientURL,getData,BaseURL} from '../FetchService'



import { Card, CardContent, withTheme, Typography, Toolbar, Paper, MenuItem, CardActionArea, Divider, CardHeader } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const tutorialSteps = [
  {

    imgPath:
      `/images/1200cs.png`,
  },

  {
    imgPath:`/images/cs1.png`,
  },
  {
    imgPath:`/images/cs2.png`,
  },

];







const styles = theme => ({
footer:{
  position:'fixed',
  width:'100%',
  left:0,
  bottom:0,
  backgroundColor:'#585555',
  color:'white',
},
appBar: {
  top: 'auto',
  bottom: 0,
},
footer:{
  position:'relative',
  bottom:0 ,
  width:'100%',
  height:'130%',
  marginTop:'2px'
},

root: {
  overflow: 'hidden',
  flexGrow: 1,
},
header: {
  display: 'flex',
  alignItems: 'center',
  height:5,
  paddingLeft: theme.spacing.unit * 4,
  backgroundColor: theme.palette.background.default,
  marginTop:'12px'

},
img: {
  height:'30%',
  display: 'block',

  overflow: 'hidden',
  width: '100%',
},
divvalue:{display:'flex',justifyContent:'center',alignItems:'center'}
});




class MainPage extends Component {

  state = {
    activeStep: 0,
    coursearray:[]
  };

   readAllRecords=async()=>{
    let result=await getData('course/fetchdata')
    this.setState({coursearray:result})
  }

  componentWillMount(){
    this.readAllRecords()
  }

  componentDidMount(){
    this.readAllRecords()
  }
  

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  componentWillMount()
  { console.log(this.props)

}

  Display(classes)
  {
    const { theme } = this.props;
    const { activeStep } = this.state;
    const maxSteps = tutorialSteps.length;


return(
<div className={classes.root}>
        <Paper square elevation={0} className={classes.header}>
          <Typography>{tutorialSteps[activeStep].label}</Typography>
        </Paper>
        <AutoPlaySwipeableViews
          axis={theme.direction ==='rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
        >
          {tutorialSteps.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <img className={classes.img} src={step.imgPath} alt={step.label} />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>



</div>)

  }

  handleCompany=(value,id=0)=>{
    if(value=='3'){
    this.props.cartaction("COMPANY",value)
     }
      if(value=='Web'){
  this.props.cartaction("Web",'')

 }
 if(value=='Python'){
   localStorage.setItem("PYTHON",id)
  this.props.cartaction("Python",id)

 }
 if(value=='Java'){
  this.props.cartaction("Java",'')

 }
 if(value=='Node'){
  this.props.cartaction("Node",'')

 }
   }
  handleViewInternship=()=>{
   this.props.mainpage('SHOW_INTERN')

  }
  handleViewTraining=()=>{

    this.props.mainpage('VT')

   }
   setCity=(city)=>{

   this.props.cartaction('SHOW_INTERN_FILTER',city)
   }

  render() {
    const { classes } = this.props;
    return (<div style={{marginTop:'-2%'}}>
    {this.Display(classes)}
    <div className={classes.divvalue}>
    <Typography  style={{fontSize:'110%',marginTop:'10px',marginBottom:'10px'}}><b>Internship in popular city</b></Typography>
     </div>



     <div>

      <Grid container spacing={3} className={classes.divvalue}>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
       <Button  onClick={()=>this.setCity('Bengaluru')} >
<img src={`/images/bangalore.jpg`}></img>
</Button>
      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
      <Button onClick={()=>this.setCity('Bhopal')}>
      <img src={`/images/bhopal.jpg` }></img>
      </Button>
      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
      <Button onClick={()=>this.setCity('Chennai')}><img src={`/images/chennai.jpg`}></img></Button>


      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button onClick={()=>this.setCity('Hyderabad')}> <img src={`/images/hyderabad.jpg` }></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button onClick={()=>this.setCity('Indore')}> <img src={`/images/indore.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button onClick={()=>this.setCity('Delhi')}> <img src={`/images/delhi.jpg`}></img></Button>

      </Grid>
</Grid>
</div>

<div >


        <Grid container spacing={3} className={classes.divvalue}>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
    <Button onClick={()=>this.setCity('Gurgaon')}>     <img src={`/images/gorgaon.jpg`}></img></Button>
      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button onClick={()=>this.setCity('Pune')}> <img src={`/images/pune.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
      <Button onClick={()=>this.setCity('Kolkata')}><img src={`/images/kolkata.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button onClick={()=>this.setCity('Mumbai')}> <img src={`/images/mumbai.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
    <Button onClick={()=>this.setCity('Noida')}>  <img src={`/images/noida.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button onClick={()=>this.setCity('Agra')}> <img src={`/images/taj.jpg`}></img></Button>

      </Grid>

        </Grid>
        </div>
        <div className={classes.divvalue}>
        <Button style={{marginTop:'1%', marginBottom:'1%'}} variant="outlined" color="primary" className={classes.button} onClick={()=>this.handleViewInternship()}>
       or view all Internships >
      </Button>
       </div>


      <div className={classes.divvalue}>
      <Typography  style={{fontSize:'110%',marginTop:'10px',marginBottom:'10px'}}><b>Company Test Papers</b></Typography>
      </div>
      <div>
 <Grid container spacing={3}  className={classes.divvalue}>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
       <Button  >
<img src={`/images/ACCENTURE.png`}></img>
</Button>
      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
      <Button>
      <img src={`/images/AMDOCS.png`}></img>
      </Button>
      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
      <Button><img src={`/images/INFOSYS.png`} onClick={()=>this.handleCompany("3")}></img></Button>


      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button> <img src={`/images/MPHASIS.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button> <img src={`/images/WIPRO.jpg`} ></img></Button>

      </Grid>
       <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button> <img src={`/images/ORACLE.jpg`}></img></Button>

      </Grid>
      </Grid>
      </div>
      <div>


        <Grid container spacing={3} className={classes.divvalue}>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
    <Button>     <img src={`/images/TECHMAHINDRA.jpg`}></img></Button>
      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button> <img src={`/images/L&T.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
      <Button><img src={`/images/MIND TREE.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button> <img src={`/images/HCL.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
    <Button>  <img src={`/images/tcs.jpg`}></img></Button>

      </Grid>
      <Grid item xs={24} sm={3} style={{marginLeft:'',flexBasis:'0px'}}>
     <Button> <img src={`/images/cognizant.jpg`}></img></Button>

      </Grid>

      </Grid>
    <div className={classes.divvalue}>
    <Typography style={{fontSize:'20px',marginTop:"10px",fontWeight:500}}>Trainings Offered</Typography>
    </div>
    <Grid container spacing={3}  className={classes.divvalue}>
      {this.state.coursearray.map((item,index)=>{
        return(
          <Card style={{width:'250px',height:'200px',margin:20,textAlign:'center'}} onClick={()=>this.handleCompany('Python',item.courseid)}>
        <CardActionArea  >
        {/* onClick={()=>this.handleCompany('Python',item.courseid)} */}
        <img src={`${BaseURL}/images/${item.courseicon}`} ></img>
        <div className={classes.divvalue}>
        <Typography style={{fontsize:'50px',fontSize:'16px',color:'#263475',fontWeight:500,borderBottom:'#263475 1px solid',borderBottomStyle:'dotted'}}>{item.coursename}</Typography>
        </div>
        <div className={classes.divvalue}>
      <Typography style={{textAlign:'center',fontSize:'11px'}}>{item.coursedescription}</Typography>
        </div>
        </CardActionArea>
      </Card>
        )
      })}
    </Grid>
    {/* <Grid container spacing={3}  className={classes.divvalue}>
    <Card style={{width:'250px',height:'200px',margin:20,textAlign:'center'}} onClick={()=>this.handleCompany('Web')}>
      <CardActionArea  >

      <img src={`/images/webdeveloper.png`} ></img>
      <div className={classes.divvalue}>
      <Typography style={{fontsize:'50px',fontSize:'16px',color:'#263475',fontWeight:500,borderBottom:'#263475 1px solid',borderBottomStyle:'dotted'}}>Web Development</Typography>
      </div>
      <div className={classes.divvalue}>
      <Typography style={{fontSize:'10px',textAlign:'center',fontSize:'11px'}}> in PHP </Typography>
      </div>
      </CardActionArea>
    </Card>
    <Card style={{width:'250px',height:'200px',margin:20,textAlign:'center'}} onClick={()=>this.handleCompany('Python')}>
      <CardActionArea >


      <img src={`/images/1.png`} ></img>
      <div className={classes.divvalue}>
      <Typography style={{fontsize:'50px',fontSize:'16px',color:'#263475',fontWeight:500,borderBottom:'#263475 1px solid',borderBottomStyle:'dotted'}}> Python & ML</Typography></div>
      <div className={classes.divvalue}>
      <Typography style={{fontSize:'10px',textAlign:'center',fontSize:'11px'}}> Build IOT & ML Based Application in Python Language</Typography>
</div>
      </CardActionArea>
    </Card>
   < Card style={{width:'250px',height:'200px',margin:20,textAlign:'center'}} onClick={()=>this.handleCompany('Node')}>
      <CardActionArea >

      <img src={`/images/digitalmarketing.png`} ></img>
      <div className={classes.divvalue}>
      <Typography style={{fontsize:'50px',fontSize:'16px',color:'#263475',fontWeight:500,borderBottom:'#263475 1px solid',borderBottomStyle:'dotted'}}>Node JS & React JS</Typography></div>
      <div className={classes.divvalue}>
      <Typography style={{fontSize:'10px',textAlign:'center',fontSize:'11px'}}>Create Your Own Web & Native App with Node and React js</Typography></div>

      </CardActionArea>
    </Card>
    <Card style={{width:'250px',height:'200px',margin:20,textAlign:'center'}}onClick={()=>this.handleCompany('Java')}>
      <CardActionArea >

      <img src={`/images/e.png`} ></img>
      <div className={classes.divvalue}>
            <Typography style={{fontsize:'50px',fontSize:'16px',color:'#263475',fontWeight:500,borderBottom:'#263475 1px solid',borderBottomStyle:'dotted'}}> Java , Spring , Hibernate</Typography></div>
            <div className={classes.divvalue}>

      <Typography style={{fontSize:'10px',textAlign:'center',fontSize:'11px'}}> Learn to MVC Based Secure Web Applications</Typography></div>

      </CardActionArea>
    </Card>

    </Grid> */}
     <div  className={classes.divvalue}>
    <Button style={{marginTop:'2%', marginBottom:'1%'}} variant="outlined" color="primary" className={classes.button} onClick={()=>this.handleViewTraining()}>
    View All Training Programs
      </Button>
      </div>
   </div>
      </div>
        );
  }
}

MainPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,

  };

  export default withStyles(styles, { withTheme: true })(MainPage);
