import React from 'react';
import PropTypes from 'prop-types';
import { withStyles,MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HomeMain from "../maincategory/HomeMainStyle"
import HomeOrg from "../organization/HomeOrganizationStyle"
import HomeSubCat from "../subcategory/HomeSubCategoryStyle"
import HomeExamCat from "../examcategory/HomeExamCategoryStyle"
import ExamSet from "../examset/AddNewRecord"
import AddQuestion from  '../questions/AddNewQuestions';
import  DisplayPaper from  '../userinterface/DisplayPaper';
import green from '@material-ui/core/colors/green';

const styles = theme => ({

  button: {
      margin: theme.spacing.unit,
      display:'flex',
      flexWrap:'wrap'
    },


});



class SideBar extends React.Component {

  constructor(props){
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }
  handleClick=(opt)=> {





 }


GetIndex=event=>
{//alert(event.currentTarget.value)

  this.props.click(event.currentTarget.value-1,'Side')
}

 Qbutton(classes) {


  let i=0
  return this.props.taketeststate.map((items, index) => {
  let color=''

  if(items.statusquestion=='submit')
  {color='primary'

  }
  else if(items.statusquestion=='review')
   {color='secondary'}
   else
   {color='x'}

return (


  <Grid item xs={12} sm={4}>

  <Button variant="contained" value= {items.questionno} color={color} className={classes.button} onClick={this.GetIndex}>
    {items.questionno}
   </Button>
   </Grid>
);

})

}

  render() {
    const { classes } = this.props;
  return(<div>
     <ListItem button>
      {/*<ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>*/}
      <ListItemText style={{color:'red'}}   ><font style={{fontWeight:'bold'}} >Question Status</font></ListItemText>
    </ListItem>
     <Grid container spacing={3}>
    {this.Qbutton(classes)}
    </Grid>
  </div>)
}
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideBar);
