import React from "react";
import { render } from "react-dom";
import _ from "lodash";
// import { makeData } from "./Utils";

// Import React Table
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link'
import ReactTable from "react-table";
import "react-table/react-table.css";
import { postData,getData } from '../FetchService'
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import ShowPurchasedSetTraining from './ShowPurchasedSetTraining'

const useStyles = theme => ({
   paper:{padding:'30px',marginTop:'10px'},
  button: {
    margin: 0,
  },
  input: {
    display: 'none',
  },
});


export default class Table extends React.Component {

  constructor() {
    super();
    this.state = {
      data: [],
      pages: null,
      loading: true,
      list:[],

    };
  }

  async componentDidUpdate(prevProps,prevState) {
    if(this.props!=prevProps){
    const body={'itempurchased':this.props.itempurchased,
    'itemstatus':this.props.status
  };
   const list = await postData(`showuser/fetchtable`,body);
   this.setState({list})
 }
  }

  async componentDidMount() {

       const list = await getData(`showuser/fetchcompletetable`);
      console.log(`Data ${list}`)
        this.setState({ list });

      console.log(`Data ${this.state.list}`)

      }

    handleDeactivate=async(id)=>{
    let body={
      'userid':id
    }
    let result=await postData(`showuser/deactivate`,body)
    if(result.RESULT){
      alert("Account deactivated successfully")
    }
    else{
     alert("Account not deactivated successfully")
    }

  }

  handleDelete=async(id)=>{
    let body={
      'userid':id
    }
    let result=await postData(`showuser/delete`,body)
    if(result.RESULT){
      alert("Account deleted successfully")
    }
    else{
     alert("Account not deleted successfully")
    }

  }

  handleRetake=(userid,setorg)=>{
      this.props.setViews(<ShowPurchasedSetTraining setViews={this.props.setViews} userid={userid} setorg={setorg}/>)
  }

  checkButton=(statuspaper,userid,setorg)=>{
    if(statuspaper==='Retake Test'){
     return (<Link href="#" onClick={()=>{this.handleRetake(userid,setorg)}}>Get result</Link>)
    }
    else{
    }
  }



  render() {
    // const { data, pages, loading } = this.state;
     const classes = useStyles();
    const columns=[
            {
              Header: "ID",
              accessor: "transactionid",
              style:{
                textAlign: 'center'
              },
              width: 50,
              maxWidth: 50,
              minWidth:50
            },
            {
              Header: "orderno",
              accessor: "orderno",
              style:{
                textAlign: 'center'
              },
              width: 155,
              maxWidth: 155,
              minWidth:155
            },
            {
              Header: "userid",
              accessor: "userid",
              style:{
                textAlign: 'center'
              },
              width: 205,
              maxWidth: 205,
              minWidth:205
            },
            {
              Header: "purchasedate",
              accessor: "purchasedate",
              width: 110,
              maxWidth: 110,
              minWidth:110
            },
            {
              Header: "Time",
              accessor: "purchasetime",
              style:{
                textAlign: 'center'
              },
              width: 60,
              maxWidth: 60,
              minWidth:60
            },
            {
              Header: "₹",
              accessor: "amount",
              style:{
                textAlign: 'center'
              },
              width: 50,
              maxWidth:50,
              minWidth:50
            },
            {
              Header: "itempurchased",
              accessor: "itempurchased",
              style:{
                textAlign: 'center'
              },
              width: 120,
              maxWidth: 120,
              minWidth: 120
            },
            {
              Header: "statuspaper",
              accessor: "statuspaper",
              Cell: props =>{ return(<span>
                {props.value}<br/>
              {this.checkButton(props.value,props.original.userid,props.original.itempurchased)}
                </span>
                )},
              style:{
                textAlign: 'center'
              },
              width: 95,
              maxWidth: 95,
              minWidth:95
            }
            ,
            {
              Header: "Deactivate",
              Cell: props =>{ return(
                <Button variant="contained" onClick={()=>{this.handleDeactivate(props.original.userid)}} color="primary" className={classes.button}>
                  <CancelIcon/>
                </Button>)},
              filterable: false,
              sortable: false,
              style:{
                textAlign: 'center'
              },
              width: 90,
              maxWidth: 90,
              minWidth:90
            },
            {
              Header: "Delete",
              Cell: props =>{ return(
                <Button variant="contained" onClick={()=>{this.handleDelete(props.original.userid)}} color="secondary" className={classes.button}>
                  <DeleteIcon/>
                </Button>
                )},
              filterable: false,
              sortable: false,
              width: 65,
              maxWidth:65,
              minWidth:65
            }
          ]
    return (
      <div>
        <ReactTable
          columns= {columns}
          data={this.state.list}
          filterable
          defaultPageSize={10}
          noDataText={"Please Wait..."}
      >
        </ReactTable>
        <br />
      </div>
    );
  }
}
