import TextField from "@material-ui/core/TextField";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import Link from "@material-ui/core/Link";
import { getData, ClientURL } from "../FetchService";
import { postData } from "../FetchService";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { nfbind } from "q";
import Input from "@material-ui/core/Input";
import { InputBase, Backdrop } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CardMedia from "@material-ui/core/CardMedia";
import { th } from "date-fns/esm/locale";
import OtpInput from "react-otp-input";

const styles = {
  card: {
    width: "auto",
    height: 400,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  card1: {
    height: 320,
    padding: 20,
  },
  paper: {
    height: 400,
    marginLeft: "15%",
    width: 500,
  },
};

class UserProfileEdit extends React.Component {
  state = {
    emailotp: "",
    txtfirst_name: "",
    first_name: 0,
    last_name: "",
    txtlast_name: "",
    txtaddress1: "",
    txtaddress2: "",
    txtcity: "",
    txtcountry: "",
    email: "",
    txtemail: "",
    txtmobile: "",
    mobile: "",
    txtpassword: "",
    password: "",
    create_date: "",
    txtcreate_date: "",
    updatedate: "",
    list: [],
    address1: "",
    address2: "",
    city: "",
    LabelButton1: "Edit",
    LabelButton2: "Edit",
    LabelButton3: "Edit",
    LabelButton4: "Edit",
    LabelButton5: "Edit",
    LabelButton: "Edit",
    LabelButton6: "Edit",
    LabelButton7: "Edit",
    LabelButton8: "Edit",
    LabelButton9: "Edit",
    citystate: "",
    add1: "",
    add2: "",
    otpmsg: "",
    data: "",
    Edit: "",
    userotp: "",
    otp: "",
    numInputs: 4,
    separator: "-",
    isDisabled: false,
    hasErrored: false,
    isInputNum: false,
    open: "",
    open2: "",
    otpemail: "",
    eotp: "",
  };
  handleClickOpen = async () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleClose2 = () => {
    this.setState({ open2: false });
  };
  componentWillMount() {
    this.setState(this.props.states);
    let user = JSON.parse(localStorage.getItem("user"));
  }
  edituser = async () => {
    let user = JSON.parse(localStorage.getItem("user"));

    //alert('xxxx');
    let data = [];
    if (this.state.data == 1) {
      data = { first_name: this.state.txtfirst_name, user_id: user.user_id };
    } else if (this.state.data == 2) {
      data = { last_name: this.state.txtlast_name, user_id: user.user_id };
    } else if (this.state.data == 3) {
      data = { email: this.state.txtemail, user_id: user.user_id };
    } else if (this.state.data == 4) {
      data = { mobile: this.state.txtmobile, user_id: user.user_id };
    } else if (this.state.data == 5) {
      data = { password: this.state.txtpassowrd, user_id: user.user_id };
    } else if (this.state.data == 6) {
      data = { create_date: this.state.txtcreate_date, user_id: user.user_id };
    }

    let result = await postData("userinterface/editrecord", data);
    //alert(result)
    if (result.RESULT) {
      this.setState({ msg: "Record Submitted" });
    } else {
      this.setState({ msg: "Fail to Submit.." });
    }
  };

  Display(classes) {
    return this.state.list.map((item, index) => {
      return (
        <div className={classes.root}>
          <Card className={classes.card1}>
            <Typography
              variant="title"
              gutterBottom
              align="center"
              style={{ marginTop: "2%" }}
            >
              User Information
            </Typography>

            <Grid container spacing={24}>
              <Grid item xs={3}>
                <Typography
                  variant="title"
                  style={{ marginTop: "2%", marginLeft: "10%" }}
                >
                  First Name
                </Typography>
              </Grid>

              <Grid item xs={3} style={{ marginLeft: "10%" }}>
                <Typography variant="title" style={{ marginTop: "2%" }}>
                  {this.state.first_name}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Link
                  component="button"
                  //variant="body2"
                  onClick={this.changebtn}
                  className={classes.button}
                  // alert("I'm a button.");
                  style={{ marginLeft: "90%" }}
                >
                  {" "}
                  <Typography variant="title" style={{ marginTop: "5%" }}>
                    {this.state.LabelButton1}
                  </Typography>
                </Link>
              </Grid>
            </Grid>

            <Grid container spacing={24}>
              <Grid item xs={3}>
                <Typography
                  variant="title"
                  style={{ marginTop: "2%", marginLeft: "10%" }}
                >
                  Last Name
                </Typography>
              </Grid>

              <Grid item xs={3} style={{ marginLeft: "10%" }}>
                <Typography variant="title">{this.state.last_name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Link
                  component="button"
                  //variant="body2"
                  onClick={this.changebtn2}
                  className={classes.button}
                  // alert("I'm a button.");
                  style={{ marginLeft: "90%" }}
                >
                  {" "}
                  <Typography variant="title">
                    {" "}
                    {this.state.LabelButton2}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid container spacing={24}>
              <Grid item xs={3}>
                <Typography
                  variant="title"
                  style={{ marginTop: "2%", marginLeft: "10%" }}
                >
                  Email Address
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ marginLeft: "10%" }}>
                <Typography variant="title">{this.state.email}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Link
                  component="button"
                  //variant="body2"
                  onClick={this.changebtn1}
                  className={classes.button}
                  // alert("I'm a button.");
                  style={{ marginLeft: "90%" }}
                >
                  {" "}
                  <Typography variant="title">
                    {this.state.LabelButton3}
                  </Typography>
                </Link>
              </Grid>
            </Grid>

            <Grid container spacing={24}>
              <Grid item xs={3}>
                <Typography
                  variant="title"
                  style={{ marginTop: "2%", marginLeft: "10%" }}
                >
                  Mobile Number
                </Typography>
              </Grid>

              <Grid item xs={3} style={{ marginLeft: "10%" }}>
                <div className={classes.hello}>
                  <Typography variant="title">{this.state.mobile}</Typography>
                </div>
              </Grid>
              <Grid item xs={3}>
                <Link
                  component="button"
                  //variant="body2"
                  onClick={this.changebtn4}
                  className={classes.button}
                  // alert("I'm a button.");
                  style={{ marginLeft: "90%" }}
                >
                  {" "}
                  <Typography variant="title">
                    {" "}
                    {this.state.LabelButton4}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Card>
        </div>
      );
    });
  }

  async componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));

    const bodyr = { user_id: user.user_id };
    let list = await postData("userinterface/fetchbyuserid", bodyr);
    console.log("ddd", list);
    this.setState({ list });
    this.setState({ txtfirst_name: list[0].first_name });
    this.setState({ first_name: list[0].first_name });
    this.setState({ txtlast_name: list[0].last_name });
    this.setState({ last_name: list[0].last_name });
    this.setState({ txtemail: list[0].email });
    this.setState({ email: list[0].email });
    this.setState({ txtmobile: list[0].mobile });
    this.setState({ mobile: list[0].mobile });
    this.setState({ txtpassword: list[0].password });
    this.setState({ password: list[0].password });
    this.setState({ txtcreate_date: list[0].create_date });
    this.setState({ create_date: list[0].create_date });
  }
  handleFirstName = async (event) => {
    await this.setState({ txtfirst_name: event.target.value });
    let k = <TextField onChange={this.handleFirstName}></TextField>;

    await this.setState({ first_name: k });
  };

  changebtn = async (classes) => {
    this.setState({ data: "1" });

    if (this.state.LabelButton1 == "Edit") {
      await this.setState({ LabelButton1: "Save" });

      let k = (
        <TextField
          value={this.state.txtfirst_name}
          onChange={this.handleFirstName}
        ></TextField>
      );

      this.setState({ first_name: k });

      //    this.setState({mobile:l})
    } else if (this.state.LabelButton1 == "Save") {
      await this.setState({ LabelButton1: "Edit" });
      this.edituser();

      let k = <div>{this.state.txtfirst_name}</div>;
      this.setState({ first_name: k });
    }
  };
  handleLastName = async (event) => {
    await this.setState({ txtlast_name: event.target.value });
    let p = <TextField onChange={this.handleLastName}></TextField>;

    await this.setState({ last_name: p });
  };

  changebtn2 = async (classes) => {
    this.setState({ data: "2" });

    if (this.state.LabelButton2 == "Edit") {
      await this.setState({ LabelButton2: "Save" });

      let p = (
        <TextField
          value={this.state.txtlast_name}
          onChange={this.handleLastName}
        ></TextField>
      );

      this.setState({ last_name: p });

      //    this.setState({mobile:l})
    } else if (this.state.LabelButton2 == "Save") {
      await this.setState({ LabelButton2: "Edit" });
      this.edituser();

      let p = <div>{this.state.txtlast_name}</div>;
      this.setState({ last_name: p });
    }
  };

  handleEmail = async (event) => {
    await this.setState({ txtemail: event.target.value });
    let y = <TextField onChange={this.handleEmail}></TextField>;

    await this.setState({ email: y });
  };
  changebtn1 = async () => {
    this.setState({ data: "3" });

    if (this.state.LabelButton3 == "Edit") {
      await this.setState({ LabelButton3: "Save" });

      let y = (
        <TextField
          value={this.state.txtemail}
          onChange={this.handleEmail}
        ></TextField>
      );

      this.setState({ email: y });

      //    this.setState({mobile:l})
    } else if (this.state.LabelButton3 == "Save") {
      // this.setState({ open2: true });
      await this.setState({ LabelButton3: "Edit" });

      let b = <div>{this.state.txtemail}</div>;
      this.setState({ email: b });
    }
  };

  showNext2 = async (classes) => {
    console.log("sgregsrg", this.state.userotp);

    /*            if(this.state.userotp==this.state.otp)
                   {    */

    this.edituser();
    this.setState({ open: false });
    await this.setState({ LabelButton4: "Edit" });
  };
  /*  else{
this.setState({otpmsg:'invalid otp'})
      }   

    } */
  handleMobile = async (event) => {
    await this.setState({ txtmobile: event.target.value });
    let q = (
      <TextField
        value={this.state.txtmobile}
        onChange={this.handleMobile}
        style={{ fullWidth: true }}
      ></TextField>
    );

    await this.setState({ mobile: q });
  };

  changebtn4 = async () => {
    this.setState({ data: "4" });

    if (this.state.LabelButton4 == "Edit") {
      await this.setState({ LabelButton4: "Save" });

      let q = (
        <TextField
          value={this.state.txtmobile}
          onChange={this.handleMobile}
        ></TextField>
      );

      this.setState({ mobile: q });

      //    this.setState({mobile:l})
    } else if (this.state.LabelButton4 == "Save") {
      let userotp = Math.ceil(Math.random() * 8999 + 1000);
      await this.setState({ userotp });
      let body = { mobile: this.state.mobile, otp: userotp };

      let result = await postData("api/sendotp", body);

      console.log("otpsend", result);
      this.setState({ open: true });

      let q = <div>{this.state.txtmobile}</div>;
      this.setState({ mobile: q });
    }
  };

  handleCreateDate = async (event) => {
    await this.setState({ txtcreate_date: event.target.value });
    let g = <TextField onChange={this.handleCreateDate}></TextField>;
    this.setState({ createdate2state: true });

    await this.setState({ create_date: g });
  };
  changebtn5 = async () => {
    this.setState({ data: "6" });

    if (this.state.LabelButton5 == "Edit") {
      await this.setState({ LabelButton5: "Save" });

      let g = (
        <TextField
          value={this.state.txtcreate_date}
          onChange={this.handleCreateDate}
        ></TextField>
      );

      this.setState({ create_date: g });

      //    this.setState({mobile:l})
    } else if (this.state.LabelButton5 == "Save") {
      await this.setState({ LabelButton5: "Edit" });

      let g = <div>{this.state.txtcreate_date}</div>;
      this.setState({ create_date: g });
    }
  };

  render() {
    const { classes } = this.props;
    const bull = <span className={classes.bullet}>â€¢</span>;
    const { fullScreen } = this.props;
    const {
      otp,
      numInputs,
      separator,
      isDisabled,
      hasErrored,
      isInputNum,
    } = this.state;

    return (
      <div>
        {this.Display(classes)}
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{""}</DialogTitle>
          <DialogContent>
            <Grid>
              <Grid className={classes.divp}>
                <img src={`/images/cssbig.png`} />
              </Grid>
              <Grid style={{ marginTop: "10%" }} className={classes.divp}>
                <OtpInput
                  inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 1rem",
                    fontSize: "2rem",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                  }}
                  numInputs={numInputs}
                  isDisabled={isDisabled}
                  hasErrored={hasErrored}
                  errorStyle="error"
                  onChange={this.handleOtpChange}
                  separator={<span>{separator}</span>}
                  isInputNum={isInputNum}
                  shouldAutoFocus
                />
              </Grid>

              <Button
                type="submit"
                fullWidth
                onClick={this.showNext2}
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{ marginTop: "2%" }}
                /*                  style={{width:'20%',marginTop:'5%'}}
                 */
              >
                Verify OTP
              </Button>

              <Link
                component="button"
                variant="body2"
                onClick={this.showNext1}
                style={{ color: "red" }}
              >
                Resend OTP
              </Link>
            </Grid>
          </DialogContent>
          {this.state.otpmsg}
        </Dialog>
      </div>
    );
  }
}
UserProfileEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserProfileEdit);
