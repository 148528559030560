import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import WorkIcon from '@material-ui/icons/Work';
import CartIcon from '@material-ui/icons/ShoppingCart';
import UserLogin from './UserLogin';
import {useDispatch} from 'react-redux'

const TAX_RATE = 0.18+0.0175;

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
  },
  margin: {
    marginRight:'80%',
    paddingLeft:''
  },
  button: {
    margin: theme.spacing.unit,
  },

  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});
class ShowCart extends React.Component {
  state={rows:[],}
ccyFormat(num) {
  console.log("num:"+num)
  return `${num.toFixed(2)}`;
}

 priceRow(qty, unit) {
  return qty * unit;
}
async componentDidUpdate(prevProps, prevState) {
  if(this.props!=prevProps)
  {
    await this.FillRow()
  }}
 async componentDidMount(){
  await this.FillRow()
}
 createRow(id, row) {
  const qty=1
  const price=row.price
  const setorg=row.setorg
  const amount = this.priceRow(qty, price);
  return { id, setorg,qty, price,amount };
}

 subtotal(items) {
  return items.map(({ amount }) => amount).reduce((sum, i) => sum + i, 0);
}
  async FillRow()
  { let rows=[]
    for ( var i = 0, len = localStorage.length; i < len; ++i ) {
      console.log("set no:"+localStorage.key(i))
      if(localStorage.key(i)!='timer'){
      if(localStorage.key(i)!='user'){
        if(localStorage.key(i)!='employer'){
          if(localStorage.key(i)!='admin'){
            if(localStorage.key(i)!='PYTHON'){
      let r=JSON.parse(localStorage.getItem(localStorage.key( i )))
      console.log("r:"+r)
      rows.push(r)}}}}}
    }
    rows=rows.map((row, id) => this.createRow(id, row));
   await this.setState({rows})


  }
  ContinueShoping=event=>{

 this.props.mainpage('MAIN')
  }
  BuyNow=event=>{
    this.props.mainpage('BUY')
  }

  DeleteFromCart=event=>{
    //alert(event.currentTarget.value)

    localStorage.removeItem(event.currentTarget.value);

    this.props.cartaction("REDUCECARTVALUE","")
    this.FillRow()
  }

 EmptyCart(classes){
return(
  <div className={classes.root}>
<Typography gutterBottom variant="h5" component="h2" align='center' color='secondary' variant='title'>
   CART IS EMPTY

</Typography>
<Typography gutterBottom variant="h5" component="h2" align='center' color='secondary' variant='title'>
<Button variant="contained" color="secondary" className={classes.button} onClick={this.ContinueShoping}>
       Continue Shopping
        <CartIcon className={classes.rightIcon} />
      </Button>
      </Typography>
</div>
);



 }
SpanningTable(classes) {
 let invoiceSubtotal = this.subtotal(this.state.rows);
  let invoiceTaxes = TAX_RATE * invoiceSubtotal;
  let invoiceTotal = invoiceTaxes + invoiceSubtotal;
  console.log("TOTAL,TOTAL,TOTAL.......",invoiceTotal)
  // dispatch({type:'SET_PRICE',payload:[invoiceTotal]})
  
  // localStorage.setItem("PRICE",invoiceTotal)
  return (
    <div className={classes.root}>

        <Typography gutterBottom variant="h5" component="h2" align='center' color='secondary' variant='title'>
            List of Paper Set You Want To Purchase
        </Typography>
    <Paper className={classes.root}>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
          <TableCell>Remove</TableCell>
            <TableCell>Desc</TableCell>
            <TableCell align="right">Qty.</TableCell>
            <TableCell align="right">@</TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.rows.map(row => (
            <TableRow key={row.id}>
              <TableCell align="right">  <IconButton aria-label="Delete" className={classes.margin} value={row.setorg} onClick={this.DeleteFromCart}>
          <DeleteIcon />
        </IconButton></TableCell>
              <TableCell>{row.setorg}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
              <TableCell align="right">{row.price} <span>&#8377;</span> </TableCell>
              <TableCell align="right">{this.ccyFormat(row.amount)} <span>&#8377;</span> </TableCell>

            </TableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={3} />

            <TableCell colSpan={3}>Subtotal</TableCell>
            <TableCell align="right">{this.ccyFormat(invoiceSubtotal)} <span>&#8377;</span> </TableCell>
          </TableRow>

          <TableRow>

            <TableCell>Tax</TableCell>
            <TableCell></TableCell>
            <TableCell align="right">{`${(TAX_RATE * 100).toFixed(2)} %`}</TableCell>
            <TableCell align="right">{this.ccyFormat(invoiceTaxes)} <span>&#8377;</span> </TableCell>
          </TableRow>
          <TableRow>

            <TableCell colSpan={3}>Total</TableCell>
            <TableCell align="right">{this.ccyFormat(invoiceTotal)} <span>&#8377;</span> </TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </Paper>
    <Typography   align='center' >

    <Button variant="contained" color="secondary" className={classes.button} onClick={this.ContinueShoping}>
       Continue Shopping
        <CartIcon className={classes.rightIcon} />
      </Button>
      <Button variant="contained" color="primary" className={classes.button} onClick={this.BuyNow} >
       Buy Now
        {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
        <WorkIcon className={classes.rightIcon}/>
      </Button>

        </Typography>
    </div>
  );
}

render() {
  const { classes } = this.props;

return(<div>

  {(this.state.rows.length>0)?this.SpanningTable(classes):this.EmptyCart(classes)}

</div>)
}
}
ShowCart.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShowCart);
