import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import green from '@material-ui/core/colors/green';

import Slide from '@material-ui/core/Slide';
import { getData } from "../FetchService"
import { postData,ClientURL } from "../FetchService"
import renderHTML from 'react-render-html';
import deepOrange from '@material-ui/core/colors/deepOrange'


const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

let counter = 0;


const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },chip: {
    margin: theme.spacing.unit,
  },
  greenchip: {

    color: '#fff',
    backgroundColor: green[500],
  },

  orangechip: {
    color: '#fff',
    backgroundColor: deepOrange[500],
     },
});

class ReviewTest extends React.Component {
  state = {
    rows: [],
    page: 0,
    rowsPerPage: 1,
    result:[],
    clist:[],
    open:false,
    question:'',
    answer:'',
  };

  async componentDidMount() {



     console.log('TAB',this.props.body)

     const clist = await postData(`useranswer/fetchquestionanswersresultsummary`,this.props.body);
     await this.setState({ clist });
     const list = await postData(`useranswer/fetchquestionanswersresult`,this.props.body);

    await this.setState({ rows:list });
     console.log(`Data ${this.state.rows}`)
     }




  async componentDidUpdate(prevProps,prevState) {


   if(prevProps.body!=this.props.body){
    console.log('TAB',this.props.body)

    const clist = await postData(`useranswer/fetchquestionanswersresultsummary`,this.props.body);
    await this.setState({ clist });
    const list = await postData(`useranswer/fetchquestionanswersresult`,this.props.body);

   await this.setState({ rows:list });
    console.log(`Data ${this.state.rows}`)
    }
  }


  handleClickOpen =  (question,answer) => {

    this.setState({ open: true });
    this.setState({question})
    this.setState({answer})
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  resultChip=(classes)=>{

    let rec=this.state.clist[0]
    console.log('summary:',rec)
   var total,cs,cr,ca,ia,tm
    if(rec!==undefined)
    {total=`Total Questions: ${rec.totalquestion}`
     cs=`Questions Submit: ${rec.countsubmit}`
     cr=`Questions Review: ${rec.countreview}`
     ca=`Correct Answer: ${rec.correctanswer}`
     ia=`Incorrect Answer: ${rec.incorrectanswer}`
     tm=`Total Marks: ${rec.correctanswer}`
    }
    else
    { total=`Total Questions`
     cs=`Questions Submit`
     cr=`Questions Review`
     ca=`Correct Answer`
     ia=`Incorrect Answer`
     tm=`Total Marks`
  }
return(
<div>
<Chip
        icon={<FaceIcon />}
        label={total}

        className={classes.chip}
        color="primary"

        deleteIcon={<DoneIcon />}
      />
      <Chip
        icon={<FaceIcon />}
        label={cs}

        className={classes.chip}
        color="primary"

        deleteIcon={<DoneIcon />}
      />
       <Chip
        icon={<FaceIcon />}
        label={cr}

        className={classes.chip}
        color="primary"

        deleteIcon={<DoneIcon />}
      />
       <Chip
        icon={<FaceIcon />}
        label={ca}

        className={classes.greenchip}
        color="primary"

        deleteIcon={<DoneIcon />}

      />
       <Chip
        icon={<FaceIcon />}
        label={ia}

        className={classes.chip}
        color="secondary"

        deleteIcon={<DoneIcon />}
      />
        <Chip
        icon={<FaceIcon />}
        label={tm}

        className={classes.orangechip}
        color="primary"

        deleteIcon={<DoneIcon />}
      />
</div>
) }
  solutionDialog=()=>{
    return(

        <div>

          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">Solution</DialogTitle>
            <DialogContent>
              <DialogContentText>

              {renderHTML(this.state.question)}
              {renderHTML(this.state.answer)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Close
              </Button>

            </DialogActions>
          </Dialog>
        </div>


    );


    }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
       {this.resultChip(classes)}
        <div className={classes.tableWrapper}>

          <Table className={classes.table}>
          <TableHead>
          <TableRow>
            <TableCell>Question No</TableCell>
            <TableCell>Question</TableCell>
            <TableCell>Answer</TableCell>

            <TableCell>Your Answer</TableCell>

            <TableCell>Status</TableCell>
            <TableCell>Solution</TableCell>
         </TableRow>
        </TableHead>
            <TableBody>
              {this.state.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {

                 let cid=`${row.questionid}`;
                 let options=`<hr><table width='100%' cellspacing='10' cellpadding='10'><tr><td><b><font color='green'>A]</font></b> ${row.option1}</td><td><b><font color='green'>B]</font></b> ${row.option2}<td><tr><td><b><font color='green'>C]</font></b> ${row.option3}</td><td><b><font color='green'>D]</font></b> ${row.option4}</td></tr></table>`
                 let qno=`<font color='red' size='12'>${row.questionno}</font>`
                  let url=''
                 if(row.answer==row.userans && row.statusquestion=='submit')
                 {
                   url=`/images/tick.png`;
                 }
                 else{

                   url=`/images/crs.png`;
                 }
                 return (

                  <TableRow key={row.questionid}>
                    <TableCell component="th" scope="row">
                      {renderHTML(qno)}

                    </TableCell>
               <TableCell><div>{renderHTML(row.question)}</div>{renderHTML(options)}</TableCell>
                    <TableCell>{row.answer}</TableCell>
                    <TableCell>{row.userans}</TableCell>
                    <TableCell><img src={url}></img><br></br>{row.statusquestion}</TableCell>
                    <TableCell><Button variant="outlined" color="primary" className={classes.button} onClick={()=>this.handleClickOpen(row.question,row.solution)}>
            Solution
          </Button></TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[1,5, 10, 25]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {this.solutionDialog()}
        </div>
      </Paper>
    );
  }
}

ReviewTest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReviewTest);
