/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import { Typography, LinearProgress, Link } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { Player } from "video-react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActionArea from "@material-ui/core/CardActionArea";
import { ClientURL, postData, BaseURL } from "../FetchService";
import { makeStyles } from "@material-ui/core/styles";
import renderHtml from "react-render-html";
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#263475",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function Python(props) {
  const classes = useStyles();
  const [viewLess, setViewLess] = useState("");
  const [viewMore, setviewMore] = useState("");
  const [Edit, setEdit] = useState("ViewMore");
  const [save, setsave] = useState(
    <img src={`/images/add.png`} height="2%" width="2%"></img>
  );
  const [status, setstatus] = useState(true);
  const [expanded, setexpanded] = useState(null);
  const [sh, setsh] = useState("");
  const [expansiondata, setexpansiondata] = useState([]);
  const [innerdata, setinnerdata] = useState([]);
  const [setOpen, setsetOpen] = useState(false);
  const [dialogdata, setdialogdata] = useState({});
  const [details, setdetails] = useState({});
  const [description, setDescription] = useState("");
  //   state={
  //     viewLess:'',
  //     viewMore:'',
  //     Edit:'ViewMore',
  //     save:<img src={`/images/add.png`} height='2%'width='2%'></img>,
  //     append:'',
  //     expanded: null,

  //     sh:'',
  //     expansiondata:[],
  //     innerdata:[],
  //     setOpen:false,
  //     dialogdata:{}
  //   };

  const handleClick = async () => {
    let record = details;
    record.setorg = "#" + record.coursename;
    record.status = "Paid";
    record.organizationname = record.coursename;

    if (!localStorage.getItem("user")) {
      if (record.status == "Paid") props.cartaction("USERLOGIN", record);
      else props.cartaction("USERLOGINDEMO", record);
    } else {
      let u = JSON.parse(localStorage.getItem("user"));
      let body = { userid: u.email, setorg: record.setorg };

      const result = await postData(`purchase/checkuserpurchase`, body);
      if (!result.RESULT) {
        if (record.status == "Paid") {
          await localStorage.setItem(record.setorg, JSON.stringify(record));
          props.cartaction("BADAGE", record);
        } else {
        }
      } else {
        alert("This Course is Already Exist in Your Account.....");
      }
    }
  };

  const checkStatus = async (d) => {
    let record = d;
    record.setorg = "#" + record.coursename;
    record.status = "Paid";
    record.organizationname = record.coursename;

    if (!localStorage.getItem("user")) {
    } else {
      let u = JSON.parse(localStorage.getItem("user"));
      let body = { userid: u.email, setorg: record.setorg };

      const result = await postData(`purchase/checkuserpurchase`, body);
      if (!result.RESULT) {
        if (record.status == "Paid") {
          setstatus(true);
        } else {
          setstatus(true);
        }
      } else {
        setstatus(false);
      }
    }
  };

  const readAllRecords = async () => {
    console.log(props.id);
    let body = {
      courseId: localStorage.getItem("PYTHON"),
    };
    console.log(body);
    let result = await postData("chapterdetails/fetchChapter", body);
    let body2 = { courseId: localStorage.getItem("PYTHON") };
    let desc = await postData("coursedetails/fetchdataByCourse", body2);

    console.log("DATA DESCRIPTION...... ", desc[0]);
    setdetails(desc[0]);
    setDescription(desc[0].coursedescription);
    // this.setState({expansiondata:result})
    setexpansiondata(result);
    console.log(result);
    checkStatus(desc[0]);
  };

  const getDataFromId = async (id) => {
    let body = {
      chapterId: id,
    };
    let result = await postData("topicdetails/fetchbychapterid", body);
    console.log(result);
    // this.setState({innerdata:result})
    setinnerdata(result);
  };

  useEffect(() => {
    readAllRecords();
  }, []);

  const handleChange = (panel) => (event, expanded) => {
    // this.setState({
    //   expanded: expanded ? panel : false,
    // })
    setexpanded(expanded ? panel : false);
  };

  const handleClickOpen = (data) => {
    console.log(data);
    let body = {
      topicid: data.topicid,
      courseid: data.courseid,
      chapterid: data.chapterid,
      topicname: data.topicname,
      videoname: data.videoname,
      videoduration: data.videoduration,
      topicdescription: data.topicdescription,
      posterimage: data.posterimage,
      videotype: data.videotype,
    };
    // this.setState({dialogdata:body})
    setdialogdata(body);
    // this.setState({setOpen:true})
    setsetOpen(true);
  };

  const handleClose = () => {
    // this.setState({setOpen:false})
    setsetOpen(false);
  };

  const showDialog = () => {
    return (
      <div>
        <Dialog
          fullWidth
          width={"lg"}
          open={setOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dialogdata.topicname}
          </DialogTitle>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* {this.state.dialogdata.topicname} */}
              <Player
                playsInline
                poster={dialogdata.posterimage}
                src={`${BaseURL}/images/${dialogdata.videoname}`}
              />
            </DialogContentText>
          </DialogContent>
          {/* <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Disagree
                  </Button>
                  <Button onClick={this.handleClose} color="primary" autoFocus>
                    Agree
                  </Button>
                </DialogActions> */}
        </Dialog>
      </div>
    );
  };

  return (
    <div>
      {showDialog()}
      <Paper
        style={{
          background: "#263475",
          height: "auto",
          width: "100%",
          marginTop: "0%",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={8}>
            <div style={{ backgroundColor: "#263475", width: "100%" }}>
              <p
                style={{
                  marginLeft: "10%",
                  fontSize: "40px",
                  color: "white",
                  marginRight: "-2%",
                  paddingTop: "50px",
                  // marginTop: "-30px",
                  width: "70%",
                }}
              >
                Online {details.coursename} Certification Training Course
              </p>
            </div>
            <div
              style={{ marginLeft: "10%", fontSize: "30px", color: "white" }}
            >
              Live project Based Training..
            </div>
            <Typography
              style={{
                color: "white",
                height: "100%",
                width: "70%",
                marginLeft: "10%",
                marginTop: "2%",
                fontSize: "20px",
              }}
            >
              <hr />
              Duration:4 weeks | Certifited trainings
              <div>{details.coursename}:</div>
              <p>{details.coursedescription1} By Campusshala.. </p>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              style={{
                backgroundColor: "white",
                marginTop: "0%",
                // maxWidth: "430px",
                height: "auto",
                width: "auto",
                maxWidth: "100%",
              }}
            >
              {/* <CardActionArea> */}
              <img
                src={`${BaseURL}/images/${details.courseicon}`}
                height="30%"
                width="100%"
              ></img>

              <CardContent>
                {status ? (
                  <>
                    <span>
                      <img src={`/images/rupee.png`}></img>
                      <Typography
                        style={{
                          marginLeft: "9%",
                          marginTop: "-10%",
                          fontSize: "25px",
                        }}
                      >
                        {" "}
                        {details.price}/-
                      </Typography>
                    </span>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleClick()}
                      style={{
                        marginTop: "1.5%",
                        width: "300px",
                        height: "50px",
                        marginLeft: "8%",
                      }}
                    >
                      Add to Cart
                    </Button>

                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      style={{
                        marginTop: "5%",
                        width: "300px",
                        height: "50px",
                        marginLeft: "8%",
                      }}
                    >
                      Buy
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => alert("")}
                      style={{
                        marginTop: "1.5%",
                        width: "300px",
                        height: "50px",
                        marginLeft: "8%",
                      }}
                    >
                      Generate Certificate
                    </Button>
                  </>
                )}

                <span>
                  <Button
                    style={{
                      marginLeft: "18%",
                      fontSize: "10px",
                      marginTop: "2%",
                    }}
                    href="/Pdf/PYTHONML.pdf"
                  >
                    <img
                      src={`/images/download.png`}
                      height="10%"
                      width="10%"
                      style={{ marginLeft: "2px" }}
                    ></img>{" "}
                    <b style={{ fontSize: "10px" }}>
                      Download detailed table of contents
                    </b>
                  </Button>
                </span>

                <Typography style={{ fontSize: "20px", marginTop: "4%" }}>
                  30-Day Money Back Guarantee
                </Typography>
                <p
                  style={{
                    marginLeft: "3%",
                    marginTop: "3%",
                    fontSize: "15px",
                  }}
                >
                  {" "}
                  This courses inculdes
                </p>
                <p style={{ marginLeft: "4%" }}> 1.One Year Access</p>
                <p style={{ marginLeft: "4%" }}>
                  {" "}
                  2.Access on mobile and laptop
                </p>
                <p style={{ marginLeft: "4%" }}>
                  {" "}
                  3.Certificate after the Successful Completion
                </p>
              </CardContent>
              {/* </CardActionArea> */}
              <CardActions></CardActions>
            </Card>
          </Grid>
        </Grid>
      </Paper>
      {/* <center> */}
      <Card
        style={{
          width: "64%",
          height: "auto",
          flexDirection: "row",
          margin: "2% auto 0 auto",
          padding: 20,
        }}
      >
        <Typography
          style={{
            fontSize: "20px",
            borderBottom: "#263475 1px dotted",
            textAlign: "center",
          }}
        >
          <b>{details.coursename}:</b>
        </Typography>
        {/* <Grid container spacing={32}>
          <Grid item xs={6} style={{ fontSize: "13px", display: "flex" }}>
            <img
              src={`/images/dot.png`}
              style={{ height: "20px", marginTop: "15px", marginLeft: "1%" }}
            ></img>
            <p
              style={{
                marginLeft: "1%",
                textAlign: "flex",
                marginRight: "2%",
                paddingTop: "-10px",
              }}
            >
              Campusshala Python programming certification course enables you to
              learn Python from scratch.
            </p>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ marginTop: "1%", display: "flex", fontSize: "13px" }}
          >
            <img
              src={`/images/dot.png`}
              style={{ height: "20px", marginTop: "%" }}
            ></img>{" "}
            <p
              style={{
                fontSize: "13px",
                textAlign: "auto",
                marginLeft: "",
                color: "black",
                marginTop: "-3px",
              }}
            >
              The 6-week training program covering essential concepts on the
              building blocks of Python, data operations, file operations,
              object-oriented programming and various Python libraries such as
              Pandas, Numpy, Matplotlib which are essential for Data Science.{" "}
            </p>
          </Grid>
        </Grid> */}
        {/* <Grid container spacing={32}>
        <Grid
            item
            xs={6}
            style={{ marginTop: "1%", display: "flex", fontSize: "13px" }}
          >
            <img
              src={`/images/dot.png`}
              style={{ height: "20px", marginTop: "%" }}
            ></img>{" "}
            <p
              style={{
                fontSize: "13px",
                textAlign: "auto",
                marginLeft: "",
                color: "black",
                marginTop: "-3px",
              }}
            >
              For the practical exposure, this training program will come with
              assignments, assessment tests, code challenges, quizzes, and
              exercisesassessment tests code challenges, quizzes, and exercises.
            </p>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ marginTop: "1%", display: "flex", fontSize: "13px" }}
          >
            <img
              src={`/images/dot.png`}
              style={{ height: "20px", marginTop: "%" }}
            ></img>{" "}
            <p
              style={{
                fontSize: "13px",
                textAlign: "auto",
                marginLeft: "",
                color: "black",
                marginTop: "-3px",
              }}
            >
              At the end of this training program, you will build a live project
              with you. Doubt clearing session will be available for you 1 hour
              per day on daily basis.
            </p>
          </Grid>
        </Grid> */}
        {renderHtml(description)}
      </Card>
      {/* </center> */}
      <Typography
        style={{
          marginLeft: "42%",
          marginTop: "2%",
          fontSize: "20px",
          color: "#264375",
        }}
      >
        <b> Course Content </b>
      </Typography>

      <Card
        style={{
          width: "60%",
          height: "50%",
          margin: "1% auto 2% auto",
        }}
      >
        <Typography
          style={{
            fontSize: "15px",
            fontWeight: "bold",
            marginLeft: "5%",
            marginTop: "2%",
            color: "#264375",
          }}
        >
          {details.coursename}
          <span>
            {/* <Button style={{fontSize:'10px',marginTop:'2%'}}href="/Pdf/Node.pdf" >
<img src={`/images/download.png`}  style={{ marginTop:'-50%',height:'20px',width:'20px'}}></img> <b style={{fontSize:'10px',color:'#264375'}}></b>
</Button> */}
          </span>
        </Typography>

        {expansiondata.map((item, index) => {
          return (
            <ExpansionPanel
              onClick={() => getDataFromId(item.chapterid)}
              style={{ height: "7%", marginTop: "2%" }}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                {save}
                <Typography style={{ marginTop: "-0.07%", color: "#264375" }}>
                  <b>
                    {index + 1}. {item.chaptername}
                  </b>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div style={{ fontSize: "12px", color: "#264375" }}>
                  {/* 1.1 Why Python? */}
                  {innerdata.map((i, no) => {
                    return (
                      <div
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          fontSize: "12px",
                          color: "#264375",
                          padding: 5,
                        }}
                      >
                        <span style={{ left: 0 }}>
                          {index + 1}.{no + 1} {i.topicname}
                        </span>
                        {status ? (
                          i.videotype == "Free" ? (
                            <span
                              style={{ position: "absolute", right: 20 }}
                              onClick={() => handleClickOpen(i)}
                            >
                              <Link href="javascript:;">Preview</Link>
                            </span>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )
                        ) : (
                          <>
                            <span
                              style={{ position: "absolute", right: 25 }}
                              onClick={() => handleClickOpen(i)}
                            >
                              <Link href="javascript:;">Play</Link>
                            </span>
                          </>
                        )}
                        {/* {i.videotype == "Free" ? (
                          <span
                            style={{ position: "absolute", right: 20 }}
                            onClick={() => handleClickOpen(i)}
                          >
                            <Link href="javascript:;">Preview</Link>
                          </span>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )} */}
                      </div>
                    );
                  })}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </Card>

      <Paper style={{ height: "auto", padding: 20 }}>
        <Typography
          style={{
            // marginLeft: "38%",
            // marginTop: "2%",
            fontSize: "20px",
            color: "#264375",
            borderBottom: "#263475 1px dotted",
            width: "35%",
            margin: "2% auto 0 auto",
          }}
        >
          Extra benefits of Training from Campusshala:{" "}
        </Typography>
        <Grid container justifyContent={"center"} spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card
              style={{
                width: "auto",
                height: "auto",
                margin: "2% auto 0 auto",
                padding: 15,
              }}
            >
              <CardContent>
                <div style={{ float: "left" }}>
                  <img
                    src={`/images/certificate.png`}
                    style={{ color: "#264375" }}
                  ></img>
                </div>
                <div
                  style={{
                    float: "right",
                    position: "absolute",
                    marginLeft: "7%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "15px",
                      color: "#264375",
                    }}
                  >
                    Certificate after Completion of Training:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#264375",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    After the successful completion of training, the trainee
                    will be awarded <br /> with Certificates.{" "}
                  </Typography>
                </div>
                <div style={{ clear: "left" }} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              style={{
                width: "auto",
                height: "auto",
                margin: "2% auto 0 auto",
                padding: 22,
              }}
            >
              <CardContent>
                <div style={{ float: "left" }}>
                  <img
                    src={`/images/question.png`}
                    style={{ color: "#264375" }}
                  ></img>
                </div>
                <div
                  style={{
                    float: "right",
                    position: "absolute",
                    marginLeft: "7%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "15px",
                      color: "#264375",
                    }}
                  >
                    Live Support 24*7:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#264375",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    A human who is ridiculously committed to answer all
                    <br /> your queries.{" "}
                  </Typography>
                </div>
                <div style={{ clear: "left" }} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container justifyContent={"center"} spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card
              style={{
                width: "auto",
                height: "auto",
                margin: "2% auto 0 auto",
                padding: 23,
              }}
            >
              <CardContent>
                <div style={{ float: "left" }}>
                  <img
                    src={`/images/user.png`}
                    style={{ color: "#264375" }}
                  ></img>
                </div>
                <div
                  style={{
                    float: "right",
                    position: "absolute",
                    marginLeft: "7%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "15px",
                      color: "#264375",
                    }}
                  >
                    Project Development during Training:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#264375",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    This training will be a project based training. Trainee will
                    <br /> be having a project after the completion of Training.{" "}
                  </Typography>
                </div>
                <div style={{ clear: "left" }} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              style={{
                width: "auto",
                height: "auto",
                margin: "2% auto 0 auto",
                padding: 15,
              }}
            >
              <CardContent>
                <div style={{ float: "left" }}>
                  <img
                    src={`/images/exercise.png`}
                    style={{ color: "#264375", width: "80%", height: "80%" }}
                  ></img>
                </div>
                <div
                  style={{
                    float: "right",
                    position: "absolute",
                    marginLeft: "7%",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "15px",
                      color: "#264375",
                    }}
                  >
                    Exercises & Assignment:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "13px",
                      color: "#264375",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    This training will be packed with daily dose of assignments
                    so
                    <br /> that it brings more clarity in understanding the
                    concepts during training.{" "}
                  </Typography>
                </div>
                <div style={{ clear: "left" }} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

Python.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Python;
