import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import {   getData } from '../../../FetchService';

import renderHTML from 'react-render-html';
export default function ListCourseDetails(props) {
	const [ getColumn ] = React.useState({
		columns: [
			// {
			// 	title: 'Poster',
			// 	field: 'posterimage',
			// 	render: (rowData) => (
			// 		<img
			// 			src={`${BaseUrl}/images/${rowData.posterimage}`}
			// 			width="30"
			// 			height="30"
			// 			style={{ borderRadius: '50%' }}
			// 		/>
			// 	)
			// },
			{
				title: 'Course',
				field: 'coursename'
			},
			{ title: 'Articles', field: 'articles' },
			{
				title: 'Excersizes',
				field: 'excersizes'
			},

			{
				title: 'What Will Learn',
				field: 'whatwilllearn',
				render: (rowData) => <div>{renderHTML(rowData.whatwilllearn)}</div>
			},
			{
				title: 'Course Description',
				field: 'coursedescription',
				render: (rowData) => <div>{renderHTML(rowData.coursedescription)}</div>
			},
			{
				title: 'Requirements',
				field: 'requirements',
				render: (rowData) => <div>{renderHTML(rowData.requirements)}</div>
			}

			// { title: 'Video', field: 'mobile', render: (rowData) => <div>{rowData.videoname}</div> }
		]
	});

	const [ state, setState ] = React.useState({ data: [] });

	const fetchAllStudent = async () => {
		let list = await getData('coursedetails/fetchdata');
		setState({ data: list });
	};

	useEffect(function() {
		fetchAllStudent();
	}, []);

	return (
		<MaterialTable
			title="Course Details list"
			columns={getColumn.columns}
			data={state.data}
			actions={[
				{
					icon: 'save',
					tooltip: 'Save User',
					// onClick: (event, rowData) => alert("You saved " + rowData.studentid)
					onClick: (event, rowData) => {
						props.history.push({
							pathname: '/CourseDetailsUpdate',
							state: { id: rowData.coursedetailsid }
						});
					}
				}
			]}
			components={{
				Action: (props) => (
					<Button
						onClick={(event) => props.action.onClick(event, props.data)}
						color="primary"
						variant="contained"
						style={{ textTransform: 'none' }}
						size="small"
					>
						Change
					</Button>
				)
			}}
		/>
	);
}
