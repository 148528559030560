import React, { useState } from 'react';
import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';
import {  Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Avatar from '@material-ui/core/Avatar';
import { postData, postDataAndImage } from '../../../FetchService';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root:{
		justifyContent: 'center',
		alignContent: 'center'
	},
	paper: { padding: '30px', marginTop: '10px' },
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	dense: {
		marginTop: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1)
	},
	input: {
		display: 'none'
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
	bigAvatar: {
		margin: 10,
		width: 60,
		height: 60
	}
});
function CourseUpload(props) {
	// const classes = useStyles();
	const [ CourseName, setCourseName ] = React.useState('');
	const [ CourseDescription, setCourseDescription ] = React.useState('');
	const [price, setprice] = useState(0)
	const [ CourseIcon, setCourseIcon ] = React.useState({ icon: '', file: '' });
	const [ message, setMessage ] = React.useState('');

	const addNewRecord = async () => {
		if (CourseName !== '' && CourseDescription !== '' && CourseIcon.file !== '') {
			let body1 = {
				courseName: CourseName
			};
			const result = await postData('course/checkCourse', body1);
			console.log('result ', result);

			if (result.result) {
				setMessage('Course Name Already Existed.....');
			} else {
				let formData = new FormData();
				formData.append('courseName', CourseName);
				formData.append('courseDescription', CourseDescription);
				formData.append('price',price)
				formData.append('courseIcon', CourseIcon.file);
				const config = { headers: { 'content-type': 'multipart/form-data' } };
				const result = await postDataAndImage('course/addCourse', formData, config);
console.log(result)
				if (result === true) {
					setMessage('Course Submitted.....');
					setTimeout(function() {
						clearValues();
					}, 3000);
				} else {
					setMessage(' Not Submitted..........');
				}
			}
		} else {
			setMessage('First Fill All The Fields .....');
		}
	};

	const clearValues = () => {
		setCourseName('');
		setCourseDescription('');
		setCourseIcon('');
		setprice(0);
		setMessage('');
	};

	const { classes } = props;

	return (<div >
		<Grid container maxWidth="xs" className={classes.root}>
			<Paper className={classes.paper}>
				<Typography>Course Registration</Typography>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							id="outlined-dense"
							label="Course Name"
							className={clsx(classes.textField, classes.dense)}
							margin="dense"
							value={CourseName}
							variant="outlined"
							onChange={(event) => setCourseName(event.target.value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							className={clsx(classes.textField, classes.dense)}
							id="outlined-multiline-static"
							label="Description"
							value={CourseDescription}
							multiline
							rows="4"
							variant="outlined"
							onChange={(event) => setCourseDescription(event.target.value)}
							fullWidth
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							id="outlined-dense"
							label="Course Price"
							className={clsx(classes.textField, classes.dense)}
							margin="dense"
							value={price}
							variant="outlined"
							onChange={(event) => setprice(event.target.value)}
							fullWidth
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<input
							accept="image/*"
							className={classes.input}
							id="contained-button-file"
							multiple
							type="file"
							onChange={(event) =>
								setCourseIcon({
									icon: URL.createObjectURL(event.target.files[0]),
									file: event.target.files[0]
								})}
						/>
						<label htmlFor="contained-button-file">
							<Button variant="contained" component="span" className={classes.button}>
								Upload
								<CloudUploadIcon className={classes.rightIcon} />
							</Button>
						</label>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Avatar alt="Image" src={CourseIcon.icon} className={classes.bigAvatar} />
					</Grid>

					<Grid item xs={12}>
						<Button
							variant="contained"
							onClick={addNewRecord}
							color="primary"
							className={classes.button}
							fullWidth
						>
							Submit
						</Button>
					</Grid>
				</Grid>
				<Typography>&nbsp;&nbsp;{message}</Typography>
			</Paper>
			
		</Grid>
		</div>
	);
}
export default withStyles(styles)(CourseUpload);
