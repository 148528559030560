import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class  PaymentForm extends React.Component {
    state={
      name_on_card:'',
      card_number:'',
      exp_number:'',
      cvv:'',
    }
    componentWillMount()
    {
      this.setState(this.props.states)
    }
    
    render(){
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} md={6}>
          <TextField 
           required 
           id = "name_on_card"
           name = 'name_on_card'
           value={this.state.name_on_card}
           label="Name on card"
           onChange={e=>{this.props.states.name_on_card=e.target.value; this.setState({name_on_card:e.target.value})}} 
           fullWidth
            />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
          required 
          id="card_number"
          name="card_number" 
          value={this.state.card_number}
          label="Card number" 
          onChange={e=>{this.props.states.card_number=e.target.value;this.setState({card_number:e.target.value})}} 
          fullWidth 
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
          required 
          id="exp_date"
          name='exp_date' 
          value={this.state.exp_date}
          label="Expiry date" 
          fullWidth
          onChange={e=>{this.props.states.exp_date=e.target.value;this.setState({exp_date:e.target.value})}}
           />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            type='password'
            name="cvv"
            value={this.state.cvv}
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            onChange={e=>{this.props.states.cvv=e.target.value;this.setState({cvv:e.target.value})}}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
}
export default PaymentForm;