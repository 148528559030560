import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import Button from '@material-ui/core/Button';
import { postData ,ClientURL,getData} from "../FetchService"

const actionsStyles = theme => ({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing.unit * 2.5,
    },
  });
class TablePaginationActions extends React.Component {
    handleFirstPageButtonClick = event => {
      this.props.onChangePage(event, 0);
    };
  
    handleBackButtonClick = event => {
      this.props.onChangePage(event, this.props.page - 1);
    };
  
    handleNextButtonClick = event => {
      this.props.onChangePage(event, this.props.page + 1);
    };
  
    handleLastPageButtonClick = event => {
      this.props.onChangePage(
        event,
        Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
      );
    };
  
    render() {
      const { classes, count, page, rowsPerPage, theme } = this.props;
  
      return (
        <div className={classes.root}>
          <IconButton
            onClick={this.handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="First Page"
          >
            {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={this.handleBackButtonClick}
            disabled={page === 0}
            aria-label="Previous Page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          </IconButton>
          <IconButton
            onClick={this.handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Next Page"
          >
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </IconButton>
          <IconButton
            onClick={this.handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="Last Page"
          >
            {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      );
    }
  }
  
  TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
  };
  
  const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
    TablePaginationActions,
  );
  
  let counter = 0;
  function createData(name, calories, fat) {
    counter += 1;
    return { id: counter, name, calories, fat };
  }
  
  const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
    },
    table: {
      minWidth: 500,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  });
  








class DisplayAllInterns extends React.Component {
    state = {
        rows: [],
        page: 0,
        rowsPerPage: 5,
        from: new Date(),
        to: new Date(),

      };
    
      handleChangePage = (event, page) => {
        this.setState({ page });
      };
    
      handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
      };
    async componentWillMount(){
     let body={idemployer:this.props.id}
       let s=await postData('internship/allinterns',body)
     
       this.setState({rows:s})
     }
     allinterns=async()=>{
      let body={idemployer:this.props.id,from:this.state.from,to:this.state.to}
      let result= await postData('internship/listOfAllIternForEmmployers',body);
    console.log('result', result)
    this.setState({rows:result});
    this.tableData();
    }
   

  handleDateChange = date => {
    this.setState({ from: date });
  };
  handleDateChange2 = date => {
    this.setState({ to: date });
  };
  tableData(){
    const { classes } = this.props;

    const { rows, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
      return(
        
<Paper className={classes.root}>
     
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
          <TableHead>
          <TableRow>
            <TableCell>Intern Id</TableCell>
            <TableCell align="right">First Name</TableCell>
            <TableCell align="right">Last Name</TableCell>
            <TableCell align="right">email</TableCell>
            <TableCell align="right">Mobile</TableCell>
            <TableCell align="right">Status</TableCell>

          </TableRow>
        </TableHead>
            <TableBody>
              {this.state.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                <TableRow key={row.user_id}>
                                    <TableCell align="left">{row.user_id}</TableCell>

                  <TableCell align="right">
                    {row.first_name}
                  </TableCell>
                  <TableCell align="right">{row.last_name}</TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.mobile}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>

                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
      )
                
  }
  render() {
    const { classes } = this.props;
    const { selectedDate } = this.state;
    const { rows, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
        <div>
        
        <Typography style={{display:'flex', justifyContent:'center',marginTop:'4px',marginBottom:'0px'}}>
          <b>List of Intern Applied in Your Organization</b>
        </Typography>
            <Grid container spacing={24} >
            
        <Grid item xs={3}>
        <Typography  gutterBottom style={{marginTop:'20%',marginLeft:'80%'}}>From</Typography></Grid>
        <Grid item xs={3}>

      <MuiPickersUtilsProvider utils={DateFnsUtils} >
        <Grid container className={classes.grid} justify="space-around"style={{marginTop:'10%'}}> 
          <DatePicker
            margin="normal"
            value={this.state.from}
            onChange={this.handleDateChange}
          />
          
        </Grid>
      </MuiPickersUtilsProvider>
</Grid>
<Grid item xs={3}><Typography  gutterBottom style={{marginTop:'20%',marginLeft:'20%'}}>To</Typography></Grid>
<Grid item xs={3}>  <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container className={classes.grid} justify="space-around" style={{marginLeft:'-80%',marginTop:'10%'}}>
        <DatePicker
          margin="normal"
          value={this.state.to}
          onChange={this.handleDateChange2}
        />
        
      </Grid>
     
    </MuiPickersUtilsProvider></Grid>
    <Grid item xs={3}> <Button variant="outlined" color="primary" className={classes.button} style={{marginLeft:'350%', marginTop:'-30%'}} onClick={this.allinterns}>
    Search
      </Button></Grid>
      <Grid item xs={3}></Grid></Grid>







<div style={{marginTop:'-3%'}}>
{this.tableData(classes)}
    
    </div>
    </div>);
  }
}

DisplayAllInterns.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DisplayAllInterns);
