/* eslint-disable eqeqeq */

import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import 'video-react/dist/video-react.css'; // import css
import { Player } from 'video-react';
import getBlobDuration from 'get-blob-duration';

import clsx from 'clsx';
import { Paper, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';
import { getData,postData,postDataAndImage} from '../../../FetchService';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		width: '80%'
		//marginBottom: '10px'
		//marginLeft: '50px'
	},
	input: {
		display: 'none'
	},
	root: {
		background: '#dff9fb',
		padding: '20px',
		marginTop: '50px',
		marginBottom: '50px',
		width: '70%'
	},

	formLabel: {
		marginTop: '10px'
	},
	button: {
		marginTop: '10px'
	}
}));

export default function TopicUpload() {
	const classes = useStyles();

	const [ getCourse, setCourse ] = useState('');
	const [ getChapter, setChapter ] = useState('');
	const [ getTopic, setTopic ] = useState('');
	const [ getVideoName, setVideoName ] = useState('');
	const [ getTopicDescription, setTopicDescription ] = useState('');
	const [ getVideoPath, setVideoPath ] = useState({ file: '' });
	const [ getVideoPlay, setVideoPlay ] = useState('');

	const [ getDuration, setDuration ] = useState('');
	const [ getVideoType, setVideoType ] = useState('');
	const [ getCourseList, setCourseList ] = useState([]);
	const [ getChapterList, setChapterList ] = useState([]);
	const [ getVideoIcon, setVideoIcon ] = React.useState({ icon: '', file: '' });
	const [ getMessage, setMessage ] = useState('');

	const handleSubmit = async () => {
		if (
			getCourse != '' &&
			getChapter != '' &&
			getTopic != '' &&
			getVideoName != '' &&
			getDuration != '' &&
			getTopicDescription != '' &&
			getVideoPath.file != '' &&
			getVideoIcon.file != '' &&
			getVideoType != ''
		) {
			let body1 = {
				courseId: getCourse,
				chapterId: getChapter,
				topicName: getTopic
			};
			const result = await postData('topicdetails/checkTopic', body1);
			console.log('result ', result);

			if (result.result) {
				setMessage('Topic Name Already Existed.....');
			} else {
				let formData = new FormData();
				formData.append('courseId', getCourse);
				formData.append('chapterId', getChapter);
				formData.append('topicName', getTopic);
				formData.append('videoName', getVideoName);
				formData.append('videoDuration', getDuration);
				formData.append('topicDescription', getTopicDescription);
				formData.append('videoPath', getVideoPath.file);
				formData.append('videoType', getVideoType);
				formData.append('videoImage', getVideoIcon.file);
				const config = { headers: { 'content-type': 'multipart/form-data' } };
				const result = await postDataAndImage('topicdetails/addTopic', formData, config);
				console.log(result)
				if (result) {
					setMessage('Record Submitted.....');
					setTimeout(function() {
						clearValues();
					}, 3000);
				} else {
					setMessage(' Not Submitted..........');
				}
			}
		} else {
			setMessage('First Fill All The Fields .....');
		}
	};
	const clearValues = () => {
		setCourse('');
		setChapter('');
		setTopic('');
		setVideoName('');
		setVideoIcon({ icon: '', file: '' });
		setVideoPath('');
		setTopicDescription('');
		setDuration('');
		setVideoType('');
		setMessage('');
	};

	const handleVideoPath = (event) => {
		// setMetadata(null);
		var file = URL.createObjectURL(event.file);

		getBlobDuration(file).then(function(duration) {
			var mind = duration % (60 * 60);
			var minutes = Math.floor(mind / 60);

			var secd = mind % 60;
			var seconds = Math.ceil(secd);

			console.log('time--', `${minutes}:${seconds} mins`);

			setDuration(`${minutes}:${seconds}`);
		});

		setVideoPath({ file: event.file });
		setVideoPlay(file);
		setVideoName(`${event.file.name}`);
	};
	const fillCourse = async () => {
		const result = await getData('course/fetchdata');
		console.log('course', result);

		setCourseList(result);
	};
	const handleChapter = async (event) => {
		setCourse(event);
		let body = {
			courseId: event
		};
		const result = await postData('chapterdetails/fetchChapter', body);
		console.log('chapter', result);

		setChapterList(result);
	};
	useEffect(() => {
		fillCourse();
	}, []);
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Paper className={classes.root}>
				<Typography variant="button" display="block" gutterBottom>
					Topic Upload
				</Typography>
				<React.Fragment>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<FormControl  fullWidth>
								<InputLabel id="demo-simple-select-label">Course</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={getCourse}
									fullWidth
									onChange={(event) => handleChapter(event.target.value)}
								>
									{getCourseList.map((item) => {
										return <MenuItem value={`${item.courseid}`}>{item.coursename}</MenuItem>;
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Chapter</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={getChapter}
									fullWidth
									onChange={(event) => setChapter(event.target.value)}
								>
									{
										getChapterList.length==0?<MenuItem>-Select-</MenuItem>:<React.Fragment></React.Fragment>
									}
									{getChapterList.map((item) => {
										return <MenuItem value={`${item.chapterid}`}>{item.chaptername}</MenuItem>;
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Topic Name"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								value={getTopic}
								fullWidth
								onChange={(event) => setTopic(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<input
								accept="video/*"
								className={classes.input}
								id="contained-button-file1"
								multiple
								type="file"
								onChange={(event) => handleVideoPath({ file: event.target.files[0] })}
								// onChange={(e) => {
								// 	// const file = e.target.files[0];
								// 	setVideoFile(e.target.files[0]);
								// }}
							/>
							<label htmlFor="contained-button-file1">
								<Button variant="contained" component="span" className={classes.button} fullWidth>
									Upload Video
									<CloudUploadIcon className={classes.rightIcon} />
								</Button>
							</label>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={{ marginLeft: 10 }}>
								<Player playsInline src={getVideoPlay} />
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<input
								accept="image/*"
								className={classes.input}
								id="contained-button-file"
								multiple
								type="file"
								onChange={(event) =>
									setVideoIcon({
										icon: URL.createObjectURL(event.target.files[0]),
										file: event.target.files[0]
									})}
							/>
							<label htmlFor="contained-button-file">
								<Button variant="contained" component="span" className={classes.button}>
									Upload Poster image
									<CloudUploadIcon className={classes.rightIcon} />
								</Button>
							</label>
						</Grid>
						<Grid item xs={6} sm={3}>
							<Avatar alt="Image" src={getVideoIcon.icon} className={classes.bigAvatar} />
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Video Name"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								InputProps={{
									readOnly: true
								}}
								value={getVideoName}
								fullWidth
								onChange={(event) => setVideoName(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Video Description"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								value={getTopicDescription}
								fullWidth
								onChange={(event) => setTopicDescription(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Video Type</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={getVideoType}
									fullWidth
									onChange={(event) => setVideoType(event.target.value)}
								>
									<MenuItem value={'Free'}>Free</MenuItem>
									<MenuItem value={'Paid'}>Paid</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								id="standard dense"
								label="Video Duration"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								InputProps={{
									readOnly: true
								}}
								value={getDuration}
								fullWidth
								onChange={(event) => setDuration(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								component="span"
								className={classes.button}
								onClick={handleSubmit}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
					<Typography>{getMessage}</Typography>
				</React.Fragment>
			</Paper>
		</div>
	);
}
