/**
 * @since last change by vishal.jain on 02/06/2020
 * @feature add show or hide password
 */

/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
// import React from "react";
// import { BrowserRouter as Router } from "react-router-dom";
// import {
//   MDBNavbar,
//   MDBNavbarBrand,
//   MDBNavbarNav,
//   MDBNavItem,
//   MDBNavLink,
//   MDBNavbarToggler,
//   MDBCollapse,
//   MDBMask,
//   MDBRow,
//   MDBCol,
//   MDBIcon,
//   MDBBtn,
//   MDBView,
//   MDBContainer,
//   MDBCard,
//   MDBCardBody,
//   MDBInput,
//   MDBFormInline,
//   MDBAnimation
// } from "mdbreact";
// import './index.css';

// class UserLogin extends React.Component {
//   state = {
//     collapseID: ""
//   };

//   toggleCollapse = collapseID => () =>
//     this.setState(prevState => ({
//       collapseID: prevState.collapseID !== collapseID ? collapseID : ""
//     }));

//   render() {
//     const overlay = (
//       <div
//         id="sidenav-overlay"
//         style={{ backgroundColor: "transparent" }}
//         onClick={this.toggleCollapse("navbarCollapse")}
//       />
//     );
//     return (
//       <div id="classicformpage">
//         {/* <Router>
//           <div>
//             <MDBNavbar dark expand="md" fixed="top">
//               <MDBContainer>
//                 <MDBNavbarBrand>
//                   <strong className="white-text">MDB</strong>
//                 </MDBNavbarBrand>
//                 <MDBNavbarToggler
//                   onClick={this.toggleCollapse("navbarCollapse")}
//                 />
//                 <MDBCollapse
//                   id="navbarCollapse"
//                   isOpen={this.state.collapseID}
//                   navbar
//                 >
//                   <MDBNavbarNav left>
//                     <MDBNavItem active>
//                       <MDBNavLink to="#!">Home</MDBNavLink>
//                     </MDBNavItem>
//                     <MDBNavItem>
//                       <MDBNavLink to="#!">Link</MDBNavLink>
//                     </MDBNavItem>
//                     <MDBNavItem>
//                       <MDBNavLink to="#!">Profile</MDBNavLink>
//                     </MDBNavItem>
//                   </MDBNavbarNav>
//                   <MDBNavbarNav right>
//                     <MDBNavItem>
//                       <MDBFormInline waves>
//                         <div className="md-form my-0">
//                           <input
//                             className="form-control mr-sm-2"
//                             type="text"
//                             placeholder="Search"
//                             aria-label="Search"
//                           />
//                         </div>
//                       </MDBFormInline>
//                     </MDBNavItem>
//                   </MDBNavbarNav>
//                 </MDBCollapse>
//               </MDBContainer>
//             </MDBNavbar>
//             {this.state.collapseID && overlay}
//           </div>
//         </Router> */}

//         <MDBView>
//           <MDBMask className="d-flex justify-content-center align-items-center gradient">
//             <MDBContainer>
//               <MDBRow>
//                 <MDBAnimation
//                   type="fadeInLeft"
//                   delay=".3s"
//                   className="white-text text-center text-md-left col-md-6 mt-xl-5 mb-5"
//                 >
//                   <h1 className="h1-responsive font-weight-bold">
//                     Sign up right now!
//                   </h1>
//                   <hr className="hr-light" />
//                   {/* <h6 className="mb-4">
//                     Lorem ipsum dolor sit amet, consectetur adipisicing elit.
//                     Rem repellendus quasi fuga nesciunt dolorum nulla magnam
//                     veniam sapiente, fugiat! Commodi sequi non animi ea dolor
//                     molestiae, quisquam iste, maiores. Nulla.
//                   </h6> */}
//                   {/* <MDBBtn outline color="white">
//                     Learn More
//                   </MDBBtn> */}
//                 </MDBAnimation>

//                 <MDBCol md="6" xl="5" className="mb-4">
//                   <MDBAnimation type="fadeInRight" delay=".3s">
//                     <MDBCard id="classic-card">
//                       <MDBCardBody className="white-text">
//                         <h3 className="text-center">
//                           <MDBIcon icon="user" /> Register:
//                         </h3>
//                         <hr className="hr-light" />
//                         <MDBInput
//                           className="white-text"
//                           iconClass="white-text"
//                           label="Your name"
//                           icon="user"
//                         />
//                         <MDBInput
//                           className="white-text"
//                           iconClass="white-text"
//                           label="Your email"
//                           icon="envelope"
//                         />
//                         <MDBInput
//                           className="white-text"
//                           iconClass="white-text"
//                           label="Your password"
//                           icon="lock"
//                           type="password"
//                         />
//                         <div className="text-center mt-4 black-text">
//                           <MDBBtn color="indigo" onClick={()=>alert("")} >Sign Up</MDBBtn>
//                           <hr className="hr-light" />
//                           <div className="text-center d-flex justify-content-center white-label">
//                             <a href="#!" className="p-2 m-2">
//                               <MDBIcon
//                                 fab
//                                 icon="twitter"
//                                 className="white-text"
//                               />
//                             </a>
//                             <a href="#!" className="p-2 m-2">
//                               <MDBIcon
//                                 fab
//                                 icon="linkedin"
//                                 className="white-text"
//                               />
//                             </a>
//                             <a href="#!" className="p-2 m-2">
//                               <MDBIcon
//                                 fab
//                                 icon="instagram"
//                                 className="white-text"
//                               />
//                             </a>
//                           </div>
//                         </div>
//                       </MDBCardBody>
//                     </MDBCard>
//                   </MDBAnimation>
//                 </MDBCol>
//               </MDBRow>
//             </MDBContainer>
//           </MDBMask>
//         </MDBView>

//         {/* <MDBContainer>
//           <MDBRow className="py-5">
//             <MDBCol md="12" className="text-center">
//               <p>
//                 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//                 eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
//                 enim ad minim veniam, quis nostrud exercitation ullamco laboris
//                 nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
//                 in reprehenderit in voluptate velit esse cillum dolore eu fugiat
//                 nulla pariatur. Excepteur sint occaecat cupidatat non proident,
//                 sunt in culpa qui officia deserunt mollit anim id est laborum.
//               </p>
//             </MDBCol>
//           </MDBRow>
//         </MDBContainer> */}
//       </div>
//     );
//   }
// }

// export default UserLogin;

import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import LockIcon from "@material-ui/icons/LockOutlined";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { postData, ClientURL } from "../FetchService";
import OtpInput from "react-otp-input";
import { InputAdornment } from "@material-ui/core";
import { RemoveRedEye } from "@material-ui/icons";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Grid } from "@material-ui/core";

import renderHTML from "react-render-html";
const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginTop: 0,
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 250,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginLeft: "-175px",
    marginTop: theme.spacing.unit * 4,
    display: "",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  paper_link: {
    marginTop: theme.spacing.unit * 2,
    display: "",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "-175px",
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px ${
      theme.spacing.unit * 1
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  eye: {
    cursor: "pointer",
  },
});

class UserLogin extends React.Component {
  state = {
    user_name: this.props.uid,
    password: "",
    msg: "",
    result: [],
    open: false,
    confirmPassword: "",
    openOtp: false,
    OTP: "",
    saveOtp: "",
    openPassword: false,
    passwordIsMasked: true,
  };

  togglePasswordMask = () => {
    this.setState((prevState) => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  };

  valid = () => {
    let eerr =
      this.state.user_name.length != 0 ? "" : "User Name Should Not Blank...";
    let perr =
      this.state.password.length != 0 ? "" : "Password Should Not Blank...";
    if (eerr != "" || perr != "") {
      let errm = `<font color='red'>${eerr}<br>${perr}</font>`;
      this.setState({ msg: errm });

      return false;
    } else {
      let errm = `<font color='red'>${eerr}<br>${perr}</font>`;
      return true;
    }
  };
  checkLogin = async () => {
    if (this.valid()) {
      const body = {
        user_name: this.state.user_name,
        password: this.state.password,
      };

      const result = await postData(`userinterface/checkuserlogin`, body);
      console.log(result);

      if (result.result) {
        // const {maincategoryid,maincategory,icon}=result[0];

        let data = JSON.stringify(result.result[0]);

        localStorage.setItem("user", data);
        this.setState({ result: data, msg: "" });

        //  this.props.history.replace({pathname:`/Checkout`,state:{result:this.state.result}});

        if (this.props.loginfor == "ADDCART_WITHOUT_LOGIN_DEMO") {
          let record = this.props.record;
          let u = JSON.parse(localStorage.getItem("user"));
          let body = { userid: u.email, setorg: record.setorg };
          const result = await postData(`purchase/checkuserpurchase`, body);
          if (!result.RESULT) {
            this.addnewrecord(this.props.record);
            this.props.mainpage("TEST");
          } else {
            alert(
              "This DEMO Set/Paper is Already in Your Account Go to Your Account For Demo Test...."
            );
            this.props.mainpage("TEST");
          }
        } else if (this.props.loginfor == "BUY") this.props.mainpage("CO");
        else if (this.props.loginfor == "TEST") this.props.mainpage("TEST");
        else if (this.props.loginfor == "ADDCART_WITHOUT_LOGIN") {
          let record = this.props.record;
          let u = JSON.parse(localStorage.getItem("user"));
          let body = { userid: u.email, setorg: record.setorg };
          const result = await postData(`purchase/checkuserpurchase`, body);
          if (!result.RESULT) {
            let record = this.props.record;

            await localStorage.setItem(record.setorg, JSON.stringify(record));
            await this.props.cartaction("BADAGE", []);
            await this.props.mainpage("SHOWCART");
          } else {
            alert(
              "This Set/Paper is Already in Your Account Go to Your Account For Test...."
            );
            this.props.mainpage("SC");
          }
        } else if (this.props.loginfor == "INTERN_WITHOUT_LOGIN") {
          let u = JSON.parse(localStorage.getItem("user"));

          let body = { emailid: u.email, idemployer: this.props.idemployer };
          const result = await postData(`internship/adduserintern`, body);
          if (result) await this.props.mainpage("SHOW_INTERN");
          else alert("Fail to SUBMIT Your Intern Request");
        } else {
          alert("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
          await this.props.mainpage("SHOW_INTERN");
        }
      } else {
        await this.setState({
          msg:
            "<center><div><font color=white>Invalid Uid/Password/You Have Not Verify your Email Id Yet....</font></center></div>",
        });
        console.log(`Invalid ${result.result}`);
      }
    }
  };

  addnewrecord = async (record) => {
    const result1 = await postData(`purchase/generateorderno`);
    if (result1) {
      // const {maincategoryid,maincategory,icon}=result[0];
      //    let data=JSON.stringify(result[0]);
      let data = result1[0];
      let c = "";
      let cd = new Date();
      let u = JSON.parse(localStorage.getItem("user"));
      //alert(u.email)
      if (data.orderno == null) {
        c =
          "#1-" +
          u.user_id +
          "-" +
          cd.getDate() +
          (cd.getMonth() + 1) +
          cd.getYear() +
          "-" +
          cd.getHours() +
          cd.getMinutes();
      } else {
        let sn = data.orderno + 1;

        c =
          "#" +
          sn +
          "-" +
          u.user_id +
          "-" +
          cd.getDate() +
          (cd.getMonth() + 1) +
          cd.getYear() +
          "-" +
          cd.getHours() +
          cd.getMinutes();
      }

      try {
        let body = {
          amount: 0,
          itempurchased: record.setorg,
          orderno: c,
          emailid: u.email,
          purchasedate:
            cd.getFullYear() + "/" + (cd.getMonth() + 1) + "/" + cd.getDate(),
          purchasetime: cd.getHours() + ":" + cd.getMinutes(),
          status: "Take Test",
        };

        const result = await postData("purchase/addnewrecorddemo", body);
        // alert(result.RESULT);
        if (result.RESULT) {
          this.setState({ msg: "Record Submited....", duration: "" });
        } else {
          this.setState({ msg: "Fail to Submit Record...." });
          alert("Server Error\nTry Again");
        }
      } catch (e) {
        alert(e);
        console.log("purchase:", e);
      }
    }
  };

  handleForgot = async () => {
    let body = {
      user_name: this.state.user_name,
    };
    var result = await postData("userinterface/forgotpassword", body);
    if (result.RESULT) {
      // alert(result.RESULT)
      this.setState({ saveOtp: result.RESULT });
      {
        this.handleClose();
      }
      this.setState({ openOtp: true });
    } else {
      alert("Mobile No/Email not found");
      this.setState({ user_name: "" });
      {
        this.handleClose();
      }
    }
  };

  handleOtp = () => {
    if (this.state.OTP === this.state.saveOtp) {
      // alert("true")
      {
        this.handleCloseOtp();
      }
      this.setState({ openPassword: true });
      this.setState({ OTP: "" });
    } else {
      alert("Incorrect OTP");
      this.setState({ OTP: "" });
      // {this.handleCloseOtp()}
    }
  };

  handlePassword = async () => {
    if (this.state.password == this.state.confirmPassword) {
      let body = {
        user_name: this.state.user_name,
        password: this.state.password,
      };
      var result = await postData("userinterface/updatepassword", body);
      if (result.RESULT) {
        // alert('true')
        {
          this.handleClosePassword();
        }
        alert("Password Changed Successfully");
        this.setState({ user_name: "" });
      } else {
        alert("Password Not Changed");
        {
          this.handleClosePassword();
        }
      }
    } else {
      alert("Password not matched...");
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseOtp = () => {
    this.setState({ openOtp: false });
  };

  handleClosePassword = () => {
    this.setState({ openPassword: false });
  };

  getVerification = (classes) => {
    return (
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <img src={`/images/cssbig.png`}></img>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email address/mobile number here...
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={this.state.user_name}
            label="Email Address"
            onChange={(event) =>
              this.setState({ user_name: event.target.value })
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleForgot} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  UserLogin(classes) {
    console.log("loginid");
    return (
      <div>
        <Dialog open={this.state.open} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <img src={`/images/cssbig.png`}></img>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your email address/mobile number here...
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={this.state.user_name}
              label="Email Address"
              onChange={(event) =>
                this.setState({ user_name: event.target.value })
              }
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleForgot} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* To enter the otp */}

        <Dialog
          open={this.state.openOtp}
          onClose={this.handleCloseOtp}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <img src={`/images/cssbig.png`}></img>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {/* You receive on your mobile {mobileno} or email {email} */}
              {/* You receive on your mobile {starifyNumber(Result[0].mobileno)} or email {Result[0].email} */}
            </DialogContentText>
            <Typography component="h1" variant="h5">
              Enter OTP
            </Typography>
            <p></p>
            <OtpInput
              inputStyle={{
                width: "3rem",
                height: "3rem",
                margin: "0 1rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "1px solid rgba(0,0,0,0.3)",
              }}
              numInputs={4}
              isDisabled={false}
              hasErrored={false}
              errorStyle={"error"}
              onChange={(otp) => this.setState({ OTP: otp })}
              separator={<span>-</span>}
              isInputNum={false}
              value={this.state.OTP}
              shouldAutoFocus
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleCloseOtp} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleOtp} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.openPassword}
          onClose={this.handleClosePassword}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <img src={`/images/cssbig.png`}></img>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Password</DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="New Password"
              type="password"
              onChange={(event) =>
                this.setState({ password: event.target.value })
              }
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Confirm New Password"
              type="password"
              onChange={(event) =>
                this.setState({ confirmPassword: event.target.value })
              }
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClosePassword} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handlePassword} color="primary">
              Save Password
            </Button>
          </DialogActions>
        </Dialog>

        <main
          className={classes.main}
          style={{ marginLeft: "70%", padding: "20px" }}
        >
          <CssBaseline />

          <Paper className={classes.paper}>
            <img src={`/images/cssbig.png`}></img>

            <Typography component="h1" variant="h5">
              Student Sign in
            </Typography>
            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="user_name">User Name</InputLabel>
                <Input
                  id="user_name"
                  name="user_name"
                  autoFocus
                  onChange={(e) => this.setState({ user_name: e.target.value })}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                {/* <InputLabel htmlFor="password">Password</InputLabel> */}
                <TextField
                  name="password"
                  type={this.state.passwordIsMasked ? "password" : "text"}
                  id="password"
                  label="Password"
                  required
                  onChange={(e) => this.setState({ password: e.target.value })}
                  value={this.state.password}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {this.state.passwordIsMasked ? (
                          <VisibilityOffIcon
                            className={classes.eye}
                            onClick={this.togglePasswordMask}
                          />
                        ) : (
                          <RemoveRedEye
                            className={classes.eye}
                            onClick={this.togglePasswordMask}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Input
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <RemoveRedEye />
                      </InputAdornment>
                    ),
                  }}
                /> */}
              </FormControl>
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => this.checkLogin()}
              >
                Sign in
              </Button>
            </form>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Link
                  href="#"
                  onClick={(event) => this.setState({ open: true })}
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Link
                  href="#"
                  onClick={() =>
                    this.props.history.push({ pathname: "/ResendLink" })
                  }
                  variant="body2"
                >
                  Get Verification Mail Again!
                </Link>
              </Grid>
            </Grid>
          </Paper>

          <Paper className={classes.paper_link}>
            <p>
              {" "}
              Don't have an account?
              <br />{" "}
              <a href="javascript:;" onClick={() => this.props.mainpage("UR")}>
                Sign Up
              </a>
            </p>
          </Paper>

          {renderHTML(this.state.msg)}
        </main>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          backgroundImage: `url(${"/images/userlogin.jpg"})`,
          backgroundSize: "cover",
        }}
      >
        {/* {this.getVerification(classes)} */}
        {this.UserLogin(classes)}
      </div>
    );
  }
}
UserLogin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserLogin);
