import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import { Typography, LinearProgress } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { type } from 'os';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import CardActionArea from '@material-ui/core/CardActionArea';
import {ClientURL} from '../FetchService' 


const styles = theme => ({
    paper:{
backgroundColor:'blue',

    },
    card: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
  
})

class WebDevelopment extends React.Component {
  state={
    viewLess:'',
    viewMore:'',
    Edit:'ViewMore',
    save:<img src={`/images/add.png`} height='2%'width='2%'></img>,
    append:'',
    expanded: null,

    sh:'',
  
    
  };
Display=()=>{
  if(this.state.Edit=='ViewMore')
  {
this.setState({Edit:'ViewLess'})
  }
  else if(this.state.Edit=='ViewLess')
  {
this.setState({Edit:'ViewMore'})
  }
 
}
handleChange = panel => (event, expanded) => {
  this.setState({
    expanded: expanded ? panel : false,
  })}


  render() {
    const { expanded } = this.state;
    const { classes } = this.props;


    return (
      <div>
          <Paper style={{background:'#263475',height:'450px',width:'100%',}}>
     <div style={{}}>   
    <p style={{marginLeft:'10%',fontSize:'40px',color:'white',marginRight:'-2%',paddingTop:'50px',marginTop:'-5px'}}>Online PHP Certification Training Course</p></div> 
    <div style={{marginLeft:'10%',fontSize:'30px',color:'white'}} >
    Learn how to create a website from scratch
        </div>
      <Typography style={{color:'white',height:'100%',width:'50%',marginLeft:'10%',marginTop:'2%',fontSize:'20px'}}><hr/>
      Duration:4 weeks | Certifited trainings
      <div>PHP :</div>
      <p>Learn how to make dynamic web applications using PHP includes complete PHP and MYSQL fundamentals,Codelgniter and more</p>

      </Typography>
     
      <Card style={{ backgroundColor:'white',marginLeft:'68%',marginTop:'-48%',
maxWidth:'420px',height:'143.2%'}}>
      <CardActionArea>

         <img src={`/images/web1.png`} height='30%'width='100%' ></img>
      
        <CardContent>
          <span>
          <img src={`/images/rupee.png`} ></img><Typography style={{marginLeft:'9%',marginTop:'-10%',fontSize:'25px'}} > 570/-</Typography>
          </span>
          <Button variant="contained" color="secondary" style={{marginTop:'1.5%',width:'300px',height:'50px',marginLeft:'8%'}} >
        Add to Cart
      </Button>



          <Button variant="contained"  style={{marginTop:'5%',width:'300px',height:'50px',marginLeft:'8%'}} >
        Buy
      </Button>
      <span> 
<Button style={{fontSize:'10px',marginTop:'4%'}} href="/Pdf/Node.pdf" >
<img src={`/images/download.png`} height='8%'width='8%'></img> <b style={{fontSize:'10px',marginLeft:'2%'}}>Download detailed table of contents</b>
</Button>

</span>

<Typography style={{fontSize:'20px',marginTop:'4%'}}>
  30-Day Money Back Guarantee
</Typography>
<p style={{marginLeft:'3%',marginTop:'3%',fontSize:'20px'}}> This courses inculdes</p>
<p style={{marginLeft:'4%'}}> 1.One Year Access</p>
<p  style={{marginLeft:'4%'}} > 2.Access on mobile and laptop</p>
<p style={{marginLeft:'4%'}} > 3.Certificate after the Successful Completion</p>
         </CardContent>
      </CardActionArea>
      <CardActions>
       </CardActions>
    </Card>
          </Paper>
        
          <Card style={{width:'67%',height:'50%',flexDirection:'row'}}>
          <Typography style={{fontSize:'20px',marginLeft:'28%',width:'35%',borderBottom:'#263475 1px dotted'}}>About Web Development Training
</Typography>
<Grid  container spacing={24}>
<Grid item xs={6} style={{fontSize:'13px',display:'flex'}}>
<img src={`/images/dot.png`} style={{height:'20px',marginTop:'15px'}}></img><p  style={{marginLeft:'2%',textAlign:'flex',marginRight:'2%',paddingTop:'-10px'}}>A 6-week online training program by Campusshala will cover all the industry demanded technologieswhich includes HTML5,CSS3,JavaScript,Bootstrap,PHP,and SQL. </p></Grid>
           <Grid item xs={6} style={{marginTop:'1%',display:'flex'}}>
           <img src={`/images/dot.png`} style={{height:'20px',marginTop:'%'}}></img> <p style={{fontSize:'13px',textAlign:'justify',marginLeft:'1%',color:'black',marginTop:'-3px'}}>During this training program, we will teach you how to create a website. Syllabus designed by the experts of Campusshala by keeping the fact in mind that it covers all the aspects which industry demands</p>
 </Grid>
 </Grid>
 <Grid  container spacing={24}>
<Grid item xs={6}>
<img src={`/images/dot.png`} style={{height:'20px',marginTop:'0px'}}></img><p style={{fontSize:'13px',marginLeft:'8%',marginTop:'-6%'}}>For a great hands on learning experience, this training program is packed with assignments,assessment tests,code challenges,quizzes,and exercises.At the end of this training program</p>
</Grid>
           <Grid item xs={6}>
           <img src={`/images/dot.png`} style={{height:'20px',marginTop:'10px'}}></img><p style={{ marginLeft:'6%' ,marginTop:'-6%',fontSize:'13px' }}>you will build an e-commerce website that will work seamlessly across different devices. For doubt clearing, you would get 1-hour live chat support with the trainer every day. You can also use the Q&A forum provided.         </p>
 </Grid>
 </Grid>
            </Card>
            <Typography style={{marginLeft:'42%',marginTop:'2%',fontSize:'20px',color:'#264375'}}><b> Course Content </b></Typography>
            
      <Card style={{width:'62%',height:'50%',marginLeft:'20%'}} >
         <Typography style={{fontSize:'20px',marginTop:'2%',marginLeft:'3%',color:'#264375'}}> <b>WEB-SCRIPTING</b><span> 
<Button style={{fontSize:'10px',marginTop:'2%'}} href="/Pdf/Node.pdf" >
<img src={`/images/download.png`}  style={{ marginTop:'-50%',height:'20px',width:'20px'}}></img> <b style={{fontSize:'10px',color:'#264375'}}></b>
</Button>

</span></Typography>
        <ExpansionPanel  style={{height:'7%',marginTop:'2%',marginLeft:'3%'}} expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 1: HTML5- The Static Web Page Creation 
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            
           <div style={{fontSize:'12px',color:'#264375'}}>
           <p>1.1	HTML5  Introduction </p>                   
           <p>1.2 Structure </p>                   
           <p>1.3	Elements </p>                 
          <p>1.4	Semantics </p>
         <p>1.5 Audio & Video</p>
         <p> 1.6	Section & Article</p> 
         <p> 1.7	Canvas, Aside</p> 
         <p> 1.8	Drag & Drop </p>
        <p>  1.9	Forms & Form Elements </p>
        </div>
             </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 2: CSS 3 - The Presentation Semantics 
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p> 2.1 CSS Properties, Selectors, Style Declaration Types </p>
            <p>	2.2 Colors, Backgrounds, Text and Fonts </p>
           <p>	2.3 Images, Links, Tables and List </p>
          <p>	2.4 Borders, Padding, Margin </p>
          <p>	2.5 Cursor, Dimension, Scrollbars, Visibility and Positioning</p> 
          <p>	2.6 Pseudo class & Elements, @Rules( import, font-face, charset) </p>
          <p>	2.7 Filters, Media Types, Printing and Layouts </p>
             </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 3:Bootstrap 
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p> 3.1  Introduction to Bootstrap  </p>
            <p>	3.2 Bootstrap Grid System </p>
           <p>	3.3 Creating Layouts with Bootstrap </p>
          <p>	3.4  Bootstrap CSS - Understanding the CSS  </p>
          <p>	3.5  Responsive Web design with Bootstrap  </p> 
          <p>	3.6  Single Page Responsive site with Bootstrap </p>
          <p>	3.7  Bootstrap Layout Components  </p>
<          p> 3.8  Bootstrap Plug-ins : ? Transition  ✓ Modal  
✓ </p> <p> Dropdown  ✓ Scrollspy  ✓ Tab  ✓ Tooltip  ✓ </p>
<p>5.10 Building Websites with Bootstrap. 
</p>
 
             </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
      
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel3'} onChange={this.handleChange('panel3')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 4: JavaScript - The Interpreted Programming Language 
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p>	4.1 Interpreted Programming Languages </p>
             <p>	4.2 Integrating JavaScript with HTML </p> 
            <p>	4.3 Variables in JavaScript </p>
           <p>	4.4 Operators in JavaScript </p>
           <p>	4.5 Expressions in JavaScript</p> 
	        <p>4.6 Arrays in JavaScript </p>
         <p>	4.7 Handling Loops & Decision structures </p>
         <p>4.8 Executing Conditional statements </p>
	       <p>4.9 Working with Functions </p>
        <p>	Section 4: ES6 Write Less Do More…</p>
        <p>	5.1 Understanding ES6</p> 
        < p>	5.2 Arrow Functions </p>
      <p>	5.3 Event Handlers</p> 
      <p>	5.4 Sliding, Easing, Fading, Toggling </p>
     <p>	5.5 AJAX calls </p>
       <p>	5.6 JSON </p>

 
             </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
         <Typography style={{fontSize:'20px',marginTop:'2%',marginLeft:'3%',color:'#264375'}}><b> PROGRAMMING LANGUAGE (PHP-MySQL)</b></Typography>
         <ExpansionPanel  style={{height:'7%',marginTop:'2%',marginLeft:'3%'}} expanded={expanded === 'panel4'} onChange={this.handleChange('panel4')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 1:  PHP – Understanding the Preliminaries  
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p>	1.1 Introduction to Web And Internet </p>
<p>	1.2  Introduction to server </p>
<p>	1.3 Introduction to localhost server</p>
<p>1.4 Starting PHP </p>
<p>	1.5 PHP syntax and variables </p>
<p>	1.6 Operators And Expressions</p>
<p>	1.7 Conditional Branching And Looping Statements</p>
<p>	1.8 Learn Arrays  in PHP</p>

</div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel5'} onChange={this.handleChange('panel5')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 2:  PHP - The Core Logics and Techniques   </b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p>2.1 String and Math functions in PHP </p>
           <p> 2.2 Introduction HTML Form Elements and Fields   </p>
       <p>2.3 Accessing PHP, HTTP Data   </p>
	<p>2.4 Query Strings and Hyperlinks</p>
<p>	2.5 Describing Pre-Defined Variables - Super Global Arrays  </p>
<p>	2.6 Understanding Functions, Important PHP Functions   </p>
<p>	2.7 What are the Scope of variables </p>
<p>	2.8 Usage of Include and require statements </p>
             </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
        
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel7'} onChange={this.handleChange('panel7')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 3: PHP – File Handling. The Plain Repository of Data 
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p> 3.1 Handling files and directories in PHP  </p>
<p>3.2 Fetching information from files   </p>
<p>3.3 Uploading and downloading files 
  </p>
      
             </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel17'} onChange={this.handleChange('panel17')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 4:  PHP and MySQL - The Structured Repository 
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p>	4.1 PHP MySQL Connectivity </p>
<p>	4.2 Integrating Web Forms and Database  </p>
	<p>4.3 Using PHP’s MySQL Extension </p>
<p>4.4 Using PHP’s PDO Extension  
 </p>
         </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel9'} onChange={this.handleChange('panel9')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 5:  Learn More Advanced Techniques in PHP   
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p>5.1  Introduction to Object Oriented Programming  </p> 
<p>5.2 Classes and Objects </p>
<p> 5.3 Abstraction, Encapsulation, Inheritance and Polymorphism  
               </p>
               <p>5.4 Constructors and Destructors     </p>
               <p> 5.5 Overloading, Overriding of functions   </p>
               <p> 5.6 Regular Expressions  </p>
               <p> 5.7 Exception Handling  </p>
               <p> 5.8 Session Handling  </p>
               <p>✓ 5.9 Using Cookies </p>
                        </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel10'} onChange={this.handleChange('panel10')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 6:  Working with XML and JSON   
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
           <p>6.1 Introduction to XML     </p> 
<p>	 6.2 How to Integrate PHP and XML </p> 
<p>	 6.3 Introduction to JSON  </p>
<p>	6.4 Building a Sample Web Application</p> 

          </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel11'} onChange={this.handleChange('panel11')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 7:  Content Management Systems  
</b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p>7.1 Knowing Content Management System   </p>
          <p>7.2 Sample Web Application Development using Wordpress  </p>
<p>	 7.3 Install Wordpress On The Mysql Database  
           </p>
           <p>    7.4 Install, and activate a Theme    </p>
           <p> 7.5 Posts, Pages and Menus </p>
           <p> 7.6 Using Widgets and Plugins  </p>
           <p> 7.7 Customizing Themes  </p>

          </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
   
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel12'} onChange={this.handleChange('panel12')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 8:  CodeIgniter Framework   </b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p>8.1 Introduction to  PHP Frameworks      </p>  
          <p>	  8.2 Working with CodeIgniter Framework </p>
<p>  8.3 MVC architecture in CodeIgniter 

 
           </p>
           <p>	   8.4 Routing   </p>
           <p>	 8.5 Controllers,Views and Models </p>
           <p>	    8.6 Helpers and Libraries </p>
           <p>	  8.7 FormValidation   </p>
           <p>	   8.8 FetchingData using Input Class  </p>
           <p>	   8.9 Session   </p>
           <p>	   8.10 Database    </p>

          </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel12'} onChange={this.handleChange('panel12')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 9:  Payment Gateway, E-mail and Social Media Networks   </b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p> 9.1 Integration of payment gateway        </p>  
          <p>	 9.2 Integrating emails to web application </p>
<p>    9.3 Integrating social media networks to web application

 
           </p>
          

          </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel12'} onChange={this.handleChange('panel12')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 10:FTP Management/Web Hosting   </b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p> 10.1 Set up a domain and hosting account     
        </p>  
          <p>	  10.2 Understanding FTP   </p>
<p>  10.3 Setting up FTP Server (Live)  

 
           </p>          <p>	  10.4 Uploading and downloading FTP contents   </p>

          

          </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel12'} onChange={this.handleChange('panel12')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
         {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 11: Web Security    </b></Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
           <div style={{fontSize:'12px',color:'#264375'}}>
            <p> 11.1 Data Validation       
        </p>  
          <p>	   11.2 SQL Injection    </p>
<p>  11.3 Cross Site Scripting   

 
           </p>                

          </div>      
          </ExpansionPanelDetails>
        </ExpansionPanel>
   
        <Typography style={{fontSize:'20px',marginTop:'2%',marginLeft:'3%'}}><b>MySQL</b></Typography>
     
     <ExpansionPanel  style={{height:'7%',marginTop:'2%',marginLeft:'3%'}} expanded={expanded === 'panel18'} onChange={this.handleChange('panel18')}>
       <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 1: Relational Database</b></Typography>
       </ExpansionPanelSummary>
       <ExpansionPanelDetails>
        <div style={{fontSize:'12px',color:'#264375'}}>
         <p>1.1	Basics </p> 
<p>1.2	Brief History of MySQL </p>
   <p>1.3	Relational Databases and  Popular Databases </p>   
<p>1.3	SQL Statements</p>
      </div>      
       </ExpansionPanelDetails>
     </ExpansionPanel>
    
     <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel19'} onChange={this.handleChange('panel19')}>
       <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 2: Data Manipulation Language (DML)  </b></Typography>
       </ExpansionPanelSummary>
       <ExpansionPanelDetails>
        <div style={{fontSize:'12px',color:'#264375'}}>
         <p>	2.1 INSERT </p>
          <p>	2.2 UPDATE </p>
          <p>	2.3 DELETE </p>
           <p>	2.4 SELECT </p>
      </div>      
       </ExpansionPanelDetails>
     </ExpansionPanel>
     <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel15'} onChange={this.handleChange('panel15')}>
       <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section3: Data Definition Language (DDL)  </b></Typography>
       </ExpansionPanelSummary>
       <ExpansionPanelDetails>
        <div style={{fontSize:'12px',color:'#264375'}}>
         <p>	3.1 CREATE </p>
          <p>	3.2 ALTER </p>
         	<p>3.3 DROP </p>
      </div>      
       </ExpansionPanelDetails>
     </ExpansionPanel>
    
     <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'panel16'} onChange={this.handleChange('pane16')}>
       <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section4: Sub-Queries, Joins and Unions with MySQL   </b></Typography>
       </ExpansionPanelSummary>
       <ExpansionPanelDetails>
        <div style={{fontSize:'12px',color:'#264375'}}>
         <p>	4.1 Order By,  Like , And & Or, Where ,  Between </p>  
          <p>	4.2 Joins &  Unions </p>	
          <p>	4.3 Aggregate Functions and Grouping </p>

 </div>      
       </ExpansionPanelDetails>
     </ExpansionPanel>

     <ExpansionPanel  style={{height:'7%',marginLeft:'3%'}} expanded={expanded === 'pane17'} onChange={this.handleChange('panel17')}>
       <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      {this.state.save}<Typography style={{marginTop:'0.25%',color:'#264375'}}><b>Section 5: Constraints and Normalization    </b></Typography>
       </ExpansionPanelSummary>
       <ExpansionPanelDetails>
        <div style={{fontSize:'12px',color:'#264375'}}>
         <p>5.1 Understanding Primary and Foreign Keys </p>
          <p>5.2 Understanding Database Normalization</p> 
           <p>	5.3 Views and Triggers</p>
 </div>      
       </ExpansionPanelDetails>
     </ExpansionPanel>

    
    

        </Card>







                     
                    
<Paper >
  <Typography style={{marginLeft:'35%',marginTop:'2%',fontSize:'20px',color:'#264375', borderBottom:'#263475 1px dotted' ,width:'33%'}}>Extra benefits of Training from Campusshala: </Typography>
  <Grid container spacing={24} >

  <Card style={{width:'500px',height:'150px',marginLeft:'16%',marginTop:'3%'}}>
  <img src={`/images/certificate.png`} style={{marginLeft:'5%',marginTop:'8%'}} ></img>
<Typography style={{marginLeft:'22%',fontSize:'20px',marginTop:'-12%',color:'#264375'}}>Certificate after Completion of Training:</Typography> 
 
<CardContent style={{marginLeft:'20%',marginTop:'-2%',color:'#264375'}}> After the successful completion of training, the trainee will be awarded with Certificates. </CardContent>

     </Card>
     <Card style={{width:'500px',height:'150px' ,marginLeft:'2%',marginTop:'3%'}}>
     <img src={`/images/question.png`} style={{marginLeft:'5%',marginTop:'8%'}} ></img>

<Typography style={{fontSize:'20px',marginTop:'-10%',marginLeft:'17%',color:'#264375'}}>Live Support 24*7:</Typography> 
 
<CardContent style={{marginLeft:'15%',marginTop:'-2%',color:'#264375'}}> A human who is ridiculously committed to answer all your queries. </CardContent>

     </Card >
     </Grid>

     <Grid container spacing={24}>
  <Card style={{width:'500px',height:'150px',marginLeft:'16%',marginTop:'3%'}}>
  <img src={`/images/user.png`} style={{marginLeft:'5%',marginTop:'8%'}} ></img>

<Typography style={{marginLeft:'22%',fontSize:'20px',marginTop:'-12%',color:'#264375'}}>Project Development during Training:</Typography>
 
<CardContent  style={{marginLeft:'20%',marginTop:'-2%',color:'#264375'}}> This training will be a project based training. 
  Trainee will be having a project after the completion of Training. </CardContent>

     </Card>
     <Card style={{width:'500px',height:'150px',marginLeft:'2%',marginTop:'3%'}}>
     <img src={`/images/exercise.png`} style={{marginLeft:'5%',marginTop:'5%'}} ></img>

<Typography style={{marginLeft:'22%',fontSize:'20px',marginTop:'-14%',color:'#264375'}}>Exercises & Assignment:</Typography> 
 
<CardContent style={{marginLeft:'20%',marginTop:'-2.5%',color:'#264375'}}> This training will be packed with daily dose of assignments
   so that it brings more clarity in understanding the concepts during training. </CardContent>

     </Card>
     </Grid>
     </Paper>
 







           
      </div>
    );
  }
}

WebDevelopment.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(WebDevelopment);
  