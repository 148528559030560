/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'video-react/dist/video-react.css'; // import css
import { Player } from 'video-react';
import getBlobDuration from 'get-blob-duration';

import clsx from 'clsx';
import { Paper, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import { getData,BaseURL,postData,postDataAndImage} from '../../../FetchService';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		width: '80%'
		//marginBottom: '10px'
		//marginLeft: '50px'
	},
	input: {
		display: 'none'
	},
	root: {
		background: '#dff9fb',
		padding: '20px',
		marginTop: '50px',
		marginBottom: '50px',
		width: '70%'
	},

	formLabel: {
		marginTop: '10px'
	},
	button: {
		marginTop: '10px'
	}
}));

export default function TopicUpload(props) {
	const classes = useStyles();

	const [ getTopicId, setTopicId ] = useState('');
	const [ getCourse, setCourse ] = useState('');
	const [ getCourseId, setCourseId ] = useState('');
	const [ getImageDate, setImageDate ] = useState('');
	const [ getVideoDate, setVideoDate ] = useState('');

	const [ getChapter, setChapter ] = useState('');
	const [ getChapterId, setChapterId ] = useState('');

	const [ getTopic, setTopic ] = useState('');
	const [ getVideoName, setVideoName ] = useState('');
	const [ getTopicDescription, setTopicDescription ] = useState('');
	const [ getVideoPath, setVideoPath ] = useState({ file: '' });
	const [ getVideoPlay, setVideoPlay ] = useState({ old: '', new: '' });
	const [ getDuration, setDuration ] = useState('');
	const [ getVideoType, setVideoType ] = useState('');
	const [ getVideoIcon, setVideoIcon ] = useState({ icon: '', file: '' });
	const [ getOldVideoIcon, setOldVideoIcon ] = useState('');

	const [ getMessage, setMessage ] = useState('');

	const fillValues = async () => {
		var body = {
			topicId: props.location.state.id
		};
		console.log('body data', body);

		const result = await postData('topicdetails/fetchdataById', body);
		console.log('result', result);

		// let requirementdata = ;
		setTopicId(result[0].topicid);
		setCourse(result[0].coursename);
		setChapter(result[0].chaptername);
		setCourseId(result[0].courseid);
		setChapterId(result[0].chapterid);
		setTopic(result[0].topicname);
		setVideoPlay({ old: result[0].videoname });
		setVideoName(result[0].videoname);
		setOldVideoIcon(result[0].posterimage);
		setTopicDescription(result[0].topicdescription);
		setDuration(result[0].videoduration);
		setVideoType(result[0].videotype);
	};

	useEffect(() => {
		fillValues();
	}, []);

	const handleSubmit = async () => {
		let formData = new FormData();
		formData.append('courseId', getCourseId);
		formData.append('chapterId', getChapterId);
		formData.append('topicName', getTopic);
		formData.append('videoName', getVideoName);
		formData.append('videoDuration', getDuration);
		formData.append('topicDescription', getTopicDescription);
		formData.append('videoPath', getVideoPath.file);
		formData.append('videoType', getVideoType);
		formData.append('videoImage', getVideoIcon.file);

		const config = { headers: { 'content-type': 'multipart/form-data' } };
		const result = await postDataAndImage('topicdetails/addTopic', formData, config);

		if (result) {
			setMessage('Topic Submitted.....');
			setTimeout(function() {
				clearValues();
			}, 3000);
		} else {
			setMessage(' Not Submitted..........');
		}
	};
	const clearValues = (event) => {
		setCourse('');
		setChapter('');
		setTopic('');
		setVideoName('');
		setVideoIcon({ icon: '', file: '' });
		setVideoPath('');
		setTopicDescription('');
		setDuration('');
		setVideoType('');
		setMessage('');
	};

	const handleImage = (event) => {
		setVideoIcon({
			icon: URL.createObjectURL(event.target.files[0]),
			file: event.target.files[0]
		});
	};
	const handleVideoPath = (event) => {
		var file = URL.createObjectURL(event.file);

		getBlobDuration(file).then(function(duration) {
			var mind = duration % (60 * 60);
			var minutes = Math.floor(mind / 60);

			var secd = mind % 60;
			var seconds = Math.ceil(secd);

			console.log('time--', `${minutes}:${seconds} mins`);

			setDuration(`${minutes}:${seconds}`);
		});

		setVideoPath({ file: event.file });
		setVideoPlay({ new: file });
		setVideoName(`${event.file.name}`);
	};

	const handleEdit = async () => {
		let formData = new FormData();

		formData.append('topicId', getTopicId);
		formData.append('courseId', getCourseId);
		formData.append('chapterId', getChapterId);
		formData.append('topicName', getTopic);
		formData.append('videoName', getVideoName);
		formData.append('videoDuration', getDuration);
		formData.append('topicDescription', getTopicDescription);
		formData.append('videoPath', getVideoPath.file);
		formData.append('videoType', getVideoType);
		formData.append('videoImage', getVideoIcon.file);
		formData.append('oldVideoImage', getOldVideoIcon);
		formData.append('imageDate', getImageDate);
		formData.append('videoDate', getVideoDate);

		const config = { headers: { 'content-type': 'multipart/form-data' } };
		const result = await postDataAndImage('topicdetails/updateTopic', formData, config);

		if (result) {
			setMessage('Record Updated.....');
			setTimeout(function() {
				clearValues();
				props.history.replace({ pathname: '/ListTopic' });
			}, 3000);
		} else {
			setMessage(' Fail to Update Record......');
		}
	};
	const handleDelete = async () => {
		var body = {
			topicId: getTopicId,
			oldVideoImage: getOldVideoIcon,
			videoName: getVideoName
		};
		let result = await postData('topicdetails/deleteTopic', body);
		console.log('delete result', result);

		if (result) {
			setMessage('Record Deleted...');
			setTimeout(function() {
				clearValues();
				props.history.replace({ pathname: '/ListTopic' });
			}, 3000);
		} else {
			setMessage('Fail to Delete Record...');
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Paper className={classes.root}>
				<Typography variant="button" display="block" gutterBottom>
					Topic Delete/Update
				</Typography>
				<React.Fragment>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6}>
							<TextField
								id="standard dense"
								label="Course"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								InputProps={{
									readOnly: true
								}}
								value={getCourse}
								fullWidth
								// onChange={(event) => setArticle(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								id="standard dense"
								label="Chapter"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								InputProps={{
									readOnly: true
								}}
								value={getChapter}
								fullWidth
								// onChange={(event) => setArticle(event.target.value)}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Topic Name"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								value={getTopic}
								fullWidth
								onChange={(event) => setTopic(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<input
								accept="video/*"
								className={classes.input}
								id="contained-button-file1"
								multiple
								type="file"
								onChange={(event) => handleVideoPath({ file: event.target.files[0] })}
								// onChange={(e) => {
								// 	// const file = e.target.files[0];
								// 	setVideoFile(e.target.files[0]);
								// }}
							/>
							<label htmlFor="contained-button-file1">
								<Button variant="contained" component="span" className={classes.button} fullWidth>
									Upload Video
									<CloudUploadIcon className={classes.rightIcon} />
								</Button>
							</label>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div style={{ marginLeft: 10 }}>
								<Player
									playsInline
									src={getVideoPlay.new ? getVideoPlay.new : `${BaseURL}/images/${getVideoPlay.old}`}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={6}>
							<input
								accept="image/*"
								className={classes.input}
								id="contained-button-file"
								multiple
								type="file"
								onChange={(event) => handleImage(event)}
							/>
							<label htmlFor="contained-button-file">
								<Button variant="contained" component="span" className={classes.button}>
									Upload Poster image
									<CloudUploadIcon className={classes.rightIcon} />
								</Button>
							</label>
						</Grid>
						<Grid item xs={6} sm={3}>
							<Avatar
								alt="Image"
								src={getVideoIcon.icon ? getVideoIcon.icon : `${BaseURL}/images/${getOldVideoIcon}`}
								className={classes.bigAvatar}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Video Name"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								InputProps={{
									readOnly: true
								}}
								value={getVideoName}
								fullWidth
								onChange={(event) => setVideoName(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Video Description"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								value={getTopicDescription}
								fullWidth
								onChange={(event) => setTopicDescription(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Video Type</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={getVideoType}
									fullWidth
									onChange={(event) => setVideoType(event.target.value)}
								>
									<MenuItem value={'Free'}>Free</MenuItem>
									<MenuItem value={'Paid'}>Paid</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								id="standard dense"
								label="Video Duration"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								InputProps={{
									readOnly: true
								}}
								value={getDuration}
								fullWidth
								onChange={(event) => setDuration(event.target.value)}
							/>
						</Grid>
						<Grid item xs={3}>
							<Button onClick={handleEdit} variant="contained" color="primary" className={classes.button}>
								Edit
							</Button>
						</Grid>
						<Grid item xs={3}>
							<Button
								onClick={handleDelete}
								variant="contained"
								color="primary"
								className={classes.button}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
					<Typography>{getMessage}</Typography>
				</React.Fragment>
			</Paper>
		</div>
	);
}
