import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { getData, BaseURL } from "../FetchService"
import { postData } from "../FetchService"
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
 import {ClientURL} from '../FetchService'
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import SearchArea from "./IntegrationReactSelect"



const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },

});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

let counter = 0;


const styles = theme => ({
 searchitems:{
   width:300,
 },
  card: {
    maxWidth: 345,
    marginRight:'25px'
  },
  mainDiv:{
   display:'flex',

  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  gridstyle:{
    padding:'-5px',
    margin:'-5px'
  },

  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',

    marginTop: '0px',
    marginLeft:'0px'
  },
  table: {
    minWidth: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  panelroot: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: '4%',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '8%',
  },
  nextcolumn: {
    flexBasis: '100%',
  },


  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,

  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

class ListInternship extends React.Component {
  state = {
    rows: [],
    page: 0,
    rowsPerPage:2,
    result:[]
  };


   async componentDidUpdate(prevProps, prevState) {

        if(this.props!=prevProps)
        { if(this.props.statecity)
          { this.mainPageSearch()}

          else if(!localStorage.getItem('user'))
        {



        const rows = await getData(`internship/listemployer`);
        console.log(`Data ${rows}`)
        this.setState({ rows });
        console.log(`Data ${this.state.rows}`)
      }
      else{let u=JSON.parse(localStorage.getItem('user'));
        let body={emailid:u.email}
          const rows = await postData(`internship/listemployerbyuser`,body);

          this.setState({ rows });
      }

        }

    }
   mainPageSearch=()=>{
     let q="internlocations like '%"+this.props.citysearch+"%'"
     let id=new Date()
     let td=id.getFullYear()+"-"+(id.getMonth()+1)+"-"+id.getDate()
    let body={category:'',
      location:q,
      startfrom:td,
      maxmonth:'',
      ptft:'',
     }
  this.filterQuery(body)

   }

  async componentDidMount() {

    if(this.props.statecity)
          { this.mainPageSearch()}
    else if(!localStorage.getItem('user'))
    {

    const rows = await getData(`internship/listemployer`);
    console.log(`Data ${rows}`)
    this.setState({ rows });
    console.log(`Data ${this.state.rows}`)
  }
  else{let u=JSON.parse(localStorage.getItem('user'));
    let body={emailid:u.email}
      const rows = await postData(`internship/listemployerbyuser`,body);

      this.setState({ rows });
  }


  }
  filterQuery=async(body)=>{

   if(!localStorage.getItem('user'))
    {

      const rows = await postData(`internship/filtersearch`,body);
       if(rows.length>0)
    await this.setState({ rows });
    else
     alert('No Record Found,Pls Check Your Serach Query')

  }
  else{let u=JSON.parse(localStorage.getItem('user'));
  body['emailid']=u.email
    const rows = await postData(`internship/filtersearchuserlogin`,body);

    if(rows.length>0)
    await this.setState({ rows });
    else
     alert('No Record Found,Pls Check Your Serach Query');
  }
  }

  showall=async(idemployer)=>{
        //this.props.history.push({pathname:`/DisplayOrganizationDetails`,state:{data:body}});
     if(!localStorage.getItem('user'))
        this.props.cartaction('USERLOGIN_INTERN',idemployer)
      else{
        let u=JSON.parse(localStorage.getItem('user'));
        let body={emailid:u.email,idemployer:idemployer}

        const result = await postData(`internship/adduserintern`,body);
      if(result)
      {alert('Your Internship Request is Submitted')
      let body={emailid:u.email}
      const rows = await postData(`internship/listemployerbyuser`,body);

      this.setState({ rows });

       }
      else
      alert('Fail to SUBMIT Your Intern Request')


      }


}
searchDiv=(classes)=>{
return (


  <Card className={classes.card}>
                <CardHeader
                  title=''
                  subheader='Filter'
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}

                  className={classes.cardHeader}
                />
                <CardContent>
                <Grid container spacing={12} className={classes.gridstyle}>


   <Grid item sm={12} className={classes.gridstyle}>

 <TextField
           id="outlined-search"
           label="Category"
           type="search"
           className={classes.textField}
           margin="normal"
           variant="outlined"
          className={classes.searchitems}
         />
         </Grid>
         <Grid item sm={12} className={classes.gridstyle}>
 <TextField
           id="outlined-search"
           label="Location"
           type="search"
           className={classes.textField}
           margin="normal"
           variant="outlined"
           className={classes.searchitems}
         />
      </Grid>
      <Grid item sm={12} className={classes.gridstyle}>
     <TextField
           id="outlined-search"
           label="Sarting From"
           type="search"
           className={classes.textField}
           margin="normal"
           variant="outlined"
           className={classes.searchitems}
         />
      </Grid>

      </Grid>

                </CardContent>
                <CardActions >
                  <Button fullWidth variant='outlined' color="primary"  className={classes.searchitems}>
                    Search
                  </Button>
                </CardActions>
              </Card>




 )

}
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <div style={{display:'flex',padding:'5%'}}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={4}>
            <SearchArea filterQuery={this.filterQuery} />
          </Grid>
          <Grid item xs={12} sm={8}>


            <Paper className={classes.root}>
             <Typography variant="h6" gutterBottom align='center'>
          List Internship in Various Company
             </Typography>
              <div className={classes.tableWrapper}>
                <Table className={classes.table}>

                  <TableBody>
                    {this.state.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                             var d=new Date(row.applyby)
                             var td=d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + d.getDate()).slice(-2)
                        let btntxt=''
                        let btnstatus=true
                        if(row.result===undefined)
                        {btntxt='Apply Now'
                        btnstatus=false
                      }
                        else if(row.result==null)
                        {btntxt='Apply Now'
                        btnstatus=false
                      }
                        else
                        {btntxt='Already Applied'
                        btnstatus=true
                      }

                        let pt=''
                         if(row.isparttime=='Yes')
                              pt='Part Time'
                             else
                              pt='Full Time'
                         let joboffer=''
                         if(row.preplacement=='Yes')
                         {joboffer='With Job Offer'}

                     return (
                        <TableRow key={row.id}>

                          <TableCell>

                          <div className={classes.panelroot}>
                            <Grid container>
                              <Grid item xs={12}>
            <ExpansionPanel >{/*defaultExpanded*/} 
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>

                <div className={classes.column}>
                  <Typography className={classes.heading}> <img src={`${BaseURL}/images/${row.logo}`} width='40' height='40'></img></Typography>
                </div>
                <div className={classes.nextcolumn}>
                <Grid container spacing={12}>
                <Grid item xs={12} sm={6} >
                      <Typography className={classes.secondaryHeading} noWrap='false'><b>{row.primaryprofile}</b><br></br>
                    {row.organizationname} <br></br> <b>Location(s):</b> {row.internlocations} {joboffer}<br></br>
                  <a href={`http://www.${row.websiteurl}`} target='_blank' className={classes.link}>
                    http://www.{row.websiteurl}
                    </a>
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography  className={classNames(classes.secondaryHeading)} >
                      <b> Only those Candidate Eligible:</b><br></br>

      1] Who Can Work {pt} in office.<br></br>
      2] Who Can Join {row.startdate}<br></br>
      3] Are Available for  {row.internshipduration} {row.options}<br></br>
      4] Are from {row.internlocations} and neighboring cities<br></br>
      5] Have Skill set of {row.skills}<br></br>



                      </Typography>
                    </Grid>

                    </Grid>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
              <Grid container>
                <Grid item xs={12} sm={8}>
                <div className={classes.column} />
                <div className={classes.nextcolumn}>
                <Typography  style={{fontSize:'11px'}}>
                    <Grid container spacing={12}>

              <Grid item xs={12} sm={2}>
              <Hidden smUp implementation="js">
                <b style={{color:'darkslateblue'}} >Start Date: </b>
                    {row.startdate}
              </Hidden>
              <Hidden xsDown implementation="css">
              <b style={{color:'darkslateblue'}}>Start Date: </b><br/>
              {row.startdate}
              </Hidden>
                    
                      </Grid>
                      <Grid item xs={12} sm={2}>
                      <Hidden smUp implementation="js">
                      <b style={{color:'darkslateblue'}} >Duration: </b>&nbsp;
                    {row.internshipduration} {row.options}
              </Hidden>
              <Hidden xsDown implementation="css">
              <b style={{color:'darkslateblue'}} >Duration: </b><br/>
                    {row.internshipduration} {row.options}
              </Hidden>
                      
                      </Grid>
                      <Grid item xs={12} sm={2}>
                      <Hidden smUp implementation="js">
                      <b style={{color:'darkslateblue'}} >Stipend: </b>
                   {row.currency} {row.stipend}/- {row.duration}
              </Hidden>
              <Hidden xsDown implementation="css">
              <b style={{color:'darkslateblue'}} >Stipend: </b><br/>
                   {row.currency} {row.stipend}/-<br/> {row.duration}
              </Hidden>
                      
                      </Grid>
                      <Grid item xs={12} sm={2}>
                      <Hidden smUp implementation="js">
                      <b style={{color:'darkslateblue'}} >Post On: </b>
                  {row.date_posted}
              </Hidden>
              <Hidden xsDown implementation="css">
              <b style={{color:'darkslateblue'}} >Post On: </b><br/>
                  {row.date_posted}
              </Hidden>
                      
                      </Grid>
                      <Grid item xs={12} sm={2}>
                      <Hidden smUp implementation="js">
                      <b style={{color:'darkslateblue'}} >Apply By: </b>
                    {td}
              </Hidden>
              <Hidden xsDown implementation="css">
              <b style={{color:'darkslateblue'}} >Apply By: </b><br/>
                    {td}
              </Hidden>
                    
                      </Grid>
                    </Grid>
                     </Typography>
                </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                <div className={classNames(classes.column, classes.helper)}>


                  <Typography variant="caption" noWrap='false'>


                   <a href={row.facebookurl} target='blank'><img src={`/images/facebook.png`} width='50' height='50'/></a>
                   {/* <img src={`/images/twitter.png`} width='50' height='50'/> */}
                   <img src={`/images/instagram.png`} width='50' height='50'/>
                   {/* <img src={`/images/youtube.png`} width='50' height='50'/> */}


                  </Typography>
                </div>
                </Grid>
              </Grid>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Grid container>
                  <Grid item xs={6}>
              <Button variant="contained" color="secondary" disabled={btnstatus} onClick={()=>this.showall(row.idemployer)}>
             {btntxt}
            </Button>
            </Grid>
            </Grid>
              </ExpansionPanelActions>
            </ExpansionPanel>
          </Grid>
            </Grid>
          </div>

                          </TableCell>

                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 48 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={3}
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActionsWrapped}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </Paper>



          </Grid>
        </Grid>



      </div>
    );
  }
}

ListInternship.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListInternship);
