import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem'
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';

import { getData,ClientURL } from "../FetchService"
import { postData } from "../FetchService"
import DisplayOrganizationDetails from './DisplayOrganizationDetails'
import SearchByEmailid from './SearchByEmailid'
import Checkout from './Checkout'
import DisplayAllInterns from './DisplayAllInterns'
import Hidden from '@material-ui/core/Hidden';


const drawerWidth = '100%';

    const styles =theme=>( {
      drawer: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
        root: {
          flexGrow: 1,
        },
        grow: {
          flexGrow: 1,
        },
        menuButton: {
          marginLeft: -12,
          marginRight: 20,
        },
      });

class EmployerHome extends React.Component {

  state={view:'',
    mobileOpen:false

  }



      constructor(props)
         { super(props)


      }
      componentWillMount()
      {  if(!localStorage.getItem("employer"))
         {
          this.props.history.replace({pathname:'/EmployerLogin'})

         }
        let employer=JSON.parse(localStorage.getItem('employer'));
        this.setState({view:<DisplayAllInterns id={employer.employerid}  history={this.props.history} />})



      }

      mainpage=async(value,employerid)=>{

      this.handleMobileOpen(false)
      if(value=='Intern Request')
       {
        this.setState({view:<Checkout mainpage={this.mainpage} data={JSON.stringify([])} history={this.props.history} />})
       }
       else if(value=='List Request')
       {
        this.setState({view:<SearchByEmailid mainpage={this.mainpage}  history={this.props.history} />})
       }
       else  if(value=='Display')
       {
         this.setState({view:<DisplayOrganizationDetails  idemployer={employerid} displayAction={this.displayAction}  history={this.props.history} />})
       }
       else  if(value=='Intern Applied')
       {   let employer=JSON.parse(localStorage.getItem('employer'));
        this.setState({view:<DisplayAllInterns id={employer.employerid}  history={this.props.history} />})
       }


      }

      displayAction=(value,data)=>
      {
        if(value=='EditIntern')
        {
            this.setState({view:<Checkout mainpage={this.mainpage} data={data} history={this.props.history} />})
      }
    }
      action=(value)=>{

     // this.setState({listset:value})
     // this.setState({view:<ViewSet userview={this.state} cartaction={this.cartaction} history={this.props.history}/>})


      }
     async   componentDidMount() {


      }

      handleMobileOpen=(status)=>{
        this.setState({mobileOpen:status})
      }




      handleClicks =async (event)=> {
        if(event.currentTarget.value=='Logout')
        {localStorage.removeItem('employer');
          this.props.history.replace({pathname:'/UserMainPage'})
          }
        else{
        this.handleMobileOpen(false)
       this.mainpage(event.currentTarget.value,'')
     }

         };






      menu=(classes)=>{
        let employer=JSON.parse(localStorage.getItem('employer'))
      return(

        <React.Fragment>
             <Typography variant='body2' color="inherit" style={{textAlign:'center'}}>
                [{employer.email}]<br/> [{employer.first_name}&nbsp;{employer.last_name}]
              </Typography>

      <Button



         onClick={this.handleClicks}
         value='Intern Request'

      >
    Hire Interns
      </Button>
      <Button



         onClick={this.handleClicks}
         value='List Request'>
         Internship Posted
      </Button>

      <Button



        onClick={this.handleClicks}
        value='Intern Applied'>
        Intern Applied
     </Button>
      <Button


         onClick={this.handleClicks}
         value='Logout'>
        Logout
      </Button>

      </React.Fragment>


)}










 SearchAppBar(classes) {

  return (


        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Toolbar>
            <div>
          <img src={`/images/cssbig.png`}></img>
          </div>

               <div className={classes.grow}></div>

              {this.menu(classes)}

            </Toolbar>
          </AppBar>
        </div>
      );



}

render() {
    const { classes,...other } = this.props;
    let button;
      button = <div>
      <AppBar  position="static" color="default">
        <Toolbar>
          <MenuIcon onClick={(e)=>this.setState({mobileOpen:true})} color='primary'/>


          <img src={`/images/cssbig.png`}></img>
            <Button


               onClick={this.handleClicks}
               value='Logout'>
              Logout
            </Button>

        </Toolbar>
      </AppBar>
        <Drawer open={this.state.mobileOpen} onClose={(e)=>{this.setState({mobileOpen:false})}} variant="temporary" {...other}>
        <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <img src={`/images/cssbig.png`} onClick={()=>this.handleMobileOpen(false)}></img>
        </ListItem>
         <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            CampusShala
          </ListItemText>
        </ListItem>
      </List>
         {this.menu(classes)}
        </Drawer>
        </div>


    return (
      <div>
      <div>
        <nav className={classes.drawer}>
              <Hidden smUp implementation="js">
                {button}
              </Hidden>
              <Hidden xsDown implementation="css">
              {this.SearchAppBar(classes)}
              </Hidden>
            </nav>


    </div>
    <div>
        {this.state.view}
    </div>
    </div>

    );}



}
EmployerHome.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmployerHome);
