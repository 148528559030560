import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import { getData} from '../../../FetchService';

export default function ChapterList(props) {
	const [ getColumn ] = React.useState({
		columns: [
			{
				title: 'Course',
				field: 'coursename'
			},
			{ title: 'Chapter', field: 'chaptername' }
		]
	});

	const [ state, setState ] = React.useState({ data: [] });

	const fetchAllStudent = async () => {
		let list = await getData('chapterdetails/fetchdata');
		setState({ data: list });
	};

	useEffect(function() {
		fetchAllStudent();
	}, []);

	return (
		<MaterialTable
			title="Chapter list"
			columns={getColumn.columns}
			data={state.data}
			actions={[
				{
					icon: 'save',
					tooltip: 'Save User',
					// onClick: (event, rowData) => alert("You saved " + rowData.studentid)
					onClick: (event, rowData) =>
						props.history.push({ pathname: '/ChapterUpdate', state: { id: rowData.chapterid } })
				}
			]}
			components={{
				Action: (props) => (
					<Button
						onClick={(event) => props.action.onClick(event, props.data)}
						color="primary"
						variant="contained"
						style={{ textTransform: 'none' }}
						size="small"
					>
						Change
					</Button>
				)
			}}
		/>
	);
}
