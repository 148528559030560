import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MainPage from './MainPage';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import Privacy from './Privacy';
import OurServices from './OurServices';
import Aboutus from './Aboutus'

const styles = theme => ({


  root:
  { 
    marginTop:'5%',
    marginLeft:'10%',
    textAlign:'justify',
    marginRight:'10%'
  },
  hello:
  {
    marginTop:'0.5%',
    fontStyle:'calibri',
  },
hello1:{
  fontSize:'160%',
  marginTop:'0.5%',

},
hello2:{
  marginTop:'0.5%',


},
set:{
  marginTop:'1%'
},
set1:{
  marginTop:'2%',
  fontSize:'100%',
}
})


class TermsCondition extends React.Component {

  render() {
    const { classes } = this.props;

    return (
     <div>
      <div className={classes.root}>

<Typography className={classes.hello1}><b><u>Terms and Conditions:</u></b></Typography>

  <div className={classes.hello}>
The information and content published on this website is for information purposes regarding the internship, placement preparation and placement opportunity for the engineering students.</div>
<div className={classes.set}><b>Introduction:</b></div>
<div className={classes.set}>
This Website/Service is owned and operated by CSEP Campusshala Education Pvt Ltd.(<b>“Campusshala”</b>). Please read the Terms and Conditions (<b>“Terms”</b>) and the disclaimer policy of the Company (<b>“Disclaimer Policy”</b>) with respect to registration with us, the use of the Website, software, Service(s) and product(s) carefully before using the Website, software, Service(s) or product(s).The Terms are subject to modification by Campusshala without any prior notification, hence you are advised to regularly review the Terms. Use of this Site constitutes your unconditional acceptance of these Terms herein and by all terms, policies and guidelines incorporated by reference.</div>
<div className={classes.set}>
Campusshala does not give access to the person who is under the age of 18 or previously suspended from the Website to access the platform. On accepting the Terms or using the information from Campusshala, a user represents the age of 18 and above.</div>
<div className={classes.set1}><b><u>Fees:</u></b></div>
<div className={classes.hello2}>
       Users are not bound to pay any fees for using the services offered by us. However, please note that certain premium features and test packages can be availed only after paying requisite fees as mentioned.
       </div>
<div className={classes.set1}><b><u>Cancellations / Refunds:</u></b></div>

         Cancellation / refund in part or full for online products like online test packages is not possible once purchase has been made.
<div style={{marginTop:'2%'}}><b>
Terms and Conditions before posting any Internship:
</b></div>
<div style={{marginTop:'0.5%'}}>
<b>1.</b>If you are posting internship on the behalf of your organization, please make sure that you are authorized for this posting otherwise campusshala will not responsible for any dispute or claim arising. </div>
<div style={{marginTop:'0.5%'}}>
<b>2.</b>	Only the person who is authorized from the organization will be eligible for uploading the logo of the organization while posting of internship. The person should ensure that he is duly authorized to share your organization logo with 3rd parties and allow them to use it.
</div>
<div style={{marginTop:'0.5%'}}>
<b>3.</b>	We never allowed any organization who is not providing the complete informationas per the form which is mention on the website for the internship details. Any act of misinformation or hiding material information would result in your account being permanently blocked.
</div>
<div style={{marginTop:'0.5%'}}>
<b>4.</b>	You may be asked for documents in the name of your company for our first-time user authentication process. This information will only be used for authentication purposes and will not be used anywhere else in any form.
</div>
<div style={{marginTop:'0.5%'}}>
<b>5.</b>	You have to provide a minimum stipend of Rs. 1500 / Month to the intern. 
</div>
<div style={{marginTop:'1%'}}> 
<b>What we not allowed on our website: </b></div>
<div  style={{marginTop:'1%'}}>
<b>1.</b>	We never entertain any unpaid internships on our website.
</div>
<div style={{marginTop:'1%'}}>
<b>2.</b>	Training program from other websites where students are expected to pay a security deposit.
</div >
<div style={{marginTop:'1%'}}>
<b>3.</b>	Any kind of Call Centre Internship or Network Level Marketing (NLM) internships.
</div>
<div style={{marginTop:'1%'}}>
  <b>4.</b>	Businesses promoting or dealing in gambling and related games.
</div>
<div style={{marginTop:'1%'}}>
<b>5.</b>	Third party internship or third party agencies are not authorized to post any internship on the behalf of other companies. Any such internship will be declined and the account will be blocked permanently.
</div>
<div style={{marginTop:'1%'}} >
<b>Disclaimer:</b></div>
<div style={{marginTop:'1%'}}>
All the information on this website is published in good faith and for general information purpose only. Website Name does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (Website.com), is strictly at your own risk. will not be liable for any losses and/or damages in connection with the use of our website.
<div style={{marginTop:'1%'}}>From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone ‘bad'.</div>
<div style={{marginTop:'1%'}}>Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.
</div>
</div>






      </div>
     
      {/* <Router>
  
     <Route path='/TermsCondition' exact strict component={TermsCondition} history={this.props.history} ></Route>
      <Route path='/Privacy' exact strict component={Privacy} history={this.props.history} ></Route>
      <Route path='/OurServices' exact strict component={OurServices} history={this.props.history} ></Route>
      <Route path='/Aboutus' exact strict component={Aboutus} history={this.props.history} ></Route>
   <footer style={{backgroundColor:'#2d2d2d'}}
      
    className={classes.footer}
      >  
                <Typography  style={{marginLeft:'1040px' ,color:'white',fontSize:'20px'}}>AboutUs</Typography>
               <ul style={{listStyle:'none',marginLeft:'1000px'}}>
                <li><Link to="/Aboutus" style={{textDecorationLine:'none',color:'white',fontSize:'15px'}}>About Us</Link></li>
                <li><Link to="/TermsCondition" style={{textDecorationLine:'none',color:'white' ,fontSize:'15px'}} >Terms & Conditions</Link></li>
                <li><Link to="/OurServices" style={{textDecorationLine:'none',color:'white',fontSize:'15px'}}>OurServices</Link></li>
                <li><Link to="/Privacy" style={{textDecorationLine:'none',color:'white',fontSize:'15px'}}>Privacy</Link></li>
              </ul>
            
        
          
          
        </footer>
      </Router> */}
  
  

</div>

    );
  }

}
TermsCondition.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsCondition);
