import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import UserMainPage from "../userinterface/UserMainPage";
import TakeTest from "../userinterface/TakeTest";

import UserOrganization from "../userinterface/UserOrganization";
import ViewSet from "../userinterface/ViewSet";
import DisplayPaper from "../userinterface/DisplayPaper";
import TestPaper from "../userinterface/TestPaper";
import UserRegistration from "./UserRegistration";
import ShowPurchasedSet from "./ShowPurchasedSet";

import Checkout from "./Checkout/Checkout";
import UserLogin from "./UserLogin";
import ShowCart from "./ShowCart";
import ReviewTest from "./ReviewTest";
import ReviewTestTab from "./ReviewTestTab";
import SummaryReport from "./SummaryReport";
import InternCheckout from "../internship/Checkout";
import DisplayOrganizationDetails from "../internship/DisplayOrganizationDetails";
import SearchByEmailid from "../internship/SearchByEmailid";
import EmployerHome from "../internship/EmployerHome";
import EmployerRegistration from "../internship/EmployerRegistration";
import Dash from "../csdashboard/Dashboard";
import EmployerLogin from "../internship/EmployerLogin";
import ListInternship from "../internship/ListInternship";
import SignIn from "../login/SignIn";
import TermsCondition from "../footer/TermsCondition";
import AppAppBar from "./AppAppBar";

import Privacy from "../footer/Privacy";
import OurServices from "../footer/OurServices";

import UserDashBoard from "../userinterface/UserDashBoard";
import Certificate from "../userinterface/Certificate";

import Aboutus from "../footer/Aboutus";
import ResendLink from "./ResendLink";

class UserView extends Component {
  render() {
    return (
      <Router>
        <div>
          <Redirect to="/UserMainPage" />

          <div className="content">
            <Route
              exact
              strict
              path="/AppBar"
              component={AppAppBar}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/UserMainPage"
              component={UserMainPage}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/UserDashBoard"
              component={UserDashBoard}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/SignIn"
              component={SignIn}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/Certificate"
              component={Certificate}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/ShowCart"
              component={ShowCart}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/TakeTest"
              component={TakeTest}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/ViewSet"
              component={ViewSet}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/UserOrganization"
              component={UserOrganization}
              history={this.props.history}
            />
            <Route
              path="/DisplayPaper"
              component={DisplayPaper}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/TestPaper"
              component={TestPaper}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/UserRegistration"
              component={UserRegistration}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/UserLogin"
              component={UserLogin}
              history={this.props.history}
            />
            <Route
              path="/Checkout"
              component={Checkout}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/ShowPurchasedSet"
              component={ShowPurchasedSet}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/ReviewTest"
              component={ReviewTest}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/ReviewTestTab"
              component={ReviewTestTab}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/SummaryReport"
              component={SummaryReport}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/InternCheckout"
              component={InternCheckout}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/DisplayOrganizationDetails"
              component={DisplayOrganizationDetails}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/SearchByEmailid"
              component={SearchByEmailid}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/EmployerHome"
              component={EmployerHome}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/EmployerRegistration"
              component={EmployerRegistration}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/EmployerLogin"
              component={EmployerLogin}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/Dash"
              component={Dash}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/ListInternship"
              component={ListInternship}
              history={this.props.history}
            />
            <Route
              exact
              strict
              path="/TermsCondition"
              component={TermsCondition}
              history={this.props.history}
            ></Route>
            <Route
              exact
              strict
              path="/Privacy"
              component={Privacy}
              history={this.props.history}
            ></Route>
            <Route
              exact
              strict
              path="/OurServices"
              component={OurServices}
              history={this.props.history}
            ></Route>
            <Route
              exact
              strict
              path="/Aboutus"
              component={Aboutus}
              history={this.props.history}
            ></Route>
            <Route
              exact
              strict
              path="/ResendLink"
              component={ResendLink}
              history={this.props.history}
            ></Route>
          </div>
        </div>
      </Router>
    );
  }
}

export default UserView;
