/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { getData,postData } from '../../FetchService';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import Grid from '@material-ui/core/Grid';

const suggestions = [
  { label: 'Afghanistan' },
  { label: 'Aland Islands' },
  { label: 'Albania' },
  { label: 'Algeria' },
  { label: 'American Samoa' },
  { label: 'Andorra' },
  { label: 'Angola' },
  { label: 'Anguilla' },
  { label: 'Antarctica' },
  { label: 'Antigua and Barbuda' },
  { label: 'Argentina' },
  { label: 'Armenia' },
  { label: 'Aruba' },
  { label: 'Australia' },
  { label: 'Austria' },
  { label: 'Azerbaijan' },
  { label: 'Bahamas' },
  { label: 'Bahrain' },
  { label: 'Bangladesh' },
  { label: 'Barbados' },
  { label: 'Belarus' },
  { label: 'Belgium' },
  { label: 'Belize' },
  { label: 'Benin' },
  { label: 'Bermuda' },
  { label: 'Bhutan' },
  { label: 'Bolivia, Plurinational State of' },
  { label: 'Bonaire, Sint Eustatius and Saba' },
  { label: 'Bosnia and Herzegovina' },
  { label: 'Botswana' },
  { label: 'Bouvet Island' },
  { label: 'Brazil' },
  { label: 'British Indian Ocean Territory' },
  { label: 'Brunei Darussalam' },
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class AddressForm extends React.Component {
  state = {
    single: null,
    multi: null,
    first_name:'',
    last_name:'',
    address1:'',
    address2:'',
    email:'',
    mobile:'',
    statename:'',
    city:'',
    zipcode:'',
    country:'',
    list:[],
    list1:[],
    listcity:[],
    id:'',
    idstate:'',
    value2:'',
    state_id:''
  };
  
  async componentWillMount(){
    const list = await getData(`userdetails/DisplayjsonState`);
    this.setState({list})
    console.log('component ',list)

    let rows=list.map(fill=>({
        value:fill.name,
        id:fill.id,
        label:fill.name })) 
        await this.setState({list:rows})
        this.user = JSON.parse(localStorage.getItem("user"));
        this.props.states.first_name= this.user.first_name
        this.props.states.last_name= this.user.last_name
        this.props.states.email= this.user.email
        this.props.states.mobile= this.user.mobile
        this.setState(this.props.states)
        console.log('shreshtha')
        this.setState(this.props.states)

    }
  handleChange=async(value) => {
    console.log('xxxxx',value)
    this.props.states.statename=value
    this.props.states.state_name=value.value
   
    this.setState({
      statename: value,
      
    });
    if(value!=null){
    console.log('id',value.id)
    await this.setState({idstate:value.id})
    await this.change();
  }
  };
  handleChangeCity = city => value2 => {
    console.log(value2)
    this.props.states.city=value2
    console.log(value2.value)
    this.props.states.city_name=value2.value
    this.setState({
      city: value2,
    });
    
  };
  change=async(event)=>{
    
    const body={state_id:this.state.idstate}
let list1= await postData(`userdetails/DisplayAllCities`,body)
console.log('citylist',list1)
this.setState({list1})
let cityrows=list1.map(fill=>({
  value:fill.name,
  label:fill.name })) 
  await this.setState({list1:cityrows})

  };

  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };

    return (
      <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={2}>
        
      


        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="first_name"
            name="first_name"
            value={this.state.first_name}
            label="First name"
            fullWidth
            autoComplete="first_name"
            onChange={e=>(this.props.states.first_name=e.target.value, this.setState({first_name:e.target.value}))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          
          <TextField
            required
            id="last_name"
            name="last_name"
            value={this.state.last_name}
            label="Last name"
            fullWidth
            autoComplete="last_name"
            onChange={e=>(this.props.states.last_name=e.target.value,this.setState({last_name:e.target.value}))}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            value={this.state.address1}
            label="Address line 1"
            fullWidth
            autoComplete="billing address-line1"
            onChange={e=>{this.props.states.address1=e.target.value; this.setState({address1:e.target.value})}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            value={this.state.email}
            label="Email Address"
            fullWidth
            autoComplete="email"
            onChange={e=>{this.props.states.email=e.target.value;this.setState({email:e.target.value})}}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField id="mobile" type='number' name="mobile"value={this.state.mobile} label="Mobile Number" onChange={e=>{this.props.states.mobile=e.target.value.toString();this.setState({mobile:e.target.value})}} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            value={this.state.address2}
            label="Address line 2"
            fullWidth
            autoComplete="address2"
            onChange={e=>{this.props.states.address2=e.target.value; this.setState({address2:e.target.value})}}
          />
        </Grid>
        
       
        <Grid item xs={12} sm={6}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            options={this.state.list}
            components={components}
            value={this.state.statename}
            onChange={(event)=>this.handleChange(event)}
            placeholder="Select Your State"
            isClearable
          />
          
        </NoSsr>
     
        </Grid>
        <Grid item xs={12} sm={6}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            options={this.state.list1}
            components={components}
            value={this.state.city}
            onChange={this.handleChangeCity('city')}
            placeholder="Select Your City"
            isClearable
          />
          
        </NoSsr> 
        </Grid>

         
        <Grid item xs={12}>
          <TextField
            required
            type='number'
            id="zipcode"
            name="zipcode"
            value={this.state.zipcode}
            label="Zip / Postal code"
            fullWidth
            autoComplete="zip_code"
            onChange={e=>{this.props.states.zipcode=e.target.value;this.setState({zipcode:e.target.value})}}
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            required
            id="country"
            name="country"
            value={this.state.country}
            label="Country"
            fullWidth
            autoComplete="country"
            onChange={e=>{this.props.states.country=e.target.value;this.setState({country:e.target.value})}}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" value="yes" />}
            label="Use this address for payment details"
          />
        </Grid> */}
      </Grid>
    </React.Fragment>
  













       
    );
  }
}

AddressForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AddressForm);
