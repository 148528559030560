import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { postData,BaseURL } from '../FetchService';
import Button from '@material-ui/core/Button';
import CheckOut from './Checkout'
const styles = theme => ({
  capdiv:{
    display:'flex',
    justifyContent:'center',
    alignItem:'center',
 },
    root:{
        display:'flex',
        justifyContent:'center',
        alignItem:'center',
     },
    papers: {
    width:'700px',
    height:'auto',
    padding:'30px',
    marginTop:'15px',
  },
});

 

class DisplayOrganizationDetails extends React.Component {
    state={data:[]}
    componentDidMount() {
       // let eid=this.props.location.state.data.idemployer;
        this.searchById(this.props.idemployer)
    }

    searchById=async(idemployer)=>{
        const body={eid:idemployer};
       const result = await postData(`internship/searchbyid`,body);
       if(result)
       {  // const {maincategoryid,maincategory,icon}=result[0];
          
             
          await this.setState({ data:result[0]});
            
             
       }
       else
       {
        console.log(`Invalid ${result}`);   
       }
    }
    editRecord=()=>{
    
   // this.props.history.replace({pathname:'/InternCheckout',state:{data:JSON.stringify(this.state.data)}})
      this.props.displayAction('EditIntern',JSON.stringify(this.state.data))
  }
    PaperSheet=(classes)=>{
      var d=new Date(this.state.data.applyby)
      var td=d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + d.getDate()).slice(-2)
       let pt=''   
      if(this.state.data.isparttime=='Yes')
           pt='Part Time'
          else
           pt='Full Time'
         return (
           <div>
            <div className={classes.capdiv}> <Typography variant="subheading" gutterBottom>
               {this.state.data.primaryprofile} Internship in Multiple Locations at  {this.state.data.organizationname} 
              </Typography>
              </div>
           <div className={classes.root}>
           
            <Paper className={classes.papers} elevation={1}>
             
              <Typography component="p">
              <Grid container spacing={24}>
              <Grid item xs={6}>
              <b>{this.state.data.primaryprofile}</b><br></br>
              {this.state.data.organizationname}
                </Grid>
        <Grid item xs={6}>
        <img src={`${BaseURL}/images/${this.state.data.logo}`} width='40' height='40'></img>
        </Grid>


       
        <Grid item xs={12} style={{marginTop:'-25px'}}>
         <b>Location(s):</b>{this.state.data.internlocations}
        </Grid>

        <Grid item xs={2}>
              <b>Start Date</b><br></br>
              {this.state.data.startdate}
                </Grid>
                <Grid item xs={2}>
                <b>Duration</b><br></br>
              {this.state.data.internshipduration}
                </Grid>
                <Grid item xs={2}>
                <b>Stipend</b><br></br>
             {this.state.data.currency} {this.state.data.stipend}/- {this.state.data.duration}
                </Grid>
                <Grid item xs={2}>
                <b>Post On</b><br></br>
            {this.state.data.date_posted}
                </Grid>
                <Grid item xs={2}>
              <b>Apply By</b><br></br>
           
              {td}
                </Grid>
              </Grid>
               </Typography>
            </Paper>

            
            </div>
            <div className={classes.root}>
            <Paper className={classes.papers} elevation={1}>
             
             <Typography component="p">
             <Grid container spacing={24}>
             <Grid item xs={12}>
            <b> About {this.state.data.organizationname} ({this.state.data.websiteurl}):</b><br></br>

               
             {this.state.data.description}
             
               </Grid>
               <Grid item xs={12}>
            <b> About the Internship:</b><br></br>

               
             {this.state.data.responsibilities}
             
               </Grid>
               <Grid item xs={12}>
            <b> Number of Intern Required:</b><br></br>

               
             {this.state.data.noofopenings}
             
               </Grid>
               <Grid item xs={12}>
               <b>Skill(S) Required:</b><br></br>
              {this.state.data.skills}
             
               </Grid>
               <Grid item xs={12}>
            <b> Only those Candidate Eligible Who Can:</b><br></br>

             1] Who Can Work {pt} in office.<br></br>
             2] Who Can Join {this.state.data.startdate}<br></br>
             3] Are Available for  {this.state.data.internshipduration} {this.state.data.options}<br></br>
             4] Are from {this.state.data.internlocations} and neighboring cities<br></br>
             5] Have Skill set of   {this.state.data.skills}<br></br>
             
               </Grid>
               <Grid item xs={12}>
               <b>Perks:</b><br></br>
              {this.state.data.perks}
             
               </Grid>
               <Grid item xs={12}>
               <Button variant="contained" color="primary" className={classes.button} onClick={this.editRecord}>
               Edit Internship
               </Button>
             </Grid>
             </Grid>


              </Typography>
           </Paper>
            
            </div>
            </div>
        );
      }
    render() {
        const { classes } = this.props;
        return(
          <div>
              {this.PaperSheet(classes)}
          </div>           
               
         )
    }
}



DisplayOrganizationDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DisplayOrganizationDetails);