import React, { Component } from 'react';
import { firebase } from 'firebase';
import _ from 'lodash';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import renderHTML from 'react-render-html';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { postDataAndImage } from "../FetchService";

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getData } from "../FetchService"
import { postData } from "../FetchService"
import Avatar from '@material-ui/core/Avatar';
 
 
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 1,
      marginBottom: theme.spacing.unit * 1,
      padding: theme.spacing.unit * 2,
    },
  },
   
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  formControl: {
     minWidth:150,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  iconSmall: {
    fontSize: 20,
  },
  avatar: {
    margin: 5,
  },
  bigAvatar: {
    margin: 5,
    width: 100,
    height: 100,
  },
});
class AddNewQuestions extends Component {
 address='http://www.campusshala.com/images/';
 
  constructor(props) {
    super(props);
    this.state = {
      maincategoryid:'',  
      organizationid:'',
      subcategoryid: "",
      categoryid:"",
      questionno:"",
      question:"",
      option1:"",
      option2:"",
      option3:"",
      option4:"",
      answer:"",
      solution:"",

      msg: "",
      list: [],
      listsub:[],
      listorg:[],
      listexamcat:[],
      listsetno:[],
      setno:'',
      eventSection:'',
      img1:'',
      img2:'',
      img3:'',
      img4:'',
      img5:'',
      img6:'',
      img7:'',
      img8:'',
      img9:'',
      img10:'',
      img11:'',
      img12:'',
      img13:'',
    };
    // bind
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onHandleChangeSolution = this.onHandleChangeSolution.bind(this);
    this.handleChangeCat = this.handleChangeCat.bind(this);
    this.handleChangeOrg = this.handleChangeOrg.bind(this);
    this.handleChangeSetNo = this.handleChangeSetNo.bind(this)
    this.handleChangeExamCat = this.handleChangeExamCat.bind(this)
  }

  valid=()=>{
      
   let err1=this.state.maincategoryid.length!=0?`tick.png`:`crs.png`;
   let err2=this.state.subcategoryid.length!=0?`tick.png`:`crs.png`;
   let err3=this.state.organizationid.length!=0?`tick.png`:`crs.png`;
   let err4=this.state.setno.length!=0?`tick.png`:`crs.png`;
   let err5=this.state.categoryid.length!=0?`tick.png`:`crs.png`;
   let err6=this.state.questionno.length!=0?`tick.png`:`crs.png`;
   let err7=this.state.question.length!=0?`tick.png`:`crs.png`;
   let err8=this.state.option1.length!=0?`tick.png`:`crs.png`;
   let err9=this.state.option2.length!=0?`tick.png`:`crs.png`;
   let err10=this.state.option3.length!=0?`tick.png`:`crs.png`;
   let err11=this.state.option4.length!=0?`tick.png`:`crs.png`;
   let err12=this.state.answer.length!=0?`tick.png`:`crs.png`;
   let err13=this.state.solution.length!=0?`tick.png`:`crs.png`;
   this.setState({img1:`${this.address}${err1}`});
   this.setState({img2:`${this.address}${err2}`});
   this.setState({img3:`${this.address}${err3}`});
   this.setState({img4:`${this.address}${err4}`});
   this.setState({img5:`${this.address}${err5}`});
   this.setState({img6:`${this.address}${err6}`});
   this.setState({img7:`${this.address}${err7}`});
   this.setState({img8:`${this.address}${err8}`});
   this.setState({img9:`${this.address}${err9}`});
   this.setState({img10:`${this.address}${err10}`});
   this.setState({img11:`${this.address}${err11}`});
   this.setState({img12:`${this.address}${err12}`});
   this.setState({img13:`${this.address}${err13}`});
    

       let v='tick.png';
      if(err1==v && err2==v&& err3==v && err4==v && err5==v && err6==v && err7==v && err8==v && err9==v && err10==v && err11==v && err12==v && err13==v)
      { 
    
       return(true);} 
     else    
     {
       return(false)
     }

    }

 handleChangeCat=async(event)=> {
      this.setState({maincategoryid: event.target.value });
    const body={cid:event.target.value};
   const listsub = await postData(`subcategory/fetchsubcategories`,body);
   this.setState({ listsub });
 }


 handleChangeOrg=async(event)=> {
  this.setState({subcategoryid: event.target.value });
 
const body={cid:event.target.value};
const listorg = await postData(`questions/fetchorganization`,body);
 

this.setState({ listorg });
}

handleChangeSetNo=async(event)=> {
  this.setState({organizationid: event.target.value });
 
const body={cid:event.target.value};
const listsetno = await postData(`questions/fetchsetno`,body);
 

this.setState({ listsetno });

}

handleChangeExamCat=async(event)=> {
  this.setState({categoryid: event.target.value });
  this.setState({eventExam:event});
const body={examcategoryid:event.target.value,setno:this.state.setno,organizationid:this.state.organizationid};

const result = await postData(`questions/generatequestionnono`,body);
 
if(result)
{  // const {maincategoryid,maincategory,icon}=result[0];
  //    let data=JSON.stringify(result[0]);   
  let data= result[0];
     if(data.questionno==null){
       
        
        this.setState({questionno:'1'});
       
     }
     else{
          let sn=data.questionno+1;
         
        this.setState({questionno:sn});
     }

}
}


  // lifecycle
 async componentDidMount() {
    const list = await getData(`maincategory/fetchallmaincategories`);
    console.log(`Data ${list}`)
    this.setState({ list });
    console.log(`Data ${this.state.list}`)
    const listexamcat = await getData(`examcategory/fetchallexamcategories`);
     
    this.setState({ listexamcat });
 
    
       
  }

  // render posts from firebase
  renderPosts() {
    return _.map(this.state.posts, (post, key) => {
      return (
        <div key={key}>
          <h2>{post.title}</h2>
          <p>{renderHTML(post.body)}</p>
        </div>
      );
    });
  }

  onHandleChange(e) {
    this.setState({ question: e });
  
  }
  onHandleChangeSolution(e) {
    this.setState({solution: e });
  
  }

  
  fillMainCategory = () => {
        
    return this.state.list.map((item, index) => {
         
        return (
          <MenuItem value={item.maincategoryid}>{item.maincategory}</MenuItem>  
         );
    })
}  


fillSubCategory = () => {
        
    return this.state.listsub.map((item, index) => {
         
      
       
        return (
          <MenuItem value={item.subcategoryid}>{item.subcategoryname}</MenuItem>  
         );
    })
}  

fillOrganization = () => {
        
  return this.state.listorg.map((item, index) => {
       
    
     
      return (
        <MenuItem value={item.organizationid}>{item.organization}</MenuItem>  
       );
  })
}  


fillExamCategory = () => {
        
  return this.state.listexamcat.map((item, index) => {
        return (
        <MenuItem value={item.examcategoryid}>{item.examcategory}</MenuItem>  
       );
  })
}  



fillSetNo = () => {
        
  return this.state.listsetno.map((item, index) => {
       
    
     
      return (
        <MenuItem value={item.setno}>{item.setorg}</MenuItem>  
       );
  })
}  

showComponents1(classes) {
    
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Add Question Paper
        </Typography>
        
        
        <Grid container spacing={24}>
        <Grid item xs>
  
  
          
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-controlled-open-select">Main Category Id</InputLabel>
            <Select
               
              
               value={this.state.maincategoryid}
               onChange={this.handleChangeCat}
              
              inputProps={{
                name: 'maincatrgoryid',
                id: 'demo-controlled-open-select0',
              }}
            >
            <MenuItem value="">
                <em>Main Category</em>
              </MenuItem>
             
            {this.fillMainCategory()}
      
            </Select>
          </FormControl>  
          <img src={this.state.img1}></img>
          </Grid>
         
          <Grid item xs>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-controlled-open-select">Sub Category Id</InputLabel>
            <Select
               
              
               value={this.state.subcategoryid}
               onChange={this.handleChangeOrg}
             
              inputProps={{
                name: 'subcatrgoryid',
                id: 'demo-controlled-open-select',
              }}
            >
            <MenuItem value="">
                <em>Sub Category</em>
              </MenuItem>
             
            {this.fillSubCategory()}
      
            </Select>
          </FormControl>
          <img src={this.state.img2}></img>
          </Grid>
          
          
          <Grid item xs>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-controlled-open-select">Organization</InputLabel>
            <Select
               
              
               value={this.state.organizationid}
               onChange={this.handleChangeSetNo}
              
              inputProps={{
                name: 'organizationid',
                id: 'demo-controlled-open-select0',
              }}
            >
            <MenuItem value="">
                <em>Organization</em>
              </MenuItem>
             
              {this.fillOrganization()}
      
            </Select>
          </FormControl>
          <img src={this.state.img3}></img>
          </Grid>   
          <Grid item xs>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-controlled-open-select">Set Number</InputLabel>
            <Select
               
              
               value={this.state.setno}
              
               onChange={e => this.setState({setno: e.target.value })}
              inputProps={{
                name: 'setno',
                id: 'demo-controlled-open-select0',
              }}
            >
            <MenuItem value="">
                <em>Set Number</em>
              </MenuItem>
             
              {this.fillSetNo()}
      
            </Select>
          </FormControl>
          <img src={this.state.img4}></img>
          </Grid>   


          
            <Grid item xs>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-controlled-open-select">Section</InputLabel>
            <Select
               
              
               value={this.state.categoryid}
              
               onChange={this.handleChangeExamCat}
              inputProps={{
                name: 'categoryid',
                id: 'demo-controlled-open-select0',
              }}
            >
            <MenuItem value="">
                <em>Set Number</em>
              </MenuItem>
             
              {this.fillExamCategory()}
      
            </Select>
          </FormControl>
          <img src={this.state.img5}></img>
          </Grid>         
           </Grid>

         
      </React.Fragment>
    );
  }

  showComponents2(classes) {
    
    return (
      <React.Fragment>
         
        
     

  <Grid container spacing={24}>

<Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              id="questionno"
              name="questionno"
              label="Question No"
              
              fullWidth
              autoComplete="questionno"
              value={this.state.questionno}
              onChange={e => this.setState({questionno: e.target.value })}
              
            />
              </FormControl> 
              <img src={this.state.img6}></img>
          </Grid>
  
  <Grid  item xs={12}>
  <FormControl className={classes.formControl}>
  
 
 <div className="form-group">
   <ReactQuill
     modules={AddNewQuestions.modules}
     formats={AddNewQuestions.formats}
     value={this.state.question}
     placeholder="Body"
     onChange={this.onHandleChange}
   />
 </div>
  
</FormControl>
 <img src={this.state.img7}></img>
  </Grid>

<Grid item xs={12} sm={3} >
          <FormControl className={classes.formControl}>
            <TextField
              required
              id="option1"
              name="option1"
              label="Option 1"
              
              fullWidth
              autoComplete="option1"
              value={this.state.option1}
              onChange={e => this.setState({ option1: e.target.value })}
              
            />
              </FormControl> 
              <img src={this.state.img8}></img>
          </Grid>
          <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              id="option2"
              name="option2"
              label="Option 2"
              
              fullWidth
              autoComplete="option2"
              value={this.state.option2}
              onChange={e => this.setState({ option2: e.target.value })}
              
            />
              </FormControl> 
              <img src={this.state.img9}></img>
          </Grid>

          <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              id="option3"
              name="option3"
              label="Option 3"
              fullWidth
              autoComplete="option3"
              value={this.state.option3}
              onChange={e => this.setState({ option3: e.target.value })}
              
            />
              </FormControl> 
              <img src={this.state.img10}></img>
          </Grid>
          <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              id="option4"
              name="option4"
              label="Option4"
              
              fullWidth
              autoComplete="option4"
              value={this.state.option4}
              onChange={e => this.setState({ option4: e.target.value })}
              
            />
              </FormControl>
              <img src={this.state.img11}></img>
          </Grid>


 <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <TextField
              required
              id="answer"
              name="answer"
              label="Answer"
              
              fullWidth
              autoComplete="answer"
              value={this.state.answer}
              onChange={e => this.setState({ answer: e.target.value })}
              
            />
              </FormControl>
              <img src={this.state.img12}></img>
          </Grid>
          <Grid>
  <FormControl className={classes.formControl}>
  
 
 <div className="form-group">
  <Typography variant="h6" component="h4">
    Solution
    </Typography> 
   <ReactQuill
     modules={AddNewQuestions.modules}
     formats={AddNewQuestions.formats}
     value={this.state.solution}
     placeholder="Body"
     onChange={this.onHandleChangeSolution}
   />
 </div>
  
 
</FormControl>
 <img src={this.state.img13}></img>
  </Grid>

   </Grid>

<br/><br/>
<Button variant="contained" size="small" className={classes.button}     onClick={() => this.addrecord()}>
 <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
 Save
</Button>

     
     </React.Fragment>
    );
  }


  addrecord =async()=> {
    console.log(this.valid())
     if(this.valid()){
    try {
  
        const body={organizationid:this.state.organizationid,
          categoryid:this.state.categoryid,
        setno:this.state.setno,
        questionno:this.state.questionno,
        question:this.state.question,
        option1:this.state.option1,
        option2:this.state.option2,
        option3:this.state.option3,
        option4:this.state.option4,
        answer:this.state.answer,
        solution:this.state.solution,

         
        };
  
      const result = await postData("questions/addnewrecord", body);
     
      if(result) {
        this.setState({msg:'Record Submited....'});   
        this.handleChangeExamCat(this.state.eventExam);  
        this.setState({question:'',option1:'',option2:'',option3:'',option4:'',answer:'',solution:''});
      } else {
        this.setState({msg:'Fail to Add Question Pls Check all Entries Carefully...'});     
        alert('Server Error\nTry Again');
      }
    } catch (e) {
      console.log("SubCategory:", e);
    }}
  };
  


  render() {
    const { classes } = this.props;
    return (
      <div className="container">
        
        
        <React.Fragment>
       
        
        <main className={classes.layout}>
          <Paper className={classes.paper}>
         
            <React.Fragment>

              {this.showComponents1(classes)}
            
            </React.Fragment>
            </Paper>
            </main>
            </React.Fragment>
       
       
       <React.Fragment>
         
       <main className={classes.layout}>
          <Paper className={classes.paper}>
         
            <React.Fragment>
               <br></br>
              {this.showComponents2(classes)}
            
            </React.Fragment>
            </Paper>
            </main>
            </React.Fragment>
       
         
          





          
            <React.Fragment>
            <main className={classes.layout}>
          <Paper className={classes.paper}>
          
      
        <Typography variant="h5" component="h4">
          {this.state.msg}
        </Typography>
        
        </Paper>
            </main>
            </React.Fragment>
      </div>
    );
  }
}

AddNewQuestions.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image', 'video'],
    ['clean'],
    ['code-block']
  ]
};

AddNewQuestions.formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'image',
  'video',
  'code-block'
];
AddNewQuestions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddNewQuestions);
