 
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import React, { Component } from 'react';

class ListItems  extends Component
{
  componentWillMount(){
    console.log('props',this.props)
  }
  handleClick=(value)=>{
   this.props.showOptions(value) 

  }
mainListItems =()=> (
  <div>
    <ListItem button onClick={()=>this.handleClick('AccountDetails')} >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary=" Account Details" />
    </ListItem>
    <ListItem button  onClick={()=>this.handleClick('MyProfile')} >
      <ListItemIcon>
        <ShoppingCartIcon />
      </ListItemIcon>
      <ListItemText primary="My Profile"  />
    </ListItem>
    <ListItem button onClick={()=>this.handleClick('Change')}  >
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Change Password" />
    </ListItem>
    <ListItem button onClick={()=>this.handleClick('ChangeAddress')}  >
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Change Address" />
    </ListItem>
    <ListItem button onClick={()=>this.handleClick('Logout')}  >
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
   
  </div>
)
render() {
   
  return (
    <div>
      {this.mainListItems()}
    </div>
  )}
}
export default ListItems;