/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import MaterialTable from 'material-table';
import Button from '@material-ui/core/Button';
import { getData,BaseURL} from '../../../FetchService';

export default function ListTopic(props) {
	const [ getColumn ] = React.useState({
		columns: [
			{ title: 'Course', field: 'coursename' },
			{ title: 'Chapter', field: 'chaptername' },
			{ title: 'Topic', field: 'topicname' },
			{ title: 'Video Name', field: 'videoname' },
			{ title: 'Video Type', field: 'videotype' },
			{ title: 'Video Duration', field: 'videoduration' },
			{ title: 'Topic Description', field: 'topicdescription' },
			{
				title: 'Video Image',
				field: 'posterimage',
				render: (rowData) => (
					<div>
						<img src={`${BaseURL}/images/${rowData.posterimage}`} height={50} width={50} />
					</div>
				)
			}
		]
	});

	const [ state, setState ] = React.useState({ data: [] });

	const fetchAll = async () => {
		let list = await getData('topicdetails/fetchdata');
		setState({ data: list });
	};

	useEffect(function() {
		fetchAll();
	}, []);

	return (
		<MaterialTable
			title="Course list"
			columns={getColumn.columns}
			data={state.data}
			actions={[
				{
					icon: 'save',
					tooltip: 'Save User',
					onClick: (event, rowData) => {
						props.history.push({ pathname: '/TopicUpdate', state: { id: rowData.topicid } });
					}
				}
			]}
			components={{
				Action: (props) => (
					<Button
						onClick={(event) => props.action.onClick(event, props.data)}
						color="primary"
						variant="contained"
						style={{ textTransform: 'none' }}
						size="small"
					>
						Change
					</Button>
				)
			}}
		/>
	);
}
