/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { postData } from '../../../FetchService';

const useStyles = makeStyles((theme) => ({
	paper: { padding: '30px', marginTop: '10px' },
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	dense: {
		marginTop: theme.spacing(2)
	},
	button: {
		margin: theme.spacing(1)
	},
	input: {
		display: 'none'
	},
	rightIcon: {
		marginLeft: theme.spacing(1)
	},
	bigAvatar: {
		margin: 10,
		width: 60,
		height: 60
	}
}));
export default function ChapterUpdate(props) {
	const classes = useStyles();

	const [ getCourseId, setCourseId ] = React.useState('');
	const [ getChapterId, setChapterId ] = React.useState('');

	const [ CourseName, setCourseName ] = React.useState('');
	const [ getChapter, setChapter ] = React.useState('');

	const [ message, setMessage ] = React.useState('');

	useEffect(() => {
		fillValues();
	}, []);

	const fillValues = async () => {
		try {
			var body = {
				chapterId: props.location.state.id
			};
			console.log('body data', body);

			const result = await postData('chapterdetails/fetchdataById', body);
			console.log('result', result[0]);

			setCourseId(result[0].courseid);
			setChapterId(result[0].chapterid);

			setCourseName(result[0].coursename);
			setChapter(result[0].chaptername);
		} catch (error) {}
	};

	const handleEdit = async () => {
		var body = {
			chapterId: getChapterId,
			courseId: getCourseId,
			chapterName: getChapter
		};
		let result = await postData('chapterdetails/updateChapter', body);
		console.log('chapter result', result);

		if (result.result) {
			setMessage('Record Updated...');
			setTimeout(function() {
				clearValues();
				props.history.replace({ pathname: '/ListChapter' });
			}, 3000);
		} else {
			setMessage('Fail to Update Record...');
		}
	};

	const handleDelete = async () => {
		var body = {
			chapterId: getChapterId
		};
		let result = await postData('chapterdetails/deleteChapter', body);
		console.log('delete result', result);

		if (result) {
			setMessage('Record Deleted...');
			setTimeout(function() {
				clearValues();
				props.history.replace({ pathname: '/ListChapter' });
			}, 3000);
		} else {
			setMessage('Fail to Delete Record...');
		}
	};
	const clearValues = () => {
		setCourseName('');
		setCourseId('');
		setCourseId('');
		setChapter('');
		setMessage('');
	};
	return (
		<Container maxWidth="xs">
			<Paper className={classes.paper}>
				<Typography>Chapter Delete/Update</Typography>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							id="outlined-dense"
							label="Course Name"
							className={clsx(classes.textField, classes.dense)}
							margin="dense"
							value={CourseName}
							InputProps={{
								readOnly: true
							}}
							variant="outlined"
							onChange={(event) => setCourseName(event.target.value)}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="outlined-dense"
							label="Chapter Name"
							className={clsx(classes.textField, classes.dense)}
							margin="dense"
							value={getChapter}
							variant="outlined"
							onChange={(event) => setChapter(event.target.value)}
							fullWidth
						/>
					</Grid>

					<Grid item xs={6}>
						<Button onClick={handleEdit} variant="contained" color="primary" className={classes.button}>
							Edit
						</Button>
					</Grid>
					<Grid item xs={6}>
						<Button onClick={handleDelete} variant="contained" color="primary" className={classes.button}>
							Delete
						</Button>
					</Grid>
				</Grid>
			</Paper>
			<Typography>{message}</Typography>
		</Container>
	);
}
