import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import NoSsr from '@material-ui/core/NoSsr';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { BrowserRouter as Router, HashRouter, Route, Redirect } from 'react-router-dom';

import Course from './CourseUpload';
import DisplayAll from './ListCourse';
import EditDelete from './UpdateCourse';

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	);
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
};

function LinkTab(props) {
	return <Tab component="a" onClick={(event) => event.preventDefault()} {...props} />;
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	}
});

class HomeMainStyle extends React.Component {
	state = {
		value: 0
	};

	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		const { classes } = this.props;
		const { value } = this.state;

		return (
			<HashRouter>
				<NoSsr>
					<div className={classes.root}>
						<AppBar position="static">
							<Tabs fullWidth variant="fullWidth"
          aria-label="nav tabs example" value={value} onChange={this.handleChange}>
								<LinkTab label="Add Course" href="page1" />
								<LinkTab label="Display Course" href="page2" />
								{/* <LinkTab label="Edit Course" href="page3" /> */}
							</Tabs>
						</AppBar>
						{value === 0 && (
							<TabContainer>
								<Redirect to="/AddRecordCourse" />
							</TabContainer>
						)}
						{value === 1 && (
							<TabContainer>
								<Redirect to="/DisplayAllCourse" />
							</TabContainer>
						)}
						{value === 2 && <TabContainer />}

						<Route path="/AddRecordCourse" component={Course} />
						<Route path="/DisplayAllCourse" component={DisplayAll} history={this.props.history} />
						<Route path="/EditDeleteCourse" component={EditDelete} history={this.props.history} />
					</div>
				</NoSsr>
			</HashRouter>
		);
	}
}

HomeMainStyle.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HomeMainStyle);
