import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import UserProfileEdit from '../userinterface/UserProfileEdit'

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MainListItems from './ListItems';
import UserPasswordForm from './UserPasswordForm';
import ChangeAddressForm from './ChangeAddressForm';
import UserMainPage from './UserMainPage'
import ShowMyAccount from './ShowMyAccount';
/* import AddNewPackage from '../amc_packages/AddNewPackage'
import AddNewOptions from '../Options/AddNewOptions'
import AddOptionsInPackage from '../packagesoptions/AddOptionsInPackage'
import Workers from '../worker/Workers'
import DisplayAllPackages from '../amc_packages/DisplayAllPackages' */

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  avatar: {
    margin: 10,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class UserDashBoard extends React.Component {
  state = {
    open: true,
    viewforuser:'',
    data:[]
  };


  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  

  showOptions=(value)=>{
  if(value=='MyProfile')
  {this.setState({viewforuser:<UserProfileEdit/>})}
  else   if(value=='AccountDetails')
  {  
    this.setState({viewforuser:<ShowMyAccount  history={this.props.history}/>})}
  else if(value=='Change'){
    this.setState({viewforuser:<UserPasswordForm/>})
  }
else if(value=='ChangeAddress'){
  this.setState({viewforuser:<ChangeAddressForm/>})
}

  else   if(value=='Logout')
  { localStorage.removeItem('user')
    //this.props.history.replace({pathname:'/UserMainPage'})
    this.props.handleClosem('Login Student')
}

  }
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
       
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
        
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={this.handleDrawerClose}>
              
            </IconButton>
          </div>
          <Divider />
          <List><MainListItems showOptions={this.showOptions} history={this.props.history}/></List>
          <Divider />
          
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
           
         <div className={classes.tableContainer}>
            
         {this.state.viewforuser}

          </div>
        </main>
      </div>
    );
  }
}

UserDashBoard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserDashBoard);
