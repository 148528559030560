import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
   
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
   
});
class PersonalDetails extends React.Component {
    state={
    first_name:'',
    last_name:'',
    
    email:'',
    mobile:'',
   
    }
    
    
    componentWillMount(){
           /* this.user = JSON.parse(localStorage.getItem("admin"));
            this.props.states.first_name= this.user.first_name
            this.props.states.last_name= this.user.last_name
            this.props.states.email= this.user.email
            this.props.states.mobile= this.user.mobile
            this.setState(this.props.states)*/

           // this.user = JSON.parse(localStorage.getItem("admin"));
            
            this.setState(this.props.states)
    }
    render(){
      const { classes } = this.props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Personal Details
      </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="first_name"
            name="first_name"
            value={this.state.first_name}
            label="First name"
            fullWidth
            autoComplete="first_name"
            onChange={e=>(this.props.states.first_name=e.target.value, this.setState({first_name:e.target.value}))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="last_name"
            name="last_name"
            value={this.state.last_name}
            label="Last name"
            fullWidth
            autoComplete="last_name"
            onChange={e=>(this.props.states.last_name=e.target.value,this.setState({last_name:e.target.value}))}
          />
        </Grid>
        </Grid>
         <Grid item xs={12} >
          <TextField
            required
            id="email"
            name="email"
            value={this.state.email}
            label="Email Address"
            fullWidth
            autoComplete="email"
            onChange={e=>{this.props.states.email=e.target.value;this.setState({email:e.target.value})}}
          />
        </Grid>
        <Grid item xs={12} >
          <TextField id="mobile" type='number' name="mobile" value={this.state.mobile} label="Mobile Number" onChange={e=>{this.props.states.mobile=e.target.value.toString();this.setState({mobile:e.target.value})}} fullWidth />
        </Grid>
        
      
    </React.Fragment>
  )};
}
PersonalDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PersonalDetails);
 