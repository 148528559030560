import React from "react";
import { render } from "react-dom";
import _ from "lodash";
// import { makeData } from "./Utils";

// Import React Table
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link'
import ReactTable from "react-table";
import "react-table/react-table.css";
import { postData,getData } from '../FetchService'
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import CSV from '../management/CSV'
import ReactToPrint from 'react-to-print';
// import { CSVLink } from "react-csv";

const useStyles = theme => ({
   paper:{padding:'30px',marginTop:'10px'},
  button: {
    margin: 0,
  },
  input: {
    display: 'none',
  },
});


class Table extends React.Component {

  constructor() {
    super();
    this.state = {
      data: [],
      pages: null,
      loading: true,
      list:[],
      columns:[],
      csvheader:[]

    };
  }

  async componentDidUpdate(prevProps,prevState) {
    if(this.props!=prevProps){
    const body={'setorg':this.props.itempurchased,
      'paperstatus':this.props.paperstatus
  };
   const list = await postData(`showuser/showuserresult`,body);
   this.setState({list})
   if(this.state.list==''){
   // alert("if")
  }
  else{
    // alert("else")
    this.setState({columns:Object.keys(this.state.list[0]).map((key, id)=>{
      return {
         Header: key,
         accessor: key
       }
     })})
  }

    if(this.state.list==''){
    // alert("if")
   }
   else{
     // alert("else")
     this.setState({csvheader:Object.keys(this.state.list[0]).map((k, id)=>{
       return {
          label: k,
          key: k
        }
      })})
   }


    console.log(`Data ${this.state.list}`)
 }

  }

  async componentDidMount() {

       const list = await getData(`showuser/fetchcompletetable`);
      console.log(`Data ${list}`)
        this.setState({ list });
        this.setState({columns:Object.keys(this.state.list[0]).map((key, id)=>{
          return {
             Header: key,
             accessor: key
           }
         })})

         this.setState({csvheader:Object.keys(this.state.list[0]).map((k, id)=>{
           return {
              label: k,
              key: k
            }
          })})
      console.log(`Data ${this.state.list}`)

      }


  render() {
    // const { data, pages, loading } = this.state;
     const classes = useStyles();
     // const columns = Object.keys(this.state.list[0]).map((key, id)=>{
     //   return {
     //      Header: key,
     //      accessor: key
     //    }
     //  })


    // const columns=[
    //         {
    //           Header: "",
    //           accessor: "srno",
    //           style:{
    //             textAlign: 'center'
    //           },
    //           maxWidth: 50,
    //           filterable: false,
    //         },
    //         {
    //           Header: "First Name",
    //           accessor: "first",
    //           style:{
    //             textAlign: 'center'
    //           },
    //           width: 100,
    //           maxWidth: 100,
    //           minWidth:100
    //         },
    //         {
    //           Header: "Last Name",
    //           accessor: "last",
    //           style:{
    //             textAlign: 'center'
    //           },
    //           width: 100,
    //           maxWidth: 100,
    //           minWidth:100
    //         },
    //         {
    //           Header: "Mobile No.",
    //           accessor: "mobile",
    //           style:{
    //             textAlign: 'center'
    //           },
    //           width: 155,
    //           maxWidth: 155,
    //           minWidth:155
    //         },
    //         {
    //           Header: "userid",
    //           accessor: "userid",
    //           style:{
    //             textAlign: 'center'
    //           },
    //           width: 205,
    //           maxWidth: 205,
    //           minWidth:205
    //         },
    //         {
    //           Header: "category",
    //           accessor: "category",
    //           width: 110,
    //           maxWidth: 110,
    //           minWidth:110
    //         },
    //         {
    //           Header: "totalquestion",
    //           accessor: "totalquestion",
    //           style:{
    //             textAlign: 'center'
    //           },
    //           width: 60,
    //           maxWidth: 60,
    //           minWidth:60
    //         },
    //         {
    //           Header: "countsubmit",
    //           accessor: "countsubmit",
    //           style:{
    //             textAlign: 'center'
    //           },
    //           width: 50,
    //           maxWidth:50,
    //           minWidth:50
    //         },
    //         {
    //           Header: "itempurchased",
    //           accessor: "itempurchased",
    //           style:{
    //             textAlign: 'center'
    //           },
    //           width: 120,
    //           maxWidth: 120,
    //           minWidth: 120
    //         },
    //         {
    //           Header: "correctanswer",
    //           accessor: "correctanswer",
    //           Cell: props =>{ return(<span>
    //             {props.value}
    //             </span>
    //             )},
    //           style:{
    //             textAlign: 'center'
    //           },
    //           width: 95,
    //           maxWidth: 95,
    //           minWidth:95
    //         }
    //       ]
    return (
      <div>
      <CSV data={this.state.list} headers={this.state.csvheader} file={this.props.itempurchased}/>
        <ReactTable
          columns= {this.state.columns}
          data={this.state.list}
          filterable
          defaultPageSize={10}
          noDataText={"Please Wait..."}
      >
        </ReactTable>
        <br />
      </div>
    );
  }
}

class TableExample extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <a href="#">Print this out!</a>}
          content={() => this.componentRef}
        />
        <Table ref={el => (this.componentRef = el)} />
      </div>
    );
  }
}

export default Table;
