import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import ReactDOM from 'react-dom';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';

const styles = theme => ({
  root: {
    display: 'flex',

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 0,
  },
  dd:{
  minWidth: 100,
  marginLeft:'50px'
  },
  formControl: {
    margin: theme.spacing.unit,
    marginRight:'200px',
   },
   formControlChk: {
    margin: theme.spacing.unit,
  },
   group: {
    margin: `0px`,
    },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  papers: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop:'50px'
  },
});


class PostInternship extends React.Component {
  state = {

    primaryprofile:'Business Development(Sales)',
    typeinternship:'',
    internlocation:'',
    isparttime:'',
    noofopenings:'',
    startdate:'',
    internshipduration:'',
    options:'',
    stipendperks:'',
    currency:'',
    salary:'',
    duration:'',
    perks:[],
    preplacement:'',
    skill:'',
    question:'',
    labelWidth:0,
    roles:'',
    dw:false,
    cer:false,
    fw:false,
    lor:false,
    idc:false,
    fsb:false,
    applyby:''
  };
  componentWillMount()
  {
    this.setState(this.props.states)

  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }
  arrayRemove(arr, value) {

    return arr.filter(function(ele){
        return ele != value;
    });

 }

  handleChange=async(event)=>{
    if(event.target.value=='5 Days in a Week')
      {this.setState({dw:event.target.checked})
       {this.props.states.dw=event.target.checked}
      }
    else if(event.target.value=='Certificate')
      {this.setState({cer:event.target.checked})
      this.props.states.cer=event.target.checked
      }

      else if(event.target.value=='Flexi Work Hours')
      {this.setState({fw:event.target.checked})
      this.props.states.fw=event.target.checked
       }
      else if(event.target.value=='Letter of Recommendation')
      {this.setState({lor:event.target.checked})
      this.props.states.lor=event.target.checked
    }
      else if(event.target.value=='Informal Dress Code')
      {this.setState({idc:event.target.checked})
      this.props.states.idc=event.target.checked
    }
      else if(event.target.value=='Free Snacks & Beverages')
      {this.setState({fsb:event.target.checked})
      this.props.states.fsb=event.target.checked
    }





    if(event.target.checked)
    {
     await this.state.perks.push(event.target.value)}
     else{
     let x=this.arrayRemove(this.state.perks,event.target.value)
     await this.setState({perks:x})
    }

    this.props.states.perks=this.state.perks

    console.log(this.state.perks)

  }

  render(){
    const { classes } = this.props;
return (
  <React.Fragment>
    <Typography variant="h6" gutterBottom>
     Post Internship
    </Typography>
    <Grid container spacing={24}>

      <Grid item  sm={12}>
      <b>Select Primary Profile:</b>
      <div className={classes.root}>

        <FormControl component="fieldset" className={classes.formControl} rows>

          <RadioGroup
            aria-label="Primary Profile"
            name="primaryprofile"
            className={classes.group}
            value={this.state.primaryprofile}
            onChange={event=>{this.props.states.primaryprofile=event.target.value;this.setState({primaryprofile:event.target.value})}}
            rows
            >
             <FormControlLabel value="Business Development(Sales)" control={<Radio />} label="Business Development(Sales)" />
            <FormControlLabel value="Graphics Design" control={<Radio />} label="Graphics Design" />
            <FormControlLabel value="Digital Marketing" control={<Radio />} label="Digital Marketing" />
              <FormControlLabel value="Instructor" control={<Radio />} label="Instructor" />
              <FormControlLabel value="Lab Assistance" control={<Radio />} label="Lab Assistance" />
              <FormControlLabel value="Operation" control={<Radio />} label="Operation" />
                <FormControlLabel value="Content Writer" control={<Radio />} label="Content Writer" />
                <FormControlLabel value="Web Development" control={<Radio />} label="Web Development" />
                <FormControlLabel value="Mobile App Development" control={<Radio />} label="Mobile App Development" />
                  <FormControlLabel value="Marketing" control={<Radio />} label="Marketing" />
                     <FormControlLabel value="Human Resource [HR]" control={<Radio />} label="Human Resource [HR]" />
                     <FormControlLabel value="other" control={<Radio />} label="Other" />

          </RadioGroup>
        </FormControl>
              </div>




      </Grid>




      </Grid>
       <Grid   sm={12} >
       <b>Select type of internship:</b>
       <div className={classes.root}>

       <FormControl component="fieldset" className={classes.formControl}>

         <RadioGroup
            aria-label="Select type of internship"
            name="typeinternship"
           className={classes.group}
           value={this.state.typeinternship}
           onChange={event=>{this.props.states.typeinternship=event.target.value;this.setState({typeinternship:event.target.value})}}
         >
         <FormControlLabel value="Regular" control={<Radio />} label="Regular" />

         </RadioGroup>


       </FormControl>


       <FormControl component="fieldset" className={classes.formControl}>

         <RadioGroup
            aria-label="Select type of internship"
            name="typeinternship"
           className={classes.group}
           value={this.state.typeinternship}
           onChange={event=>{this.props.states.typeinternship=event.target.value;this.setState({typeinternship:event.target.value})}}
         >
         <FormControlLabel value="Work From Home" control={<Radio />} label="Work From Home" />



         </RadioGroup>


       </FormControl>
       </div>
      </Grid>
      <Grid item xs={12} >
        <TextField id="internlocation"  name="internlocation" value={this.state.internlocation} label="Intern Location(s)" required onChange={e=>{this.props.states.internlocation=e.target.value.toString();this.setState({internlocation:e.target.value})}} fullWidth />
      </Grid>


      <Grid   sm={12} >
      <b>Is Parttime Allowed?</b>
       <div className={classes.root}>

       <FormControl component="fieldset" className={classes.formControl}>

         <RadioGroup
            aria-label="Select type of internship"
            name="isparttime"
           className={classes.group}
           value={this.state.isparttime}
           onChange={event=>{this.props.states.isparttime=event.target.value;this.setState({isparttime:event.target.value})}}
         >
         <FormControlLabel value="Yes" control={<Radio />} label="Yes" />



         </RadioGroup>


       </FormControl>

       <FormControl component="fieldset" className={classes.formControl}>

         <RadioGroup
            aria-label="Select type of internship"
            name="isparttime"
           className={classes.group}
           value={this.state.isparttime}
           onChange={event=>{this.props.states.isparttime=event.target.value;this.setState({isparttime:event.target.value})}}
         >
         <FormControlLabel value="No" control={<Radio />} label="No" />



         </RadioGroup>


       </FormControl>
       </div>
      </Grid>
      <Grid item xs={12} >
        <TextField id="noofopenings"  name="noofopenings" value={this.state.noofopenings} label="No of Openings:" required onChange={e=>{this.props.states.noofopenings=e.target.value.toString();this.setState({noofopenings:e.target.value})}} fullWidth />
      </Grid>
      <Grid   sm={12} >
     <b>Internship Start Date:</b>
       <div className={classes.root}>

       <FormControl component="fieldset" className={classes.formControl}>

         <RadioGroup
            aria-label="Start Date"
            name="startdate"
           className={classes.group}
           value={this.state.startdate}
           onChange={event=>{this.props.states.startdate=event.target.value;this.setState({startdate:event.target.value})}}
         >
         <FormControlLabel value="Immediately" control={<Radio />} label="Immediately[After Next 30 Days]" />



         </RadioGroup>


       </FormControl>

       <FormControl component="fieldset" className={classes.formControl}>

         <RadioGroup
            aria-label="Start Date"
            name="startdate"
           className={classes.group}
           value={this.state.startdate}
           onChange={event=>{this.props.states.startdate=event.target.value;this.setState({startdate:event.target.value})}}
         >
         <FormControlLabel value="Later" control={<Radio />} label="Later" />



         </RadioGroup>


       </FormControl>
       </div>
      </Grid>
      <Grid item xs={24} >
        <TextField id="internshipduration"  style = {{width:400}}  name="internshipduration" value={this.state.internshipduration} label="Internship Duration:" required onChange={e=>{this.props.states.internshipduration=e.target.value.toString();this.setState({internshipduration:e.target.value})}}  />

        <FormControl variant="outlined" className={classes.dd}>
          <InputLabel
            ref={ref => {
              this.InputLabelRef = ref;
            }}
            htmlFor="outlined-options"
          >
           <b>Months</b>
          </InputLabel>
          <Select
            value={this.state.options}
            onChange={event=>{this.props.states.options=event.target.value;this.setState({options:event.target.value})}}
            input={
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="options"
                id="outlined-age-options"
              />
            }
          >

            <MenuItem value={'Months'}>Months</MenuItem>
            <MenuItem value={'Days'}>Days</MenuItem>
            <MenuItem value={'Year'}>Years</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={24} >
      <Paper className={classes.papers} elevation={1}>
      <Typography variant="h6" gutterBottom>
     <center>Intern's Responsibilities</center>
    </Typography>
         <b>Write the detailed role and responsibilities of Intern:</b>

         <TextField
            value={this.state.roles}
            onChange={event=>{this.props.states.roles=event.target.value;this.setState({roles:event.target.value})}}
           id="outlined-multiline-static"
           label=""
           multiline
           rows="3"
           placeholder='Intern Day By Day Responsibilities'
           className={classes.textField}
           margin="normal"
           variant="outlined"
           fullWidth
        />
      </Paper>
      </Grid>
      <Grid item xs={24} >
      <Paper className={classes.papers} elevation={1}>
      <Typography variant="h6" gutterBottom>
     <center>Stipend & Perks</center>
    </Typography>
     <b>Stipend*:</b>
    <FormControl component="fieldset"  className={classes.formControl}>

         <RadioGroup
           aria-label="Primary Profile"
           name="primaryprofile"

           value={this.state.stipendperks}
           onChange={event=>{this.props.states.stipendperks=event.target.value;this.setState({stipendperks:event.target.value})}}
           row
         >
          <FormControlLabel value="Fixed" control={<Radio />} label="Fixed" />
           <FormControlLabel value="Negotiable" control={<Radio />} label="Negotiable" />
           <FormControlLabel value="Performance Based" control={<Radio />} label="Performance Based" />
           <FormControlLabel value="Unpaid" control={<Radio />} label="Unpaid" />


         </RadioGroup>

       </FormControl>
       <FormControl variant="outlined" >
          <InputLabel
            ref={ref => {
              this.InputLabelRef = ref;
            }}
            htmlFor="outlined-currency"
          >
           Rs
          </InputLabel>
          <Select
            value={this.state.currency}
            onChange={event=>{this.props.states.currency=event.target.value;this.setState({currency:event.target.value})}}
            input={
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="currency"
                id="outlined-age-options"
              />
            }
          >

            <MenuItem value={'Rs'}>Rs</MenuItem>
            <MenuItem value={'$'}>$</MenuItem>

          </Select>
        </FormControl>
        <TextField
          id="outlined-dense"
          label="Salary"
          className={classNames(classes.textField, classes.dense)}
          value={this.state.salary}
          variant="outlined"
          placeholder='Ex 5000/-'
          onChange={event=>{this.props.states.salary=event.target.value;this.setState({salary:event.target.value})}}
        />
<FormControl variant="outlined" className={classes.dd} >
          <InputLabel
            ref={ref => {
              this.InputLabelRef = ref;
            }}
            htmlFor="outlined-duration"
          >
           Duration
          </InputLabel>
          <Select

            value={this.state.duration}
            onChange={event=>{this.props.states.duration=event.target.value;this.setState({duration:event.target.value})}}
            input={
              <OutlinedInput
                labelWidth={this.state.labelWidth}
                name="duration"
                id="outlined-age-options"
              />
            }
          >

            <MenuItem value={'Month'}>Month</MenuItem>
            <MenuItem value={'Week'}>Week</MenuItem>
            <MenuItem value={'Day'}>Day</MenuItem>


          </Select>
        </FormControl><br></br>


      <div className={classes.root}>

      <FormControl component="fieldset" className={classes.formControlChk}>
          <FormLabel><b>Perks</b></FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox onChange={this.handleChange} checked={this.state.dw}  value="5 Days in a Week" />
              }
              label="5 Days in a Week"
            />
            <FormControlLabel
              control={
                <Checkbox  onChange={this.handleChange} checked={this.state.cer}  value="Certificate" />
              }
              label="Certificate"
            />
            <FormControlLabel
              control={
                <Checkbox
                checked={this.state.fw}
                  onChange={this.handleChange}
                  value="Flexi Work Hours"
                />
              }
              label="Flexi Work Hours"
            />
          </FormGroup>

        </FormControl>
        <FormControl component="fieldset" className={classes.formControlChk}>
        <FormLabel><b>&nbsp;</b></FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox onChange={this.handleChange} checked={this.state.lor}  value="Letter of Recommendation" />
              }
              label="Letter of Recommendation"
            />
            <FormControlLabel
              control={
                <Checkbox  onChange={this.handleChange} checked={this.state.idc}  value="Informal Dress Code" />
              }
              label="Informal Dress Code"
            />
            <FormControlLabel
              control={
                <Checkbox
                checked={this.state.fsb}
                  onChange={this.handleChange}
                  value="Free Snacks & Beverages"
                />
              }
              label="Free Snacks & Beverages"
            />
          </FormGroup>

        </FormControl>





        </div>
        <b>Does this internship come up with pre-placement offer(PPO)?</b>
       <div className={classes.root}>

       <FormControl component="fieldset" className={classes.formControl}>

         <RadioGroup
            aria-label="pre-placement"
            name="preplacement"
           className={classes.group}
           value={this.state.preplacement}
           onChange={event=>{this.props.states.preplacement=event.target.value;this.setState({preplacement:event.target.value})}}
         >
         <FormControlLabel value="Yes" control={<Radio />} label="Yes" />



         </RadioGroup>


       </FormControl>

       <FormControl component="fieldset" className={classes.formControl}>

         <RadioGroup
           aria-label="pre-placement"
           name="preplacement"
           className={classes.group}
           value={this.state.preplacement}
           onChange={event=>{this.props.states.preplacement=event.target.value;this.setState({preplacement:event.target.value})}}
         >
         <FormControlLabel value="No" control={<Radio />} label="No" />



         </RadioGroup>
         </FormControl>
         </div>
      </Paper>
      </Grid>

      <Grid item xs={24} >
      <Paper className={classes.papers} elevation={1}>
      <Typography variant="h6" gutterBottom>
     <center>Who Can Apply</center>
    </Typography>
        <b> Skill(s) that you are looking for:</b><br></br>
        Type one or more skills(Recommended)

         <TextField
            value={this.state.skill}
            onChange={event=>{this.props.states.skill=event.target.value;this.setState({skill:event.target.value})}}
           id="outlined-multiline-static"
           label=""
           multiline
           rows="3"
           placeholder='Ex PHP,Java,C,C++,English Proficiency etc..'
           className={classes.textField}
           margin="normal"
           variant="outlined"
           fullWidth
        /><br></br>
        <b>Any Questions For Intern:</b><br></br>


         <TextField
            value={this.state.question}
            onChange={event=>{this.props.states.question=event.target.value;this.setState({question:event.target.value})}}
           id="outlined-multiline-static"
           label=""
           multiline
           rows="3"
           placeholder='Ex: Why you should hire for an intern? etc.'
           className={classes.textField}
           margin="normal"
           variant="outlined"
           fullWidth
        />
        <br></br>
        <b>Candidate Apply Before:</b><br></br>
         <TextField
        id="date"
        variant="outlined"
        type="date"
        defaultValue={this.state.applyby}

        onChange={event=>{this.props.states.applyby=event.target.value;this.setState({applyby:event.target.value})}}
        InputLabelProps={{
          shrink: true,
        }}
      />
      </Paper>
      </Grid>
  </React.Fragment>

)};
}

PostInternship.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PostInternship);
