import { CSVLink } from "react-csv";
import React from 'react'
import Button from '@material-ui/core/Button'

export default class CSV extends React.Component{
  // constructor() {
  //   super();
  //   this.state = {
  //     data: [],
  //
  //   };
  // }
  //
  // componentDidMount() {
  //       this.setState({ data:this.props.data });
  //
  //     console.log(`Data ${this.state.data}`)
  //
  //     }
  //
  // data = [
  //   { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
  //   { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
  //   { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
  // ];

render(){
  const headers = [
    { label: "Srno", key: "srno"},
    { label: "First Name", key: "first" },
    { label: "Last Name", key: "last" },
    { label: "Mobile", key: "mobile" },
    { label: "Userid", key: "userid"}
  ];
  return(
<CSVLink data={this.props.data} filename={this.props.file+"-"+new Date()+".csv"} headers={this.props.headers}>
  <Button color='primary'>Download as CSV/XLSX</Button>
</CSVLink>)
}
}
