import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import deepOrange from '@material-ui/core/colors/deepOrange'
import { postData, BaseURL } from "../FetchService"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

import renderHTML from 'react-render-html'
import DisplayPaper from './DisplayPaper';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  orangeAvatar: {
    margin: 1,
    color: '#fff',
    backgroundColor: deepOrange[500],
    width: 80,
    height: 80,
    marginLeft:80,
  },
});
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class TestPaper extends React.Component {
    i=0

    state={
      list:[],
       value: 0,
       body:[],
       time:this.props.body.duration,
       watchicon:'watch.gif',
       saveicon:'Save.gif',
       openr:false,
       openc:false,
       openfinal:false,



    }

   mytimer=''

   timerFunction=async()=>
  {


  let k=this.state.time;
    k=k-1
    if(k==0)
    { clearInterval(this.mytimer)
      this.setState({saveicon:'Save.png'})
    this.setState({watchicon:'watch.jpg'})
    let body={transactionid:this.props.body.transactionid,status:this.props.body.paperstatus}
    const result = await postData("purchase/updatepurchasestatus", body);
    localStorage.removeItem('timer')
  }
  else
  { localStorage.setItem('timer',k)}
    if(k>0){
      // alert(`TIME2 ${k}`)
      console.log("TIME2",k)
    this.setState({time:k})


  }
  else{
    this.setState({time:k})
    this.handleClickOpenR()
  }

   }

    async componentDidMount() {
    console.log('boooooooooody:'+this.props.body.setno)
     const body={
            cid:this.props.body.setno,
          oid:this.props.body.organizationid}

        const list = await postData(`questions/fetchexamcategoryforuser`,body);
        console.log(`Data ${list}`)
        this.setState({ list });
        this.handleClick= this.handleClick.bind(this)
        this.handleClickSubmit= this.handleClickSubmit.bind(this)
        this.handleChange= this.handleChange.bind(this)


       if(localStorage.getItem('timer'))
       {let k=parseInt(localStorage.getItem('timer'))
      console.log("TIME",k)
       this.setState({time:k})
     }
     else{
      console.log("TIME1",this.state.time) 
      localStorage.setItem('timer',this.state.time)

    }

     this.mytimer=setInterval(this.timerFunction,60000)

    //  this.handleClick(body,index)

    let body1={setno:this.props.body.setno,
      oid:this.props.body.organizationid,
       ecid:this.state.list[0].examcategoryid}

       this.handleClick(body1,0)

    }

   ListTab() {



      return this.state.list.map((item, index) => {
          let urlicon=`${BaseURL}/images/${item.examicon}`
         let body={setno:this.props.body.setno,
            oid:this.props.body.organizationid,
             ecid:item.examcategoryid}

  return (


  <Tab  onClick={() => this.handleClick(body,index)}  label={item.examcategory} icon={<img src={urlicon} style={{ width: 20, height: 20 }}/>}/>

      );



    })

    }

    handleClickSubmit = () => {
    // alert('xxxx'+this.props.body.transactionid)
    if(this.state.time==0)
    {
     this.handleClickOpenR();

    }
    else{
    this.setState({ openc: true });
    }
    }

    handleClick =async (body,value) => {
     if(this.state.time==0)
     {
      
      this.handleClickOpenR();

     }
     else
     {

     this.setState({value});//move blue line to next tab
     await this.setState({body:body})
     this.props.click(0,'Tab')
   //await  this.setState({view:<DisplayPaper body={this.state.body} />})
    }
    };



  handleChange = (event, value) => {
    console.log(event.currentTarget.value)
    alert(event)
    this.setState({ value });

  };





  handleClickOpenR =  () => {

    this.setState({ openr: true });
  };

  handleCloseR = () => {
    this.setState({ openr: false });
  };
  handleCloseC = () => {
    this.setState({ openc: false });
  };

  handleCloseFinal = () => {
    this.setState({ openfinal: false });
    this.props.footerClick("MAIN")
  };
  handleOk=async()=>{
     try{
       let body={transactionid:this.props.body.transactionid,status:this.props.body.paperstatus}
    const result = await postData("purchase/updatepurchasestatus", body);

     if(result) {
       this.setState({msg:'Record Submited....'});
       localStorage.removeItem('timer')
       this.setState({time:1});
       this.timerFunction()
       this.setState({ openc: false });
       this.setState({ openr: false });
       this.setState({ openfinal: true });

     } else {
       this.setState({msg:'Failllllll'});
       }
   } catch (e) {
     console.log("SubmitFinal:", e);
   }
  }

  alertCaution(){
    const { fullScreen } = this.props;
   return(
    <div>

        <Dialog
          open={this.state.openc}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseC}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Caution???"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
          This Action Will End Your Test Are You Sure To Submit the test?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseC} color="primary">
             Cancel
            </Button>
            <Button onClick={this.handleOk} color="primary">
             Ok
            </Button>

          </DialogActions>
        </Dialog>
      </div>
);


  }


  alertFinal(){
    const { fullScreen } = this.props;
   return(


    <div>

        <Dialog
          open={this.state.openfinal}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseFinal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Alert???"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
           To Check the Score or Review the Test Go to Account Section...
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseFinal} color="primary">
             Close
            </Button>

          </DialogActions>
        </Dialog>
      </div>
);


  }




  alertInfoDialog(){
    const { fullScreen } = this.props;
   return(


    <div>

        <Dialog
          open={this.state.openr}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleOk}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Alert...Alert..???"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
           Sorry Your Test Time is Over Test Automatically Submitted....
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleOk} color="primary">
             Close
            </Button>

          </DialogActions>
        </Dialog>
      </div>
);


  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    let urlwicon=`/images/${this.state.watchicon}`
    let urlsicon=`/images/${this.state.saveicon}`
    let t=`<div><font color='blue'><b>${this.state.time}<br> min left<b></font></div>`
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}

            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
          {this.ListTab({value})}
          <Tab onClick={() => this.handleClickSubmit()}  label='Submit Test' icon={<img src={urlsicon}   style={{ width: 20, height: 20 }}/>}/>
          <Tab   label={renderHTML(t)} icon={<img src={urlwicon} style={{ width: 20, height: 20 }}/>}/>
          </Tabs>


        </AppBar>
        <TabContainer><DisplayPaper body={this.state} ts={this.props.ts}  click={this.props.click}  action={this.props.action}  /></TabContainer>
        {this.alertInfoDialog()}
        {this.alertCaution()}
        {this.alertFinal()}
      </div>
    );
  }
}

TestPaper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TestPaper);
