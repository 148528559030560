/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import clsx from 'clsx';
import { Paper, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import {  postData,getData } from '../../../FetchService';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		width: '80%'
		//marginBottom: '10px'
		//marginLeft: '50px'
	},
	input: {
		display: 'none'
	},
	root: {
		// background: '#dff9fb',
		padding: '20px',
		marginTop: '50px',
		marginBottom: '50px',
		width: '70%'
	},
	title: {
		fontSize: 24,
		textAlign: 'center'
	},
	formLabel: {
		marginTop: '10px'
	},
	button: {
		marginTop: '10px'
	}
}));

export default function CourseDetailsUpdate(props) {
	const classes = useStyles();

	const [ getCourseDetailId, setCourseDetailId ] = useState('');
	const [ getMessage, setMessage ] = useState('');
	const [ getLearn, setLearn ] = useState('');
	const [ getCourse, setCourse ] = useState('');
	const [ getCourseName, setCourseName ] = React.useState('');
	const [ getArticle, setArticle ] = useState('');
	const [ getDescription, setDescription ] = useState('');
	const [ getRequirements, setRequirements ] = useState('');
	const [ getExcersize, setExcersize ] = useState('');
	const [ getCourseList, setCourseList ] = useState([]);

	const handleSubmit = async () => {
		var body = {
			courseId: getCourse,
			article: getArticle,
			excersize: getExcersize,
			whatWillLearn: getLearn,
			description: getDescription,
			requirements: getRequirements
		};
		console.log('body data', body);

		const result = await postData('coursedetails/addCourse', body);

		if (result === true) {
			setMessage('Record Submitted.....');
			setTimeout(function() {
				clearValues();
			}, 3000);
		} else {
			setMessage('Not Submitted..........');
		}
	};
	const fillValues = async () => {
		var body = {
			courseDetailsId: props.location.state.id
		};
		console.log('body data', body);

		const result = await postData('coursedetails/fetchdataById', body);
		console.log('result', result[0]);

		// let requirementdata = ;
		setCourseDetailId(result[0].coursedetailsid);
		setCourse(result[0].coursename);
		// setCourseName(result[0].coursename);
		setArticle(result[0].articles);
		setExcersize(result[0].excersizes);
		setRequirements(result[0].requirements);
		setLearn(result[0].whatwilllearn);
		setDescription(result[0].coursedescription);
		fillCourse();
	};

	useEffect(() => {
		fillValues();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleEdit = async () => {
		let body = {
			courseDetailsId: getCourseDetailId,
			courseId: getCourse,
			article: getArticle,
			excersize: getExcersize,
			whatWillLearn: getLearn,
			description: getDescription,
			requirements: getRequirements
		};

		console.log('body.......', body);

		let result = await postData('coursedetails/updateCourse', body);
		console.log('update result', result);

		if (result) {
			setMessage('Record Updated...');
			setTimeout(function() {
				clearValues();
				props.history.replace({ pathname: '/ListCourseDetails' });
			}, 3000);
		} else {
			setMessage('Fail to Update Record...');
		}
	};

	const handleDelete = async () => {
		let body = {
			courseDetailsId: getCourseDetailId
		};
		let result = await postData('coursedetails/deleteCourse', body);
		console.log('update result', result);

		if (result) {
			setMessage('Record Deleted...');
			setTimeout(function() {
				clearValues();
				props.history.replace({ pathname: '/ListCourseDetails' });
			}, 1500);
		} else {
			setMessage('Fail to Delete Record...');
		}
	};
	const fillCourse = async () => {
		const result = await getData('course/fetchdata');
		setCourseList(result);
	};
	const clearValues = () => {
		setCourseDetailId('');
		setCourse('');
		setArticle('');
		setExcersize('');
		setRequirements('');
		setLearn('');
		setDescription('');
		setMessage('');
	};
	const handleReset = async () => {
		clearValues();
	};
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Paper className={classes.root}>
				<Typography variant="button" display="block" gutterBottom className={classes.title}>
					Course Update
				</Typography>
				<React.Fragment>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Course"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								InputProps={{
									readOnly: true
								}}
								value={getCourse}
								fullWidth
								// onChange={(event) => setArticle(event.target.value)}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Number of Articles"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								value={getArticle}
								fullWidth
								onChange={(event) => setArticle(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Number of Coding Exercises"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								value={getExcersize}
								fullWidth
								onChange={(event) => setExcersize(event.target.value)}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant="button" display="block" gutterBottom>
								What will learn ? (use # before next line)
							</Typography>
							<ReactQuill value={getLearn} height={100} onChange={(value) => setLearn(value)} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="button" display="block" gutterBottom>
								Course Description
							</Typography>
							<ReactQuill
								value={getDescription}
								height={100}
								onChange={(value) => setDescription(value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="button" display="block" gutterBottom>
								Requirements (use # before next line)
							</Typography>
							<ReactQuill
								value={getRequirements}
								height={100}
								onChange={(value) => setRequirements(value)}
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Button onClick={handleEdit} variant="contained" color="primary" className={classes.button}>
								Edit
							</Button>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Button
								onClick={handleDelete}
								variant="contained"
								color="primary"
								className={classes.button}
							>
								Delete
							</Button>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								onClick={() => handleReset()}
							>
								Reset
							</Button>
						</Grid>
					</Grid>
					{getMessage}
				</React.Fragment>
			</Paper>
		</div>
	);
}
