import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { postDataAndImage } from "../FetchService";

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getData ,BaseURL} from "../FetchService"
import { postData } from "../FetchService"
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
   
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  formControl: {
     minWidth: 150,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  iconSmall: {
    fontSize: 20,
  },
  avatar: {
    margin: 5,
  },
  bigAvatar: {
    margin: 5,
    width: 100,
    height: 100,
  },
});

class EditDelete extends Component {
    state = {
        maincategoryid:'',
        subcategoryid:'' ,
        subcategoryname:'',
        description:'',
        icon:'',
        file: "",
        msg: "",
        list:[],
        show:false,
      };

      constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this)
      }

      async componentDidMount() {
let data= JSON.parse(this.props.location.state.result);
     console.log('................');
     console.log(data);
    this.setState({maincategoryid:data.maincategoryid});
    this.setState({subcategoryid:data.subcategoryid});
    this.setState({subcategoryname:data.subcategoryname});
    this.setState({description:data.description});
    this.setState({icon:data.icon});
    
    let url=`${BaseURL}/images/${data.icon}`;
    this.setState({icon:url});
    const list = await getData(`maincategory/fetchallmaincategories`);
    console.log(`Data ${list}`)
    this.setState({ list });
    console.log(`Data ${this.state.list}`);
     }

     fillMainCategory = () => {
        
      return this.state.list.map((item, index) => {
           
        
         
          return (
            <MenuItem value={item.maincategoryid}>{item.maincategory}</MenuItem>  
           );
      })
  }  
  showHide=(classes)=>{
   let x=this.state.show;
  let k=x?<Button variant="contained" size="small" className={classes.button}    onClick={() => this.editicon()}><SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} /> Save image</Button>:'';
    return(k);
}

  handleChange(event) {
    this.setState({
      file:event.target.files[0]});
    this.setState({icon:URL.createObjectURL(event.target.files[0])});
    this.setState({show:true});

  }

     showComponents(classes) {
      return (
        <React.Fragment>
          <Typography variant="h6" gutterBottom>
            Edit/Delete Sub Category Names
          </Typography>
          
          
          <Grid container spacing={24}>
            <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="demo-controlled-open-select">Main Category Id</InputLabel>
              <Select
                 
                
                 value={this.state.maincategoryid}
                 onChange={e => this.setState({maincategoryid: e.target.value })}
                inputProps={{
                  name: 'maincatrgoryid',
                  id: 'demo-controlled-open-select',
                }}
              >
              <MenuItem value="">
                  <em>Main Category</em>
                </MenuItem>
               
              {this.fillMainCategory()}
        
              </Select>
            </FormControl>
            </Grid>
           
    
            
            <Grid item xs={12}>
              <TextField
                required
                id="subcategoryname"
                name="subcategoryname"
                label="Sub Category Name"
                
                fullWidth
                autoComplete="subcategoryname"
                value={this.state.subcategoryname}
                onChange={e => this.setState({ subcategoryname: e.target.value })}
                
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                name="description"
                label="Description"
                fullWidth
                autoComplete="description"
                value={this.state.description}
                onChange={e => this.setState({ description: e.target.value })}
             
             />
            </Grid>
            <Grid item xs={12} sm={6}>
              Select Icon:<br/>
              <input
            accept="image/*"
            className={classes.input}
            id="icon"
        
            name="icon"
            type="file"
            onChange={this.handleChange}
           
          />
          <label htmlFor="icon">
    
    
          <Button variant="contained" component="span" className={classes.button}>
              Upload
              <CloudUploadIcon className={classes.rightIcon}  />
            </Button>
         
          </label>
        
            </Grid>
            <Grid item xs={12} sm={6}>
            <Avatar alt="Remy Sharp" src={this.state.icon} className={classes.bigAvatar} />
             
             {this.showHide(classes)}
            </Grid>
          </Grid>
          <br/><br/>
          <Button variant="contained" size="small" className={classes.button}     onClick={() => this.editrecord()}>
            <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
            Edit
          </Button>

           <Button variant="contained" size="small" className={classes.button}     onClick={() => this.deleterecord()}>
           <DeleteIcon className={classes.rightIcon} />
         Delete
          </Button>
        </React.Fragment>
      );
    }

     


     editicon =async()=> {
     
      try {
  
          const formData = new FormData();
          formData.append('subcategoryid',this.state.subcategoryid);
          formData.append('icon',this.state.file);
          const config = {
              headers: {
                  'content-type': 'multipart/form-data'
              }
          };
  
        const result = await postDataAndImage("subcategory/editicon", formData,config);
       alert(result.RESULT);
        if(result.RESULT) {
          this.setState({msg:'Picture Edit....'});   
          this.props.history.replace({pathname:`/DisplayAllSub`});
    
        } else {
          this.setState({msg:''});     
          alert('Server Error\nTry Again');
        }
      } catch (e) {
        console.log("MainCategory:", e);
      }
    };
  

  editrecord =async()=> {
     
    try {

        const body={
          maincategoryid:this.state.maincategoryid,
          subcategoryid:this.state.subcategoryid,
          subcategoryname:this.state.subcategoryname,
          description:this.state.description
        }; 

      const result = await  postData("subcategory/editrecord", body);
     alert(result);
      if(result.RESULT) {
        this.setState({msg:'Record Edited....'});     
        this.props.history.replace({pathname:`/DisplayAllSub`});
  
      } else {
        this.setState({msg:'Fail to EDIT Record...'});     
        alert('Server Error\nTry Again');
      }
    } catch (e) {
      console.log("MainCategory:", e);
    }
  };

  deleterecord =async()=> {
     
    try {

        const body={
       
          subcategoryid:this.state.subcategoryid,
        }; 

      const result = await postData("subcategory/deleterecord", body);
     alert(result);
      if(result.RESULT) {
        this.setState({msg:'Record Deleted....'});   
        this.props.history.replace({pathname:`/DisplayAllSub`});
  
      } else {
        this.setState({msg:'Fail to Delete Record...'});     
        alert('Server Error\nTry Again');
      }
    } catch (e) {
      console.log("MainCategory:", e);
    }
  };






  render() {
     
    const { classes } = this.props;
    
    return (
      <div>
      <React.Fragment>
              
              <main className={classes.layout}>
                <Paper className={classes.paper}>
                 
                  <React.Fragment>
      
                  {this.showComponents(classes)}
                  </React.Fragment>
                  </Paper>
                  </main>
                  </React.Fragment>
                  <React.Fragment>
                  
                  <main className={classes.layout}>
                <Paper className={classes.paper}>
                
            
              <Typography variant="h5" component="h4">
                {this.state.msg}
              </Typography>
              
              </Paper>
                  </main>
                  </React.Fragment>
               
             
            </div>
         );
  }
}
EditDelete.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditDelete);

