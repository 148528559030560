import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { BrowserRouter as Router,HashRouter, Route,NavLink,Redirect } from "react-router-dom";
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import SignIn from '../login/SignIn' ;
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { getData, ClientURL } from "../FetchService"
import Animation from "../Animation"
import Hidden from '@material-ui/core/Hidden';

import { postData } from "../FetchService"
import Menu from '@material-ui/core/Menu'
import UserOrganization from '../userinterface/UserOrganization'
import UserDashBoard from '../userinterface/UserDashBoard'

import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import ShowCart from '../userinterface/ShowCart'
import ViewSet from './ViewSet';
import UserLogin from './UserLogin';
import UserRegistration from "./UserRegistration";
import ShowPurchasedSet from "./ShowPurchasedSet";
import TakeTest from "./TakeTest";
import Checkout from './Checkout/Checkout';
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ReviewTestTab from './ReviewTestTab';
import EmployerLogin from '../internship/EmployerLogin';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EmployerRegistration from '../internship/EmployerRegistration';
import ListInternship from '../internship/ListInternship';
import UsersWhoApplyIntern from '../internship/UsersWhoApplyIntern';
import ShowPurchasedSetTraining from './ShowPurchasedSetTraining';
import Certificate from './Certificate'
import ShowMyAccount from './ShowMyAccount';
import MainPage from '../footer/MainPage'
import Python from '../footer/Python'
import Node from '../footer/Node'
import Java from '../footer/Java'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import ListItem from '@material-ui/core/ListItem'

import WebDevelopment from '../footer/WebDevelopment'

import Fab from '@material-ui/core/Fab';
import TermsCondition  from '../footer/TermsCondition'
import classNames from 'classnames';
import Privacy from '../footer/Privacy';
import OurServices from '../footer/OurServices';


import Aboutus from '../footer/Aboutus'
import UserProfileEdit from './UserProfileEdit';
import {isMobile,BrowserView,MobileView} from 'react-device-detect';

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Training',
    description: ['Python', 'Machine Learning', 'Django & IOT', 'Node Js', 'React Js'],
  },
  {
    title: 'Internship',
    description: ['Noida', 'Delhi', 'Indore', 'Bengaluru'],
  },
  {
    title: 'Legal',
    description: ['About Us','Privacy Policy', 'Terms And Condition','Our Services'],
  },
];

const drawerWidth = '100%';

const styles = theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  foot: {
    flexGrow: 1,
    marginTop:20,

        justifyContent: 'center',
        alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10,
  },

  input: {
    display: 'none',
  },
    rootMenu: {
        display: 'flex',
      },
      paperMenu: {
        marginRight: theme.spacing.unit * 2,
      },

      rooot: {
    display: 'flex',
    minHeight: '100vh',
  },

      mainroot: {
        overflow: 'hidden',
        flexGrow: 1,
      },
      root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  badge: {
    top: '30%',
    right:-2,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  usericon: {
    marginLeft: 0,
    marginRight:0,
  },
  avatar: {
    margin:0,
    width: '70%',
    height: '70%',
  },
  layout: {
    backgroundColor:'#263475',

    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width:'100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  footer: {
    marginBottom: theme.spacing.unit * 2,

    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 3}px 0`,
  },
});

class Header extends React.Component {

  state = {
        open: false,
        listorg:[],
        sublist:[],
        list:[] ,
        anchorEl:null,
        view:'',
        listset:[],
        cartvalue:0,
        anchorElm: null,
        usermenustatus:'Before',
       viewof:'',
       accountimage:'user.gif',
       mobileOpen:false,



      };


      constructor(props)
         { super(props)


      }

      componentWillMount()
      {   console.log('usermainpage', this.props)
        var list1=localStorage.getItem("user")
        // alert(`componentWillMount ${list1}`)
        if(list1===null){
          this.setState({cartvalue:0});
          this.setState({accountimage:'user.gif'})
          this.setState({usermenustatus:'Before'})
        }
        else{
          this.setState({accountimage:'myaccount.gif'})
          this.setState({usermenustatus:'After'})
        }

        this.props.view(<MainPage   mainpage={this.mainpage} cartaction={this.cartaction}  />)

      }

       footerClick=(val)=>{
       let value=val.item
        if(value=='About Us')
        {

          this.props.view(<Aboutus />)
        }
        else  if(value=='About us')
        {

          this.props.view(<Aboutus />)
        }
      else  if(value=='Terms And Condition')
      {

        this.props.view(<TermsCondition />)
      }
     else  if(value=='Our Services')
     {

       this.props.view(<OurServices />)
     }
     else  if(value=='Privacy Policy')
     {

       this.props.view(<Privacy />)
     }
      else  if(val=='MAIN')
     {
       this.props.view(<MainPage  mainpage={this.mainpage}   cartaction={this.cartaction} />)
     }
     else  if(value=='Noida')
     {

      this.cartaction('SHOW_INTERN_FILTER',value)
     }
     else  if(value=='Delhi')
     {

      this.cartaction('SHOW_INTERN_FILTER',value)
     }
     else  if(value=='Indore')
     {

      this.cartaction('SHOW_INTERN_FILTER',value)
     }
     else  if(value=='Bengaluru')
     {

      this.cartaction('SHOW_INTERN_FILTER',value)
     }
       }
       footTop=(classes)=>{
         return( <div style={{marginTop:'50px',marginBottom:'50px'}}> <Grid  container container={24}>
          <Grid item xs={12} sm={4}>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}> <img src={`/images/call.png`} />

          </div>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}> +91 8962533532

          </div>
          </Grid>
          <Grid item xs={12} sm={4}>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}> <img src={`/images/mail.png`}/></div>
          <div style={{display:'flex',justifyContent:'center',margin:'10px',alignItems:'center'}}>  admin@campusshala.com

        </div>
          </Grid>
          <Grid item xs={12} sm={4}>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}> <img src={`/images/location.png`}/></div>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>  New Delhi

        </div>
          </Grid>

          </Grid> </div>)

       }
       footerDetails=(classes)=>{
        return (
          <div  >



          <center>
       <Fab  color="default" aria-label="Edit" style={{justifyContent:'center',alignItems:'center',align:'center',marginTop:'2%'}}  >
            <img src={`/images/facebookm.png`} alt=''/>
          </Fab>
          <Fab color="default" aria-label="Edit" style={{marginLeft:'2%',justifyContent:'center',alignItems:'center',align:'center',marginTop:'2%'}} >
            <img src={`/images/instagramm.png`} alt=''/>
          </Fab>
          <Fab color="default" aria-label="Edit" style={{marginLeft:'2%',justifyContent:'center',alignItems:'center',align:'center',marginTop:'2%'}}  >
            <img src={`/images/twitterm.png`} alt=''/>
          </Fab>
          </center>


            <Typography>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10px',marginBottom:'10px'}}>  @2019,Campusshala Education Pvt Ltd</div>
            </Typography>













          </div>
        );
      }



       footerButtons=(classes)=> {

        return (
          <div>
          <div style={{height:'5px', backgroundColor:'#06c'}}></div>


      <footer className={classNames(classes.footer, classes.layout)}>
      <Grid container spacing={32} justify="space-evenly">
        {footers.map(footer => (
          <Grid item xs key={footer.title} style={{ marginLeft:'100px'}}>
            <Typography variant="h6" style={{color:'#fffde7'}} gutterBottom>
              {footer.title}
            </Typography>
            {footer.description.map(item => (
             <div>  <Button style={{color:'#ffffffff',fontSize:'12px', margin:0,padding:0,minWidth:0}}   className={classes.button} onClick={()=>this.footerClick({item})}>

            {item}

            </Button>

            </div>


            ))}
          </Grid>
        ))}
      </Grid>
    </footer>
    </div>


        );
      }



      TakeTest=async(value,data)=>{
         if(value=='TAKETEST')
        {
         this.props.view(<TakeTest footerClick={this.footerClick} mainpage={this.mainpage}  data={data}  history={this.props.history} />,"Test")
        }
        else if(value=='REVIEWTEST')
        {
         this.props.view(<ReviewTestTab mainpage={this.mainpage} body={data}  history={this.props.history} />)
        }
        else if(value=='CERTIFICATE')
        {
         this.props.view(<Certificate mainpage={this.mainpage} body={data}  history={this.props.history} />)
        }
      }

      mainpage=async(value)=>{

       if(value=='SC')
       {
        const body={cid:1};
        const listorg = await postData(`questions/fetchorganization`,body);


        this.setState({ listorg });
       this.props.view(<UserOrganization userview={this.state} action={this.action}/>)

       }
       else  if(value=='MAIN')
      {
        this.props.view(<MainPage  mainpage={this.mainpage}   cartaction={this.cartaction} />)
      }
       else if(value=='SHOWCART')
        {

          this.props.view(<ShowCart cartaction={this.cartaction} mainpage={this.mainpage} history={this.props.history}/>)
        }

        else if(value=='VT')
       {
        const body={cid:14};
        const listorg = await postData(`questions/fetchorganization`,body);


        this.setState({ listorg });
       this.props.view(<UserOrganization userview={this.state} action={this.action}/>)

       }
       else if(value=='BUY')
       {if(!localStorage.getItem('user'))
        this.props.view(<UserLogin mainpage={this.mainpage} loginfor='BUY'  history={this.props.history} />)
        else
        {this.props.view(<Checkout cartaction={this.cartaction} mainpage={this.mainpage} history={this.props.history} />)
       //  this.setState({usermenustatus:'After'})
      }

       }
       else if(value=='UR')
       {
        this.props.view(<UserRegistration mainpage={this.mainpage} history={this.props.history} />)
       }
       else if(value=='CO')
       {
        this.props.view(<Checkout  cartaction={this.cartaction} mainpage={this.mainpage} history={this.props.history} />)
        this.setState({accountimage:'myaccount.gif'})
        this.setState({usermenustatus:'After'})
       }
       else if(value=='TEST')
       {let u=JSON.parse(localStorage.getItem('user'));
       const body={userid:u.email};
     const listset = await postData(`purchase/fetchpurchasedsetnouser`,body);
       if(listset.length==0)
       {  this.props.view(<ListInternship citysearch={''} statecity={false} cartaction={this.cartaction} history={this.props.history}/>)    }
       else{
     this.props.view(<ShowPurchasedSet listset={listset} TakeTest={this.TakeTest} history={this.props.history} />)
       }
       this.setState({accountimage:'myaccount.gif'})
       this.setState({usermenustatus:'After'})

       }
       else if(value=='USERLOGIN')
       {
        this.props.view(<UserLogin mainpage={this.mainpage} loginfor='BUY'  history={this.props.history} />)
       }
       else if(value=='USERLOGIN_UR')
       {
        this.props.view(<UserLogin mainpage={this.mainpage} loginfor='TEST'  history={this.props.history} />)
       }

      else if(value=='ER')
      {
       this.props.view(<EmployerRegistration mainpage={this.mainpage} history={this.props.history} />)
      }
      else if(value=='LOGIN_EMPLOYER')
      {

       this.props.view(<EmployerLogin mainpage={this.mainpage} history={this.props.history} />)
       }
       else if(value=='SHOW_INTERN')
       {

                this.props.view(<ListInternship citysearch={''} statecity={false} cartaction={this.cartaction} history={this.props.history}/>)
               // this.setState({usermenustatus:'After'})
      }


    }

      cartaction=async(value,record)=>
      {
        // alert(value,record)

        if(value=='USERLOGINDEMO')
        {
          this.props.view(<UserLogin mainpage={this.mainpage} loginfor='ADDCART_WITHOUT_LOGIN_DEMO' record={record} cartaction={this.cartaction} history={this.props.history} />)
        }
       else  if(value=='USERLOGIN')
        {
          this.props.view(<UserLogin mainpage={this.mainpage} loginfor='ADDCART_WITHOUT_LOGIN' record={record} cartaction={this.cartaction} history={this.props.history} />)
        }
        else if(value=='USERLOGIN_INTERN')
        {
          this.props.view(<UserLogin mainpage={this.mainpage} loginfor='INTERN_WITHOUT_LOGIN' idemployer={record} history={this.props.history} />)
        }
        else if(value=='Python'){
          this.props.view(<Python id={record} cartaction={this.cartaction}/>)
        }
        else if(value=='Node'){
          this.props.view(<Node/>)
        }
        else if(value=='Java'){
          this.props.view(<Java/>)
        }
        else if(value=='Web'){
          this.props.view(<WebDevelopment/>)
        }
        else  if(value=='SHOWCART')
        {

          this.props.view(<ShowCart cartaction={this.cartaction} mainpage={this.mainpage} history={this.props.history}/>)
        }
        else if(value=='SHOW_INTERN_FILTER')
        {

                 this.props.view(<ListInternship citysearch={record} statecity={true} cartaction={this.cartaction} history={this.props.history}/>)
               //  this.setState({usermenustatus:'After'})
       }
       else if(value=='COMPANY')
       {     const body={cid:record};
             let rec = await postData(`questions/fetchsetnouser`,body);

            await this.setState({listset:rec})
             this.props.view(<ViewSet userview={this.state} cartaction={this.cartaction} history={this.props.history}/>)
      }
      let c=0
      if(localStorage.getItem("employer")){

        //this.setState({cartvalue:localStorage.length-1});
          c=c+1
       }
       if(localStorage.getItem("admin")){

       // this.setState({cartvalue:localStorage.length-1});

         c=c+1
       }
       if(localStorage.getItem("timer")){

        //this.setState({cartvalue:localStorage.length-1});
          c=c+1
       }
       if(localStorage.getItem("user")){

        //this.setState({cartvalue:localStorage.length-1});
          c=c+1
       }

       if(localStorage.getItem("PYTHON")){

        //this.setState({cartvalue:localStorage.length-1});
          c=c+1
       }

       if(value=='REDUCECARTVALUE'){
         this.setState({cartvalue:(localStorage.length)-c})
       }

       // alert(`user ${localStorage.getItem("user")}`)
       // alert(`length ${(localStorage.length)}`)
       // alert(`c ${(c)}`)
        if(!localStorage.getItem("user")){

          this.setState({accountimage:'user.gif'})
          this.setState({usermenustatus:'Before'})
        }
         // if(((localStorage.length)-c)!==0 && ((localStorage.length)-c)>=0)
         if(((localStorage.length)-c)>0 && (localStorage.getItem("user")))
         {
           this.setState({cartvalue:(localStorage.length)-c});
           this.setState({accountimage:'myaccount.gif'})
         this.setState({usermenustatus:'After'})
        }
        if(value=='' && record==''){
          this.setState({cartvalue:0})
        }


      }

      action=async(value)=>{

      this.setState({listset:value})
      this.props.view(<ViewSet userview={this.state} cartaction={this.cartaction} history={this.props.history}/>)


      }
     async   componentDidMount() {
       var list1=localStorage.getItem("user")
       // alert(`componentDidMount ${list1}` )
       if(list1===null){
         // if(list1==''){
         this.setState({cartvalue:0});
         this.setState({accountimage:'user.gif'})
         this.setState({usermenustatus:'Before'})
       }
       else{
         this.setState({accountimage:'myaccount.gif'})
         this.setState({usermenustatus:'After'})
       }

       const list = await getData(`maincategory/fetchallmaincategories`);
      console.log(`Data ${list}`)
        this.setState({ list });

      console.log(`Data ${this.state.list}`)
      this.handleClicks = this.handleClicks.bind(this)
      this.handleClose = this.handleClose.bind(this)
      this.handleClosem = this.handleClosem.bind(this)
      this.handleMenum = this.handleMenum.bind(this)
      this.cartaction()
      this.setState({viewof:''})

      }

      handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
      };

      handleMobileOpen=(status)=>{
        this.setState({mobileOpen:status})
      }


      handleClicks =async (event)=> {

      if(event.currentTarget.value=="Internship")
      {
        this.props.view(<ListInternship cartaction={this.cartaction} history={this.props.history}/>)
        this.handleMobileOpen(false)
      }
      else{ this.setState({ anchorEl: event.currentTarget });
      console.log(event.currentTarget.value)
        const body={cid:event.currentTarget.value};
       const sublist = await postData(`subcategory/fetchsubcategories`,body);
       this.setState({sublist  });
       //this.setState({mobileOpen:false})
      }


         };


      handleClose = async(event) => {
        console.log('close:'+event.target.value)
        this.setState({ anchorEl: null });
        this.setState({subcategoryid: event.target.value });
        if(event.target.value!=undefined){
          const body={cid:event.target.value};
        const listorg = await postData(`questions/fetchorganization`,body);


        this.setState({ listorg });
       this.props.view(<UserOrganization userview={this.state} action={this.action}/>)
       this.handleMobileOpen(false)
        }
        else{
          this.props.view(<MainPage   mainpage={this.mainpage} cartaction={this.cartaction}  />)
       this.handleMobileOpen(false)
        }
        


      };







      menu=()=>{
              const { anchorEl } = this.state;

              return this.state.list.map((item,index)  =>  {

                console.log('kkkkkkkkkkkkkkkkkk '+item)

                return (



                  <React.Fragment>
            <Button
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"

               onClick={this.handleClicks}
               value={item.maincategoryid}

            >
            {item.maincategory}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              value=''
              onClose={this.handleClose}
            >
            {this.fillSubCategory()}


            </Menu>


            </React.Fragment>
        );
      }
      )}



fillSubCategory = () => {


  return this.state.sublist.map((item, index) => {



      return (
        <MenuItem onClick={this.handleClose} value={item.subcategoryid} >{item.subcategoryname}</MenuItem>
       );
  })
}
ShowCartItems=()=>{
  this.props.view(<ShowCart  cartaction={this.cartaction} mainpage={this.mainpage} history={this.props.history}/>)
}

handleMenum = event => {
  this.setState({ anchorElm: event.currentTarget });
};

handleClosem = async(value) => {

  this.setState({ anchorElm: null });

  if(value=='User Internship')
  {this.props.view(<UsersWhoApplyIntern  history={this.props.history}/>)

  }
  else if(value=='Login Student')
  {this.props.view(<UserLogin loginfor='TEST' mainpage={this.mainpage} history={this.props.history}/>)
  this.setState({accountimage:'user.gif'})
  this.setState({usermenustatus:'Before'})
  }
  else if(value=='Login Admin')
  {
    this.props.history.push({pathname:'/SignIn'})

  }

  else if(value=='My Profile')
  {this.props.view(<UserDashBoard handleClosem={this.handleClosem} history={this.props.history}/>)

  }
  else if(value=='My Account')
  {this.props.view(<ShowMyAccount  history={this.props.history}/>)

  }
  else if(value==='Logout')
  {localStorage.clear()
    this.setState({accountimage:'user.gif'})
    this.setState({usermenustatus:'Before'})
    this.setState({cartvalue:0})
    this.props.view(<MainPage   mainpage={this.mainpage} cartaction={this.cartaction}  />)


  }
  else if(value=='Clear Cart')
  {
    var count=0
    for (let i = localStorage.length-1;i>=0; --i ) {
      console.log("set no:"+localStorage.key(i))
      if(localStorage.key(i)==null)
      {localStorage.removeItem(localStorage.key(i))}
      else if(localStorage.key(i).startsWith('#'))
      {
        count=count+1
        localStorage.removeItem(localStorage.key(i))

      }

    }
    this.setState({cartvalue:this.state.cartvalue-count})
    this.cartaction('SHOWCART','')

  }
  else if(value=='Placement Papers')
  {
    this.props.view(<ShowPurchasedSet TakeTest={this.TakeTest} history={this.props.history} />)
  }
  else if(value=='Training Papers')
  {this.props.view(<ShowPurchasedSetTraining TakeTest={this.TakeTest} history={this.props.history} />) }

  else if(value=='Login Employer')
  {if(localStorage.getItem("employer"))
  {
   this.props.history.push({pathname:'/EmployerHome'})

  }

  else{
    console.log('employe',this.props.history);
    this.props.view(<EmployerLogin loginfor='TEST' mainpage={this.mainpage} history={this.props.history}/>)
  }
  }
};


menuBeforeUserLogin=()=>
{
return(<div>
  <MenuItem onClick={()=>this.handleClosem('Login Student')} >
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon> */}
          <ListItemText  primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login Student" />
        </MenuItem>

        <MenuItem onClick={()=>this.handleClosem('Login Employer')} >
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon> */}
          <ListItemText   primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login Employer" />
        </MenuItem>
        <MenuItem onClick={()=>this.handleClosem('Login Admin')} >
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon>inset */}
          <ListItemText   primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login Admin" />
        </MenuItem>
 </div>
);

}

menuAfterUserLogin=()=>
{
return(<div>
  <MenuItem onClick={()=>this.handleClosem('My Profile')}>
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon> inset */}
          <ListItemText   primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Profile" />
        </MenuItem>
        <MenuItem onClick={()=>this.handleClosem('My Account')}>
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon> */}
          <ListItemText  primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Account" />
        </MenuItem>
        <MenuItem onClick={()=>this.handleClosem('Placement Papers')}>
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon> */}
          <ListItemText   primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Placement Test" />
        </MenuItem>
        <MenuItem onClick={()=>this.handleClosem('Training Papers')}>
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon> */}
          <ListItemText   primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Training Test" />
        </MenuItem>


        <MenuItem onClick={()=>this.handleClosem('User Internship')}>
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon> */}
          <ListItemText   primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Internship Applied For" />
        </MenuItem>


        <MenuItem onClick={()=>this.handleClosem('Logout')}>
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon> */}
          <ListItemText   primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Logout" />
        </MenuItem>
        <MenuItem onClick={()=>this.handleClosem('Clear Cart')} >
          {/* <ListItemIcon > */}
            <AccountCircle />
          {/* </ListItemIcon> */}
          <ListItemText   primary="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Clear Cart" />
        </MenuItem>

   </div>
);

}

 CustomizedBadge(classes) {
  const { anchorElm} = this.state;

  const open = Boolean(anchorElm);
  const url=`/images/${this.state.accountimage}`;
  let options=''
  // alert(`options ${this.state.usermenustatus}`)
  if(this.state.usermenustatus=='Before')
  options=this.menuBeforeUserLogin
  if(this.state.usermenustatus=='After')
  options=this.menuAfterUserLogin

  return (
  <div>
  <IconButton aria-label="Cart">
      <Badge badgeContent={this.state.cartvalue} color="primary" classes={{ badge: classes.badge }} onClick={this.ShowCartItems}>
        <ShoppingCartIcon />

      </Badge>
    </IconButton>

                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenum}
                  color="primary"
                  className={classes.usericon}
                >
                  <Avatar alt="Remy Sharp" src={url} className={classes.avatar} />


                </IconButton>
                <Menu
                 id="menu-appbar"
                 anchorEl={anchorElm}
                 open={open}
                 value=''
                 onClose={this.handleClosem}
                >
                {options()}

                </Menu>

  </div>
  );
}

CustomizedBadgeMobile(classes) {
  const { anchorElm} = this.state;

  const open = Boolean(anchorElm);
  const url=`/images/${this.state.accountimage}`;
  let options=''
  // alert(`options ${this.state.usermenustatus}`)
  if(this.state.usermenustatus=='Before')
  options=this.menuBeforeUserLogin
  if(this.state.usermenustatus=='After')
  options=this.menuAfterUserLogin

  return (
  <div style={{marginLeft:'19%'}} >
  <IconButton aria-label="Cart">
      <Badge badgeContent={this.state.cartvalue} color="primary" classes={{ badge: classes.badge }} onClick={this.ShowCartItems}>
        <ShoppingCartIcon />

      </Badge>
    </IconButton>

                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenum}
                  color="primary"
                  className={classes.usericon}
                >
                  <Avatar alt="Remy Sharp" src={url} className={classes.avatar} />


                </IconButton>
                <Menu
                 id="menu-appbar"
                 anchorEl={anchorElm}
                 open={open}
                 value=''
                 onClose={this.handleClosem}
                >
                {options()}

                </Menu>

  </div>
  );
}

 handleDrawerToggle = () => {
  this.setState({ mobileOpen: !this.state.mobileOpen });
};


 SearchAppBar(classes) {

  return (<div className={classes.root}>

      <AppBar  position="static" color="default">
        <Toolbar>

          <div>
          <img src={`/images/cssbig.png`} style={{marginBottom:'6%', marginTop:'2%'}}  onClick={()=>this.footerClick("MAIN")}></img>
          </div>


          <div className={classes.grow} />

      <Button
        onClick={this.handleClicks}
        value='Internship'>
    Internship
     </Button>
          {this.menu(classes)}
          {/*<div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase

              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />

          </div>*/}
          {this.CustomizedBadge(classes)}
        </Toolbar>
      </AppBar>

          </div>
  );
}




render() {
    const { classes, ...other } = this.props;
    let button;
    button = (<div style={{width:'100%'}}>
    <AppBar  position="static" color="default">
      <Toolbar>
        <MenuIcon onClick={(e)=>this.setState({mobileOpen:true})} color='primary'/>


        <img src={`/images/cssbig.png`} style={{width:'25%'}} onClick={()=>this.footerClick("MAIN")}></img>

        {this.CustomizedBadgeMobile(classes)}
      </Toolbar>
    </AppBar>
      <Drawer open={this.state.mobileOpen} onClose={(e)=>{this.setState({mobileOpen:false})}} variant="temporary" {...other}>
      <List disablePadding>
      <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
        <img src={`/images/cssbig.png`} onClick={()=>this.handleMobileOpen(false)}></img>
      </ListItem>
       <ListItem className={clsx(classes.item, classes.itemCategory)}>
        <ListItemIcon className={classes.itemIcon}>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.itemPrimary,
          }}
        >
          CampusShala
        </ListItemText>
      </ListItem>
    </List>
        <Button
          onClick={this.handleClicks}
          value='Internship'>
      Internship
       </Button>
       {this.menu(classes)}
      </Drawer>
      </div>
    )

    return (
      <div className={classes.root}>
        <nav className={classes.drawer}>
              <Hidden smUp implementation="js">
                {button}
              </Hidden>
              <Hidden xsDown implementation="css">
              {this.SearchAppBar(classes)}
              </Hidden>
            </nav>
    </div>

    );}



}
Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
