import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getData, BaseURL } from "../FetchService"
import { postData,ClientURL } from "../FetchService"
import ViewSet from "../userinterface/ViewSet"

const styles = {
    root: {
        display: 'flex',
        flexWrap:"wrap",
        marginTop:'50px',
        justifyContent: 'center',
        alignItems: 'center',
      },
  card: {
    maxWidth: 345,
    // height:345,
    // width:345,
    marginRight:'25px',
    marginTop:'10px',

  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    height:'100%',

    width: '100%',
  },
};


class UserOrganization extends React.Component {
    i=0
    componentDidMount() {
      this.handleClick= this.handleClick.bind(this)


    }

 handleClick=async(id)=>{
 // alert(id)

  const body={cid:id};
  const listset = await postData(`questions/fetchsetnouser`,body);
  this.props.action(listset)





}

// <div className={classes.root}>
//   <Grid container>
//     <Grid item xs={12} sm={4}>
// <Card><CardContent>
//   <Typography gutterBottom variant="h5" component="h2">
//     {"Hello"}
//   </Typography>
//   <Typography component="p">
//    Test Papers
//   </Typography>
// </CardContent></Card>
// </Grid>
// <Grid item xs={12} sm={4}>
// <Card><CardContent>
//   <Typography gutterBottom variant="h5" component="h2">
//     {"Hello"}
//   </Typography>
//   <Typography component="p">
//    Test Papers
//   </Typography>
// </CardContent></Card>
// </Grid>
// <Grid item xs={12} sm={4}>
// <Card><CardContent>
//   <Typography gutterBottom variant="h5" component="h2">
//     {"Hello"}
//   </Typography>
//   <Typography component="p">
//    Test Papers
//   </Typography>
// </CardContent></Card>
// </Grid>
// </Grid>
// </div>


showreward=(c,m,classes)=>{
  if(c!==0 && m===0){
    return(
  <div>

  </div>
)
  }
}


 ImgMediaCard(classes) {
   let count=0
   let m=1
    return this.props.userview.listorg.map((item, index) => {
        let url=`${BaseURL}/images/${item.icon}`
        if(item.maincategory){
          var category=item.maincategory
          var findn=category.search("Training")
          //alert(findn)
          if(findn!==-1){
          count=count+1
          m=m-1
         }
        }

  return (
    <div>
    <div style={{display: 'flex',
    flexWrap:"wrap",}}>
    {this.showreward(count,m,classes)}
  </div>
  <div>
    <Card className={classes.card}>
      {/*<CardActionArea >*/}
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          className={classes.media}
          height="140"
          image={url}
          title={item.organization}

        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {item.organization}
          </Typography>
          <Typography component="p">
           Test Papers
          </Typography>
        </CardContent>
      {/*</CardActionArea>*/}
      <CardActions>
        <Button size="small" color="primary" id={item.organizationid} value={item.organizationid}   onClick={() => this.handleClick(item.organizationid)}>
         Buy Now
        </Button>


      </CardActions>
    </Card>
    </div>
  </div>

  );
})
}
render() {
    const { classes } = this.props;
    return (<div>
 <div className={classes.root}>
        {this.ImgMediaCard(classes)}
        </div>


    </div>)}

}
UserOrganization.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserOrganization);
