import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({


  root:
  { 
    marginTop:'5%',
    marginLeft:'10%',
    textAlign:'justify',
    marginRight:'10%'
  },
  hello:
  {
    marginTop:'0.5%',
    fontStyle:'calibri',
  },
hello1:{
  fontSize:'100%',
  marginTop:'0.5%',

},
hello2:{
  marginTop:'0.5%',


},
set:{
  marginTop:'1%'
},
set1:{
  marginTop:'2%',
  fontSize:'100%',
}
})


class PrivacyReady extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div>
      <div className={classes.root}>

<Typography className={classes.hello1}><b><u>Privacy Policy+:</u></b></Typography>
<p>
We value the trust you place in us. That’s why we insist upon the highest standards for secure transactions and customer information privacy.
 Please read the following statement to learn about our information gathering and dissemination practices.
</p>
<Typography className={classes.hello1}><b><u>Collection of Information</u></b></Typography>
<p>
We respect your privacy and strive to provide a safe, secure user experience.
By using our services, you consent to the policies and practices described in this statement.
We will store your data which may be inside or outside India and your usage of the Services constitutes consent to the transfer of your data out of India.
In our website, it may contain links to other website which can redirect you to other website and we are not responsible for any kind of harm to your data. 
This privacy statement applies only to the campusshala.com.

</p>
<Typography className={classes.hello1}><b><u>Privacy Policy+:</u></b></Typography>
<p>
<b>Information entered when you our service:</b> During the services which you will use on our websitewe will ask you to provide the details including your name, address,
 e-mail address, telephone number, contact information, billing information, education details,
 and any other information from which your identity is discernible.
</p>
<p>
<b>Information collected when you use third party services:</b>  We may collect, process and store your information associated with your Google account, Facebook Account, LinkedIn Account 
if you choose to sign in using Google, Facebook, LinkedIn. When you sign in to your account with your Google account information, you consent to our collection, storage,
 and use of the information that you make available to us through your Google account in accordance with this Privacy Policy. 
 This could include your name, email address and phone number associated with your Google account.
 The same policy will be applied when you logged in through facebook, LinkedIn etc.
</p>
<Typography className={classes.hello1}><b><u>Security Precautions</u></b></Typography>
<p>
Our Website has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. 
Whenever you change or access your account information, we offer the use of a secure server. 
Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.
</p>
<Typography className={classes.hello1}><b><u>Sharing of information</u></b></Typography>
<p>
•	Campusshala will ask you the information when you are posting details related to internship such as company name, web address, and description related to internship such as roles and responsibilities, skills required etc. will be published online and can be viewed by anyone visiting campusshala site. Further, we may share the details of your organization with students
    who apply to your internships or get hired by you through campusshala.
•	If you are a student looking for a student, we will share your personal information 
    which you shared with us during your submission of application of internship with employers whose internships you apply to.
•	We are not responsible for any of the information which you shared on the public areas of Campusshala since this information will be accessible to everyone.
    It may be collected and used by others over whom Campusshala has no control.
•	When we send you an email or SMS regarding the successful submission of application of intership,
    we use a third party service. In this case, it becomes necessary to pass on your email address/ mobile number to the third party. Your email address and mobile number will be shared woth the thirs party over which we have no control. 

</p>
<Typography className={classes.hello1}><b><u>Editable Information</u></b></Typography>
<p>
As a student, you can edit your details which you have filled during the profile you have created on Campusshala.
As an employer, you may edit your personal information and information about your organization by visiting the profile (personal details and organization details) section. 

</p>
<Typography className={classes.hello1}><b><u>Choice/Opt-Out</u></b></Typography>
<p>
We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from us on behalf of our partners, and from us in general, after setting up an account.

If you want to remove your contact information from all campusshala.com lists and newsletters, please contact us at support@campusshala.com or live chat support.


</p>
<Typography className={classes.hello1}><b><u>How your information is secured with us?</u></b></Typography>
<p>
We have implemented all the terms and conditions as per industry standards in terms of security measures to protect your information on Campusshala. The third party payment service providers (payment gateways) are all validated as compliant with the payment card industry standard (generally referred to as PCI compliant service providers).
In order to keep personal information secure, you must not share your password or other security information (for example, unique keys) of your Campusshala account with anyone. If you are using a shared computer, please make sure you logout after every use. If we receive instructions using your email and password, we will consider that the instructions have been authorized by you.

</p>
<Typography className={classes.hello1}><b><u>Your Consent</u></b></Typography>
<p>
By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy.
If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.

</p>
</div>
</div>
    );
  }

}
PrivacyReady.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyReady);
