/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

import clsx from 'clsx';
import { Paper, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';
import { postData, getData} from '../../../FetchService';

const useStyles = makeStyles(() => ({
	formControl: {
		// margin: theme.spacing(1),
		// width: '80%'
		//marginBottom: '10px'
		//marginLeft: '50px'
	},
	input: {
		display: 'none'
	},
	root: {
		background: '#dff9fb',
		padding: '20px',
		marginTop: '50px',
		marginBottom: '50px',
		width: '70%'
	},

	formLabel: {
		marginTop: '10px'
	},
	button: {
		marginTop: '10px'
	}
}));

export default function ChapterUpload() {
	const classes = useStyles();

	const [ getMessage, setMessage ] = useState('');
	const [ getCourse, setCourse ] = useState('');
	const [ getChapter, setChapter ] = useState('');
	const [ getCourseList, setCourseList ] = useState([]);

	const handleSubmit = async () => {
		if (getCourse != '' && getChapter != '') {
			let body1 = {
				courseId: getCourse,
				chapterName: getChapter
			};
			const result = await postData('chapterdetails/checkChapter', body1);

			console.log('check result ', result);

			if (result.result) {
				setMessage('Chapter Name Already Existed.....');
			} else {
				var body = {
					courseId: getCourse,
					chapterName: getChapter
				};
				console.log('body data', body);
				const result = await postData('chapterdetails/addChapter', body);
				console.log('result', result);
				if (result.result == true) {
					setMessage('Record Submitted.....');
					setTimeout(function() {
						clearValues();
					}, 3000);
				} else {
					setMessage('Not Submitted..........');
				}
			}
		} else {
			setMessage('First Fill All The Fields .....');
		}
	};
	const fillCourse = async () => {
		const result = await getData('course/fetchdata');
		setCourseList(result);
	};
	const handleChapter = async (event) => {
		setChapter(event);
	};
	const clearValues = () => {
		setChapter('');
		setCourse('');
		setMessage('');
	};
	useEffect(() => {
		fillCourse();
	}, []);
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Paper className={classes.root}>
				<Typography variant="button" display="block" gutterBottom>
					Chapter Upload
				</Typography>
				<React.Fragment>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<FormControl className={classes.formControl} fullWidth>
								<InputLabel id="demo-simple-select-label">Course</InputLabel>
								<Select
								fullWidth
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={getCourse}
									onChange={(event) => setCourse(event.target.value)}
								>
									{getCourseList.map((item) => {
										return <MenuItem value={`${item.courseid}`}>{item.coursename}</MenuItem>;
									})}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Chapter Name"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								value={getChapter}
								fullWidth
								onChange={(event) => handleChapter(event.target.value)}
							/>
						</Grid>

						<Grid item xs={12}>
							<Button
								variant="contained"
								component="span"
								className={classes.button}
								onClick={handleSubmit}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
					{getMessage}
				</React.Fragment>
			</Paper>
		</div>
	);
}
