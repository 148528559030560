import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { getData, BaseURL,ClientURL } from "../FetchService"
import { postData } from "../FetchService"
 
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
 
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';


const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5,
  },
  
});

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions,
);

let counter = 0;
 

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  panelroot: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '8%',
  },
  nextcolumn: {
    flexBasis: '55%',
  },
  
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

class UserWhoApplyIntern extends React.Component {
  state = {
    rows: [],
    page: 0,
    rowsPerPage: 2,
    result:[]
  };

  async componentDidMount() {
   
    let u=JSON.parse(localStorage.getItem('user'));
    let body={emailid:u.email}
     
    const rows = await postData(`internship/all_intern_apply_user`,body);
    console.log(`Data ${rows}`)
    this.setState({ rows });
    console.log(`Data ${this.state.rows}`) 
  
  }

   

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  
    return (
      <Paper className={classes.root}>
       <Typography variant="h6" gutterBottom align='center'>
    You Have Applied For Following Companies
       </Typography>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
          
            <TableBody>
              {this.state.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                  let btntxt=''
                  let btnstatus=true
                  var d=new Date(row.applyby)
                  var td=d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + d.getDate()).slice(-2)
                  if(row.result===undefined)
                  {btntxt='Apply'
                  btnstatus=false
                }
                  else if(row.result==null)
                  {btntxt='Apply'
                  btnstatus=false
                }
                  else
                  {btntxt='Already Applied'
                  btnstatus=true
                }

                  let pt=''   
                   if(row.isparttime=='Yes')
                        pt='Part Time'
                       else
                        pt='Full Time'
               return (
                  <TableRow key={row.id}>
                     
                    <TableCell>
                    
                    <div className={classes.panelroot}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.column}>
            <Typography className={classes.heading}> <img src={`${BaseURL}/images/${row.logo}`} width='40' height='40'></img></Typography>
          </div>
          <div className={classes.nextcolumn}>
          <Grid container spacing={24}>
          <Grid item xs={6} >
                <Typography className={classes.secondaryHeading} noWrap='false'><b>{row.primaryprofile}</b><br></br>
              {row.organizationname} <br></br> <b>Location(s):</b>{row.internlocations}<br></br>
              <a href={`http://www.${row.websiteurl}`} className={classes.link}>
              http://www.{row.websiteurl}
              </a>
              </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.secondaryHeading} noWrap='false'>
                <b> Only those Candidate Eligible:</b><br></br>

1] Who Can Work {pt} in office.<br></br>
2] Who Can Join {row.startdate}<br></br>
3] Are Available for  {row.internshipduration} {row.options}<br></br>
4] Are from {row.internlocations} and neighboring cities<br></br>
5] Have Skill set of   {row.skills}<br></br>

                 
             
                </Typography>
              </Grid>   
              
              </Grid>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <div className={classes.column} />
          <div className={classes.nextcolumn}>
          <Typography component="p">
              <Grid container spacing={24}>
              


       

        <Grid item xs={2}>
              <b>Start Date</b><br></br>
              {row.startdate}
                </Grid>
                <Grid item xs={2}>
                <b>Duration</b><br></br>
              {row.internshipduration}
                </Grid>
                <Grid item xs={2}>
                <b>Stipend</b><br></br>
             {row.currency} {row.stipend}/- {row.duration}
                </Grid>
                <Grid item xs={2}>
                <b>Post On</b><br></br>
            {row.date_posted}
                </Grid>
                <Grid item xs={2}>
              <b>Apply By</b><br></br>
              {td}
                </Grid>
              </Grid>
               </Typography>
          </div>
          <div className={classNames(classes.column, classes.helper)}>
          <Typography className={classes.secondaryHeading} noWrap='false'>
          <b>You have Apply on dated {row.user_currentdate}<br></br>
             Your Status of Internship is {row.user_status}</b>
             </Typography>
            <Typography variant="caption" noWrap='false'>
         
             
             <img src={`/images/facebook.png`} width='50' height='50'/>
             <img src={`/images/twitter.png`} width='50' height='50'/>
             <img src={`/images/instagram.png`} width='50' height='50'/>
             <img src={`/images/youtube.png`} width='50' height='50'/>

             
            </Typography>
          </div>
        </ExpansionPanelDetails>
        
      </ExpansionPanel>
    </div>
                    
                    </TableCell>
                     
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={3}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

UserWhoApplyIntern.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserWhoApplyIntern);