import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import suggestions from './cities.json';
import profiles from './profile.json';
//https://www.npmjs.com/package/material-ui-pickers
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';


const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  input: {
    display: 'flex',
    padding:10,
    width:310,
    height:'auto',


  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  gridstyle:{
    padding:'-5px',
    margin:'-5px'
  },

  card: {
    maxWidth: '100%',
    marginRight:'5%',
    height:'auto'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'wrap',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    paddingLeft:'10px'
  },
  paper: {

    position: 'absolute',
    zIndex: 1,
    marginTop:'-10px',
    left: 0,
    right: 0,
    width:300,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
    id="outlined-search"

    margin="normal"
    variant="outlined"
    InputProps={{
      inputComponent,
      inputProps: {
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps,
      },

    }}
    {...props.selectProps.textFieldProps}

  />

  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}

    </Typography>
  );
}



function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square elevation={3} className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,

  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  state = {
    location: null,
    category: null,
    month:null,
    ft:'',
    pt:'',
    suggestions:[],
    profiles:[],
    selectedDate: new Date(),
    months:[],
    ptft:[],
    pt:false,
    ft:false,
  };
   months=[{"month":'1'},{"month":'2'},{"month":'3'},{"month":'4'},{"month":'6'},
{"month":'12'},{"month":'24'},{"month":'36'}]
  componentWillMount()
  {let rows=suggestions.map(suggestion => ({
    value: suggestion.name,
    label: suggestion.name,
  }));
   this.setState({suggestions:rows})
   console.log(this.state.rows)
   let uprofiles=profiles.map(profile => ({
    value: profile.profile,
    label: profile.profile,
  }));
   this.setState({profiles:uprofiles})

   let months=this.months.map(month => ({
    value: month.month,
    label: month.month,
  }));
   this.setState({months})

  }

  arrayRemove(arr, value) {

    return arr.filter(function(ele){
        return ele != value;
    });

 }
  handleChangeChk=async(event)=>{
    if(event.target.value=='Part Time')
      {this.setState({pt:event.target.checked})

      }
    else if(event.target.value=='Full Time')
      {this.setState({ft:event.target.checked})

      }

      if(event.target.checked)
      {
       await this.state.ptft.push(event.target.value)}
       else{
       let x=this.arrayRemove(this.state.ptft,event.target.value)
       await this.setState({ptft:x})
      }



  }
  queryBuilder=(para,field)=>{
    let cat='';
    if(para!=null)
   { if(para.length>0){
     cat=field+" like '%"
     para.map((item,index)=>{

      cat=cat+item.value+"%'"
      if(index<para.length-1)
      cat=cat+" or  "+field+" like '%"

   })
  }
  }
  return cat
  }

  queryBuilder_1=(para,field)=>{
    let cat='';
    if(para.length>0)
   { cat=field+" like '%"
     para.map((item,index)=>{
      if(item=="Part Time")
      cat=cat+'Yes'+"%'"
      else
      cat=cat+'NO'+"%'"
      if(index<para.length-1)
      cat=cat+" or '%"

   })
  }
  return cat
  }
  filterClick=()=>{

   let cat=this.queryBuilder(this.state.category,"primaryprofile")

   let loc=this.queryBuilder(this.state.location,"internlocations")

   let month=this.queryBuilder(this.state.month,"intershipduration")

   let id=new Date(this.state.selectedDate)
   let td=id.getFullYear()+"-"+(id.getMonth()+1)+"-"+id.getDate()

   let parttime=this.queryBuilder_1(this.state.ptft,"isparttime")



    let body={category:cat,
    location:loc,
    startfrom:td,
    maxmonth:month,
    ptft:parttime,
   }
this.props.filterQuery(body)
//this.setState({category:'',location:'',intershipduration:'',isparttime:''})
  }
  handleChange = name => value => {
    this.setState({
      [name]: value,
    });
  };
  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };
  searchDiv=(classesx,selectStyles)=>{
    const { classes } = this.props;
    return (


      <Card className={classes.card}>
                    <CardHeader

                      subheader='Filter Your Choice of Internship'
                      titleTypographyProps={{ align: 'center' }}
                      subheaderTypographyProps={{ align: 'center' }}

                      className={classes.cardHeader}
                    />
                    <CardContent>
                    <Grid container spacing={12} className={classes.gridstyle}>


       <Grid item xs={12} className={classes.gridstyle}>

       <Select
            classes={classesx}
            styles={selectStyles}
            textFieldProps={{
              label: '',
              InputLabelProps: {
                shrink: true,
              },
            }}
            options={this.state.suggestions}
            components={components}
            value={this.state.location}
            onChange={this.handleChange('location')}
            placeholder=" Select Location"
            isMulti
          />

             </Grid>
             <Grid item xs={12} className={classes.gridstyle}>
             <Select
            classes={classesx}
            styles={selectStyles}
            textFieldProps={{
              label: '',
              InputLabelProps: {
                shrink: true,
              },
            }}
            options={this.state.profiles}
            components={components}
            value={this.state.category}
            onChange={this.handleChange('category')}
            placeholder=" Select Category"
            isMulti
          />
          </Grid>


        <Grid item xs={12} className={classes.gridstyle}>
        <Typography style={{paddingTop:'10px'}}><b>Start from (or after):</b></Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            margin="normal"

            value={this.state.selectedDate}
            onChange={this.handleDateChange}
            variant="outlined"
            style={{marginTop:'0px',width:'330px'}}
          />
            </MuiPickersUtilsProvider>
        </Grid>


        <Grid item xs={12} className={classes.gridstyle}>
             <Select
            classes={classesx}
            styles={selectStyles}
            textFieldProps={{
              label: '',
              InputLabelProps: {
                shrink: true,
              },
            }}
            options={this.state.months}
            components={components}
            value={this.state.month}
            onChange={this.handleChange('month')}
            placeholder=" Max Duration (Months)"
            isMulti
          />
          </Grid>
          <Grid item xs={12} className={classes.gridstyle}>
           <FormControl component="fieldset" className={classes.formControlChk}>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox onChange={this.handleChangeChk} checked={this.state.pt}  value="Part Time" />
              }
              label="Part Time"
            />

            <FormControlLabel style={{marginTop:'-20px'}}
              control={
                <Checkbox  onChange={this.handleChangeChk} checked={this.state.ft}  value="Full Time"  />
              }
              label="Full Time"
            />

          </FormGroup>

        </FormControl>
           </Grid>

          </Grid>

                    </CardContent>
                    <CardActions >
                      <Button fullWidth variant='outlined' color="primary"  className={classes.searchitems} onClick={this.filterClick}>
                        Search
                      </Button>
                    </CardActions>
                  </Card>




     )

    }
  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    };


    return (
      <div className={classes.root}>
        <NoSsr>
         { this.searchDiv(classes,selectStyles)}


        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
