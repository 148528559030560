import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import clsx from 'clsx';
import { Paper, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';

import {  postData, getData } from '../../../FetchService';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		width: '80%'
		//marginBottom: '10px'
		//marginLeft: '50px'
	},
	input: {
		display: 'none'
	},
	root: {
		// background: '#dff9fb',
		padding: '20px',
		marginTop: '50px',
		marginBottom: '50px',
		width: '70%'
	},
	title: {
		fontSize: 24,
		textAlign: 'center'
	},
	formLabel: {
		marginTop: '10px'
	},
	button: {
		marginTop: '10px'
	}
}));

export default function CourseUpload() {
	const classes = useStyles();

	const [ getMessage, setMessage ] = useState('');
	const [ getLearn, setLearn ] = useState('');
	const [ getCourse, setCourse ] = useState('');
	const [ getCourseList, setCourseList ] = useState([]);
	const [ getArticle, setArticle ] = useState('');
	const [ getDescription, setDescription ] = useState('');
	const [ getRequirements, setRequirements ] = useState('');
	const [ getExcersize, setExcersize ] = useState('');

	const handleSubmit = async () => {
		if (
			getCourse !== '' &&
			getArticle !== '' &&
			getExcersize !== '' &&
			getLearn !== '' &&
			getDescription !== '' &&
			getRequirements !== ''
		) {
			let body1 = {
				courseId: getCourse
			};
			const result = await postData('coursedetails/checkCourse', body1);
			console.log('result ', result);

			if (result.result) {
				setMessage('Course Already Existed.....');
			} else {
				var body = {
					courseId: getCourse,
					article: getArticle,
					excersize: getExcersize,
					whatWillLearn: getLearn,
					description: getDescription,
					requirements: getRequirements
				};
				console.log('body data', body);
				const result = await postData('coursedetails/addCourse', body);
				console.log(result);
				
				if (result.result === true) {
					setMessage('Record Submitted.....');
								setCourse('');
								setArticle('');
								setExcersize('');
								setRequirements('');
								setLearn('');
								setDescription('');
				} else {
					setMessage('Not Submitted..........');
				}
			}
		} else {
			setMessage('First Fill All The Fields .....');
		}
	};
	const fillCourse = async () => {
		const result = await getData('course/fetchdata');
		setCourseList(result);
	};

	useEffect(() => {
		fillCourse();
	}, []);

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			<Paper className={classes.root}>
				<Typography variant="button" display="block" gutterBottom className={classes.title}>
					Course Upload
				</Typography>
				<React.Fragment>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel fullWidth id="demo-simple-select-label">Course</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select" 
									fullWidth
									value={getCourse}
									onChange={(event) => setCourse(event.target.value)}
								>
									{getCourseList.map((item) => {
										return <MenuItem fullWidth value={`${item.courseid}`}>{item.coursename}</MenuItem>;
									})}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Number of Articles"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								value={getArticle}
								fullWidth
								onChange={(event) => setArticle(event.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								id="standard dense"
								label="Number of Coding Exercises"
								className={clsx(classes.textField, classes.dense)}
								margin="dense"
								value={getExcersize}
								fullWidth
								onChange={(event) => setExcersize(event.target.value)}
							/>
						</Grid>

						<Grid item xs={12}>
							<Typography variant="button" display="block" gutterBottom>
								What will learn ? (use # before next line)
							</Typography>
							<ReactQuill value={getLearn} height={100} onChange={(value) => setLearn(value)} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="button" display="block" gutterBottom>
								Course Description
							</Typography>
							<ReactQuill
								value={getDescription}
								height={100}
								onChange={(value) => setDescription(value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="button" display="block" gutterBottom>
								Requirements (use # before next line)
							</Typography>
							<ReactQuill
								value={getRequirements}
								height={100}
								onChange={(value) => setRequirements(value)}
							/>
						</Grid>

						<Grid item xs={6}>
						<Button
								variant="contained"
								component="span"
								color=
								{'primary'}
								className={classes.button}
								onClick={handleSubmit}
							>
								Submit
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								onClick={() => {
								setCourse('');
								setArticle('');
								setExcersize('');
								setRequirements('');
								setLearn('');
								setDescription('');
								setMessage('');}}
							>
								Reset
							</Button>
						</Grid>
					</Grid>
					{getMessage}
				</React.Fragment>
			</Paper>
		</div>
	);
}
