/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/**
 * @author vishal.jain
 * @fileoverview to verify or deverify the user
 */

import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { getData } from "../../FetchService";
import { Button } from "@material-ui/core";

export default function DisplayUser(props) {
  const [stateCol] = React.useState({
    columns: [
      { title: "ID", field: "user_id" },
      { title: "First Name", field: "first_name" },
      { title: "Last Name", field: "last_name" },
      { title: "Email", field: "email" },
      { title: "Mobile", field: "mobile" },
      { title: "Status", field: "status" },
    ],
  });
  const [state, setState] = React.useState({
    data: [],
  });

  const [status, setStatus] = React.useState("");

  const readAllRecords = async (ss) => {
    var list = await getData(`userinterface/displaycheck/${ss}`);
    setState({ data: list });
  };
  useEffect(() => {
    setStatus(props.location.state.status);
    readAllRecords(props.location.state.status);
  }, [props.location.state.status]);

  const handleStatus = async (oldData) => {
    let ss = oldData.status == "Not Verified" ? "Verified" : "Not Verified";
    let result = await getData(
      `userinterface/status/${oldData.email}/${oldData.token}/${ss}`
    );
    readAllRecords(status);
  };

  const View = () => {
    return (
      <MaterialTable
        title={`${status} User`}
        columns={stateCol.columns}
        data={state.data}
        editable={{
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                const data = [...state.data];
                data.splice(data.indexOf(oldData), 1);
                setState({ ...state, data });
                handleStatus(oldData);
              }, 600);
            }),
        }}
        icons={{
          Delete: (oldData) => (
            <Button variant="contained" color="primary">
              {status == "Verified" ? "Unverify" : "Verify"}
            </Button>
          ),
        }}
        localization={{
          body: {
            editRow: {
              deleteText: `Are you sure you want to ${
                status == "Verified" ? "Unverify" : "Verify"
              } this user?`,
            },
            deleteTooltip: "Edit Status"
          },
            
        }}
        options={{
          exportButton: true,
          actionsColumnIndex: -1,
        }}
      />
    );
  };

  return <div> {View()} </div>;
}
