/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
// import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
import {ClientURL} from '../FetchService';
import IconButton from '@material-ui/core/IconButton';
// import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import ListItems from './listItems';
import Clock from 'react-live-clock';
import Avatar from '@material-ui/core/Avatar';



const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },

  toolbarIconLogo: {
    alignItems: 'right',
    paddingLeft: 10,


  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',

    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,

    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 60,

  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  avatar: {
    margin: 0,
  },
  bigAvatar: {
    margin: 0,
    width: 40,
    height: 40,
  },
});

class Dashboard extends React.Component {
  state = {
    open: true,
    opt:''
  };
 adata='';

  componentWillMount() {

    if (!localStorage.getItem("admin")) {
       console.log("-==-=-=-=-=-=-=--");
      this.props.history.replace("/SignIn");
    }
  }
   changeOption=(opt)=>{

    this.setState({opt});
   }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;


     this.adata=JSON.parse(localStorage.getItem("admin"));


    return (
      <div className={classes.root}>

        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>

            <IconButton onClick={this.handleDrawerClose}>
            <img src={`/images/cssbig.png`}></img>
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden,
              )}
            >



              <MenuIcon  />
              </IconButton>

          </div>


          <List><ListItems  action={this.changeOption} history={this.props.history} /></List>


        </Drawer>
        <main className={classes.content}>
       <table cellspacing='10' width='100%'>
         <tr>
          <td>

        <Clock format="dddd, MMMM Do, YYYY"/>
        </td>
        <td>

      <Clock format="HH:mm:ss A" ticking={true} interval={1000} />
      </td>

        <td>
         {this.adata.adminname}
         </td>
        <td>
        <Avatar alt="Not AVL" src={`/images/${this.adata.picture}`} className={classes.bigAvatar} />

         </td>
         </tr>
         </table>
            <div style={{
                width: '100%',
                margin: '0 auto',
                height:'auto'
            }} >
            {this.state.opt}
            </div>
            

        </main>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
