import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";

import UserLogin from "../userinterface/UserLogin";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import { getData, BaseURL } from "../FetchService";
import { postData } from "../FetchService";

import "./theme.css";

const styles = {
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "50px",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    maxWidth: 345,
    marginRight: "25px",
    marginTop: "15px",
  },
  media: {
    height: "100%",

    width: "100%",
  },
  orangeAvatar: {
    margin: 1,
    color: "#fff",
    backgroundColor: deepOrange[500],
    width: 60,
    height: 60,
    marginLeft: 80,
  },
};

class ViewSet extends React.Component {
  i = 0;

  componentDidMount() {
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = async (setorg, record) => {
    console.log("RECORD...", record);
    // let body={id:id,oid:oid}
    // this.props.history.replace({pathname:`/TakeTest`,data:body});
    // this.props.history.replace({pathname:`/UserLogin`,data:body});
    console.log("cart:" + record.setorg);
    if (!localStorage.getItem("user")) {
      if (record.status == "Paid") this.props.cartaction("USERLOGIN", record);
      else this.props.cartaction("USERLOGINDEMO", record);
    } else {
      let u = JSON.parse(localStorage.getItem("user"));
      let body = { userid: u.email, setorg: record.setorg };

      const result = await postData(`purchase/checkuserpurchase`, body);
      if (!result.RESULT) {
        if (record.status == "Paid") {
          await localStorage.setItem(setorg, JSON.stringify(record));
          this.props.cartaction("BADAGE", record);
        } else {
          this.addnewrecord(record);
        }
      } else {
        alert("This Set/Test is Already Exist in Your Account.....");
      }
    }
  };

  addnewrecord = async (record) => {
    const result1 = await postData(`purchase/generateorderno`);
    if (result1) {
      // const {maincategoryid,maincategory,icon}=result[0];
      //    let data=JSON.stringify(result[0]);
      let data = result1[0];
      let c = "";
      let cd = new Date();
      let u = JSON.parse(localStorage.getItem("user"));
      //alert(u.email)
      if (data.orderno == null) {
        c =
          "#1-" +
          u.user_id +
          "-" +
          cd.getDate() +
          (cd.getMonth() + 1) +
          cd.getYear() +
          "-" +
          cd.getHours() +
          cd.getMinutes();
      } else {
        let sn = data.orderno + 1;

        c =
          "#" +
          sn +
          "-" +
          u.user_id +
          "-" +
          cd.getDate() +
          (cd.getMonth() + 1) +
          cd.getYear() +
          "-" +
          cd.getHours() +
          cd.getMinutes();
      }

      try {
        let body = {
          amount: 0,
          itempurchased: record.setorg,
          orderno: c,
          emailid: u.email,
          purchasedate:
            cd.getFullYear() + "/" + (cd.getMonth() + 1) + "/" + cd.getDate(),
          purchasetime: cd.getHours() + ":" + cd.getMinutes(),
          status: "Take Test",
        };

        const result = await postData("purchase/addnewrecorddemo", body);

        if (result.RESULT) {
          alert(
            "This Demo Paper is Added in Your Account..Go in Your Account Section For a Test..."
          );
          this.setState({ msg: "Record Submited....", duration: "" });
        } else {
          this.setState({ msg: "Fail to Submit Record...." });
          alert("Server Error\nTry Again");
        }
      } catch (e) {
        alert(e);
        console.log("purchase:", e);
      }
    }
  };

  ImgMediaCard(classes) {
    return this.props.userview.listset.map((item, index) => {
      let url = `${BaseURL}/images/${item.organizationicon}`;
      let bodycart = {
        setno: item.setno,
        setorg: item.setorg,
        organizationname: item.organizationname,
        organizationid: item.organizationid,
        price: item.price,
        duration: item.duration,
        status: item.paidstatus,
      };
      console.log("BC:", bodycart);
      let price = "";
      let status = "";
      if (item.paidstatus == "Paid") {
        status = "Add to Cart";
        price = item.price;
      } else {
        status = "Take Demo";
        price = "0";
      }

      return (
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia
              component="img"
              className={classes.media}
              image={url}
              title={item.setorg}
            />
            <CardContent>
              <h1 className={"primaryhead"}>Paper Set: {item.setorg}</h1>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <table>
              <tr>
                <td align="left">
                  <Button
                    size="small"
                    id={item.setno}
                    onClick={() => this.handleClick(item.setorg, bodycart)}
                  >
                    <h1 className={"cartfont"}>{status}</h1>
                  </Button>
                </td>
                <td align="right">
                  <Avatar className={classes.orangeAvatar}>
                    {" "}
                    {price} <span>&#8377;</span>{" "}
                  </Avatar>
                </td>
              </tr>
            </table>
          </CardActions>
        </Card>
      );
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.root}>{this.ImgMediaCard(classes)}</div>
      </div>
    );
  }
}
ViewSet.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewSet);
