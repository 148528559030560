import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { postDataAndImage, BaseURL } from "../FetchService";

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getData } from "../FetchService"
import { postData } from "../FetchService"
import Avatar from '@material-ui/core/Avatar';
import renderHTML from 'react-render-html';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

 
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
   
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  formControl: {
     minWidth: 400,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  input: {
    display: 'none',
 
  },
  iconSmall: {
    fontSize: 20,
  },
  rootradio: {
    display: 'flex',
  },
  formControlradio: {
    margin: theme.spacing.unit * 1,
  },
  group: {
    margin:0,
  },
 
});
class AddNewRecord extends React.Component {
  
    state = {
        transactionid: "",
        setno:"",
        setorg:"",
        organization:"",
         organizationid:"",
         
         duration:'',
         price:'',
         paperstatus:'',
         icon:"",
         value: 'Paid',
         msg: "",
         list: [],
         
         
       };

       
  valid=()=>{
      
    let err1=this.state.organizationid.length!=0?'':'Pls Select Organization...';
    
     let err2=this.state.duration.length!=0?'':'Pls Set Duration for Set Number...';
    
     
     if(err1!='' || err2!='')
     {let errm=`<font color='Blue'><b>Errors</b>:<br></Font><font color=Red>${err1}<br>${err2}</font>`;
     this.setState({msg:errm})
    
       return(false);} 
     else    
     {
       return(true)
     }

    }
  constructor(props){
    super(props)
 
    this.handleChange = this.handleChange.bind(this)
     
  }
 

  handleChangeRadio = event => {
    this.setState({ value: event.target.value });
  };
   

  handleChange=async(event)=> {
    this.setState({organizationid: event.target.value })
    const body={cid:event.target.value};
    const result = await postData(`organization/fetchbyid`,body);
    if(result)
    {  // const {maincategoryid,maincategory,icon}=result[0];
      //    let data=JSON.stringify(result[0]);   
      let data= result[0];
         
          console.log(data.icon);
   
    this.setState({organization:data.organization}); 

    let url=`${BaseURL}/images/${data.icon}`;
   
    this.setState({icon:url});  
    
      
    
    }
    const result1 = await postData(`examset/generatesetno`,body);
    if(result1)
    {  // const {maincategoryid,maincategory,icon}=result[0];
      //    let data=JSON.stringify(result[0]);   
      let data= result1[0];
         if(data.setno==null){
           
            this.setState({setorg:`#${this.state.organization}-1`}); 
            this.setState({setno:'1'});
           
         }
         else{
              let sn=data.setno+1;
            this.setState({setorg:`#${this.state.organization}-${sn}`}); 
            this.setState({setno:sn});
         }
          
   
    

      
    
    }

  }
 
  async componentDidMount() {
        
    const list = await getData(`organization/fetchallorganization`);
    console.log(`Data ${list}`)
    this.setState({ list });
    console.log(`Data ${this.state.list}`)
  }

  fillOrganization= () => {
        
    return this.state.list.map((item, index) => {
         return (
          <MenuItem value={item.organizationid}>{item.organization}</MenuItem>  
         );
    })
}  

addrecord =async()=> {
     if(this.valid()){
    try {
  
        const body={organizationid:this.state.organizationid,
        setno:this.state.setno,
        setorg:this.state.setorg,
        duration:this.state.duration,
        paidstatus:this.state.value,
        price:this.state.price,
        paperstatus:this.state.paperstatus

        };
  
      const result = await postData("examset/addnewrecord", body);
     alert(result.RESULT);
      if(result.RESULT) {
        this.setState({msg:'Record Submited....',duration:''});     
      } else {
        this.setState({msg:'Fail to Submit Record....'});     
        alert('Server Error\nTry Again');
      }
    } catch (e) {
      console.log("SubCategory:", e);
    }
  }};
  

 showComponents(classes) {
    
    
  return (
      
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Add Exam Set
      </Typography>
      
      
      <Grid container spacing={24}>
        <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="demo-controlled-open-select">Select Organization To Generate Set Number</InputLabel>
          <Select
              value={this.state.organizationid}
             
             onChange={this.handleChange}
            inputProps={{
              name: 'organizationid',
              id: 'demo-controlled-open-select',
              
            }}
          >
          <MenuItem value="">
              <em>Organization</em>
            </MenuItem>
           
          {this.fillOrganization()}
    
          </Select>
        </FormControl>
        </Grid>
       

        
        <Grid item xs={12}>
          <TextField
            required
            id="organization"
            name="organization"
            label="Organization Name"
            
            fullWidth
            autoComplete="organization"
            value={this.state.organization}
            onChange={e => this.setState({ organization: e.target.value })}
            
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="setorg"
            name="setorg"
            label="Set Number"
            
            fullWidth
            autoComplete="setorg"
            value={this.state.setorg}
            onChange={e => this.setState({ setorg: e.target.value })}
            
          />
        </Grid>


        <Grid item xs={12}>
          <TextField
            required
            id="duration"
            name="duration"
            label="Duration"
            
            fullWidth
            autoComplete="duration"
            value={this.state.duration}
            onChange={e => this.setState({ duration: e.target.value })}
            
          />
        </Grid>

        <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="ps-simple">Paper Type</InputLabel>
          <Select
            value={this.state.paperstatus}
            onChange={event=>{this.setState({paperstatus:event.target.value})}}
            inputProps={{
              name: 'ps',
              id: 'ps-simple',
            }}
          >
          
            <MenuItem value='Placement Papers'>Placement Papers</MenuItem>
            <MenuItem value='Training Papers'>Training Papers</MenuItem>
            
          </Select>
        </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="price"
            name="price"
            label="Price"
            
            fullWidth
            autoComplete="price"
            value={this.state.price}
            onChange={e => this.setState({ price: e.target.value})}
            
          />
        </Grid>

        <Grid item xs={12}  sm={6}>
        
        <FormControl component="fieldset" className={classes.formControlradio}>
          <FormLabel component="legend">Paid Status</FormLabel>
          <RadioGroup
            aria-label="Gender"
            name="gender1"
            className={classes.group}
            value={this.state.value}
            onChange={this.handleChangeRadio}
          >
            <FormControlLabel value="Paid" control={<Radio />} label="Paid"  />
            <FormControlLabel value="Free" control={<Radio />} label="Free" />
            
          </RadioGroup>
        </FormControl>
       
        </Grid>
        



        <Grid item xs={12} sm={6}>
            
            <Avatar alt="Remy Sharp" src={this.state.icon} className={classes.bigAvatar} />
             
           
            </Grid>
        
       
        
      </Grid>
      <br/><br/>
      <Button variant="contained" size="small" className={classes.button}     onClick={() => this.addrecord()}>
        <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
        Save
      </Button>
    </React.Fragment>
  );
}
  render() {
    const { classes } = this.props;
    return (
      <div>
      <Grid container spacing={24}>
      <Grid item xs={12}  sm={6}>
<React.Fragment>

      
      <main className={classes.layout}>
       
        <Paper className={classes.paper}>
         
           <React.Fragment>

            {this.showComponents(classes)}
          </React.Fragment>
          </Paper>
          </main>
          </React.Fragment>

    </Grid>
    <Grid item xs={12}  sm={6}> 
          <React.Fragment>
          
          <main className={classes.layout}>
          <Paper  style={{marginLeft:'100px',width:'400px',height:'100px',marginTop:'47px'}}>
        
    
        <h5 align='center'>Messages:</h5> 
        <h6>  {renderHTML(this.state.msg)}</h6>
      
      
      </Paper>
          </main>
          </React.Fragment>
          
     </Grid>
     </Grid>
    </div>

    );}


}
AddNewRecord.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddNewRecord);
