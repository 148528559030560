import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import deepOrange from '@material-ui/core/colors/deepOrange'
import { postData, BaseURL } from "../FetchService"

import renderHTML from 'react-render-html'
import DisplayPaper from './DisplayPaper';
import ReviewTest from './ReviewTest';
import SummaryReport from './SummaryReport';


function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  orangeAvatar: {
    margin: 1,
    color: '#fff',
    backgroundColor: deepOrange[500],
    width: 80,
    height: 80,
    marginLeft:80,
  },
});

class ReviewTestTab extends React.Component {
    i=0

    state={
      list:[],
       value: 0,
       view:'',


    }





    async componentDidMount() {
    console.log('boooooooooody:'+this.props.body.setno)
    let u=JSON.parse(localStorage.getItem('user'));
    let bdy=this.props.body;
    const ubody={organizationid:bdy.organizationid,
      setno:bdy.setno,
      userid:bdy.userid,
    };
       this.setState({view:<SummaryReport ubody={ubody}/>})

     const body={
            cid:this.props.body.setno,
          oid:this.props.body.organizationid}

        const list = await postData(`questions/fetchexamcategoryforuser`,body);
        console.log(`Data ${list}`)
        this.setState({ list });
        this.setState({value:this.state.list.length})
        this.handleClick= this.handleClick.bind(this)
        this.handleChange= this.handleChange.bind(this)
    }

   ListTab() {

    let u=JSON.parse(localStorage.getItem('user'));

      return this.state.list.map((item, index) => {

          let urlicon=`${BaseURL}/images/${item.examicon}`

             let bdy=this.props.body;

             const bodytab={organizationid:bdy.organizationid,
              examcategoryid:item.examcategoryid,
            setno:bdy.setno,
            userid:bdy.userid,
            };

          let tabhead=`${item.examcategory} Report`;
  return (


  <Tab  onClick={() => this.handleClick(bodytab,index)}  label={tabhead} icon={<img src={urlicon} style={{ width: 20, height: 20 }}/>}/>
      );



    })

    }
    handleClickSummary = async(value) => {
      let u=JSON.parse(localStorage.getItem('user'));
      let bdy=this.props.body;
    const ubody={organizationid:bdy.organizationid,

    setno:bdy.setno,
    userid:bdy.userid,
    };
       this.setState({view:<SummaryReport ubody={ubody}/>})
       this.setState({ value });

    };

    handleClick = async(body,value) => {


      await  this.setState({view:<ReviewTest body={body} />})
   this.setState({value});//move blue line to next tab

    };



  handleChange = (event, value) => {
    console.log(event.currentTarget.value)
    alert(event)
    this.setState({ value });

  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    let summicon=`/images/summary.png`

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">

          <Tabs
            value={value}

            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
          {this.ListTab({value})}
          <Tab   label='Section Wise Report'  onClick={() => this.handleClickSummary(this.state.list.length)} icon={<img src={summicon} style={{ width: 20, height: 20 }}/>}/>
          </Tabs>


        </AppBar>
        <TabContainer>{this.state.view}</TabContainer>


      </div>
    );
  }
}

ReviewTestTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReviewTestTab);
