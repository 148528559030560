import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Footer1 from './Footer1'
const styles = theme => ({


  root:
  {
    marginTop:'1%',
    marginLeft:'10%',
    textAlign:'justify',
    marginRight:'10%'
  },
  hello:
  {
    marginTop:'0.5%',
    fontStyle:'calibri',
  },
hello1:{
  fontSize:'100%',
  marginTop:'0.5%',

},
hell:{
    fontSize:'160%',
    marginTop:'10%',
    marginLeft:'35%'

  },

hello2:{
  marginTop:'0.5%',


},
set:{
  marginTop:'1%'
},
set1:{
  marginTop:'2%',
  fontSize:'100%',
}
})


class OurServices extends React.Component {


 render() {
    const { classes } = this.props;

    return (
      <div>
      <div className={classes.root}>

<Typography className={classes.hell} style={{marginTop:''}}><b><u>Products & Services</u></b></Typography>
<p>
Campusshala offers following services to its users (Employers and Students).
</p>
<Typography className={classes.hello1}><b><u>For Companies:</u></b></Typography>
<p>
1.	Posting of Intership: The reputed organization which are looking to hire interns from different colleges for their specific rquirements.
 This service is free of cost.
</p>
<Typography className={classes.hello1}><b><u>For Students:</u></b></Typography>
<p>
2.	Searching of Internship: Student from all over India from different colleges can apply for intership.
This service is free of cost for all the student.</p>
<p>
3.	Online Trainings: Online training on various new technologies like NodeJS, ReactJS, Python with Machine learning etc.
are also provided on the platform of Campusshala.</p>
<p>
4.	Test & Certificate: Campusshala will conduct assessment test after the training and will provide certificate to all the participants after the clearance of the exam.
</p>
<p>
5.	Placement Preparation: Campusshala equipped with more than 500+ mock test which is designed and developed by the experts of campusshala for the students who are preparing for various companies like Amazon, TekSystem, TCS, Infosys,  IBM, Wipro, Accenture, Mindtree, Honeywell, etc.
Campusshala reserves the right to add/remove any product and service at its sole discretion or change the price charged for such offerings.

</p>
</div>

</div>
    );
  }

}
OurServices.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OurServices);
