import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
 
import { postDataAndImage, BaseURL } from "../FetchService";

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getData } from "../FetchService"
import { postData } from "../FetchService"
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
   
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  formControl: {
     minWidth: 150,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  iconSmall: {
    fontSize: 20,
  },
  avatar: {
    margin: 5,
  },
  bigAvatar: {
    margin: 5,
    width: 100,
    height: 100,
  },
});

class EditDelete extends Component {
    state = {
        organizationid:'',
        maincategoryid:'',
        subcategoryid:'' ,
        organization:'',
        
        icon:'',
        file: "",
        msg: "",
        list:[],
        listsub:[],
        show:false,
      };

      constructor(props){
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeIcon = this.handleChangeIcon.bind(this)
      }
    
      
       handleChangeIcon(event) {
         this.setState({
           file:event.target.files[0]});
         this.setState({icon:URL.createObjectURL(event.target.files[0])});
         this.setState({show:true});
     
       }
    
      handleChange=async(event)=> {
           this.setState({maincategoryid: event.target.value });
        this.setState({maincategoryid: event.target.value });
        const body={cid:event.target.value};
        const listsub = await postData(`subcategory/fetchsubcategories`,body);
        console.log(`Sub Data ${listsub}`)
      
        this.setState({ listsub });
        console.log(`Sub Data ${this.state.listsub}`)
       
    
      }

      async componentDidMount() {
let data= JSON.parse(this.props.location.state.result);
     console.log('................');
     console.log(data);
     this.setState({maincategoryid:data.maincategoryid});
    this.setState({subcategoryid:data.subcategoryid});
    this.setState({organizationid:data.organizationid});
    this.setState({organization:data.organization});
    
    this.setState({icon:data.icon});
    
    let url=`${BaseURL}/images/${data.icon}`;
    this.setState({icon:url});
    const list = await getData(`maincategory/fetchallmaincategories`);
    console.log(`Data ${list}`)
    this.setState({ list });
    console.log(`Data ${this.state.list}`);
    const body={cid:data.maincategoryid};
    const listsub = await postData(`subcategory/fetchsubcategories`,body);
    this.setState({ listsub });
     }

     fillMainCategory = () => {
        
      return this.state.list.map((item, index) => {
           
        
         
          return (
            <MenuItem value={item.maincategoryid}>{item.maincategory}</MenuItem>  
           );
      })
  }  

  fillSubCategory = () => {
        
    return this.state.listsub.map((item, index) => {
         
      
       
        return (
          <MenuItem value={item.subcategoryid}>{item.subcategoryname}</MenuItem>  
         );
    })
}  
  showHide=(classes)=>{
   let x=this.state.show;
  let k=x?<Button variant="contained" size="small" className={classes.button}    onClick={() => this.editicon()}><SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} /> Save image</Button>:'';
    return(k);
}

  handleChange(event) {
    this.setState({
      file:event.target.files[0]});
    this.setState({icon:URL.createObjectURL(event.target.files[0])});
    this.setState({show:true});

  }

  showComponents(classes) {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
        Edit/Delete  Organization Names
        </Typography>
        
        
        <Grid container spacing={24}>
          <Grid item xs={12}>
  
  
          
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-controlled-open-select">Main Category Id</InputLabel>
            <Select
               
              
               value={this.state.maincategoryid}
               onChange={this.handleChange}
              
              inputProps={{
                name: 'maincatrgoryid',
                id: 'demo-controlled-open-select0',
              }}
            >
            <MenuItem value="">
                <em>Main Category</em>
              </MenuItem>
             
            {this.fillMainCategory()}
      
            </Select>
          </FormControl>
          </Grid>
         
          <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="demo-controlled-open-select">Sub Category Id</InputLabel>
            <Select
               
              
               value={this.state.subcategoryid}
               onChange={e => this.setState({subcategoryid: e.target.value })}
              inputProps={{
                name: 'subcatrgoryid',
                id: 'demo-controlled-open-select',
              }}
            >
            <MenuItem value="">
                <em>Sub Category</em>
              </MenuItem>
             
            {this.fillSubCategory()}
      
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="organizationid"
              name="organizationid"
              label="Organization Id"
              
              fullWidth
              autoComplete="organizationid"
              value={this.state.organizationid}
              onChange={e => this.setState({ organizationid: e.target.value })}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="organization"
              name="organization"
              label="Organization"
              
              fullWidth
              autoComplete="organization"
              value={this.state.organization}
              onChange={e => this.setState({ organization: e.target.value })}
              
            />
          </Grid>
          <Grid item xs={12} sm={6}>
                Select Icon:<br/>
                <input
              accept="image/*"
              className={classes.input}
              id="icon"
          
              name="icon"
              type="file"
              onChange={this.handleChangeIcon}
             
            />
            <label htmlFor="icon">
      
      
            <Button variant="contained" component="span" className={classes.button}>
                Upload
                <CloudUploadIcon className={classes.rightIcon}  />
              </Button>
           
            </label>
          
              </Grid>
              <Grid item xs={12} sm={6}>
            <Avatar alt="Remy Sharp" src={this.state.icon} className={classes.bigAvatar} />
             
             {this.showHide(classes)}
            </Grid>
          </Grid>
          <br/><br/>
          <Button variant="contained" size="small" className={classes.button}     onClick={() => this.editrecord()}>
            <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
            Edit
          </Button>

           <Button variant="contained" size="small" className={classes.button}     onClick={() => this.deleterecord()}>
           <DeleteIcon className={classes.rightIcon} />
         Delete
          </Button>
      </React.Fragment>
    );
  }

     


     editicon =async()=> {
     
      try {
  
          const formData = new FormData();
          formData.append('organizationid',this.state.organizationid);
          formData.append('icon',this.state.file);
          const config = {
              headers: {
                  'content-type': 'multipart/form-data'
              }
          };
  
        const result = await  postDataAndImage("organization/editicon", formData,config);
       alert(result);
        if(result.RESULT) {
          this.setState({msg:'Picture Edit....'});    
          this.props.history.replace({pathname:`/DisplayAllOrg`}); 
        } else {
          this.setState({msg:'Fail to EDIT ICON...'});     
          alert('Server Error\nTry Again');
        }
      } catch (e) {
        console.log("MainCategory:", e);
      }
    };
  

  editrecord =async()=> {
     
    try {

        const body={
          maincategoryid:this.state.maincategoryid,
          subcategoryid:this.state.subcategoryid,
          organizationid:this.state.organizationid,
          organization:this.state.organization
        }; 

      const result = await postData("organization/editrecord", body);
     alert(result);
      if(result) {
        this.setState({msg:'Record Edited....'});  
        this.props.history.replace({pathname:`/DisplayAllOrg`});   
      } else {
        this.setState({msg:'Fail to EDIT Record.....'});   

        alert('Server Error\nTry Again');
      }
    } catch (e) {
      console.log("MainCategory:", e);
    }
  };

  deleterecord =async()=> {
     
    try {

        const body={
       
          organizationid:this.state.organizationid,
        }; 

      const result = await postData("organization/deleterecord", body);
     alert(result);
      if(result.RESULT) {
        this.setState({msg:'Record Deleted....'});  
        this.props.history.replace({pathname:`/DisplayAllOrg`});    
      } else {
        this.setState({msg:'Fail to Delete Record....'});     
        alert('Server Error\nTry Again');
      }
    } catch (e) {
      console.log("MainCategory:", e);
    }
  };






  render() {
     
    const { classes } = this.props;
    
    return (
      <div>
      <React.Fragment>
             
             
              <main className={classes.layout}>
                <Paper className={classes.paper}>
                  <Typography component="h1" variant="h4" align="center">
                  Campus-Shala
                  </Typography>
                  <React.Fragment>
      
                  {this.showComponents(classes)}
                  </React.Fragment>
                  </Paper>
                  </main>
                  </React.Fragment>
                  <React.Fragment>
                  
                  <main className={classes.layout}>
                <Paper className={classes.paper}>
                
            
              <Typography variant="h5" component="h4">
                {this.state.msg}
              </Typography>
              
              </Paper>
                  </main>
                  </React.Fragment>
               
             
            </div>
         );
  }
}
EditDelete.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditDelete);

