import React, { Component } from "react";
import UserView from "./component/userinterface/UserView";

class App extends Component {
  render() {
    return (
      <div>
        <UserView />
      </div>
    );
  }
}

export default App;
