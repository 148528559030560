import React, { Component } from 'react';
import './interncss/index.css';
import './interncss/App.css';
import Button from '@material-ui/core/Button';

import { postData,ClientURL } from '../FetchService';

import Typography from '@material-ui/core/Typography';



class Certificate extends Component {
    state={
        Certificateno:'',
        studentname:'',
        date: '',
        rows:[],
        course: '',
        per:-1
      }

     async componentWillMount(){
     /* let  body={uemail:this.props.body.email}
      let result= await postData('purchase/certificate')
      console.log('result',result)
      */
     let data= JSON.parse(this.props.location.state.result);
     const rows = await postData(`useranswer/fetchquestionanswersresultsummarycategorywise`,data);
    console.log("Cerr",rows)
     let per=(parseInt(rows[0].correctanswer)/parseInt(rows[0].totalquestion))*100
     alert(per)
     if(per>=60){
     this.setState({per})
     console.log(`Data ${this.state.rows}`)
     this.setState({Certificateno:data.transactionid})
    this.setState({studentname:data.name})
    this.setState({course:data.organizationname})
    var tempDate = new Date();

    var t= tempDate.getDate()  + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getFullYear()  ;
    this.setState({date:t})
    this.state = {

      /*     studentname:'SHUBHAM SONI',
       */
      /*     Certificateno:'2018/156/JDHDGSSNS',
       */    /* date:"5-29-2019", */
          teachername:"Mr. N. K. Jain",



          printsts:"rotate(-90deg)",
          btn:false


        }}
    else{
      this.setState({per})
    }

    }




finalprit=async()=>{
  await this.setState({
    printsts:"rotate(0deg)",
    btn:true
  })

  window.print();


}

  prit=()=>{

    this.setState({
      printsts:"rotate(0deg)",
      btn:true
    })

  }



  default=()=>{
    this.setState({
      printsts:"rotate(-90deg)",
      btn:false
    })

  }
 goToMain=()=>{
  this.props.history.replace({pathname:`/UserMainPage`});

 }

  render() {
    return ((this.state.per>=60 && this.state.per<=100)?(
   <div>

    <button hidden={this.state.btn} style={{marginTop:"-20%"}} onClick={this.finalprit}>Print Certificate</button>

<div onClick={this.default} class="book"  style={{transform:this.state.printsts}} >
    <div  class="page">

    <div style={{
borderRight:"6px solid #2571bf ",
height: "970px",
marginRight:"-50px"

    }}>


<img src="images/CAM.png" style={{

  transform: "rotate(90deg)",
  marginLeft:"-70px",
  marginTop:"70px",
 float:"right"

  }} />


<h1 style={{
float:"right",

writingMode: "vertical-rl",
marginTop:"300px",
marginRight:"-40px"


}} >CSEP CampusShala Education </h1>


<h5
style={{
  float:"right",
writingMode: "vertical-rl",
marginTop:"300px",
//marginRight:"-10px"

  }}

>Awarded to</h5>


<h1 style={{

float:"right",
writingMode: "vertical-rl",
marginTop:"300px",
color:"#2571bf",

}} >{this.state.studentname}</h1>



<h5
style={{
  float:"right",
writingMode: "vertical-rl",
marginTop:"300px",
marginRight:"30px"
  }}

>For successfully meeting criteria in the skill assessment on:</h5>


<h2 style={{

float:"right",
writingMode: "vertical-rl",
marginTop:"300px",
color:"#2571bf"
}} >{this.state.course}</h2>


<h6
style={{

  float:"right",
writingMode: "vertical-rl",
marginTop:"300px"

  }}

>{this.state.teachername}<br/>
{this.state.date}
</h6>


<text style={{

  float:"right",
writingMode: "vertical-rl",
marginTop:"300px",
  fontSize:"10px"
  }} >
    On behalf of CSEP Education Services
  </text>


  <img src="images/sing.png" style={{
  height:"70px",
  width:"70px",
  float:"right",
writingMode: "vertical-rl",
marginTop:"300px",
transform: "rotate(90deg)",

  }} />

<text style={{

  float:"right",
writingMode: "vertical-rl",
marginTop:"300px",
  fontSize:"10px"
  }} >
    Country Manager , CSPE PointText(Project Sevices)
  </text>




  <text style={{

  float:"right",
writingMode: "vertical-rl",
marginTop:"300px",
marginRight:"25px",
  fontSize:"10px",
  paddingRight:"40px"
  }} >
    Certificate No. :<font style={{color:"#2571bf"}}>{this.state.Certificateno}</font>
  </text>



  <text style={{

  float:"right",
writingMode: "vertical-rl",
marginTop:"300px",
marginRight:"5px",
  fontSize:"10px"
  }} >
    To verify the authenticity of this Certificate , Plese Visit<font style={{color:"#2571bf"}}> www.campusshala.com</font>
  </text>




<div style={{
borderLeft:"25px solid #2571bf",
height: "970px",
marginLeft:"-72px"
    }}></div>


</div>
    </div>

</div>
    </div>):(this.state.per>=0 && this.state.per<=59)?(<div><center><Typography component="h1" variant="h5">Sorry!You Have Not Passed the Exam Your Got {this.state.per}% Marks</Typography></center></div>):<div>{this.state.per}</div>
    );
  }
}

export default Certificate;
