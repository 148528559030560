import React from 'react';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { postData } from "../FetchService";
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import renderHTML from 'react-render-html';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import SideBar from '../userinterface/SideBar'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
 


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: '0px',
      bottomMargin:'0px',
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing.unit,
      },
     
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      group: {
        margin: `1px`,
      },
       
       
   
  });
  function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

class DisplayPaper extends React.Component {
 
 max=0;
    state = {
    list:[],
    selectedIndex:0,
    value: '',
    qa:[],
    statetab:true,
    userid:'',
    openr:false,
    
  };

  constructor(props){
    super(props)
    
    this.ToggleNext= this.ToggleNext.bind(this)
    this.TogglePrev=this.TogglePrev.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  

  valid=()=>{
      
    let err=this.state.value.length!=0?true:false;
    return err;
  }
  //update from 
  updateIndex(value)
  {  

    this.setState({selectedIndex:value})
   
  }
  

  async   componentDidMount() {
    let u=JSON.parse(localStorage.getItem('user'));
         this.setState({userid:u.email})
        if(this.props.body.body!=null)
       {
    const list = await postData(`questions/fetchquestionsforuser`,this.props.body.body);
    this.max=list.length;
   
     this.setState({ list });
    await  this.fillquestionsanswer();
   
   console.log(`Data ${this.state.list}`)
       }
       else{
        this.setState({ list:[] });

       }  
   }

   async   componentDidUpdate(prevProps,prevState) {
  
    if(prevProps.ts!=this.props.ts){
      console.log(prevProps+","+this.props.ts.selectedIndex)
      
     if(this.props.ts.status=='Side')
      {this.setState({selectedIndex:this.props.ts.selectedIndex})
      if(this.state.list.length>0)
      this.filterquestion(this.state.qa,this.state.list[this.props.ts.selectedIndex])
    }
    else if(this.props.ts.status=='Tab')
    {
      this.setState({selectedIndex:this.props.ts.selectedIndex})
    }
    
    }
    if(this.props.body.body!=prevProps.body.body)
    {
    if(this.props.body.body!=null)
    {
 const list = await postData(`questions/fetchquestionsforuser`,this.props.body.body);
 this.max=list.length;
  
 
 this.max=list.length;

  this.setState({ list });
   
  //this.setState({selectedIndex:0})
 await this.updateButtonStatus()
  
  await this.fillquestionsanswer();
 await this.filterquestion(this.state.qa,this.state.list[this.state.selectedIndex])
console.log(`Data ${this.state.list}`)
 
    }
    else{
     this.setState({ list:[] });
     

    }
   
  }
  


} 
//print buttons on left side
async updateButtonStatus()
{let bdy=this.props.body.body;
  const body={organizationid:bdy.oid,
   examcategoryid:bdy.ecid,
 setno:bdy.setno,
 userid:this.state.userid,
 };
  const qlist = await postData(`useranswer/fetchquestionanswerstatus`,body);
  this.props.action(qlist)
 }

async fillquestionsanswer()
{ let bdy=this.props.body.body;
  const body={organizationid:bdy.oid,
    examcategoryid:bdy.ecid,
  setno:bdy.setno,
  userid:this.state.userid,
 
   
  };
  const qa = await postData(`useranswer/fetchquestionanswer`,body);
  
 
   this.setState({ qa });

}



handleClickOpenR =  () => {
   
  this.setState({ openr: true });
};

handleCloseR = () => {
  this.setState({ openr: false });
};


alertInfoDialog(){
  const { fullScreen } = this.props;
 return(


  <div>
       
      <Dialog
        open={this.state.openr}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Alert...Alert..???"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
         Sorry Your Test Time is Over U Can't Submit Your Test Now....
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseR} color="primary">
           Close
          </Button>
           
        </DialogActions>
      </Dialog>
    </div>
);


}



handleChange = event => {
  //alert(event.target.value)
  this.setState({ value: event.target.value });
};


  async ToggleNext() {
    if(this.props.body.time==0)
    {this.handleClickOpenR()}
    else{
    if(this.state.selectedIndex == this.max - 1)
       { alert('This Category End Here...')
         return;}
     let k=this.state.selectedIndex;
     k++;
     
    await this.filterquestion(this.state.qa,this.state.list[k])
     await   this.setState({selectedIndex:k})
     this.props.click(k,'Toggle')
    }
    
}

async TogglePrev() {
  if(this.props.body.time==0)
  {this.handleClickOpenR()}
  else{
  if(this.state.selectedIndex == 0)
    {  alert('Stop Pressing Previous Button This is the Beganing...')
      
      return;}
     let k=this.state.selectedIndex;
     k--;
  
    await this.filterquestion(this.state.qa,this.state.list[k])
   
     await   this.setState({selectedIndex:k})
     this.props.click(k,'Toggle')
         
  }
    }
    filterquestion = (qa,item) => {
      
      const data={organizationid:item.organizationid,
        examcategoryid:item.examcategoryid,
      setno:item.setno,
      questionno:item.questionno,
      userid:this.state.userid,}
     const result = qa.filter((item) => {
           
        return (item.setno==data.setno && item.organizationid==data.organizationid && item.examcategoryid==data.examcategoryid && item.questionno==data.questionno?true:false)

      
      })
   
  
      if(result.length>0)
      { 
       console.log("result:"+result[0]['answer'])
       this.setState({value:result[0]['answer']})
       
     }
     else{
      this.setState({value:''})

     }
    }

 ShowQuestion(classes) {
   console.log('INdex.........'+this.state.selectedIndex)
  
 if(this.state.list.length>0 && this.state.selectedIndex <= this.max - 1)
 {
  let items=this.state.list[this.state.selectedIndex];
   
 let opt1=`<table cellspacing=10><tr align='left' valign='baseline'><td><b>A:</b></td><td> ${items.option1}</td></tr></table>`
 let opt2=`<table cellspacing=10><tr align='left' valign='baseline'><td><b>B:</b></td><td> ${items.option2}</td></tr></table>`
 let opt3=`<table cellspacing=10><tr align='left' valign='baseline'><td><b>C:</b></td><td> ${items.option3}</td></tr></table>`
 let opt4=`<table cellspacing=10><tr align='left' valign='baseline'><td><b>D:</b></td><td> ${items.option4}</td></tr></table>`
  
  
 
    return (
      <div  className={classes.root}>
 
      


<Paper ><table ><tr valign='baseline'><td><b>{items.questionno}</b>:</td><td>{ renderHTML(items.question)}</td></tr></table></Paper>
<br></br>
<FormControl component="fieldset" >
<FormLabel component="legend">Select Any One From Choice:</FormLabel>
          <RadioGroup
            aria-label="options"
            name="options"
            className={classes.group}
            value={this.state.value}
            onChange={this.handleChange}
          >        

  
          
        <FormControlLabel
              value="A"
              control={<Radio color="primary" />}
              label={renderHTML(opt1)}
              
            /> 
        <FormControlLabel
              value="B"
              control={<Radio color="primary" />}
              label={renderHTML(opt2)}
              
            /> 
        <FormControlLabel
              value="C"
              control={<Radio color="primary" />}
              label={renderHTML(opt3)}
             
              
            /> 
         <FormControlLabel
              value="D"
              control={<Radio color="primary" />}
              label={renderHTML(opt4)}
            
               
            /> 
          
            </RadioGroup>
        </FormControl><br/>
        <Button variant="contained" color="secondary" className={classes.button} onClick={this.ReviewClick}>
       Review
        {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
        <CloudUploadIcon className={classes.rightIcon} />
      </Button>
      <Button variant="contained"  className={classes.button} onClick={this.TogglePrev}>
      <ArrowLeftIcon color="primary" className={classes.rightIcon} />
     Previous
        {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
        
      </Button>
      <Button variant="contained" color="primary" className={classes.button} onClick={this.addrecord}>
       Submit
        {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
        <CloudUploadIcon className={classes.rightIcon} />
      </Button>
      
      <Button variant="contained"  className={classes.button} onClick={this.ToggleNext}>
      Next
        {/* This Button uses a Font Icon, see the installation instructions in the docs. */}
        <ArrowRightIcon color="primary" className={classes.rightIcon} />
      </Button>
      
      {this.alertInfoDialog()}
        
      </div>
    );}}
    addrecord =async()=> {
      if(this.props.body.time==0)
      {this.handleClickOpenR()}
      else{
       if(this.valid()){
      try {
    
        let items=this.state.list[this.state.selectedIndex] 
        var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();
         let cd=year+"/"+month+"/"+date
        var h= new Date().getHours();
        var m = new Date().getMinutes();
        var s = new Date().getSeconds();
         let ct=h+":"+m+":"+s
         let bdy=this.props.body.body;
          const body={organizationid:bdy.oid,
            examcategoryid:bdy.ecid,
          setno:bdy.setno,
          userid:this.state.userid,
          examdate:cd,
          examtime:ct,
          questionno:items.questionno,
          answer:this.state.value,
          statusquestion:'submit'          
          };
    
        const result = await postData("useranswer/addnewrecord", body);
       //alert(result);
        if(result) {
          this.setState({msg:'Record Submited....'});   
         // this.setState({value:''});
        await this.fillquestionsanswer()
         await this.updateButtonStatus()
          
        } else {
          this.setState({msg:'Fail to Add Question Pls Check all Entries Carefully...'});     
          alert('Server Error\nTry Again');
        }
      } catch (e) {
        console.log("SubCategory:", e);
      }
    }
    else
    {
     alert('Pls Select Ur Answer dear..')


    }}
    };
    
    ReviewClick =async()=> {
      //console.log(this.valid())
      if(this.props.body.time==0)
      {this.handleClickOpenR()}
      else{
       if(this.valid()){
      try {
    
        let items=this.state.list[this.state.selectedIndex] 
        var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();
         let cd=year+"/"+month+"/"+date
        var h= new Date().getHours();
        var m = new Date().getMinutes();
        var s = new Date().getSeconds();
         let ct=h+":"+m+":"+s
         let bdy=this.props.body.body;
          const body={organizationid:bdy.oid,
            examcategoryid:bdy.ecid,
          setno:bdy.setno,
          userid:this.state.userid,
          examdate:cd,
          examtime:ct,
          questionno:items.questionno,
          answer:this.state.value,
          statusquestion:'review'
           
           
          };
    
        const result = await postData("useranswer/addnewrecord", body);
      // alert(result);
        if(result) {
          this.setState({msg:'Record Submited....'});  
      //    this.setState({value:''}); 
         await this.fillquestionsanswer()
          await this.updateButtonStatus()
          
        } else {
          this.setState({msg:'Fail to Add Question Pls Check all Entries Carefully...'});     
          alert('Server Error\nTry Again');
        }
      } catch (e) {
        console.log("SubCategory:", e);
      }
    }
    else
    {
     alert('Pls Select Ur Answer dear..')

    }
  }
    };
    
  render() {
    const { classes } = this.props;
   
    return (
           <div>
             {this.ShowQuestion(classes)}
            
             
             
          </div>
         );
  }
}
DisplayPaper.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
  export default withStyles(styles)(DisplayPaper);