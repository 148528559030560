import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TermsCondition  from './TermsCondition'
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import Privacy from './Privacy';
import OurServices from './OurServices';
import { Card, CardContent, withTheme, Typography, Toolbar, Paper, MenuItem, CardActionArea } from '@material-ui/core';
 
import Aboutus from './Aboutus'
 

const styles = theme => ({
    footer:{
        position:'fixed',
        width:'100%',
        left:0,
        bottom:0,
        backgroundColor:'#263475',
        color:'white',
      },
      

})

class Footer1 extends React.Component {
    state = {
        activeStep: 0,
      };
 render() { 
     const { classes } = this.props;
 return (<div>      
      
<Router>
  
   <Route path='/TermsCondition' exact strict component={TermsCondition} history={this.props.history} ></Route>
    <Route path='/Privacy' exact strict component={Privacy} history={this.props.history} ></Route>
    <Route path='/OurServices' exact strict component={OurServices} history={this.props.history} ></Route>
    <Route path='/Aboutus' exact strict component={Aboutus} history={this.props.history} ></Route>
    <div style="height:5px; background-color:#06c;">
     <footer style={{backgroundColor:'#263475'}}
    
  className={classes.footer}
    >  
              <Typography  style={{ color:'white',fontSize:'20px'}}>AboutUs</Typography>
             <ul style={{listStyle:'none'}}>
              <li><Link to="/Aboutus" style={{textDecorationLine:'none',color:'white',fontSize:'15px'}}>About Us</Link></li>
              <li><Link to="/TermsCondition" style={{textDecorationLine:'none',color:'white' ,fontSize:'15px'}} >Terms & Conditions</Link></li>
              <li><Link to="/OurServices" style={{textDecorationLine:'none',color:'white',fontSize:'15px'}}>OurServices</Link></li>
              <li><Link to="/Privacy" style={{textDecorationLine:'none',color:'white',fontSize:'15px'}}>Privacy</Link></li>
            </ul>
          
      
        
        
      </footer>
      </div>
    </Router>
    </div>);
} 
}
    Footer1.propTypes = {
        classes: PropTypes.object.isRequired,
    
      };
      
      export default withStyles(styles)(Footer1);
    