import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { postDataAndImage } from "../FetchService";

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
 
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getData } from "../FetchService"
import { postData } from "../FetchService"
import Avatar from '@material-ui/core/Avatar';
import renderHTML from 'react-render-html';
 
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
   // marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
     // marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
   
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  formControl: {
     minWidth: 150,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  iconSmall: {
    fontSize: 20,
  },
  avatar: {
    margin: 5,
  },
  bigAvatar: {
    margin: 5,
    width: 100,
    height: 100,
  },
});
class AddNewRecord extends React.Component {
  state = {
    maincategoryid:'',  
    organizationid:'',
    subcategoryid: "",
    organization:"",
    icon:"",
    file: "",
    msg: "",
    list: [],
    listsub:[]
    
  };

  constructor(props){
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeIcon = this.handleChangeIcon.bind(this)
  }

  valid=()=>{
      
    let err1=this.state.maincategoryid.length!=0?'':'Pls Select Main Category...';
     let err2=this.state.subcategoryid.length!=0?'':'Pls Select Main Category...';
     let err3=this.state.file.length!=0?'':'Pls Choose Icon...';
     let err4=this.state.organization.length!=0?'':'Organization Should Not Blank...';
     if(err1!='' || err2!='' || err3!='' || err4!='')
     {let errm=`<font color='Blue'><b>Errors</b>:<br></Font><font color=Red>${err1}<br>${err2}<br>${err3}<br>${err4}</font>`;
     this.setState({msg:errm})
    
       return(false);} 
     else    
     {
       return(true)
     }

    }
  
   handleChangeIcon(event) {
     this.setState({
       file:event.target.files[0]});
     this.setState({icon:URL.createObjectURL(event.target.files[0])});
     this.setState({show:true});
 
   }

  handleChange=async(event)=> {
       this.setState({maincategoryid: event.target.value });

    const body={cid:event.target.value};
    const listsub = await postData(`subcategory/fetchsubcategories`,body);
    console.log(`Sub Data ${listsub}`)
  
    this.setState({ listsub });
    console.log(`Sub Data ${this.state.listsub}`)
   

  }
 
  async componentDidMount() {
        
    const list = await getData(`maincategory/fetchallmaincategories`);
    console.log(`Data ${list}`)
    this.setState({ list });
    console.log(`Data ${this.state.list}`)
  }

  fillMainCategory = () => {
        
    return this.state.list.map((item, index) => {
        return (
          <MenuItem value={item.maincategoryid}>{item.maincategory}</MenuItem>  
         );
    })
}  


fillSubCategory = () => {
        
    return this.state.listsub.map((item, index) => {
         
      
       
        return (
          <MenuItem value={item.subcategoryid}>{item.subcategoryname}</MenuItem>  
         );
    })
}  
addrecord =async()=> {
  if(this.valid()){ 
  try {

      const formData = new FormData();
      console.log('zzzzzzzzzzzzoooooooom');
      console.log(this.state.file.name);
      formData.append('maincategoryid',this.state.maincategoryid);
      formData.append('subcategoryid',this.state.subcategoryid);
      formData.append('organization',this.state.organization);
       formData.append('icon',this.state.file);
      const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
      };

    const result = await postDataAndImage("organization/addnewrecord", formData,config);
   alert(result);
    if(result) {
      this.setState({msg:'Record Submited....',organization:'',icon:''});    
       
    } else {
      this.setState({msg:'Fail to Submit Record....'});     
      alert('Server Error\nTry Again');
    }
  } catch (e) {
    console.log("SubCategory:", e);
  }
}
};


 showComponents(classes) {
  return (
    
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Add Organization Names
      </Typography>
      
      
      <Grid container spacing={24}>
        <Grid item xs={12}>


        
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="demo-controlled-open-select">Main Category Id</InputLabel>
          <Select
             
            
             value={this.state.maincategoryid}
             onChange={this.handleChange}
            
            inputProps={{
              name: 'maincatrgoryid',
              id: 'demo-controlled-open-select0',
            }}
          >
          <MenuItem value="">
              <em>Main Category</em>
            </MenuItem>
           
          {this.fillMainCategory()}
    
          </Select>
        </FormControl>
        </Grid>
       
        <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="demo-controlled-open-select">Sub Category Id</InputLabel>
          <Select
             
            
             value={this.state.subcategoryid}
             onChange={e => this.setState({subcategoryid: e.target.value })}
            inputProps={{
              name: 'subcatrgoryid',
              id: 'demo-controlled-open-select',
            }}
          >
          <MenuItem value="">
              <em>Sub Category</em>
            </MenuItem>
           
          {this.fillSubCategory()}
    
          </Select>
        </FormControl>
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            required
            id="organization"
            name="organization"
            label="Organization"
            
            fullWidth
            autoComplete="organization"
            value={this.state.organization}
            onChange={e => this.setState({ organization: e.target.value })}
            
          />
        </Grid>
        <Grid item xs={12} sm={6}>
              Select Icon:<br/>
              <input
            accept="image/*"
            className={classes.input}
            id="icon"
        
            name="icon"
            type="file"
            onChange={this.handleChangeIcon}
           
          />
          <label htmlFor="icon">
    
    
          <Button variant="contained" component="span" className={classes.button}>
              Upload
              <CloudUploadIcon className={classes.rightIcon}  />
            </Button>
         
          </label>
        
            </Grid>
            <Grid item xs={12} sm={6}>
            <Avatar alt="Remy Sharp" src={this.state.icon} className={classes.bigAvatar} />
             
           
            </Grid>
          </Grid>
     
      <br/><br/>
      <Button variant="contained" size="small" className={classes.button}     onClick={() => this.addrecord()}>
        <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
        Save
      </Button>
    </React.Fragment>
  );
}
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container spacing={24}>
    <Grid item xs={12}  sm={6}>
<React.Fragment>
       
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            
            <React.Fragment>

              {this.showComponents(classes)}
            </React.Fragment>
            </Paper>
            </main>
            </React.Fragment>
            </Grid>
            <Grid item xs={12}  sm={6}>
            <React.Fragment>
            
            <main className={classes.layout}>
            <Paper  style={{marginLeft:'100px',width:'400px',height:'100px',marginTop:'-4%'}}>
        
    
        <h5 align='center'>Messages:</h5> 
        
        <h6>  {renderHTML(this.state.msg)}</h6>
        
        </Paper>
            </main>
            </React.Fragment>
            </Grid>
            </Grid>
         
       
      </div>

    );}


}
AddNewRecord.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AddNewRecord);
