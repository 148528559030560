import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { postDataAndImage } from "../FetchService";

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { getData,BaseURL } from "../FetchService"
import { postData } from "../FetchService"
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  formControl: {
     minWidth: 150,
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  iconSmall: {
    fontSize: 20,
  },
  avatar: {
    margin: 5,
  },
  bigAvatar: {
    margin: 5,
    width: 100,
    height: 100,
  },
});

class EditDelete extends Component {
    state = {
        examcategoryid:'',
        examcategory:'',
         icon:"",
        file: "",
        msg: "",

      };


      constructor(props){
        super(props)

        this.handleChangeIcon = this.handleChangeIcon.bind(this)
      }


       handleChangeIcon(event) {
         this.setState({
           file:event.target.files[0]});
         this.setState({icon:URL.createObjectURL(event.target.files[0])});
         this.setState({show:true});

       }


      async componentDidMount() {
let data= JSON.parse(this.props.location.state.result);
     console.log('................');
     console.log(data);
     this.setState({examcategoryid:data.examcategoryid});
    this.setState({examcategory:data.examcategory});
     this.setState({icon:data.icon});

    let url=`${BaseURL}/images/${data.icon}`;
    this.setState({icon:url});

     }


  showHide=(classes)=>{
   let x=this.state.show;
  let k=x?<Button variant="contained" size="small" className={classes.button}    onClick={() => this.editicon()}><SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} /> Save image</Button>:'';
    return(k);
}


  showComponents(classes) {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
        Edit/Delete  Exam Category Names
        </Typography>


        <Grid container spacing={24}>



          <Grid item xs={12}>
            <TextField
              required
              id="examcategoryid"
              name="examcategoryid"
              label="Exam Category Id"

              fullWidth
              autoComplete="examcategoryid"
              value={this.state.examcategoryid}
              onChange={e => this.setState({examcategoryid: e.target.value })}

            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="examcategory"
              name="examcategory"
              label="Exam Category"

              fullWidth
              autoComplete="examcategory"
              value={this.state.examcategory}
              onChange={e => this.setState({ examcategory: e.target.value })}

            />
          </Grid>
          <Grid item xs={12} sm={6}>
                Select Icon:<br/>
                <input
              accept="image/*"
              className={classes.input}
              id="icon"

              name="icon"
              type="file"
              onChange={this.handleChangeIcon}

            />
            <label htmlFor="icon">


            <Button variant="contained" component="span" className={classes.button}>
                Upload
                <CloudUploadIcon className={classes.rightIcon}  />
              </Button>

            </label>

              </Grid>
              <Grid item xs={12} sm={6}>
            <Avatar alt="Remy Sharp" src={this.state.icon} className={classes.bigAvatar} />

             {this.showHide(classes)}
            </Grid>
          </Grid>
          <br/><br/>
          <Button variant="contained" size="small" className={classes.button}     onClick={() => this.editrecord()}>
            <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
            Edit
          </Button>

           <Button variant="contained" size="small" className={classes.button}     onClick={() => this.deleterecord()}>
           <DeleteIcon className={classes.rightIcon} />
         Delete
          </Button>
      </React.Fragment>
    );
  }




     editicon =async()=> {

      try {

          const formData = new FormData();
          formData.append('examcategoryid',this.state.examcategoryid);
          formData.append('icon',this.state.file);
          const config = {
              headers: {
                  'content-type': 'multipart/form-data'
              }
          };

        const result =  postDataAndImage("examcategory/editicon", formData,config);
       alert(result);
        if(result.RESULT) {
          this.setState({msg:'Picture Edit....'});
          this.props.history.replace({pathname:`/DisplayAllCat`});
        } else {
          this.setState({msg:'Fail to EDIT Icon.....'});
          alert('Server Error\nTry Again');
        }
      } catch (e) {
        console.log("MainCategory:", e);
      }
    };


  editrecord =async()=> {

    try {

        const body={
            examcategoryid:this.state.examcategoryid,
            examcategory:this.state.examcategory,

        };

      const result = await postData("examcategory/editrecord", body);
     alert(result);
      if(result.RESULT) {
        this.setState({msg:'Record Edited....'});
        this.props.history.replace({pathname:`/DisplayAllCat`});
      } else {
        this.setState({msg:'Fail To EDIT Record.....'});
        alert('Server Error\nTry Again');
      }
    } catch (e) {
      console.log("MainCategory:", e);
    }
  };

  deleterecord =async()=> {

    try {
        const body={

          examcategoryid:this.state.examcategoryid,
        };

      const result = await postData("examcategory/deleterecord", body);
     alert(result);
      if(result.RESULT) {
        this.setState({msg:'Record Deleted....'});
        this.props.history.replace({pathname:`/DisplayAllCat`});
      } else {
        this.setState({msg:'Fail to Delete Record....'});
        alert('Server Error\nTry Again');
      }
    } catch (e) {
      console.log("MainCategory:", e);
    }
  };






  render() {

    const { classes } = this.props;

    return (
      <div>
      <React.Fragment>


              <main className={classes.layout}>
                <Paper className={classes.paper}>

                  <React.Fragment>

                  {this.showComponents(classes)}
                  </React.Fragment>
                  </Paper>
                  </main>
                  </React.Fragment>
                  <React.Fragment>

                  <main className={classes.layout}>
                <Paper className={classes.paper}>


              <Typography variant="h5" component="h4">
                {this.state.msg}
              </Typography>

              </Paper>
                  </main>
                  </React.Fragment>


            </div>
         );
  }
}
EditDelete.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(EditDelete);
