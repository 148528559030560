import React, { Component } from 'react';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getData } from "../FetchService"
import { postData,ClientURL } from "../FetchService"
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Grid from '@material-ui/core/Grid';
import OtpInput from 'react-otp-input';
import classNames from 'classnames';


const styles = theme => ({
    dense: {
    marginTop: 19,
  },
});
class UserPasswordForm extends React.Component {

state={
  userotp:'',
  otp: '',
  numInputs: 4,
  separator: '-',
  isDisabled: false,
  hasErrored: false,
  isInputNum: false,
password:'',
newpassword:'',
cnfrmpassword:''

    }
    async componentWillMount()

{
  

  let user=JSON.parse(localStorage.getItem("user"))
  this.setState({mobile:user.mobile})
  console.log('mine'+user.mobile)
 
}
handleClickOpen = async() => {
  this.setState({ open: true });
  let userotp=Math.ceil((Math.random()*8999)+1000)
  let msg='Dear User, Your Secret OTP  for Resetting of Password is '+userotp+" of number ending with XXXXXX"+this.state.mobile.substring(5)+". Valid for 5 Minutes Do not share this OTP for security reasons."

  await  this.setState({userotp})
 let body={mobile:this.state.mobile,otp:msg}

let result= await postData('api/sendotp',body)

   console.log('otpsend',result)
  

};

handleClose = () => {
  this.setState({ open1: false });
  this.setState({ open: false });

};
changebtn= async()=>{}

showNext2=async(classes)=>{
  this.setState({ open1: true });
  this.setState({ open:false });

  console.log('new passs',this.props.otp)

              
  /*              alert(user_otp+","+this.state.otp1)
   */             /*  if(this.state.userotp==this.state.otp)
               { this.setState({msg:'OTP Verified'})   */
   
  let b=<TextField
  
  id="standard-dense"
  label="New Password"
  type="password"

  margin="dense"
  onChange={event=>this.setState({password:event.target.value})}
  
  />
  this.setState({newpassword:b})
    this.setState({cnfrmpassword:<TextField
      
      id="standard-dense"
      label="Confirm  Password"
      onChange={event=>this.setState({cnpassword:event.target.value})}

      type="password"
      className={classes.textField}
      margin="dense"
    />})
                  
  this.setState({changepassword: <Button
      type="submit"
      
      variant="contained"
      color="primary"
      className={classes.submit}
      style={{marginTop:'2%',}}
  onClick={this.changepassword}
    >
      Change Password
    </Button>
           })
  
  }
            
                /*  else
                 {
                   this.setState({msg:'OTP Not  Verified'})
  
                 } 
                }   */
      
                 
              changepassword=async()=>{
                  let user=JSON.parse(localStorage.getItem("user"))
    this.setState({mobileno:user.mobileno})
    console.log('mine'+user.mobileno)
    if(this.state.password===this.state.cnpassword){
   
             const body={mobile:user.mobile,password:this.state.password}
             let result= await postData('userinterface/changepassword',body)
            if(result){
                this.setState({passwordmsg:'passwordchange'})
  
             }
             else{
              this.setState({passwordmsg:'no data available'})
  
             }
  
  
  
            }
            else{
              this.setState({passwordmsg:'password not match'})
            }
            this.setState({ open1: false });
              }
            
       

 render() {
    const { classes } = this.props;
    const { fullScreen } = this.props;
    const { otp, numInputs, separator, isDisabled, hasErrored, isInputNum } = this.state;

     return (
      <div>
                   <form className={classes.form}> 
      <TextField
          id="standard-dense"
          label="Mobile Number:"
          value={`XXXXXX${this.state.mobile.substring(6)}`}      

          style={{marginLeft:'30%',fontSize:'20px'}}
          margin="dense"
        />
 <Button onClick={this.handleClickOpen}>
        send OTP
      </Button></form>









      <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{""}</DialogTitle>
          <DialogContent>
          <Grid>    
                 <Grid className={classes.divp}>
                   <img src={ `/images/cssbig.png`} width='auto' />
                   </Grid>
       <Grid style={{marginTop:'10%'}} className={classes.divp}>
                     
                       <OtpInput
                         inputStyle={{
                           width: '3rem',
                           height: '3rem',
                           margin: '0 1rem',
                           fontSize: '2rem',
                           borderRadius: 4,
                           border: '1px solid rgba(0,0,0,0.3)',
                         }}
                         numInputs={numInputs}
                         isDisabled={isDisabled}
                         hasErrored={hasErrored}
                         errorStyle="error"
                         onChange={this.handleOtpChange}
                         separator={<span>{separator}</span>}
                         isInputNum={isInputNum}
                         shouldAutoFocus
                       />
                     
       
                 </Grid>
                  
                 <Button
                type="submit"
                fullWidth
                onClick={this.showNext2}
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{marginTop:'2%',}}
/*                  style={{width:'20%',marginTop:'5%'}}
 */    
              >
                Verify OTP
              </Button>
                     
    
       
       
       
             <Link
             component="button"
             variant="body2"
             onClick={this.showNext1}
                      style={{color:'red'}}
           >
             Resend OTP
 </Link>
       </Grid>
      
          </DialogContent>
          
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open1}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{""}</DialogTitle>
          <DialogContent>
             
<div>{this.state.newpassword}</div>
<div>{this.state.cnfrmpassword}</div>
<div>{this.state.changepassword}</div>
<div>{this.state.passwordmsg}</div>
              
          </DialogContent>
          
        </Dialog>
     </div>
    );
  }
}
UserPasswordForm.propTypes = {
    classes: PropTypes.object.isRequired,

  };
  


export default withStyles(styles)(UserPasswordForm);
