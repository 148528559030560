import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";

const products = [
  { name: "Product 1", desc: "A nice thing", price: "$9.99" },
  { name: "Product 2", desc: "Another thing", price: "$3.45" },
  { name: "Product 3", desc: "Something else", price: "$6.51" },
  { name: "Product 4", desc: "Best thing of all", price: "$14.11" },
  { name: "Shipping", desc: "", price: "Free" },
];

const styles = (theme) => ({
  listItem: {
    padding: `${theme.spacing.unit}px 0`,
  },
  total: {
    fontWeight: "700",
  },
  title: {
    marginTop: theme.spacing.unit * 2,
  },
});
const TAX_RATE = 0.18 + 0.0175;
class Review extends React.Component {
  state = { rows: [] };
  cyFormat(num) {
    console.log("num:" + num);
    return `${num.toFixed(2)}`;
  }

  priceRow(qty, unit) {
    return qty * unit;
  }

  createRow(id, row) {
    const qty = 1;
    const price = row.price;
    const setorg = row.setorg;
    const amount = this.priceRow(qty, price);
    return { id, setorg, qty, price, amount };
  }

  subtotal(items) {
    return items.map(({ amount }) => amount).reduce((sum, i) => sum + i, 0);
  }
  async FillRow() {
    let rows = [];
    for (var i = 0, len = localStorage.length; i < len; ++i) {
      console.log("set no:" + localStorage.key(i));
      if (localStorage.key(i) != "user" && localStorage.key(i) != "PYTHON") {
        let r = JSON.parse(localStorage.getItem(localStorage.key(i)));
        console.log("r:" + r);
        rows.push(r);
      }
    }
    rows = rows.map((row, id) => this.createRow(id, row));
    await this.setState({ rows });
  }

  invoiceTotalValue = (value) => {
    this.props.changeValue(value);
    return value;
  };

  async componentDidMount() {
    await this.FillRow();
  }

  render() {
    console.log(this.props.payment_form);
    const addresses = [];
    if (this.props.shipping_address.address1 != "") {
      addresses.push(this.props.shipping_address.address1);
    }
    if (this.props.shipping_address.address2 != "") {
      addresses.push(this.props.shipping_address.address2);
    }
    if (this.props.shipping_address.city_name != "") {
      addresses.push(this.props.shipping_address.city_name);
    }
    if (this.props.shipping_address.state_name != "") {
      addresses.push(this.props.shipping_address.state_name);
    }
    if (this.props.shipping_address.zipcode != "") {
      addresses.push(this.props.shipping_address.zipcode);
    }

    console.log(addresses);
    const payments = [
      { name: "Card type", detail: "Visa" },
      { name: "Card holder", detail: this.props.payment_form.name_on_card },
      { name: "Card number", detail: this.props.payment_form.card_number },
      { name: "Expiry date", detail: this.props.payment_form.exp_date },
    ];

    let invoiceSubtotal = this.subtotal(this.state.rows);
    let invoiceTaxes = TAX_RATE * invoiceSubtotal;
    let invoiceTotal = invoiceTaxes + invoiceSubtotal;
    
    // localStorage.setItem('PRICE',invoiceTotal)
    // this.props.invoice_total=invoiceTotal
    const { classes } = this.props;
    console.log(this.props.shipping_address.city_name);
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Order summary
        </Typography>
        <List disablePadding>
          {this.state.rows.map((product) => (
            <ListItem className={classes.listItem} key={product.setorg}>
              <ListItemText
                primary={product.setorg}
                secondary={"Test Papers"}
              />
              <Typography variant="body2">
                {product.amount} <span>&#8377;</span>{" "}
              </Typography>
            </ListItem>
          ))}
          <ListItem className={classes.listItem}>
            <ListItemText primary="Total" />
            <Typography variant="subtitle1" className={classes.total}>
              {invoiceSubtotal} <span>&#8377;</span>
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Taxes" />
            <Typography variant="subtitle1" className={classes.total}>
              {invoiceTaxes.toFixed(2)} <span>&#8377;</span>
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Total" />
            <Typography variant="subtitle1" className={classes.total}>
              {Math.round(invoiceTotal)}
              {localStorage.setItem('PYTHON',Math.round(invoiceTotal))}
               <span>&#8377;</span>
            </Typography>
          </ListItem>
        </List>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              Shipping
            </Typography>
            <Typography gutterBottom>
              {this.props.shipping_address.first_name}&nbsp;
              {this.props.shipping_address.last_name}
            </Typography>
            <Typography gutterBottom>{addresses.join(", ")}</Typography>
          </Grid>
          {/* <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Payment details
          </Typography>
          <Grid container>
            {payments.map(payment => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid> */}
        </Grid>
      </React.Fragment>
    );
  }
}

Review.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Review);
