/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { postData } from "../FetchService";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import renderHTML from "react-render-html";
import Slide from "@material-ui/core/Slide";
import { InputAdornment } from "@material-ui/core";
import { RemoveRedEye } from "@material-ui/icons";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import './interncss/UserRegistration.css'
var otpGenerator = require("otp-generator");


const styles = (theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  paper_link: {
    marginTop: theme.spacing.unit * 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    margin: theme.spacing.unit,
  },
  formControl: {
    minWidth: 400,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  iconSmall: {
    fontSize: 20,
  },
  eye: {
    cursor: "pointer",
  },
});
function Transition(props) {
  return <Slide direction="up" {...props} />;
}
class UserRegistration extends React.Component {
  state = {
    msg: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    password: "",
    confirmpassword: "",
    list: "",
    confirm: "",
    setOpen: false,
    open: true,
    openc: false,
    passwordIsMasked: true,
    passwordIsMasked1: true,
  };

  togglePasswordMask = (statename,statevalue) => {
    this.setState((prevState) => ({
      [statename]: !statevalue,
    }));
  };

  handleCloseC = () => {
    this.setState({ openc: false });
  };

  alertMailDialog = () => {
    const { fullScreen } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.openc}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseC}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"Verify Your Email Address..???"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {`We now need to verify your email address.We have sent an email to ${this.state.email} to verify your address. Please click the link in that email to continue...`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseC} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
  validateEmail = (email) => {
    var re = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const result = re.test(email);
    return result;
  };
  handleClose() {
    this.setState({ setOpen: false });
  }
  valid = () => {
    let err1 =
      this.state.first_name.length !== 0 ? "" : "Pls Fill First Name...";

    let err2 = this.state.last_name.length != 0 ? "" : "Pls Fill Last Name...";
    let err3 = this.state.email.length != 0 ? "" : "Pls Fill Email Address...";
    let err4 = this.state.mobile.length != 0 ? "" : "Pls Fill Mobile Number...";
    let err5 = this.state.password.length != 0 ? "" : "Pls Fill Password...";
    let err6 =
      this.state.mobile.length == 10 ? "" : "Mobile Number Must Be 10 digit`s";
    let err7 = this.validateEmail(this.state.email)
      ? ""
      : `Invalid Email Address`;
    let err8 = this.state.confirmpassword.length != 0 ? "" : "Pls Fill Confirm Password...";

    if (
      err1 != "" ||
      err2 != "" ||
      err3 != "" ||
      err4 != "" ||
      err5 != "" ||
      err6 != "" ||
      err7 != "" ||
      err8 != ""
    ) {
      let errm = `<font color='Blue'><b>Errors</b>:<br></Font><font color=Red>${err1}<br>${err2}<br>${err3}<br>${err4}<br>${err5}<br>${err6}<br>${err7}<br>${err8}</font>`;
      this.setState({ msg: errm });

      return false;
    } else {
      let errm = `<font color='Blue'><b>Errors</b>:<br></Font><font color=Red>${err1}<br>${err2}<br>${err3}<br>${err4}<br>${err5}<br>${err6}<br>${err7}<br>${err8}</font>`;
      this.setState({ msg: errm });
      if(this.state.password==this.state.confirmpassword){
        return true;
      }
      else{
        let errm = `<font color='Blue'><b>Errors</b>:<br></Font><font color=Red>Password and Confirm Password not matched!</font>`;
        this.setState({ msg: errm });
        return false;
      }
      
    }
  };

  addrecord = async () => {
    const token = otpGenerator.generate(35, { specialChars: false });
    if (this.valid()) {
      try {
        var date = new Date().getDate();
        var month = new Date().getMonth() + 1;
        var year = new Date().getFullYear();
        let cd = year + "/" + month + "/" + date;
        const body = {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          email: this.state.email,
          mobile: this.state.mobile,
          password: this.state.password,
          create_date: cd,
          update_date: cd,
          status: "Not Verified",
          token: token,
        };
        const result = await postData("userinterface/addnewrecord", body);
        console.log("rrrr", result.result);
        if (result.result == "true") {
          this.setState({ openc: true });
          this.setState({ msg: "Record Submited...." });

          const b1 = {
            email: this.state.email,
            first_name: this.state.first_name,
            emailenc: btoa(`email=${this.state.email}`),
            token: token,
          };

          const list = await postData("mail/sendemail", b1);

          //  this.props.history.replace({pathname:'/UserLogin'})
          this.props.mainpage("BUY");
        } else if (result.result == "duplicate") {
          this.setState({
            msg:
              '<font color="red">Email/Mobile Number is Already Exist...</font>',
          });
        } else {
          this.setState({ msg: "Fail to Submit Record...." });
        }
      } catch (e) {
        console.log("UserRegistration:", e);
      }
    }
  };

  showComponents(classes) {
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Student Registration
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              id="first_name"
              name="first_name"
              label="First Name"
              fullWidth
              autoComplete="first_name"
              value={this.state.first_name}
              onChange={(e) => this.setState({ first_name: e.target.value })}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              id="last_name"
              name="last_name"
              label="Last Name"
              fullWidth
              autoComplete="last_name"
              value={this.state.last_name}
              onChange={(e) => this.setState({ last_name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email Address"
              fullWidth
              autoComplete="email"
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              type="number"
              id="mobile"
              name="mobile"
              label="Mobile"
              className="mobile"
              fullWidth
              autoComplete="mobile"
              value={this.state.mobile}
              onChange={(e) => this.setState({ mobile: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              type={this.state.passwordIsMasked ? "password" : "text"}
              id="password"
              name="password"
              label="Password"
              fullWidth
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.passwordIsMasked ?<VisibilityOffIcon
                      className={classes.eye}
                      onClick={()=>this.togglePasswordMask("passwordIsMasked",this.state.passwordIsMasked)}
                    />:<RemoveRedEye
                    className={classes.eye}
                    onClick={()=>this.togglePasswordMask("passwordIsMasked",this.state.passwordIsMasked)}
                  />}
                    
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              type={this.state.passwordIsMasked1 ? "password" : "text"}
              id="password"
              name="password"
              label="Confirm Password"
              fullWidth
              value={this.state.confirmpassword}
              onChange={(e) =>
                this.setState({ confirmpassword: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {this.state.passwordIsMasked1 ?<VisibilityOffIcon
                      className={classes.eye}
                      onClick={()=>this.togglePasswordMask("passwordIsMasked1",this.state.passwordIsMasked1)}
                    />:<RemoveRedEye
                    className={classes.eye}
                    onClick={()=>this.togglePasswordMask("passwordIsMasked1",this.state.passwordIsMasked1)}
                  />}
                    
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              size="small"
              className={classes.button}
              onClick={() => this.addrecord()}
            >
              <SaveIcon
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              Save
            </Button>
          </Grid>
          <Grid item xs={8}>
            <div style={{ marginTop: 15 }}>
              <small>
                {" "}
                Already have an account?{" "}
                <a
                  href="javascript:;"
                  onClick={() => this.props.mainpage("USERLOGIN_UR")}
                >
                  Log In
                </a>
              </small>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={6}>
            <React.Fragment>
              <main className={classes.layout}>
                <Paper className={classes.paper}>
                  <React.Fragment>
                    {this.showComponents(classes)}
                  </React.Fragment>
                </Paper>
              </main>
            </React.Fragment>
          </Grid>
          <Grid item xs={12} sm={6}>
            <React.Fragment>
              <main className={classes.layout}>
                <Paper className={classes.paper}>
                  <h5>Messages:</h5>
                  <h6> {renderHTML(this.state.msg)}</h6>

                  {this.state.confirm}
                  {this.state.setOpen}
                </Paper>
              </main>
            </React.Fragment>
          </Grid>
        </Grid>
        {this.alertMailDialog()}
      </div>
    );
  }
}
UserRegistration.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(UserRegistration);
