import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { BrowserRouter as Router,HashRouter, Route,NavLink,Redirect } from "react-router-dom";
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import SignIn from '../login/SignIn' ;
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { getData, ClientURL } from "../FetchService"
import Animation from "../Animation"

import { postData } from "../FetchService"
import Menu from '@material-ui/core/Menu'
import UserOrganization from '../userinterface/UserOrganization'
import UserDashBoard from '../userinterface/UserDashBoard'

import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import ShowCart from '../userinterface/ShowCart'
import Avatar from '@material-ui/core/Avatar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmployerLogin from '../internship/EmployerLogin';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EmployerRegistration from '../internship/EmployerRegistration';
import ListInternship from '../internship/ListInternship';
import UsersWhoApplyIntern from '../internship/UsersWhoApplyIntern';
import MainPage from '../footer/MainPage'
import Python from '../footer/Python'
import Node from '../footer/Node'
import Java from '../footer/Java'

import WebDevelopment from '../footer/WebDevelopment'

import Fab from '@material-ui/core/Fab';
import TermsCondition  from '../footer/TermsCondition'
import classNames from 'classnames';
import Privacy from '../footer/Privacy';
import OurServices from '../footer/OurServices';
import TextField from '@material-ui/core/TextField';
import Materialtable from './Table'


const styles = theme => ({
   paper:{padding:'30px',marginTop:'10px'},
  foot: {
    flexGrow: 1,
    marginTop:20,

        justifyContent: 'center',
        alignItems: 'center',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -18,
    marginRight: 10,
  },

  input: {
    display: 'none',
  },
    rootMenu: {
        display: 'flex',
      },
      paperMenu: {
        marginRight: theme.spacing.unit * 2,
      },

      mainroot: {
        overflow: 'hidden',
        flexGrow: 1,
      },
      root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  badge: {
    top: '30%',
    right:-2,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  usericon: {
    marginLeft: 30,
    marginRight:40,
  },
  avatar: {
    margin:0,
    width: 70,
    height: 70,
  },
  layout: {
    backgroundColor:'#263475',

    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width:'100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  footer: {
    marginBottom: theme.spacing.unit * 2,

    borderTop: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 3}px 0`,
  },
});

class Showuser extends React.Component {

  setViews=(Views)=>{
    this.setState({view:Views})
  }

  setRefresh=(r)=>{
    this.setState({refresh:r})
    alert(r)
  }

    state = {
        sublist:[],
        list:[] ,
        from:new Date(),
        to:new Date(),
       table:[],
       refresh:'',
       view:<Materialtable setViews={this.setViews} from={''} to={''} setRefresh={this.setRefresh}/>
      };




      constructor(props)
         { super(props)


      }
      componentWillMount()
      {
        // this.setState({view:<Materialtable  itempurchased={this.mainpage}  setViews={this.setViews} />})
    }





     async   componentDidMount() {

       const list = await getData(`showuser/show`);
      console.log(`Data ${list}`)
        this.setState({ list });

      console.log(`Data ${this.state.list}`)

      }











handleDateChange = date => {
  this.setState({ from: date });
  // alert(this.state.from)
  if(this.state.to==new Date()){
// alert('xxxxxxxxxxxxxxxx')
  }
  else{
  // alert(this.state.from)
  }

};
handleDateChange2 = date => {
  this.setState({ to: date });
  // alert(this.state.to)
};

showInternship=()=>{
  this.setState({view:<Materialtable from={this.state.from} to={this.state.to} setRefresh={this.setRefresh} setViews={this.setViews} />})
}




render() {
    const { classes } = this.props;


    return (
      <div className={classes.mainroot}>
      <Paper className={classes.paper}>
        <Typography style={{display:'flex', justifyContent:'center',marginTop:'4px',marginBottom:'0px'}}>
          <b>List of Internship Posted</b>
        </Typography>
      <Grid container spacing={24}>
        <Grid item xs={12} sm={2}>
          <Typography  gutterBottom style={{marginTop:'20%',marginLeft:'20%'}}>From</Typography></Grid>
          <Grid item xs={12} sm={3}>

        <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <Grid container className={classes.grid} justify="space-around">
            <DatePicker
              margin="normal"
              value={this.state.from}
              onChange={this.handleDateChange}
              style={{marginTop:'10%'}}
            />

          </Grid>
        </MuiPickersUtilsProvider>
  </Grid>
  <Grid item xs={12} sm={2}><Typography  gutterBottom style={{marginTop:'20%',marginLeft:'20%'}}>To</Typography></Grid>
  <Grid item xs={12} sm={3}>  <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container className={classes.grid} justify="space-around">
          <DatePicker
            margin="normal"
            value={this.state.to}
            onChange={this.handleDateChange2}
            style={{marginTop:'10%'}}
          />

        </Grid>

      </MuiPickersUtilsProvider></Grid>
    <Grid item xs={12} sm={2}>
      <Button color='primary' style={{marginTop:'10%'}} variant="contained" onClick={()=>this.showInternship()}>
        Search
      </Button>
    </Grid>

        </Grid>
        {this.state.view}
        </Paper>
    </div>
    );}

}
Showuser.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Showuser);
