/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ClientURL, getData } from "../FetchService";
import Dialog from "./Dialog/Dialog";

function Copyright({ props }) {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link
        color="inherit"
        href="#"
        onClick={() => props.history.replace({ pathname: "/UserMainPage" })}
      >
        CSEP Campusshala Education Pvt. Ltd.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random/?programming)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResendLink(props) {
  const [username, setusername] = useState("");
  const [dialogstatus, setdialogstatus] = useState(false);
  const [Status, setStatus] = useState("");
  const classes = useStyles();

  const handleClick = async () => {
    let result = await getData(`userinterface/checkuser/${username}`);
    // alert(JSON.stringify(result));
    setStatus(result.RESULT);
    setdialogstatus(true);
  };

  const changeRoute = () => {
    props.history.replace({ pathname: "/UserMainPage" });
  };

  return (
    <Grid container component="main" className={classes.root}>
      {dialogstatus && <Dialog changeRoute={changeRoute} heading={Status} />}
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}> */}
          <img
            onClick={() => props.history.replace({ pathname: "/UserMainPage" })}
            src={`/images/cssbig.png`}
          />
          {/* </Avatar> */}
          <Typography component="h1" variant="h5">
            Get Verification Link
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={(e) => setusername(e.target.value)}
            id="email"
            label="Email Address or Mobile Number"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            fullWidth
            onClick={handleClick}
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Get Link
          </Button>
          <Box mt={5}>
            <Copyright props={props} />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}
