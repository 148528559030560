import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SideBar,{secondaryListItems} from './SideBar';
import { getData, ClientURL } from "../FetchService"
import Clock from 'react-live-clock';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Avatar from '@material-ui/core/Avatar';
import TestPaper from './TestPaper';
import {isMobile,BrowserView,MobileView} from 'react-device-detect';


const drawerWidth = '100%';

const styles = theme => ({
  root: {
    display: 'flex',
  },

  toolbarIconLogo: {
    alignItems: 'right',
    paddingLeft: 10,


  },
  toolbarIcon: {
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',

    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,

    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 60,

  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100vh',
    overflow: 'auto',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: '80%',
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  avatar: {
    margin: 0,
  },
  bigAvatar: {
    margin: 0,
    width: 40,
    height: 40,
  },
});

class TakeTest extends React.Component {
  state = {
    open: true,
    opt:'',
    sideques:[],
    selectedIndex:0,
    status:'Tab',

  };
 adata='';

  componentWillMount() {
    /*
    if (!localStorage.getItem("admin")) {
       console.log("-==-=-=-=-=-=-=--");
      this.props.history.replace("/LoginPage");
    }*/

  }
   getQuestionNo=(value,status)=>{

    this.setState({selectedIndex:value})
    this.setState({status})


   }
   changeOption=(sideques)=>{

    this.setState({sideques});

   }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  appbar=(classes)=>{
    return(<div className={classes.root}>

        <AppBar  position="static" color="default">
          <Toolbar>

            <div>
            <img src={`/images/cssbig.png`}></img>
            </div>


          </Toolbar>
        </AppBar>

            </div>)
  }

  render() {
    const { classes } = this.props;



    return (<div>
      <center><img src={`/images/cssbig.png`} style={{width:'50'}} onClick={()=>alert("Click is not allowed outside the tab")}></img></center>
          <nav>
                <Hidden smUp implementation="js">
                  <Grid container >
                    <Grid item xs={12}>
                      <TestPaper body={this.props.data} footerClick={this.props.footerClick} ts={this.state} click={this.getQuestionNo}  action={this.changeOption} history={this.props.history}/>
                    </Grid>
                  </Grid>
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Grid container>
                     <Grid item xs={12} sm={2}>
                   <Drawer
                     variant="permanent"
                     classes={{
                       paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                     }}
                     open={this.state.open}
                   >

                  <SideBar taketeststate={this.state.sideques} click={this.getQuestionNo} history={this.props.history} />


                  </Drawer>
                  </Grid>
                  <Grid item xs={12} sm={10}>


                      <TestPaper body={this.props.data} footerClick={this.props.footerClick} ts={this.state} click={this.getQuestionNo}   action={this.changeOption} history={this.props.history}/>

                   </Grid>
                   </Grid>
                </Hidden>
              </nav>
{/*
       //  <Grid container>
       //    <Grid item xs={12} sm={3} className={classes.root}>
       //  <Drawer
       //    variant="permanent"
       //    classes={{
       //      paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
       //    }}
       //    open={this.state.open}
       //  >
       //
       // <SideBar taketeststate={this.state.sideques} click={this.getQuestionNo} history={this.props.history} />
       //
       //
       // </Drawer>
       // </Grid>
       // <Grid item xs={12} sm={9}>
       //  <main className={classes.content}>
       //
       //
       //     <TestPaper body={this.props.data} ts={this.state} click={this.getQuestionNo}   action={this.changeOption} history={this.props.history}/>
       //
       //  </main>
       //  </Grid>
       //  </Grid> */}
     </div>
    );
  }
}

TakeTest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TakeTest);
/* 1)ts props of Testpaper component send to displaypaper component where we change the selectedIndex with prop->selectedIndex
   2)all index change by prop click thru sidebar component
   3)click prop is used in Testpaper maintain the category change in testpaper
*/
