import TextField from '@material-ui/core/TextField';
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Link from '@material-ui/core/Link';
import { getData } from "../FetchService"
import { postData } from "../FetchService"
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import { nfbind } from 'q';
import Input from '@material-ui/core/Input';
import { InputBase, Backdrop } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CardMedia from '@material-ui/core/CardMedia';
import { th } from 'date-fns/esm/locale';
import OtpInput from 'react-otp-input';

const styles = {
  card1: {
      marginTop:'2%',
    width:'auto',
    height:250
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper:{
    height:400,
marginLeft:'15%',
width:500,

  }
};

class ChangeAddressForm extends React.Component {
    state = {
        
       
       
txtaddress1:'',
txtaddress2:'',
txtcity:'',
txtcountry:'',
       
        updatedate: '',
        list: [],
        address1:'',
        address2:'',
        city:'',
        
      LabelButton6:'Edit',
      LabelButton7:'Edit',
      LabelButton8:'Edit',
      LabelButton9:'Edit',
      citystate:'',
      add1:'',
      add2:'',
otpmsg:'',
        data: '',
        Edit: '',
       
       
      }
     
      componentWillMount(){
        this.setState(this.props.states)
      let user=JSON.parse(localStorage.getItem("user"))
      
       }
        edituser = async () => {
        let user=JSON.parse(localStorage.getItem("user"))

        //alert('xxxx');
        let data=[]
        
               if(this.state.data==7){
                data={address1:this.state.txtaddress1,user_id:user.user_id}}
                else if(this.state.data==8){
                  data={address2:this.state.txtaddress2,user_id:user.user_id}}
                  else if(this.state.data==9){
                    data={city:this.state.txtcity,user_id:user.user_id}}
                   
          
        let result = await postData('userdetails/editrecord', data)
        //alert(result)
        if (result.RESULT) {
          this.setState({ msg: 'Record Submitted' })
    
        }
        else { this.setState({ msg: 'Fail to Submit..' }) }
    
      }

      Display(classes)
      {
        
        return this.state.list.map((item, index) => {
          return (
      
            <div className={classes.root} >
           
           <Card className={classes.card1}>
                <Typography variant="title" gutterBottom align="center"  style={{marginTop:'2%',marginLeft:'-15%'}}>
                  User  Address Information
            </Typography>
      
               
            <Grid container spacing={24} >
                <Grid item xs={3}   >
                <Typography   variant="title" style={{marginTop:'5%',marginLeft:'10%'}}>Address1</Typography>
                    </Grid>
                
                  <Grid item xs={3}              style={{marginLeft:'10%'}}
  >
                    <div className={classes.hello}>
                    <Typography   variant="title"   style={{marginLeft:'10%'}}
 > {this.state.address1}</Typography>

                     
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                  <Link
            component="button"
            //variant="body2"
            onClick={this.changebtn6}
            className={classes.button}
             // alert("I'm a button.");
             style={{marginLeft:'90%'}}
          >                    <Typography   variant="title">  {this.state.LabelButton6}</Typography>

          
           
          </Link>
        
              </Grid>
            </Grid>
            

            <Grid container spacing={24} >
                <Grid item xs={3}   >
                                    <Typography   variant="title" style={{marginTop:'2%',marginLeft:'10%'}}> Address2</Typography>
                    </Grid>
                
                  <Grid item xs={3}              style={{marginLeft:'10%'}}
  >
                    <div className={classes.hello}>
                                  <Typography   variant="title"   style={{marginLeft:'10%'}}
 > {this.state.address2}</Typography>

                      
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                  <Link
            component="button"
            //variant="body2"
            onClick={this.changebtn7}
            className={classes.button}
             // alert("I'm a button.");
             style={{marginLeft:'90%'}}
          ><Typography   variant="title"> {this.state.LabelButton7}</Typography>
            
          </Link>
        
              </Grid>
            </Grid>
            


            <Grid container spacing={24} >
                <Grid item xs={3}   >
                <Typography   variant="title" style={{marginTop:'2%',marginLeft:'10%'}}>City</Typography>                    </Grid>
                
                  <Grid item xs={3}              style={{marginLeft:'10%'}}
  >
                    <div className={classes.hello}>
                    <Typography   variant="title"   style={{marginLeft:'10%'}}
 > {this.state.city}</Typography>
                      
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                  <Link
            component="button"
            //variant="body2"
            onClick={this.changebtn8}
            className={classes.button}
             // alert("I'm a button.");
             style={{marginLeft:'90%'}}
          >                    <Typography   variant="title"> {this.state.LabelButton8}</Typography>

           
          </Link>
        
              </Grid>
            </Grid>
            
























              
              </Card>
      
            </div>
          )
        }
        )
      
      }



       async componentDidMount()

      { 
      let user=JSON.parse(localStorage.getItem("user"))
     
      
        const bodyr={user_id:user.user_id}
        let list = await postData('userdetails/fetchbyuserid',bodyr)
        if(list.length!=0){
        console.log('ddd',list)
        this.setState({ list })
       
       this.setState({ txtaddress1: list[0].address1 })
       this.setState({ address1: list[0].address1 })
       this.setState({ txtaddress2: list[0].address2 })
       this.setState({address2: list[0].address2 })
       this.setState({ txtcity: list[0].city })
       this.setState({ city: list[0].city })
        }
        else
        {
          this.setState({ txtaddress1: '' })
          this.setState({ address1:'' })
          this.setState({ txtaddress2:'' })
          this.setState({address2:''})
          this.setState({ txtcity:''})
          this.setState({ city:'' })
        }
      }
      

      handleAddress1 = async (event) => {
        await this.setState({ txtaddress1: event.target.value })
         let ah= <TextField
         onChange={this.handleAddress1}
           ></TextField>

        await this.setState({ address1: ah })
      
      }
      
      
      changebtn6 = async (classes) => {
        this.setState({ data:'7' })
      
        if (this.state.LabelButton6 == 'Edit') {
          await this.setState({ LabelButton6: 'Save' })
      
          let ah= <TextField
            value={this.state.txtaddress1}
            onChange={this.handleAddress1}
      
          ></TextField>
      
          this.setState({ address1: ah })
      
          //    this.setState({mobile:l})
        }
        else if (this.state.LabelButton6== 'Save') {
          await this.setState({ LabelButton6: 'Edit' })
          this.edituser()
      
      let ah=<div>
      {this.state.txtaddress1}
      </div>
      this.setState({address1:ah}) 
       }
      }


      handleAddress2 = async (event) => {
        await this.setState({ txtaddress2: event.target.value })
         let bh= <TextField
         onChange={this.handleAddress2}
           ></TextField>

        await this.setState({ address2: bh })
      
      }
      
      
      changebtn7 = async (classes) => {
        this.setState({ data:'8' })
      
        if (this.state.LabelButton7 == 'Edit') {
          await this.setState({ LabelButton7: 'Save' })
      
          let bh= <TextField
            value={this.state.txtaddress2}
            onChange={this.handleAddress2}
      
          ></TextField>
      
          this.setState({ address2: bh })
      
          //    this.setState({mobile:l})
        }
        else if (this.state.LabelButton7== 'Save') {
          await this.setState({ LabelButton7: 'Edit' })
          this.edituser()
      
      let bh=<div>
      {this.state.txtaddress2}
      </div>
      this.setState({address2:bh}) 
       }
      }




      handleCity = async (event) => {
        await this.setState({ txtcity: event.target.value })
         let ch= <TextField
         onChange={this.handleCity}
           ></TextField>

        await this.setState({ city: ch})
      
      }
      
      
      changebtn8 = async (classes) => {
        this.setState({ data:'9' })
      
        if (this.state.LabelButton8 == 'Edit') {
          await this.setState({ LabelButton8: 'Save' })
      
          let ch= <TextField
            value={this.state.txtcity}
            onChange={this.handleCity}
      
          ></TextField>
      
          this.setState({ city: ch })
      
          //    this.setState({mobile:l})
        }
        else if (this.state.LabelButton8== 'Save') {
          await this.setState({ LabelButton8: 'Edit' })
          this.edituser()
      
      let ch=<div>
      {this.state.txtcity}
      </div>
      this.setState({city:ch}) 
       }
      }






















    render(){
        
  const { classes } =this.props;
  const bull = <span className={classes.bullet}>â€¢</span>;
  const { fullScreen } = this.props;
  const { otp, numInputs, separator, isDisabled, hasErrored, isInputNum } = this.state;

  return (
    <div>
     {this.Display(classes)}
     <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{""}</DialogTitle>
          <DialogContent>
          <Grid>    
                 <Grid className={classes.divp}>
                   <img src='/images/plooto1.png' width='20%' />
                   </Grid>
       <Grid style={{marginTop:'10%'}} className={classes.divp}>
                     
                       <OtpInput
                         inputStyle={{
                           width: '3rem',
                           height: '3rem',
                           margin: '0 1rem',
                           fontSize: '2rem',
                           borderRadius: 4,
                           border: '1px solid rgba(0,0,0,0.3)',
                         }}
                         numInputs={numInputs}
                         isDisabled={isDisabled}
                         hasErrored={hasErrored}
                         errorStyle="error"
                         onChange={this.handleOtpChange}
                         separator={<span>{separator}</span>}
                         isInputNum={isInputNum}
                         shouldAutoFocus
                       />
                     
       
                 </Grid>
                  
                 <Button
                type="submit"
                fullWidth
                onClick={this.showNext2}
                variant="contained"
                color="primary"
                className={classes.submit}
                style={{marginTop:'2%',}}
/*                  style={{width:'20%',marginTop:'5%'}}
 */    
              >
                Verify OTP
              </Button>
                     
    
       
       
       
             <Link
             component="button"
             variant="body2"
             onClick={this.showNext1}
                      style={{color:'red'}}
           >
             Resend OTP
 </Link>
       </Grid>
      
          </DialogContent>
          {this.state.otpmsg}
        </Dialog>
    
  </div>

  );
}
}
ChangeAddressForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangeAddressForm);
