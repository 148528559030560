import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PersonalDetails from './PersonalDetails';
import OrganizationDetails from './OrganizationDetails';
import PostInternship from './PostInternship'
import { postDataAndImage ,BaseURL} from "../FetchService";

import CircularProgress from '@material-ui/core/CircularProgress';
const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3,
    },
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
});

const steps = ['Personal Details', 'Organization Details', 'Post Internship'];



class Checkout extends React.Component {
  state = {
    activeStep: 0,
    personal_details:{

        first_name:'',
        last_name:'',

        email:'',
        mobile:'',


          },
    organization_details:{
      organizationname:'',
      organizationdescription:'',
      icon:'',
      websiteUrl:'',
      facebookUrl:'',
      instagramUrl:'',
      linkedinUrl:'',
      youtubeUrl:'',
      file: "",

    },

    postinternship:{

      primaryprofile:'Business Development(Sales)',
      typeinternship:'',
      internlocation:'',
      isparttime:'',
      noofopenings:'',
      startdate:'',
      internshipduration:'',
      options:'',
      stipendperks:'',
      currency:'',
      salary:'',
      duration:'',
      perks:[],
      preplacement:'',
      skill:'',
      question:'',
      labelWidth:0,
      roles:'',
      dw:false,
      cer:false,
      fw:false,
      lor:false,
      idc:false,
      fsb:false,
      applyby:new Date('yyyy-mm-dd')
    },
    btnstatus:'',
    idemployer:''

  };

   componentWillMount(){
   /* console.log('data',this.props.data)
    let data=JSON.parse(this.props.data)
    console.log('Len',Object.keys(data).length)
     */
    try{

      let data=JSON.parse(this.props.data)
     if(Object.keys(data).length>0)
     {

    this.state.idemployer=data.idemployer
    let employer=JSON.parse(localStorage.getItem('employer'))
        this.state.personal_details.first_name=employer.first_name
        this.state.personal_details.last_name=employer.last_name
     this.state.personal_details.email=data.emailid
    this.state.personal_details.mobile=data.mobile
    this.state.organization_details.organizationname=data.organizationname
    this.state.organization_details.organizationdescription=data.description
    this.state.organization_details.icon=`${BaseURL}/images/${data.logo}`
    this.state.organization_details.websiteUrl=data.websiteurl
    this.state.organization_details.facebookUrl=data.facebookurl
    this.state.organization_details.instagramUrl=data.instagramurl
    this.state.organization_details.linkedinUrl=data.linkedinurl
    this.state.organization_details.youtubeUrl=data.youtubeurl
    this.state.organization_details.file=data.logo
    this.state.postinternship.primaryprofile=data.primaryprofile
    this.state.postinternship.typeinternship=data.typeofinternship
    this.state.postinternship.internlocation=data.internlocations
    this.state.postinternship.isparttime=data.isparttime
    this.state.postinternship.noofopenings=data.noofopenings
    this.state.postinternship.startdate=data.startdate
    this.state.postinternship.internshipduration=data.internshipduration
    this.state.postinternship.options=data.options
    this.state.postinternship.stipendperks=data.stipendtype
    this.state.postinternship.currency=data.currency
    this.state.postinternship.salary=data.stipend
    this.state.postinternship.duration=data.duration

    this.state.postinternship.preplacement=data.preplacement
    this.state.postinternship.skill=data.skills
    this.state.postinternship.question=data.questions

    this.state.postinternship.roles=data.responsibilities
    var d=new Date(data.applyby)
    var td=d.getFullYear()+"-"+("0" + (d.getMonth() + 1)).slice(-2)+"-"+("0" + d.getDate()).slice(-2)

    this.state.postinternship.applyby=td
    this.state.postinternship.dw=false
    this.state.postinternship.cer=false
     this.state.postinternship.fw=false
     this.state.postinternship.lor=false
    this.state.postinternship.idc=false
    this.state.postinternship.fsb=false

    let v=data.perks.split(',')
    this.state.postinternship.perks=v

    for(let i=0;i<v.length;i++)
    {
    if(v[i]=='5 Days in a Week')
      { this.state.postinternship.dw=true
      }
    else if(v[i]=='Certificate')
      {this.state.postinternship.cer=true
      }

      else if(v[i]=='Flexi Work Hours')
      {this.state.postinternship.fw=true}
      else if(v[i]=='Letter of Recommendation')
      {     this.state.postinternship.lor=true
      }
      else if(v[i]=='Informal Dress Code')
      {    this.state.postinternship.idc=true
      }
      else if(v[i]=='Free Snacks & Beverages')
      {this.state.postinternship.fsb=true
    }}
      this.setState({btnstatus:'Edit'})
    }
      else{

        let employer=JSON.parse(localStorage.getItem('employer'))
        this.state.personal_details.first_name=employer.first_name
        this.state.personal_details.last_name=employer.last_name
        this.state.personal_details.email=employer.email
       this.state.personal_details.mobile=employer.mobile
        this.setState({btnstatus:'Submit'})
  }



}
     catch(e){
   console.log('error',e)
   alert(e)

     }


   }

   CircularIndeterminate=()=>{
  return (
    <div>
      <CircularProgress className={this.props.classes.progress} color="secondary" />
    </div>
  );
}
saveData(states,form){

}
getStepContent(step) {
  switch (step) {
    case 0:

      return <PersonalDetails states={this.state.personal_details}  />;

    case 1:

      return <OrganizationDetails states={this.state.organization_details}  />;


    case 2:
      return <PostInternship states={this.state.postinternship} />;

    default:
      throw new Error('Unknown step');
  }
}


validPD=()=>{
  var phoneno = /^[0-9]{10}/;

  if(this.state.personal_details.mobile.length==0)
  {
    alert("Mobile Number Should Not Be Blank....")
    return(false)
  }
  else{
    if(!this.state.personal_details.mobile.match(phoneno))
    {
      alert("Invalid Mobile Number Must Be of 10 Digit Only....")
      return(false)
  }
    }
    return(true)
  }


  validOD=()=>{

     let size=parseFloat(this.state.organization_details.file.size)/1024.0
     if(size>=500)
     {
      alert("Size of Logo Should not be more then 500KB")
      return(false)
     }
    if(this.state.organization_details.organizationname.length==0)
    {
      alert("Organization name should not be blank....")
      return(false)
    }

    if(this.state.organization_details.organizationdescription.length==0)
    {alert("Organization Description should not be blank....")
    return(false)}

    if(this.state.organization_details.icon.length==0)
    {alert("Please upload organization logo....")
    return(false)}
    if(this.state.organization_details.websiteUrl.length==0)
    {alert("Website Url Should Not be Blank....")
    return(false)}
   return true
  }
  validPI=()=>{
    if(this.state.postinternship.primaryprofile.length==0)
    {
      alert("Please Choose Primary Profile....")
      return(false)
    }
    if(this.state.postinternship.typeinternship.length==0)
    {
      alert("Please Choose Type of Internship....")
      return(false)
    }
    if(this.state.postinternship.internlocation.length==0)
    {
      alert("Please Input Intern Location....")
      return(false)
    }

    if(this.state.postinternship.isparttime.length==0)
    {
      alert("Please Choose Inter is Parttime or Regular....")
      return(false)
    }

    if(this.state.postinternship.noofopenings.length==0)
    {
      alert("Number of Opening should Not be blank....")
      return(false)
    }

    if(this.state.postinternship.roles.length==0)
    {
      alert("Please Input Roles and Responsibilities of Intern....")
      return(false)
    }

    if(this.state.postinternship.stipendperks.length==0)
    {
      alert("Please Choose Stipned Type....")
      return(false)
    }
    if(this.state.postinternship.salary.length==0)
    {
      alert("Please Input Stipend Amount for Intern....")
      return(false)
    }
    if(this.state.postinternship.preplacement.length==0)
    {
      alert("Please Choose Weather You Offer Pre-Placement  for  an Intern....")
      return(false)
    }

    return true
  }


submit_employer=async()=>{

  if(this.state.btnstatus=='Submit')
  {
    alert(this.state.postinternship.startdate)

  try{


  const body={
    email:this.state.personal_details.email,
    mobile:this.state.personal_details.mobile,
    organizationname:this.state.organization_details.organizationname,
    organizationdescription:this.state.organization_details.organizationdescription,
    icon:this.state.organization_details.icon,
    websiteUrl:this.state.organization_details.websiteUrl,
    facebookUrl:this.state.organization_details.facebookUrl,
    instagramUrl:this.state.organization_details.instagramUrl,
    linkedinUrl:this.state.organization_details.linkedinUrl,
    youtubeUrl:this.state.organization_details.youtubeUrl,
    icon: this.state.organization_details.file,
    primaryprofile:this.state.postinternship.primaryprofile,
    typeinternship:this.state.postinternship.typeinternship,
    internlocation:this.state.postinternship.internlocation,
    isparttime:this.state.postinternship.isparttime,
    noofopenings:this.state.postinternship.noofopenings,
    startdate:this.state.postinternship.startdate,
    internshipduration:this.state.postinternship.internshipduration,
    options:this.state.postinternship.options,
    stipendperks:this.state.postinternship.stipendperks,
    currency:this.state.postinternship.currency,
    salary:this.state.postinternship.salary,
    duration:this.state.postinternship.duration,
    perks:this.state.postinternship.perks.toString().replace("'",""),
    preplacement:this.state.postinternship.preplacement,
    skill:this.state.postinternship.skill,
    question:this.state.postinternship.question,
    labelWidth:0,
    roles:this.state.postinternship.roles,
    dw:this.state.postinternship.dw,
    cer:this.state.postinternship.cer,
    fw:this.state.postinternship.fw,
    lor:this.state.postinternship.lor,
    idc:this.state.postinternship.idc,
    fsb:this.state.postinternship.fsb,
    applyby:this.state.postinternship.applyby


  }

  const formData = new FormData();

    formData.append('body',JSON.stringify(body));
    formData.append('icon',this.state.organization_details.file);

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    };





    const result = await postDataAndImage("internship/addnewrecord", formData,config);

   alert(result);
    if(result) {
      this.setState({msg:'Record Submited....',organization:'',icon:''});

    } else {
      this.setState({msg:'Fail to Submit Record....'});
      alert('Server Error\nTry Again');
    }
  } catch (e) {
    console.log("Internship:", e);
  }

}
  else{

    try{
       console.log(this.state.organization_details.file)

      const body={
        email:this.state.personal_details.email,
        mobile:this.state.personal_details.mobile,
        organizationname:this.state.organization_details.organizationname,
        organizationdescription:this.state.organization_details.organizationdescription,
        icon:this.state.organization_details.icon,
        websiteUrl:this.state.organization_details.websiteUrl,
        facebookUrl:this.state.organization_details.facebookUrl,
        instagramUrl:this.state.organization_details.instagramUrl,
        linkedinUrl:this.state.organization_details.linkedinUrl,
        youtubeUrl:this.state.organization_details.youtubeUrl,
        icon: this.state.organization_details.file,
        primaryprofile:this.state.postinternship.primaryprofile,
        typeinternship:this.state.postinternship.typeinternship,
        internlocation:this.state.postinternship.internlocation,
        isparttime:this.state.postinternship.isparttime,
        noofopenings:this.state.postinternship.noofopenings,
        startdate:this.state.postinternship.startdate,
        internshipduration:this.state.postinternship.internshipduration,
        options:this.state.postinternship.options,
        stipendperks:this.state.postinternship.stipendperks,
        currency:this.state.postinternship.currency,
        salary:this.state.postinternship.salary,
        duration:this.state.postinternship.duration,
        perks:this.state.postinternship.perks.toString().replace("'",""),
        preplacement:this.state.postinternship.preplacement,
        skill:this.state.postinternship.skill,
        question:this.state.postinternship.question,
        labelWidth:0,
        roles:this.state.postinternship.roles,
        dw:this.state.postinternship.dw,
        cer:this.state.postinternship.cer,
        fw:this.state.postinternship.fw,
        lor:this.state.postinternship.lor,
        idc:this.state.postinternship.idc,
        fsb:this.state.postinternship.fsb,
        idemployer:this.state.idemployer,
        applyby:this.state.postinternship.applyby


      }

      const formData = new FormData();

        formData.append('body',JSON.stringify(body));
        formData.append('icon',this.state.organization_details.file);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };





        const result = await postDataAndImage("internship/editrecord", formData,config);

       alert(result);
        if(result) {
          this.setState({msg:'Record Submited....',organization:'',icon:''});

        } else {
          this.setState({msg:'Fail to Submit Record....'});
          alert('Server Error\nTry Again');
        }
      } catch (e) {
        console.log("Internship:", e);
      }

  }
}



  handleNext =async () => {
    if(this.state.activeStep==0 && this.validPD())
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }))
    else  if(this.state.activeStep==1 && this.validOD())
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }))

  if(this.state.activeStep===steps.length-1){console.log("step");
  }
   if(this.state.activeStep==2 &&  this.validPI())
   {await  this.submit_employer()}

  };






  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));

  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };


ShowCheckOut=(classes)=>{
  const { activeStep } = this.state;
return(
  <React.Fragment>
  <CssBaseline />
  <main className={classes.layout}>
    <Paper className={classes.paper}>
      <Typography component="h1" variant="h4" align="center">
        Employer Intern Recruitment Request
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
        {activeStep === steps.length ?
        <React.Fragment>
          <Typography variant="h5" gutterBottom>
            Thank you for your order.
          </Typography>
          <Typography variant="subtitle1">
            Your order Success. We have emailed your order confirmation, and will
            send you an update when your order has shipped.
          </Typography>
        </React.Fragment>
        : (
          <React.Fragment>
            {this.getStepContent(activeStep)}
            <div className={classes.buttons}>
              {activeStep !== 0 && (
                <Button onClick={this.handleBack} className={classes.button}>
                  Back
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? `${this.state.btnstatus}` : 'Next'}
              </Button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    </Paper>
  </main>
</React.Fragment>

);

}

  render() {
    const { classes } = this.props;


    return (<div>
     { this.ShowCheckOut(classes)
          }

     </div>
    );
  }
}

Checkout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Checkout);
