import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Avatar from "@material-ui/core/Avatar";
import { postDataAndImage, postData, BaseURL } from "../../../FetchService";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "center",
    alignContent: "center",
  },
  paper: { padding: "30px", marginTop: "10px" },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
}));
export default function UpdateCourse(props) {
  const classes = useStyles();

  const [getCourseId, setCourseId] = React.useState("");
  const [CourseName, setCourseName] = React.useState("");
  const [price, setprice] = useState(0);
  const [CourseDescription, setCourseDescription] = React.useState("");
  const [CourseIcon, setCourseIcon] = React.useState({ icon: "", file: "" });
  const [oldCourseIcon, setOldCourseIcon] = React.useState("");
  const [message, setMessage] = React.useState("");

  useEffect(() => {
    fillValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fillValues = async () => {
    try {
      var body = {
        courseId: props.location.state.id,
      };
      console.log("body data", body);

      const result = await postData("course/fetchdataById", body);
      console.log("result", result[0]);

      setCourseId(result[0].courseid);
      setCourseName(result[0].coursename);
      setprice(result[0].price);
      setCourseDescription(result[0].coursedescription);
      setOldCourseIcon(result[0].courseicon);
      // setCourseIcon({ icon: result[0].courseicon });
    } catch (error) {}
  };

  const handleEdit = async () => {
    if (CourseIcon.file !== "") {
      let formData = new FormData();

      formData.append("courseId", getCourseId);
      formData.append("courseName", CourseName);
      formData.append("price", price);
      formData.append("courseDescription", CourseDescription);
      formData.append("oldCourseIcon", oldCourseIcon);
      formData.append("courseIcon", CourseIcon.file);

      const config = { headers: { "content-type": "multipart/form-data" } };
      const result = await postDataAndImage(
        "course/updateCourse",
        formData,
        config
      );

      if (result) {
        setMessage("Record Updated...");
        setTimeout(function () {
          clearValues();
          props.history.replace({ pathname: "/DisplayAllCourse" });
        }, 3000);
      } else {
        setMessage("Fail to Update Record...");
      }
    } else {
      let body = {
        courseId: getCourseId,
        courseName: CourseName,
        courseDescription: CourseDescription,
        price: price,
      };

      const result = await postData("course/updateCoursewithoutimage", body);

      if (result) {
        setMessage("Record Updated...");
        setTimeout(function () {
          clearValues();
          props.history.replace({ pathname: "/DisplayAllCourse" });
        }, 3000);
      } else {
        setMessage("Fail to Update Record...");
      }
    }
  };

  const handleDelete = async () => {
    var body = {
      courseId: props.location.state.id,
    };
    let result = await postData("course/deleteCourse", body);
    console.log("delete result", result);

    if (result) {
      setMessage("Record Deleted...");
      setTimeout(function () {
        clearValues();
        props.history.replace({ pathname: "/DisplayAllCourse" });
      }, 3000);
    } else {
      setMessage("Fail to Delete Record...");
    }
  };
  const clearValues = () => {
    setCourseName("");
    setCourseDescription("");
    setCourseIcon("");
    setprice(0);
    setMessage("");
  };
  return (
    <Grid container maxWidth="xs" className={classes.root}>
      <Paper className={classes.paper}>
        <Typography>Course Delete/Update</Typography>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              id="outlined-dense"
              label="Course Name"
              className={clsx(classes.textField, classes.dense)}
              margin="dense"
              value={CourseName}
              variant="outlined"
              onChange={(event) => setCourseName(event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={clsx(classes.textField, classes.dense)}
              id="outlined-multiline-static"
              label="Description"
              value={CourseDescription}
              multiline
              rows="4"
              variant="outlined"
              onChange={(event) => setCourseDescription(event.target.value)}
              fullWidth
            />
          </Grid>

		  <Grid item xs={12}>
            <TextField
              id="outlined-dense"
              label="Course Price"
              className={clsx(classes.textField, classes.dense)}
              margin="dense"
              value={price}
              variant="outlined"
              onChange={(event) => setprice(event.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <input
              accept="image/*"
              className={classes.input}
              id="contained-button-file"
              multiple
              type="file"
              onChange={(event) =>
                setCourseIcon({
                  icon: URL.createObjectURL(event.target.files[0]),
                  file: event.target.files[0],
                })
              }
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                className={classes.button}
              >
                Upload
                <CloudUploadIcon className={classes.rightIcon} />
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Avatar
              alt="Image"
              src={
                CourseIcon.icon
                  ? CourseIcon.icon
                  : `${BaseURL}/images/${oldCourseIcon}`
              }
              className={classes.bigAvatar}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              onClick={handleEdit}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Edit
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleDelete}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
        <Typography>{message}</Typography>
      </Paper>
    </Grid>
  );
}
